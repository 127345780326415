<template>
	<div class="mr-3">
		<table>
			<thead>
				<tr>
					<th v-for="(month, propertyName) in dateAxis" :key="propertyName" :colspan="month.length" class="text-center">
						<span class="font-secondary brand-medium">{{
							DateTime.fromISO(propertyName).toLocaleString({ month: 'long', year: 'numeric' })
						}}</span>
					</th>
				</tr>
				<tr id="gantt-days">
					<template v-for="month in dateAxis">
						<th
							:data-id="date.toISODate()"
							v-for="date in month"
							:key="date.toISODate()"
							:id="`gantt-column-${date.toISODate()}`"
							style="min-width: 35px; max-width: 35px; position: relative;" class="text-center"
						>
							<div :class="(date.toISODate() === DateTime.now().toISODate() ? 'today' : '') + ' row-format centered'" style="width: 24px; height: 24px">
								<div>{{ date.toFormat('d') }}</div>
							</div>
							<div class="today-bar" v-if="date.toISODate() === DateTime.now().toISODate()"></div>
						</th>
					</template>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in calculatedRows" :key="row.id" style="min-height: 60px" @dragover="handleDragOver">
					<td
						v-for="block in row.blocks"
						:key="block.id"
						:colspan="block.days"
						style="max-width: 35px; border-left: none; border-right: none; min-height: 30px;"
					>

						<vue-resizable
							:data-id="row.id"
							v-if="block.type === 'deliverable'"
							width="auto"
							:max-height="42"
							:min-height="42"
							@resize:end="handleEndResize"
						>
							<div :data-id="row.id"
								class="row-format align-center deliverable gantt-deliverable pl-2 pointer" style="height: 100%; gap: 12px"
								@click.stop="$emit('edit-deliverable', row)"
								draggable="true"
								@dragstart="handleDragStart"
								@dragend="handleDragEnd"
								@drop="handleDrop"
								@dragenter="handleDragEnter"
							>
								<client-avatar v-if="block.days > 3 && row.client" :client="row.client"></client-avatar>
								<div class="text-left" style="white-space: nowrap">
									<div class="font-14 brand-medium">{{ row.name }}</div>
									<div class="font-12" v-if="row.project">{{ row.project.name }}</div>
								</div>
								<v-icon v-if="row.archived"  size="20" color="gray_70" v-tippy="{content:'Archived'}">lock</v-icon>

								<div v-if="row.assignedToList.length" class="row-format align-center">
									<assigned-user
											v-for="(assignedTo, index) in row.assignedToList"
											:key="assignedTo"
											:show-name="false"
											:small="true"
											:assigned-to="assignedTo"
											:style="
													`${
														index > 0 ? 'margin-left: -6px' : ''
													}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
												"
									></assigned-user>
								</div>
								<div class="row-format align-center" v-if="row.tasks.length">
									<v-icon size="16" class="mr-1">check_circle_outline</v-icon>
									<div class="font-gray_80 font-12">{{getTaskCountLabel(row.tasks)}}</div>
								</div>
								<div class="deliverableStatus row-format align-center" :style="`--status-color:${row.status.hexColor}`">
									<div class="statusBox"></div>
									<div>{{row.status.label}}</div>
								</div>
							</div>
						</vue-resizable>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';
	import { v4 as uuid4 } from 'uuid';
	import VueResizable from 'vue-resizable';
	import ClientAvatar from '@/components/ClientAvatar';
	import AssignedUser from "@/components/AssignedUser";

	export default {
		name: 'GanttChart',

		props: ['startDate', 'endDate', 'deliverables','visible'],

		components: { VueResizable, ClientAvatar, AssignedUser },

		data: function () {
			return {
				DateTime: DateTime,
				xAdjust: 0
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			scrollTodayIntoView: function(){
				let column = document.getElementById('gantt-column-' + DateTime.now().toISODate());
				column.scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"});
			},

			getWidth: function() {
				let max = Math.max(
						document.body.scrollWidth,
						document.documentElement.scrollWidth,
						document.body.offsetWidth,
						document.documentElement.offsetWidth,
						document.documentElement.clientWidth
				);

				if(this.$store.state.leftNavExpanded){
					max = max - 300;
				}else{
					max = max - 60;
				}
				return max;
			},

			handleDragStart: function (e) {
				e.target.style.opacity = '0.4';
				e.dataTransfer.effectAllowed = 'move';
				this.xAdjust =  e.clientX - e.target.getBoundingClientRect().x;
			},

			handleDragEnd: function (e) {
				e.target.style.opacity = '1';

				let deliverable = this.deliverables.find(d => d.id === e.target.getAttribute('data-id'));
				let daySpan = Math.ceil(deliverable.end.diff(deliverable.start, ['days']).days);
				let startDate = DateTime.fromISO(this.findDateFromCoordinate(e.clientX - this.xAdjust)).startOf('day');
				let endDate = startDate.plus({days:daySpan});

				this.$emit('dates-updated', {
					id: deliverable.id,
					startDate: startDate.toISODate(),
					dueDate: endDate.toISODate(),
				});
			},

			handleDragEnter: function(e){
				e.preventDefault();
				return false;
			},

			handleDragOver: function (e) {
				e.preventDefault();
				return false;
			},

			handleDrop: function (event) {
				event.stopPropagation(); // stops the browser from redirecting.
				return false;
			},

			handleEndResize: function (event) {
				let bounds = event.cmp.$el.getBoundingClientRect();
				let startDate = this.findDateFromCoordinate(bounds.left);
				let dueDate = this.findDateFromCoordinate(bounds.left + bounds.width);
				let id = event.cmp.$el.getAttribute('data-id');
				this.$emit('dates-updated', {
					id: id,
					startDate: startDate,
					dueDate: dueDate,
				});
			},

			findDateFromCoordinate: function (coordinate) {
				let coordinates = this.loadCoordinates();
				let found = coordinates.find((c) => c.start <= coordinate && c.end > coordinate);
				if (found) {
					return found.date;
				} else {
					return null;
				}
			},

			loadCoordinates: function () {
				let coordinates = [];
				document.getElementById('gantt-days').childNodes.forEach((n) => {
					let dayBound = n.getBoundingClientRect();
					let dayStart = dayBound.left;
					let dayEnd = dayBound.left + dayBound.width;
					let coordinate = {
						start: dayStart,
						end: dayEnd,
						date: n.getAttribute('data-id'),
					};
					coordinates.push(coordinate);
				});
				return coordinates;
			},

			groupBy: function (array, key) {
				return array.reduce((result, currentValue) => {
					(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
					return result;
				}, {});
			},

			calculateBlocks: function (row) {
				let diffFromStart = row.start.diff(this.startDate, ['days']).days;
				let diffFromEnd = this.endDate.diff(row.end, ['days']).days;
				let daySpan = Math.ceil(row.end.diff(row.start, ['days']).days);

				let blocks = [];

				if (diffFromStart > 0) {
					blocks.push({ id: uuid4(), type: 'blank', days: diffFromStart });
				}

				blocks.push({ id: uuid4(), type: 'deliverable', days: daySpan });

				if (diffFromEnd > 0) {
					blocks.push({ id: uuid4(), type: 'blank', days: diffFromEnd });
				}

				return blocks;
			},

			getTaskCountLabel: function (tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					tasks.forEach((t) => (t.complete ? completedCount++ : null));

					return completedCount + '/' + taskCount;
				}
			},
		},

		watch: {
			visible: {
				immediate: true,
				handler(val){
					if(val){
						this.$nextTick(() => this.scrollTodayIntoView());
					}
				}
			}
		},

		computed: {
			calculatedRows: function () {
				let result = [...this.deliverables];
				for (let i = 0; i < result.length; i++) {
					result[i].blocks = this.calculateBlocks(result[i]);
				}
				return result;
			},

			dateAxis: function () {
				let days = [];
				let now = this.startDate;

				while (now < this.endDate) {
					let element = {
						date: now,
						month: now.startOf('month').toISODate(),
					};
					days.push(element);
					now = now.plus({ days: 1 });
				}

				let grouped = this.groupBy(days, 'month');

				for (let key in grouped) {
					grouped[key] = grouped[key].map((e) => e.date);
				}

				return grouped;
			},
		},
	};
</script>

<style scoped lang="scss">
	table,
	th,
	td {
		border: 1px solid var(--v-gray_30-base);
		border-collapse: collapse;
		padding: 4px 4px;
		color: var(--v-secondary-base);
		font-size: 14px;
	}

	.deliverable {
		background-color: var(--v-gray_30-base);
		color: var(--v-gray_90-base);
		border-radius: 4px;
	}

	.today {
		background-color: var(--v-primary-base);
		color: var(--v-white-base);
		border-radius: 40px;
	}

	.today-bar {
		position: absolute;
		height: calc(var(--vh) - 160px);
		width: 1px;
		background-color: var(--v-blue_30-base);
		top: 32px;
		left: 16px;
	}

	.deliverableStatus {
		font-size: 12px;
		padding:2px 8px;
		box-sizing: border-box;
		border-radius: 4px;
		white-space: nowrap;
		border: 1px solid var(--v-gray_50-base);
		background-color: var(--v-white-base);

		.statusBox {
			margin-right: 8px;
			width:10px;
			height:10px;
			border-radius: 2px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}
</style>
