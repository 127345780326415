<template>
	<div>
		<div class="row-format">
			<v-menu :close-on-content-click="true" :close-on-click="true">
				<template v-slot:activator="{ on }">
					<v-btn class="secondary-action ml-auto mr-2" v-on="on">+ Add</v-btn>
				</template>
				<div class="more-menu">
					<div
						class="more-menu-item row-format align-center gap-1"
						v-for="add in addItems"
						:key="add.value"
						@click="addItem(add.value)"
					>
						<v-icon size="20" color="secondary" className="material-symbols-rounded">{{ add.icon }}</v-icon>
						<div>{{ add.value }}</div>
					</div>
				</div>
			</v-menu>
		</div>
		<div class="column-format gap-2 mt-4" v-if="items.length" :key="refreshKey">
			<div
				class="row-format workflow gap-2 py-2 align-center"
				v-for="item in items"
				:key="item.id"
				style="border-bottom: 1px solid var(--v-gray_30-base)"
			>
				<component
					class="flex-grow-1"
					:is="item.itemType"
					:item="item"
					:opportunity="opportunity"
					@deleted="deleteItem(item)"
					@refresh="refreshViews"
					@actual-time="setActualTime(item,$event)"
				></component>
				<v-icon small color="secondary" class="ml-auto material-symbols-rounded pointer unlink" @click="unlink(item)"
					>link_off</v-icon
				>
			</div>
		</div>
		<div v-if="!items.length" class="font-12 font-gray_70" style="text-align: center">No activity found</div>

		<basic-modal v-if="formsDialog" :dialog="formsDialog" @close="formsDialog = false" :persistent="false">
			<meetings-and-forms :opportunity="opportunity" :type="meetingOrForm" @close="formsDialog = false"></meetings-and-forms>
		</basic-modal>
	</div>
</template>

<script>
	import ScheduledMeeting from '@/modules/pipeline/opportunity/workflow/ScheduledMeeting';
	import Proposal from '@/modules/pipeline/opportunity/workflow/Proposal';
	import Project from '@/modules/pipeline/opportunity/workflow/Project';
	import Form from '@/modules/pipeline/opportunity/workflow/Form';
	import Call from '@/modules/pipeline/opportunity/workflow/Call';
	import Request from '@/modules/pipeline/opportunity/workflow/Request';
	import MessageThread from '@/modules/pipeline/opportunity/workflow/MessageThread';
	import Invoice from '@/modules/pipeline/opportunity/workflow/Invoice';
	import EmailThread from '@/modules/pipeline/opportunity/workflow/EmailThread';
	import ConfirmModal from '@/components/ConfirmModal';
	import ProposalMixin from '@/modules/pipeline/opportunity/workflow/mixins/ProposalMixin';
	import InvoiceMixin from '@/modules/pipeline/opportunity/workflow/mixins/InvoiceMixin';
	import HDateTime from '@/modules/utils/HDateTime';
	import ProjectBilling from '@/modules/pipeline/opportunity/workflow/ProjectBilling';
	import ProjectMixin from '@/modules/pipeline/opportunity/workflow/mixins/ProjectMixin';
	import MeetingsAndForms from "@/modules/pipeline/opportunity/MeetingsAndForms";
	import BasicModal from "@/components/BasicModal";
	import Agreement from "@/modules/pipeline/opportunity/workflow/Agreement";
	import TimerEvent from "@/modules/pipeline/opportunity/workflow/TimerEvent";
	import CalendarEvent from "@/modules/pipeline/opportunity/workflow/CalendarEvent";

	export default {
		name: 'Workflow',

		props: ['opportunity'],

		mixins: [ProposalMixin, InvoiceMixin, ProjectMixin],

		components: {
			MeetingsAndForms,
			Call,
			EmailThread,
			Form,
			Invoice,
			MessageThread,
			Project,
			Proposal,
			Request,
			ScheduledMeeting,
			ProjectBilling,
			BasicModal,
			Agreement,
			TimerEvent,
			CalendarEvent
		},

		data: function() {
			return {
				refreshKey: 0,
				formsDialog: null,
				meetingOrForm: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openMeetingsAndForms: function(type){
				this.formsDialog = true;
				this.meetingOrForm = type;
			},

			unlink: function(item) {
				let binding = {
					bodyText: 'Are you sure you want to unlink this activity from the opportunity?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.opportunity.workflow.findIndex((w) => w.id === item.id);
						if (ix > -1) {
							this.opportunity.workflow.splice(ix, 1);
							this.$emit('save');
						}
					}
				});
			},

			refreshViews: function() {
				this.refreshKey++;
			},

			setActualTime: function(item,event){
				let dateTime = HDateTime.fromISO(event).toISO();
				item.timestamp = dateTime;
				let ix = this.items.findIndex(i => i.id === item.id);
				this.items.splice(ix,1,item);
			},

			addItem: function(type) {
				if (type === 'Form request') {
					this.newForm();
				} else if (type === 'Meeting') {
					this.newMeeting();
				} else if (type === 'Agreement') {
					this.newAgreement();
				} else if (type === 'Invoice') {
					this.newInvoice();
				} else if (type === 'Project') {
					this.newProject();
				}else if(type === 'Email'){
					if(this.opportunity.formData) {
						this.$emit('send-email', this.opportunity.formData.email);
					}else{
						this.$emit('send-email');
					}
				}
			},

			newForm: function() {
				this.openMeetingsAndForms('Form');
			},

			newMeeting: function() {
				this.openMeetingsAndForms('Meeting');
			},

			newAgreement: function() {
				if (this.checkClient()) {
					this.addNewProposal(this.opportunity);
				}
			},

			newInvoice: function() {
				if (this.checkClient()) {
					this.addNewInvoice(this.opportunity);
				}
			},

			newProject: function() {
				if (this.checkClient()) {
					this.addNewProject(this.opportunity);
				}
			},

			deleteItem: function(item) {
				let ix = this.opportunity.workflow.findIndex((w) => w.id === item.id);
				if (ix > -1) {
					this.opportunity.workflow.splice(ix, 1);
					this.$emit('save');
				}
			},

			checkClient: function() {
				if (!this.opportunity.clientId) {
					let binding = {
						headingText: 'Client or prospect required',
						bodyText:
							'This opportunity must be connected with a client or prospect before you can perform this action.',
						yesText: 'OK',
						hideNo: true,
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding);
					return false;
				} else {
					return true;
				}
			},
		},

		computed: {
			addItems: function() {
				let result = [];
				result.push({ icon: 'email', value: 'Email' });
				result.push({ icon: 'fact_check', value: 'Form request' });
				result.push({ icon: 'event', value: 'Meeting' });
				result.push({ icon: 'handshake', value: 'Agreement' });
				result.push({ icon: 'request_quote', value: 'Invoice' });
				result.push({ icon: 'folder_special', value: 'Project' });
				return result;
			},

			items: function() {
				let items = [...this.opportunity.workflow];
				items.sort((a, b) => {
					let aDate = HDateTime.fromISO(a.timestamp);
					let bDate = HDateTime.fromISO(b.timestamp);
					return bDate < aDate ? -1 : bDate > aDate ? 1 : 0;
				});
				return items;
			},
		},
	};
</script>

<style scoped lang="scss">
	.workflow {
		.unlink {
			display: none;
		}
		&:hover {
			.unlink {
				display: block;
			}
		}
	}
</style>
