import Communicator from "@/modules/communicator/Communicator";
import CommunicatorSettings from "@/modules/communicator/CommunicatorSettings";
import CommunicatorRouter from "@/modules/communicator/CommunicatorRouter";
import TicketView from "@/modules/communicator/inbox/TicketView";
import EmailLogs from "@/modules/communicator/emailLog/EmailLogs";

export default [
	{
		path: '/inbox',
		name: 'inbox',
		component: CommunicatorRouter,
		props: true,
	},
	{
		path: '/inbox/messages',
		name: 'inbox',
		component: Communicator,
		props: true,
	},
	{
		path: '/inbox/tickets',
		name: 'inbox',
		component: TicketView,
		props: true
	},
	{
		path: '/tickets',
		name: 'inbox',
		component: TicketView,
		props: true
	},
	{
		path: '/inbox/email-log',
		name: 'sent',
		component: EmailLogs,
		props: {
			recordType: 'SENT_LOG'
		},
	},
	{
		path: '/inbox/scheduled-emails',
		name: 'scheduled',
		component: EmailLogs,
		props: {
			recordType: 'SCHEDULED'
		},
	},
	{
		path: '/communicator/:page',
		name: 'communicator',
		component: CommunicatorSettings,
		props: (route) => ({
			page: route.params.page,
		})
	},
];
