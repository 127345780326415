<template>
	<div class="send-widget">
		<v-btn icon class="close-dialog" @click="cancel">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">{{ $t('invoice.details.send-widget.title') }}</h2>
			<div class="modal-subtitle" v-if="invoice">
				{{ $t('invoice.invoice-number-appended', { invoiceNumber: invoice.invoiceNumberFormatted }) }}
			</div>

			<div class="client-name" v-if="client">
				<client-avatar :client="client" left></client-avatar>
				<div>{{ client.name }}</div>
			</div>

			<div class="rowFormat">
				<span class="emailHeader">{{ $t('global.email-from') }}: </span>
				<span>{{ $store.getters.getFromEmail }}</span>
			</div>

			<div class="rowFormat d-flex justify-space-between align-center">
				<div class="row-format" style="flex-wrap: wrap">
					<div class="" @click="showToMenu = true">
						<span class="emailHeader">{{ $t('global.email-to') }}:</span>
						<span v-for="contact in sendToContacts" class="pointer" :key="contact.email">
							{{ formatEmail(contact) }}
						</span>
					</div>
				</div>
				<div style="flex: 0 0 28px;">
					<v-menu v-model="showToMenu" :close-on-content-click="false" min-width="500" nudge-left="476" nudge-top="12">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon small rounded v-bind="attrs" v-on="on">
								<v-icon small>$chevronDown</v-icon>
							</v-btn>
						</template>
						<div style="background-color: var(--v-white-base);" v-if="client">
							<v-container v-if="toEmailMode === 'selectContact'" fluid>
								<v-row class="py-3 px-3">
									<v-col cols="10" class="" align="left">
										<div v-for="contact in client.contacts" :key="contact.email" class="row-format">
											<v-simple-checkbox
												:value="sendToContacts.findIndex((v) => v.email === contact.email) > -1"
												@click="handleContactChecked(contact)"
												class="float-left mr-2"
											/>
											<span>{{ formatEmail(contact) }}</span>
										</div>
										<v-btn class="mt-4 primary-action" @click="toEmailMode = 'createContact'"
											><v-icon small left class="mr-2">$plus</v-icon> Add new contact</v-btn
										>
									</v-col>
									<v-col cols="2" align="right">
										<v-btn icon rounded @click="closeToMenu()">
											<v-icon small>$chevronUp</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
							<div v-if="toEmailMode === 'createContact'">
								<v-btn icon rounded @click="closeToMenu()" class="close-dialog">
									<v-icon small>$chevronUp</v-icon>
								</v-btn>

								<div class="modal-padding">
									<div class="modal-title">Create new contact</div>
									<div class="d-flex justify-space-between align-center">
										<div style="flex: 0 0 50%" class="pr-1">
											<v-text-field
												class="h-outline"
												persistent-placeholder
												hide-details
												v-model="newContact.firstName"
												label="First name"
												autocomplete="new-password"
											></v-text-field>
										</div>
										<div style="flex: 0 0 50%" class="pl-1">
											<v-text-field
												class="h-outline"
												persistent-placeholder
												hide-details
												v-model="newContact.lastName"
												label="Last name"
												autocomplete="new-password"
											></v-text-field>
										</div>
									</div>
									<v-text-field
										class="h-outline"
										persistent-placeholder
										hide-details
										v-model="newContact.email"
										label="Email"
										autocomplete="new-password"
									></v-text-field>
								</div>

								<div class="modal-footer">
									<v-btn
										class="secondary-action mr-1"
										width="150"
										@click="cancelCreateNewContact()"
										:disabled="processingCreate"
										>Cancel</v-btn
									>
									<v-btn
										class="primary-action ml-1"
										width="150"
										@click="createNewContact()"
										:disabled="processingCreate"
										>Save</v-btn
									>
								</div>
							</div>
						</div>
					</v-menu>
				</div>
			</div>
			<div class="mb-3 mt-6">
				<email-contact-selector :to-list="[]" label="BCC" :suppress-contacts="true" :underlined="true" @update="bccUpdated($event)"></email-contact-selector>
			</div>
			<div class="rowFormat">
				<div style="display: flex; flex-direction: row;">
					<span class="emailHeader">{{ $t('global.email-subject') }}: </span>
					<input v-model="subject" type="text" style="outline: none; width: 100%; color: var(--v-black-base)" />
				</div>
			</div>
			<div>
				<div>
					<editor
						v-if="body !== null"
						ref="bodyEditor"
						:initial-value="body"
						:init="mceConfigText"
						:spellcheck="true"
						@input="handleBodyInput"
					></editor>
				</div>
				<div v-if="s3Attachments.length" class="row-format mt-2" style="flex-wrap: wrap; gap: 8px">
					<v-chip color="blue_10" small v-for="(s3,index) in s3Attachments" :key="s3.fileName + index" close close-icon="cancel" @click:close="removeS3Attachment(s3)">{{s3.fileName}}</v-chip>
				</div>
			</div>
		</div>
		<div class="modal-footer row-format centered">
			<v-btn class="secondary-action mx-2" width="120" @click="cancel()" :disabled="disabled">Cancel</v-btn>
			<send-email-button :width="150" :disabled="disabled || !this.sendToContacts.length" @click="sendInvoice($event)"></send-email-button>
		</div>
	</div>
</template>

<script>
	import ClientService from '@/modules/clients/ClientService';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import ClientAvatar from '@/components/ClientAvatar';
	import EmailTemplateSelector from "@/modules/templates/emails/EmailTemplateSelector";
	import EmailContactSelector from "@/modules/communicator/inbox/email/EmailContactSelector";
	import SendEmailButton from "@/components/SendEmailButton";
	import {DateTime} from 'luxon';

	export default {
		name: 'SendWidget',
		props: {
			clientId: { type: String, required: true },
			invoiceId: { type: String, required: true },
			paymentId: { type: String, required: false },
			status: { type: String, required: false },
			openState: { type: Boolean, required: false, default: true },
		},

		components: {
			SendEmailButton,
			ClientAvatar,
			EmailContactSelector,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				clientService: new ClientService(),
				invoiceService: new InvoiceService(),

				client: null,
				invoice: null,

				disabled: false,
				sendToContacts: [],
				showToMenu: false,
				toEmailMode: 'selectContact',
				s3Attachments: [],

				subject: 'Invoice from - ' + this.$store.getters.getAccountName,
				body: null,
				isTemplate: false,
				bcc: [],

				processingCreate: false,

				newContact: {
					firstName: null,
					lastName: null,
					email: null,
				},

				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '800px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						`undo redo | styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | bullist numlist | link unlink code | ${this.$store.getters.isPaidAccount ? 'template' : ''}`,
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('template', {
							text: 'Insert template',
							onAction: () => {
								this.selectEmailTemplate();
							},
						});
					},
				},
			};
		},

		mounted() {
			this.initialize();
		},

		methods: {
			initialize: function() {
				this.clientService
					.getClientDetail(this.clientId)
					.then((res) => {
						this.client = res.data;
						// Try to set send-to contact
						if (this.invoiceContacts.length) this.sendToContacts = this.invoiceContacts;
						else if (this.defaultContacts.length) this.sendToContacts = this.defaultContacts;
						else if (this.client.contacts.length) this.sendToContacts = [this.client.contacts[0]];
					})
					.catch((err) => {
						console.log(err);
					});


				if(this.status === 'DRAFT'){
					this.invoiceService
							.markAsSent(this.clientId, this.invoiceId)
							.then((res) => {
								this.invoice = res.data;
								this.subject = `Invoice ${this.invoice.invoiceNumberFormatted} from ${this.$store.getters.getAccountName}`;
								this.initBodyContent();
							})
							.catch((err) => {
								console.log(err);
							});
				}else {
					this.invoiceService
							.getInvoice(this.clientId, this.invoiceId)
							.then((res) => {
								this.invoice = res.data;
								this.subject = `Invoice ${this.invoice.invoiceNumberFormatted} from ${this.$store.getters.getAccountName}`;
								this.initBodyContent();
							})
							.catch((err) => {
								console.log(err);
							});
				}
			},

			initBodyContent: function(){
				this.invoiceService.invoiceEmailTemplate(this.clientId,this.invoiceId,this.paymentId).then((res) => {
					if(res.data){
						this.subject = res.data.subject;
						this.body = res.data.htmlContent;
						this.s3Attachments.splice(0,this.s3Attachments.length);
						this.s3Attachments.push(... res.data.attachments);
						this.isTemplate = true;
					}else{
						this.body = this.defaultBodyContent();
					}
				})
			},

			selectEmailTemplate: function(){
				let binding = {
					contacts:this.sendToContacts,
					clientId: this.clientId,
					invoiceId: this.invoiceId,
					paymentId: this.paymentId
				};
				this.$store.state.globalModalController.openModal(EmailTemplateSelector,binding).then((res) => {
					if(res){
						this.subject = res.subject;
						this.$refs.bodyEditor.editor.setContent('');
						this.$refs.bodyEditor.editor.execCommand('mceInsertContent', false, res.htmlContent);
						this.s3Attachments.splice(0,this.s3Attachments.length);
						this.s3Attachments.push(... res.attachments);
						this.isTemplate = true;
					}
				});
			},

			defaultBodyContent: function(){
				return `Hi ${this.client.name} Team,<br>Here is the invoice as we agreed.<br>Thank you!`;
			},

			handleContactChecked(contact) {
				const index = this.sendToContacts.findIndex((v) => v.email == contact.email);
				if (index > -1) return this.sendToContacts.splice(index, 1);
				this.sendToContacts.push(contact);
			},

			closeToMenu: function() {
				this.showToMenu = false;
				this.toEmailMode = 'selectContact';
			},

			handleBodyInput: function() {
				this.body = this.$refs.bodyEditor.editor.getContent();
			},

			formatEmail: function(contact) {
				if (contact) {
					return contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>';
				} else {
					return '';
				}
			},

			cancel: function() {
				this.sendToContacts = [];
				this.disabled = false;
				this.$emit('cancel',this.invoice);
			},

			finalize: function() {
				this.disabled = false;
				this.sendToContacts = [];
				this.$emit('cancel');
			},

			sendInvoice: function(sendLater = null) {
				this.$track.recordServerSide('action', { module: 'send-invoice' });
				this.disabled = true;
				this.$store.commit('startLoading');

				let invoiceSent = {
					contacts: this.sendToContacts,
					bcc: this.bcc,
					subject: this.subject,
					body: this.body,
					template: this.isTemplate,
					s3Attachments: this.s3Attachments,
				};

				this.invoiceService
					.sendInvoice(this.clientId, this.invoiceId, invoiceSent, sendLater)
					.then((res) => {
						if(sendLater){
							this.$store.commit('success', `Email successfully scheduled for ${DateTime.fromISO(sendLater).toLocaleString(DateTime.DATETIME_MED)}`);
						}else{
							this.$store.commit('success', this.$t('invoice.details.email-sent'));
						}
						this.$emit('invoice-sent', res.data);
						this.finalize();
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						this.disabled = false;
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			cancelCreateNewContact: function() {
				this.newContact = {
					firstName: null,
					lastName: null,
					email: null,
				};
				this.toEmailMode = 'selectContact';
			},

			bccUpdated: function (bcc) {
				this.bcc.splice(0, this.bcc.length);
				this.bcc.push(...bcc);
			},

			createNewContact: function() {
				this.processingCreate = true;
				let opList = [];
				let operation = {};

				operation.op = 'add';
				operation.path = '/contacts/-';
				operation.value = this.newContact;

				opList.push(operation);

				this.clientService
					.patchClient(this.client.id, opList)
					.then((res) => {
						this.client.contacts = res.data.contacts;

						for (let i = 0; i < this.client.contacts.length; i++) {
							if (this.client.contacts[i].email === this.newContact.email) {
								this.sendToContacts.push(this.client.contacts[i]);
								break;
							}
						}
						this.showToMenu = false;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.processingCreate = false;
						this.cancelCreateNewContact();
					});
			},

			alert: function(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},

			removeS3Attachment: function(s3File){
				let ix = this.s3Attachments.findIndex(s => s.fileName === s3File.fileName);
				if(ix > -1){
					this.s3Attachments.splice(ix,1);
				}
			}
		},

		computed: {
			isPaidAccount(){
				return this.$store.getters.isPaidAccount;
			},

			invoiceContacts() {
				return this.client.contacts.filter((v) => v.invoiceContact == true);
			},
			defaultContacts() {
				return this.client.contacts.filter((v) => v.defaultContact == true);
			},
		},
		watch: {
			openState: function() {
				this.initialize();
			},
		},
	};
</script>


<style scoped lang="scss">
	.send-widget {
		background-color: var(--v-white-base);
		width: 850px;
		max-width: 850px;
	}
	.emailPill {
		background-color: #c9def7;
		border-radius: 8px;
		color: #002b52;
	}

	.rowFormat {
		text-align: left;
		padding: 12px 0 !important;
		&:not(:last-child) {
			border-bottom: 1px solid var(--v-gray_50-base);
		}
	}

	.client-name {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;

		div {
			align-self: center;
			white-space: nowrap;
			flex: 0 0 auto;
		}
	}

	.linkBox {
		width: 500px;
		border-radius: 8px;
		background-color: var(--v-gray_30-base);
		padding: 15px;
	}

	.v-menu__content {
		border-radius: 0px;
		border: 1px solid var(--v-gray_30-base);
	}

	.emailHeader {
		margin-right: 10px;
		color: var(--v-gray_70-base);
	}
</style>
