<template>
	<div v-if="contact" class="panel-modal" style="max-width: 500px">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">
					<span class="" v-if="isNewContact">{{ $t('contact.new-contact') }}</span>
					<span v-else>{{ $t('contact.edit-contact') }}</span>
				</div>

				<div class="ml-auto">
					<v-btn class="super-action" @click="saveContact">
						{{ $t('global.save') }}
					</v-btn>
					<v-icon size="20" color="gray_60" class="pointer ml-2" @click="confirmDelete">$delete</v-icon>
				</div>
			</div>
		</div>

		<div class="panel-modal-body text-left show-scrollbar">
			<v-form id="update-contact" ref="updateContact" lazy-validation v-model="isValid">
				<v-container fluid class="pa-0 ma-0">
					<v-row dense>
						<v-col cols="12" class="column-format gap-3 pt-2">
							<div class="row-format gap-2">
								<v-text-field
									@blur="resetValidation"
									v-model.trim="contact.firstName"
									:label="$t('contact.first-name')"
									hide-details
									outlined
									dense
									persistent-placeholder
									:rules="[rules.firstName]"
								></v-text-field>
								<v-text-field
									@blur="resetValidation"
									hide-details
									outlined
									dense
									persistent-placeholder
									v-model.trim="contact.lastName"
									:label="$t('contact.last-name')"
									:rules="[rules.lastName]"
								></v-text-field>
							</div>

							<v-text-field
								v-model.trim="contact.role"
								:label="$t('contact.role')"
								hide-details
								dense
								outlined
								persistent-placeholder
							></v-text-field>

							<v-text-field
								validate-on-blur
								:rules="emailRequired ? [rules.required,rules.email] : [rules.email]"
								type="email"
								hide-details
								outlined
								dense
								persistent-placeholder
								v-model.trim="contact.email"
								:label="$t('contact.email')"
							></v-text-field>

							<div style="width:100%; position: relative">
								<vue-tel-input  v-model="phone" :valid-characters-only="true" :preferred-countries="preferredCountries" @input="phoneUpdated" class="dense-phone" :inputOptions="{placeholder:'Phone'}"></vue-tel-input>
								<div class="phone-label">Phone</div>
							</div>

							<div class="column-format gap-3">
								<custom-field
									v-for="field in fields"
									:key="field.id"
									mode="dense"
									:field="field"
									:value="getCustomValue(field.id)"
									@change="handleCustomFieldChange($event)"
								></custom-field>
							</div>

							<v-textarea
								v-model.trim="contact.notes"
								:label="$t('global.notes')"
								no-resize
								auto-grow
								rows="1"
								hide-details
								dense
								outlined
								persistent-placeholder
							></v-textarea>
							<select-client v-if="!clientId" v-model="contact.clientId" label="Client" :show-avatar="true" :dense="true"></select-client>
							<div v-if="contact.clientId">
								<v-switch
									dense
									inset
									hide-details
									v-model.trim="contact.defaultContact"
									:label="$t('contact.default-contact')"
								></v-switch>
								<v-switch
									class="mt-1"
									dense
									inset
									hide-details
									v-model.trim="contact.invoiceContact"
									:label="$t('contact.invoice-contact')"
								></v-switch>
								<v-switch
									class="mt-1"
									dense
									inset
									hide-details
									v-model.trim="contact.portalAccess"
									:label="$t('contact.portal-access')"
								></v-switch>
							</div>
						</v-col>
					</v-row>
				</v-container>
				<div class="mt-8">
					<person-communications
						:person="contact"
						@view-communication="openCommunication($event)"
					></person-communications>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import ContactService from '@/modules/clients/contacts/ContactService';
	import SelectClient from '@/modules/clients/SelectClient';
	import PersonCommunications from '@/modules/communicator/inbox/PersonCommunications';
	import CommunicationDetailModal from '@/modules/communicator/inbox/CommunicationDetailModal';
	import CustomField from '@/components/CustomField';
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'ContactEdit',
		components: { PersonCommunications, SelectClient, CustomField },
		isRightModal: true,

		data() {
			return {
				contactService: new ContactService(),
				deleteDialog: false,
				contact: null,
				phone: null,
				isValid: false,
			};
		},

		props: ['id', 'clientId', 'newContact','emailRequired','formData'],

		mounted() {
			this.getContact();
		},

		watch: {},

		methods: {
			confirmDelete: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this contact?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res) {
						this.deleteContact();
					}
				})
			},

			phoneUpdated(number, object) {
				this.contact.phone = object.number;
			},

			getCustomValue: function(id) {
				let value = this.contact.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.contact.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.contact.customValues.splice(ix, 1, value);
					} else {
						this.contact.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.contact.customValues.push(value);
				}
			},

			getContact() {
				if (this.id) {
					this.contactService.getContact(this.id).then((res) => {
						this.contact = res.data;
						this.phone = this.contact.phone ? this.contact.phone : this.contact.mobile;
					});
				}else if(this.formData){
					this.contact = {
						clientId: this.clientId,
						firstName: this.formData.firstName,
						lastName:this.formData.lastName,
						email: this.formData.email,
						phone: this.formData.phone,
						role: this.formData.role,
						defaultContact: true,
						customValues: [],
						portalAccess: this.$store.state.podAccount.accountPreferences.defaultPortalAccess
					}

					if (this.formData.answers) {
						for (let i = 0; i < this.formData.answers.length; i++) {
							let answer = this.formData.answers[i];
							this.mergeData(
									answer.fieldKey,
									answer.answer,
									this.fields,
									this.contact.customValues
							);
						}
					}

				} else {
					this.contact = {
						id: null,
						clientId: this.clientId,
						defaultContact: false,
						invoiceContact: false,
						portalAccess: false,
						customValues: [],
					};

					if (this.newContact) {
						this.contact.firstName = this.newContact.firstName;
						this.contact.lastName = this.newContact.lastName;
						this.contact.email = this.newContact.email;
						this.contact.phone = this.newContact.phone;
					}
				}
			},

			handleClose: function() {
				this.saveContact();
			},

			mergeData: function(mappingKey, value, customFields, customValues) {
				let customField = customFields.find((f) => f.mappingKey === mappingKey);

				console.log(mappingKey);
				console.log(customField);

				if (customField) {
					customValues.push({
						fieldId: customField.id,
						mappingKey: customField.mappingKey,
						fieldName: customField.fieldName,
						value: value,
						type: customField.type,
					});
				}
			},

			saveContact() {
				if (!this.$refs.updateContact.validate()) {
					return false;
				}

				if (this.contact.email) {
					this.contact.email = this.contact.email.trim();
				}

				if (this.contact.id) {
					this.contactService
						.updateContact(this.contact.id, this.contact)
						.then((res) => {
							this.$emit('result', res.data);
							this.$store.state.eventBus.$emit('contact-update',{message:res.data});
						});
				} else {
					this.contactService.createContact(this.contact).then((res) => {
						this.$emit('result', res.data);
						this.$store.state.eventBus.$emit('contact-update',{message:res.data});
					});
				}
			},

			deleteContact() {
				if (this.contact.id) {
					this.contactService.deleteContact(this.contact.id).then(() => {
						this.contact.deleted = true;
						this.$emit('result', this.contact);
						this.$store.state.eventBus.$emit('contact-delete',{message:this.contact});
					});
				} else {
					this.$emit('result');
				}
			},

			doAlert(type, message) {
				this.$emit('alert', { type: type, message: message });
			},

			resetValidation() {
				this.$refs.updateContact.resetValidation();
			},

			openCommunication: function(communication) {
				this.$store.state.globalModalController.openModal(CommunicationDetailModal, { communication: communication });
			},

			addCountry: function(countryArray, country) {
				let ix = countryArray.findIndex((c) => c === country);
				if (ix > -1) {
					countryArray.splice(ix, 1);
				}
				countryArray.unshift(country);
			},
		},

		computed: {
			fields: function() {
				if (this.$store.state.contactFields) {
					return this.$store.state.contactFields.fields;
				} else {
					return [];
				}
			},

			preferredCountries: function() {
				let countries = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ'];

				if (this.$store.state.podAccount.country) {
					this.addCountry(countries, this.$store.state.podAccount.country);
				}

				if (this.client && this.client.country) {
					this.addCountry(countries, this.client.country);
				}

				return countries;
			},

			isNewContact: function() {
				return this.contact.id === null;
			},

			client: function() {
				if (this.contact && this.contact.clientId) {
					return this.$store.getters.getClientById(this.contact.clientId);
				} else {
					return null;
				}
			},

			rules: function() {
				if (this.contact) {
					return {
						required: (v) => !!v || 'Required',
						email: (v) => /^(.+@.+\..+)?$/.test(v) || v == null || this.$t('valid.email'),
						firstName: (v) => !!v || !!this.contact.lastName || 'First or last name required',
						lastName: (v) => !!v || !!this.contact.firstName || 'First or last name required',
						phone: (v) => this.$validations.validPhone(v) || this.$t('valid.phone'),
					};
				} else {
					return {};
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.contact-edit-modal {
		min-width: 500px;
		max-width: 500px;
	}
</style>
