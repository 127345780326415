<template>
	<div id="new-proposal" :style="view !== 'SELECT_CLIENT' ? 'min-height: 625px;' : ''">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div v-if="view === 'SELECT_CLIENT'" class="cloneList">
				<div class="modal-title">
					Create new agreement
				</div>
				<div>
					<select-client v-model="selectedClientId" :key="clientRefreshKey"></select-client>
					<div class="font-14 font-primary mb-3 pointer" @click="addNewClient()">+ Add new client</div>
				</div>
				<v-btn
					class="primary-action mt-2"
					style="min-width:160px;"
					@click="view = 'START_VIEW'"
					:disabled="!selectedClientId"
					>{{ $t('global.continue') }}</v-btn
				>
				<div class="loadingIcon" v-if="processing">
					<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
				</div>
			</div>

			<div v-if="view === 'START_VIEW'" class="cloneList">
				<div class="modal-title">
					{{ $t('proposal.new.heading') }}
				</div>

				<v-tabs v-model="tab" v-if="showV1">
					<v-tab key="1">Agreements [v2]</v-tab>
					<v-tab key="0">Agreements [v1 - classic]</v-tab>
				</v-tabs>

				<div>
					<div v-if="tab === 1" class="column-format gap-2">
						<div class="h-outline d-flex align-start justify-start py-3 px-2 mt-4" @click="freshStart('Proposal')">
							<div class="pr-3 pl-1">
								<v-icon>$proposals</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">{{ $t('proposal.new.proposal') }}</div>
								<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.proposal-caption') }}</div>
							</div>
						</div>
						<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="freshStart('Contract')">
							<div class="pr-3 pl-1">
								<v-icon>$signature</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">{{ $t('proposal.new.contract') }}</div>
								<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.contract-caption') }}</div>
							</div>
						</div>
						<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="freshStart('Quote')">
							<div class="pr-3 pl-1">
								<v-icon>$invoices</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">{{ $t('proposal.new.quote') }}</div>
								<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.quote-caption') }}</div>
							</div>
						</div>
						<div class="py-3 px-2 column-format fill-width gap-2" style="border: 1px solid var(--v-gray_50-base); border-radius: 4px">
							<div class="row-format">
								<div class="pr-3 pl-1">
									<v-icon>$duplicate</v-icon>
								</div>
								<div class="text-left">
									<div class="brand-medium gray_90--text">
										Start from a saved template
									</div>
								</div>
							</div>
							<v-autocomplete :items="proposalTemplateList" :return-object="true" item-text="name" hide-details dense outlined v-model="selectedTemplate" @change="confirmCreateFromTemplate"></v-autocomplete>
						</div>
					</div>
					<div v-else class="column-format gap-2 mt-4">
						<div class="h-outline d-flex align-start justify-start py-3 px-2"  @click="newAgreement(base)">
							<div class="pr-3 pl-1">
								<v-icon>handshake</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">Blank agreement</div>
								<div class="font-14 brand-semilight mt-1">Try the fully refreshed agreement module with a ton of great features and improved customization options.</div>
							</div>
						</div>
						<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="newAgreement(proposal)">
							<div class="pr-3 pl-1">
								<v-icon>$proposals</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">{{ $t('proposal.new.proposal') }}</div>
								<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.proposal-caption') }}</div>
							</div>
						</div>
						<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="newAgreement(contract)">
							<div class="pr-3 pl-1">
								<v-icon>$signature</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">{{ $t('proposal.new.contract') }}</div>
								<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.contract-caption') }}</div>
							</div>
						</div>
						<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="newAgreement(quote)">
							<div class="pr-3 pl-1">
								<v-icon>$invoices</v-icon>
							</div>
							<div class="text-left">
								<div class="brand-medium gray_90--text">{{ $t('proposal.new.quote') }}</div>
								<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.quote-caption') }}</div>
							</div>
						</div>
						<div class="py-3 px-2 column-format fill-width gap-2" style="border: 1px solid var(--v-gray_50-base); border-radius: 4px">
							<div class="row-format">
								<div class="pr-3 pl-1">
									<v-icon>$duplicate</v-icon>
								</div>
								<div class="text-left">
									<div class="brand-medium gray_90--text">
										Start from a saved template
									</div>
								</div>
							</div>
							<v-autocomplete :items="agreementTemplateList" :return-object="true" item-text="name" hide-details dense outlined v-model="selectedAgreementTemplate" @change="confirmCreateFromAgreementTemplate"></v-autocomplete>
						</div>
					</div>
				</div>

				<div class="loadingIcon" v-if="processing">
					<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProposalService from './ProposalService';
	import defaultProposal from './templates/defaultProposal.json';
	import defaultQuote from './templates/defaultQuote.json';
	import defaultContract from './templates/defaultContract.json';
	import SelectClient from '@/modules/clients/SelectClient';
	import TemplateService from '@/modules/templates/TemplateService';
	import ClientCreate from "@/modules/clients/ClientCreate";
	import ClientService from "@/modules/clients/ClientService";
	import HDateTime from "@/modules/utils/HDateTime";
	import AgreementService from "@/modules/agreements/AgreementService";
	import ConfirmModal from "@/components/ConfirmModal";
	import proposal from "@/modules/agreements/templates/proposal"
	import quote from "@/modules/agreements/templates/quote"
	import contract from "@/modules/agreements/templates/contract"
	import base from "@/modules/agreements/templates/base"

	export default {
		name: 'NewProposal',
		components: { SelectClient },

		props: ['openState', 'clientId', 'navOrigin', 'opportunityId'],

		data: function() {
			return {
				proposal:proposal,
				quote:quote,
				contract:contract,
				base:base,
				tab: 0,
				view: 'START_VIEW',
				proposalService: new ProposalService(),
				agreementService: new AgreementService(),
				defaultProposal: defaultProposal,
				defaultQuote: defaultQuote,
				defaultContract: defaultContract,
				templateService: new TemplateService(),
				clientService: new ClientService(),
				proposalTemplateList: [],
				agreementTemplateList: [],
				templateCount: 0,
				processing: false,
				isReady: false,
				clientRefreshKey: 0,
				selectedTemplate: null,
				selectedAgreementTemplate: null,

				cloneHeaders: [
					{ text: 'Date created', value: 'dateCreated' },
					{ text: 'Client', value: 'client.name' },
					{ text: 'Proposal Name', value: 'coverPage.heading' },
					// { text: '', value: 'clone', sortable: false },
				],
				templateHeaders: [
					{ text: 'Template Name', value: 'name' },

					{ text: 'Heading', value: 'coverPage.heading' },
					// { text: '', value: 'clone', sortable: false },
				],
				selectedClientId: null,
			};
		},

		mounted() {
			this.selectedClientId = this.clientId;
			if (!this.clientId) {
				this.view = 'SELECT_CLIENT';
			}
			this.getProposalTemplates();
			this.getAgreementTemplates();

			let savedTabValue = localStorage.getItem('AGREEMENT_VERSION_PREF');
			if(savedTabValue){
				this.tab = !this.showV1 ? 0 : Number(savedTabValue);
			}
		},

		methods: {
			addNewClient: function(){
				let binding = {
					clientList: this.$store.getters.clients,
					isOpen: true,
				};
				this.$store.state.globalModalController.openModal(ClientCreate, binding).then((res) => {
					if(res){
						this.selectedClientId = res.id;
						this.clientRefreshKey++;
					}
				});
			},

			newAgreement: function(type){
				this.agreementService.initializeAgreement(this.selectedClientId,this.opportunityId,type).then((res) => {
					this.$emit('result', { action: 'NEW_AGREEMENT', clientId: this.selectedClientId, id: res.data.id });
				})
			},

			freshStart: function(type = 'Proposal') {
				this.processing = true;
				this.clientService.getClientDetail(this.selectedClientId).then((res) => {
					let client = res.data;
					this.proposalService
							.initializeProposal(this.selectedClientId)
							.then((res) => {
								this.setDefaultOptions(client,type,res.data);
							})
							.catch((err) => {
								this.processing = false;
								this.$store.commit('error', err.response.data.message);
							});
				})

			},

			upgradeClicked() {
				this.$emit('result', { action: 'UPGRADE' });
			},

			setDefaultOptions(client,type,proposal) {
				let defaultConfig;

				if(type === 'Proposal'){
					defaultConfig = JSON.parse(JSON.stringify(defaultProposal));
				}else if(type === 'Quote'){
					defaultConfig = JSON.parse(JSON.stringify(defaultQuote));
				}else if(type === 'Contract'){
					defaultConfig = JSON.parse(JSON.stringify(defaultContract));
				}

				proposal.coverPage = defaultConfig.coverPage;
				proposal.proposalType = type;
				proposal.formatting = defaultConfig.formatting;
				proposal.formatting.headerFont = this.$store.state.podAccount.accountPreferences.brandingPreferences.font;
				proposal.formatting.bodyFont = this.$store.state.podAccount.accountPreferences.brandingPreferences.font;
				proposal.contentBlocks = defaultConfig.contentBlocks;

				this.setAccountInfoToken(proposal);
				this.setClientInfoToken(client,proposal);
				this.setTerms(client,proposal);

				for (let i = 0; i < proposal.contentBlocks.length; i++) {
					if (proposal.contentBlocks[i].blockType === 'SignatureBlock') {
						proposal.contentBlocks[i].us.signerName = this.$store.getters.getLoggedInUserName;
						break;
					}
				}

				this.proposalService
					.updateProposal(this.selectedClientId, proposal.id, proposal)
					.then(() => {
						this.processing = false;
						this.$emit('result', proposal);
					})
					.catch((err) => {
						this.processing = false;
						this.$store.commit('error', err.response.data.message);
						this.$emit('error', err);
					});
			},

			setTerms: function(client,proposal){
				for(let i=0; i < proposal.contentBlocks.length; i++){
					let block = proposal.contentBlocks[i];
					if(block.blockType === 'Terms'){
						block.effectiveDate = HDateTime.now().toISO();
						block.governingLaw = this.$store.state.podAccount.locality;
						block.clientLegalName = client.name;
						block.clientState = client.locality;
					}
				}
			},

			setAccountInfoToken: function(proposal){
				let accountInfo = '';
				let account = this.$store.state.podAccount;
				accountInfo = this.appendIfPropExists(accountInfo,account,['accountName'],[]);
				accountInfo = this.appendIfPropExists(accountInfo,account,['address1'],[]);
				accountInfo = this.appendIfPropExists(accountInfo,account,['address2'],[]);
				accountInfo = this.appendIfPropExists(accountInfo,account,['city','locality','postal'],[null,',']);
				accountInfo = this.appendIfPropExists(accountInfo,account,['website'],[]);
				accountInfo = this.appendIfPropExists(accountInfo,account,['phone'],[]);

				for(let i=0; i < proposal.contentBlocks.length; i++){
					if(proposal.contentBlocks[i].text){
						proposal.contentBlocks[i].text = proposal.contentBlocks[i].text.replace('{{AccountInfo}}', accountInfo)
					}
				}
			},

			setClientInfoToken(client,proposal){
				let clientInfo = '';
				clientInfo = this.appendIfPropExists(clientInfo,client,['name'],[]);
				clientInfo = this.appendIfPropExists(clientInfo,client,['address1'],[]);
				clientInfo = this.appendIfPropExists(clientInfo,client,['address2'],[]);
				clientInfo = this.appendIfPropExists(clientInfo,client,['city','locality','postal'],[null,',']);
				clientInfo = this.appendIfPropExists(clientInfo,client,['website'],[]);
				clientInfo = this.appendIfPropExists(clientInfo,client,['phone'],[]);

				for(let i=0; i < proposal.contentBlocks.length; i++){
					if(proposal.contentBlocks[i].text){
						proposal.contentBlocks[i].text = proposal.contentBlocks[i].text.replace('{{ClientInfo}}', clientInfo)
					}
				}
			},

			appendIfPropExists(string,object,propList,separator = []){
				let foundOne = false;

				for(let i=0; i < propList.length; i++){
					let prop = propList[i];
					if(object[prop]){
						foundOne = true;
						string = string + object[prop]
						if(separator[i]){
							string = string + separator[i];
						}

						if(i < propList.length - 1){
							string = string + ' ';
						}
					}
				}

				if(foundOne){
					string = string + '<br>';
				}

				return string;
			},

			confirmCreateFromTemplate: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: `Do you want to create a new proposal from your "${this.selectedTemplate.name}" template?`
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.selectTemplate(this.selectedTemplate.id);
					}else{
						this.selectedTemplate = null;
					}
				})
			},

			confirmCreateFromAgreementTemplate: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: `Do you want to create a new proposal from your "${this.selectedAgreementTemplate.name}" template?`
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.selectAgreementTemplate(this.selectedAgreementTemplate.id);
					}else{
						this.selectedAgreementTemplate = null;
					}
				})
			},

			selectAgreementTemplate: function(templateId){
				this.processing = true;
				this.templateService
						.agreementTemplateToAgreement(templateId, this.selectedClientId, this.opportunityId)
						.then((res) => {
							this.$emit('result', { action: 'NEW_AGREEMENT', clientId: this.selectedClientId, id: res.data.id });
							this.processing = false;
						})
						.catch((e) => {
							console.log(e);
							this.$store.commit('error', e.response.data.message);
							this.processing = false;
							this.$emit('error', e);
						});
			},

			selectTemplate(templateId) {
				this.processing = true;
				this.templateService
					.createProposalFromTemplate(templateId, this.selectedClientId)
					.then((res) => {
						res.data.action = 'NEW_PROPOSAL';
						this.$emit('result', res.data);
						this.processing = false;
					})
					.catch((e) => {
						console.log(e);
						this.$store.commit('error', e.response.data.message);
						this.processing = false;
						this.$emit('error', e);
					});
			},

			getProposalTemplates: function() {
				this.templateService
					.getProposalTemplates()
					.then((res) => {
						this.proposalTemplateList.splice(0);
						this.proposalTemplateList.push(... res.data);
						this.proposalTemplateList.sort((a,b) => a.name.localeCompare(b.name));
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			getAgreementTemplates: function() {
				this.templateService
						.getAgreementTemplates()
						.then((res) => {
							this.agreementTemplateList.splice(0);
							this.agreementTemplateList.push(... res.data);
							this.agreementTemplateList.sort((a,b) => a.name.localeCompare(b.name));
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
			},

			resetForm: function() {
				this.view = 'START_VIEW';
			},

			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
			buttonDisabled: function() {
				return !this.selectedClientId || this.processing;
			},

			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},

			showV1: function(){
				return this.$store.state.podAccount.accountId < 32437;
			}
		},

		watch: {
			openState: function(newVal) {
				if (newVal === true) {
					this.resetForm();
				}
			},

			tab: function(newVal){
				localStorage.setItem('AGREEMENT_VERSION_PREF',newVal);
			}
		},
	};
</script>

<style lang="scss">
	#new-proposal {
		width: 420px;
		//min-width: 320px;
		background-color: var(--v-white-base);
		.h-outline:not(.select-client) {
			cursor: pointer;
			min-height: 96px;
			&.disabled {
				opacity: 0.5;
				cursor: default;
			}
			&:hover:not(.disabled) {
				background-color: var(--v-gray_20-base);
				border-color: var(--v-gray_20-base);
				svg {
					--icon-color: var(--v-primary-base);
					color: var(--v-primary-base);
				}
			}
		}
		.previous-proposals-table {
			th,
			td {
				padding: 16px 4px !important;
			}
			td {
				cursor: pointer;
			}
		}
	}

	.loadingIcon {
		position: absolute;
		top: 50%;
		right: 50%;
		z-index: 300;
	}
</style>
