<template>
	<div
		@click="handleClick"
		:class="
			`${route ? 'pointer' : ''} row-format ${expanded ? 'align-center' : 'align-center'} ${
				specialLink ? 'special-link' : ''
			} mx-2 ${!specialLink && active ? 'nav-link-active' : ''} nav-link${expanded ? '-expanded' : ''}`
		"
	>
		<div v-if="expanded" class="row-format align-center" style="padding-left: 12px;">
			<v-icon :class="!active ? 'material-symbols-rounded' : 'material-symbols-rounded'" :color="active ? 'primary' : 'black'" size="20">{{
				icon
			}}</v-icon>
			<div :class="`ml-2 nav-label ${!specialLink && active ? 'active' : ''}`">{{ label }}</div>
			<v-icon class="ml-2" v-if="specialLink" size="20" color="white" v-tippy="{ content: 'Dismiss' }">close</v-icon>
		</div>
		<div
			v-else
			class="row-format centered"
			style="padding-left: 12px;"
			:content="label"
			v-tippy="{ placement: 'right', arrow: true, arrowType: 'round', animation: 'fade', delay: 50, boundary: 'window' }"
		>
			<v-icon :class="!active ? 'material-symbols-rounded' : 'material-symbols-rounded'" :color="active ? 'primary' : 'black'" size="20">{{
				icon
			}}</v-icon>
		</div>
	</div>
</template>

<script>
	//import HIcon3 from '@/components/HIcon3';
	export default {
		name: 'NavLink',

		props: ['label', 'icon', 'route', 'disableActive', 'altRoutes', 'specialLink', 'secondLine'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClick: function(event) {
				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(this.route, '_blank');
					w.focus();
				} else {
					this.$router.push(this.route);
				}
			},
		},

		computed: {
			expanded: function() {
				return this.$store.state.leftNavExpanded;
			},

			active: function() {
				if (this.disableActive) {
					return false;
				}
				let active = this.$route.path.startsWith(this.route);
				if (active) {
					return true;
				}

				if (this.altRoutes) {
					for (let i = 0; i < this.altRoutes.length; i++) {
						if (this.$route.path.startsWith(this.altRoutes[i])) {
							return true;
						}
					}
				}

				return false;
			},
		},
	};
</script>

<style scoped lang="scss">
	.nav-label {
		font-size: 14px;
		line-height: 14px;
		color: var(--v-black-base);
		font-weight: 400;
	}

	//.filled {
	//	font-variation-settings: 'FILL' 100, 'wght' 200, 'GRAD' 0, 'opsz' 24;
	//}

	.active {
		color: var(--v-primary-base) !important;
		background-color: var(--v-white-base);
		border: none;
	}

	.special-link {
		color: var(--v-white-base) !important;
		background-color: var(--v-primary-base);
		border: none;
		margin-bottom: 8px;
		border-radius: 4px !important;
		.v-icon {
			color: var(--v-white-base) !important;
		}
		.nav-label {
			color: var(--v-white-base) !important;
		}
	}

	.nav-link-active {
		background-color: var(--v-white-base);
	}

	.nav-link {
		width: 44px;
		height: 38px;
		border-radius: 4px;
	}

	.nav-link-expanded {
		width: 208px;
		height: 38px;
		border-radius: 4px;
	}
</style>
