<template>
	<div v-if="isReady" class="mt-2">
		<v-data-table
			:key="refreshKey"
			:headers="headers"
			:items="domains"
			:hide-default-footer="true"
			:disable-pagination="true"
			:fixed-header="true"
			:items-per-page="-1"
		>
			<template v-slot:item.id="{ item }">
				<a :href="`https://${item.id}`" target="_blank">{{ item.id }}</a>
			</template>

			<template v-slot:item.currentStatus="{item}">
				<v-icon v-if="item.currentStatus === 'READY'" color="success" small>$check</v-icon>
				<v-icon v-else color="warning" small>$error</v-icon>
			</template>

			<template v-slot:item.dnsVerified="{ item }">
				<span>{{ item.dnsVerified || item.providedDomain ? 'Yes' : 'No' }}</span>
			</template>

			<template v-slot:item.certEnabled="{ item }">
				<span>{{ item.certEnabled || item.providedDomain ? 'Yes' : 'No' }}</span>
			</template>

			<template v-slot:item.statusHistory="{ item }">
				<div class="pointer" @click="toggleExpanded(item.id)">
					<div v-if="item.statusHistory.length && !item.statusExpanded">
						{{ DateTime.fromISO(item.statusHistory[0].statusTimestamp).toLocaleString(DateTime.DATETIME_MED) }} -
						{{ item.statusHistory[0].statusMessage }}
					</div>
					<div v-if="item.statusExpanded">
						<div v-for="(status, index) in item.statusHistory" :key="index">
							{{ DateTime.fromISO(status.statusTimestamp).toLocaleString(DateTime.DATETIME_MED) }} -
							{{ status.statusMessage }}
						</div>
					</div>
				</div>
			</template>

			<template v-slot:item.action="{ item }">
				<v-icon v-if="!item.providedDomain" @click="confirmDelete(item.id)" small>$delete</v-icon>
			</template>
		</v-data-table>

		<div class="text-left px-3" style="border-top: 1px solid var(--v-gray_50-base)">
			<v-form v-model="isValid" :disabled="!paidAccount || $store.getters.isInTrial"  @submit.prevent>
				<div class="brand-medium ml-1 mt-8">Register custom domain</div>
				<v-text-field :disabled="domains.length >=2"
					:rules="required"
					class="my-2"
					v-model="newDomain"
					dense
					outlined
					hide-details
					placeholder="clients.mydomain.com"
					style="width: 400px"
				></v-text-field>
				<v-checkbox v-model="confirm" class="mb-2" hide-details dense  :disabled="domains.length >=2">
					<template v-slot:label>
						<span class="font-14">I have added a CNAME to tls-proxy.withmoxie.com</span>
					</template>
				</v-checkbox>
				<v-btn v-if="paidAccount && !$store.getters.isInTrial" class="primary-action" :disabled="!confirm || !isValid" @click="addNew">Register now</v-btn>
				<div v-else class="font-italic font-secondary">This feature is not available on the free trial of Moxie.  Please subscribe to add your custom domain.</div>
				<div class="font-12 brand-semilight mt-3" style="width: 400px" v-if="domains.length < 2">
					To add a custom domain for the Client Portal, you must already own a domain name and have the ability to
					create a CNAME record.
					<a href="https://help.withmoxie.com/en/articles/5855651-client-portal-custom-domains" target="_blank" style="color:var(--v-primary-base)">Check our our help article on how to prepare your domain to use with Moxie's Client Portal.</a>
				</div>
				<div v-else class="font-12 brand-medium mt-3">
					<v-icon small color="warning">$alert</v-icon> Maximum 2 custom domain limit has been reached.
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import CustomDomainService from '@/modules/portal/CustomDomainService';
	import DateTime from "@/modules/utils/HDateTime";
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'CustomDomains',

		props: [],

		components: {},

		data: function () {
			return {
				isReady: false,
				isValid: false,
				confirm: false,
				refreshKey: 0,
				DateTime: DateTime,
				domains: [],
				customDomainService: new CustomDomainService(),
				newDomain: null,
				channelName: this.getChannelName(),
				required: [
					(v) => !!v || 'Required',
					(v) => /([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}/.test(v) || 'Required',
				],
			};
		},

		mounted() {
			this.getCustomDomains();
			this.$store.state.eventBus.$on(this.channelName, this.eventHandler);
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.channelName, this.eventHandler);
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function(){
				this.$store.state.eventBus.$off(this.channelName, this.eventHandler);
				this.channelName = this.getChannelName();
				this.$store.state.eventBus.$on(this.channelName, this.eventHandler);
				this.getCustomDomains();
			},

			getCustomDomains: function () {
				this.customDomainService.getCustomDomains().then((res) => {
					this.domains.splice(0, this.domains.length);
					this.domains.push(...res.data);
					this.isReady = true;
				});
			},

			addNew: function () {
				this.$store.commit('startLoading');
				this.newDomain = this.newDomain.trim().toLowerCase();
				this.customDomainService.addCustomDomain(this.newDomain).then((res) => {
					this.$store.commit('stopLoading')
					this.domains.push(res.data);
					this.refreshKey++;
					this.newDomain = null;
					this.confirm = false;
				}).catch((err) => {
					this.$store.commit('stopLoading');
					let binding = {
						headingText: 'There was an error verifying your domain.',
						bodyText: err.response.data.message,
						hideNo: true,
						yesText: 'OK'
					}
					this.$store.state.globalModalController.openModal(ConfirmModal,binding,true,false);
				})
			},

			eventHandler: function (event) {
				let domain = event.message;
				console.log(domain);
				let ix = this.domains.findIndex((d) => d.id === domain.id);
				if (ix > -1) {
					this.domains.splice(ix, 1, domain);
					this.refreshKey++;
				}
			},

			getChannelName: function () {
				return this.$store.getters.getMessageChannelPrefix + '.custom-domain';
			},

			confirmDelete: function (domain) {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete the custom domain ${domain}?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.customDomainService.deleteCustomDomain(domain).then(() => this.getCustomDomains()).finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			toggleExpanded(domain) {
				let d = this.domains.find((d) => d.id === domain);
				if (d.statusExpanded) {
					d.statusExpanded = false;
				} else {
					d.statusExpanded = true;
				}
				this.refreshKey++;
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			headers: function () {
				let result = [
					{ text: this.$t('portal.domains.name'), value: 'id' },
					{ text: this.$t('portal.domains.status'), value: 'currentStatus', width: 100, align: 'center' },
					{ text: this.$t('portal.domains.history'), value: 'statusHistory' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
