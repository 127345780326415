<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">Hang tight, we are connecting your account.</div>
	</div>
</template>

<script>
	import PlaidService from '@/modules/accounting/accounts/PlaidService';

	export default {
		name: 'PlaidOAuthHandler',

		props: [''],

		components: {},

		data: function () {
			return {
				plaidService: new PlaidService(),
			};
		},

		mounted() {
			this.processOAuthFlow();
		},

		beforeDestroy() {},

		methods: {
			processOAuthFlow: function(){
				let token = localStorage.getItem('PLAID_LINK_TOKEN');
				let itemId = localStorage.getItem('PLAID_LINK_ITEM_ID');

				let plaidHandler = window.Plaid.create({
					token: token,
					onSuccess: (token, metadata) => {
						this.plaidSuccess(token, metadata, itemId);
					},
					onLoad: () => {},
					onExit: this.plaidExit,
					onEvent: this.plaidEvent,
					receivedRedirectUri: window.location.href,
				});

				plaidHandler.open();
			},

			reSync: function(itemId){
				this.plaidService.resSync(itemId);
			},

			plaidSuccess: function (token, metadata, itemId) {
				console.log('Plaid Success');
				console.log(token);
				console.log(metadata);

				this.plaidService
						.exchange(token)
						.then(() => this.reSync(itemId))
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						}).finally(() => this.$store.state.eventBus.$emit('open-settings-modal','connected-apps'));
			},

			plaidExit: function (err, metadata) {
				console.log('Plaid Exit');
				console.log(err);
				console.log(metadata);
			},

			plaidEvent: function (eventName, metadata) {
				console.log('Plaid Event');
				console.log(eventName);
				console.log(metadata);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
