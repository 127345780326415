<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%; border: 1px solid var(--v-gray_50-base); border-radius: 4px; position: relative" class="pa-2">
		<div class="row-format gap-2 font-14" style="flex-wrap: wrap">
			<label v-for="(option, index) in field.options" :key="index">
				<input
						class="custom-checkbox"
						style="width: 12px; height: 12px; border-radius: 4px"
						:type="field.type.toLowerCase()"
						:value="option"
						:true-value="option"
						v-model="ourValue"
				/>
				<span class="ml-1">{{ option }}</span>
			</label>
		</div>
		<div class="phone-label">{{field.name}}</div>
	</div>
	<div v-else class="row-format gap-2" style="flex-wrap: wrap">
		<label v-for="(option, index) in field.options" :key="index">
				<input
						class="custom-checkbox"
						style="width: 12px; height: 12px; border-radius: 4px"
						:type="field.type.toLowerCase()"
						:value="option"
						:true-value="option"
						v-model="ourValue"
					/>
				<span class="ml-1">{{ option }}</span>
		</label>
	</div>
</template>

<script>
	export default {
		name: 'CustomCheckRadio',

		props: ['field', 'value','mode'],

		components: {},

		data: function() {
			return {
				ourValue:null,
				isReady: false,
			};
		},

		mounted() {
			if(this.field.type === 'Checkbox'){
				this.ourValue = [];
				if(this.value){
					this.ourValue.push(... this.value.split("||"));
				}
			}else{
				this.ourValue = this.value;
			}
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {},

		watch: {
			ourValue: function(){
				if(this.field.type === 'Checkbox'){
					if(this.ourValue.length){
						this.$emit('change',this.ourValue.join("||"));
					}else{
						this.$emit('change',null);
					}
				}else{
					this.$emit('change',this.ourValue);
				}
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.custom-checkbox {
		&:checked {
			accent-color: var(--v-secondary-base);
		}
	}
</style>
