<template>
	<div class="panel-modal" style="width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">{{ $t('recurring.deliverables.heading') }}</div>
				<div class="ml-auto">
					<v-btn
							v-if="isReady"
							class="primary-action"
							@click="saveRecurringDeliverable"
							:disabled="!deliverable.name"
					>
						{{ $t('global.save-changes') }}
					</v-btn>
					<v-btn class="delete ml-2" icon @click="deleteRecurringDeliverable"><v-icon size="20">$delete</v-icon></v-btn>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left show-scrollbar">
			<recurring-event-config :recurring="recurring"></recurring-event-config>
			<hr class="mt-3 mb-6" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />
			<deliverable-detail :deliverable="deliverable" :fee-schedule="feeSchedule" :attachment-service="recurringAttachmentService"></deliverable-detail>
		</div>
	</div>
</template>

<script>
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import RecurringEventConfig from '@/modules/recurrence/RecurringEventConfig';
	import DeliverableDetail from '@/modules/templates/deliverables/DeliverableDetail';
	import { v4 as uuidv4 } from 'uuid';
	import ConfirmModal from "@/components/ConfirmModal";
	import RecurringAttachmentService from "@/modules/recurrence/RecurringAttachmentService";

	export default {
		name: 'RecurringDeliverable',
		isRightModal: true,

		props: ['projectId','id', 'feeSchedule'],

		mixins: [RecurringEventMixin],

		components: { DeliverableDetail, RecurringEventConfig },

		data: function () {
			return {
				deliverable: null,
				recurring: null,
				isReady: false,
				recurringAttachmentService: null,
			};
		},

		mounted() {
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			initialize: function () {
				if (this.id) {
					this.getRecurringEvent(this.id).then((res) => {
						this.deliverable = res.data.deliverable.deliverable;
						this.recurring = res.data;
						this.isReady = true;
						this.recurringAttachmentService = new RecurringAttachmentService(this.recurring);
					});
				} else {
					this.deliverable = this.initializeDeliverable();
					this.recurring = this.initRecurring('DELIVERABLE');
					this.saveRecurringDeliverable(false);
				}
			},

			initializeDeliverable: function () {
				return {
					id: uuidv4().replace(/-/g, ''),
					name: null,
					description: null,
					dueDate: {
						duration: null,
						timeUnit: 'WEEKS',
					},
					tasks: [],
					assignedToList: [],
				};
			},

			saveRecurringDeliverable: function(close=true){
				let payload = {
					projectId: this.projectId,
					deliverable: this.deliverable,
				}

				this.saveRecurringEvent(this.recurring,payload,'DELIVERABLE').then((res) => {
					if(close) {
						this.$emit('result', res.data);
					}else{
						this.deliverable = res.data.deliverable.deliverable;
						this.recurring = res.data;
						this.isReady = true;
						this.recurringAttachmentService = new RecurringAttachmentService(this.recurring);
					}
				})
			},

			deleteRecurringDeliverable: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this recurring deliverable?'
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.deleteRecurringEvent(this.id).then(() => {
							this.recurring.deleted = true
							this.$emit('result',this.recurring);
						})
					}
				})
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	#recurring-deliverable-detail-modal {
		max-width: 500px;
		.label-style {
			margin-top: 8px;
			font-weight: 500;
			font-size: 12px;
			color: var(--v-gray_80-base);
			text-align: left;
		}
		.half {
			> div {
				flex: 0 1 50%;
			}
		}
	}
</style>
