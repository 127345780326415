var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height column-format mt-2",attrs:{"id":"meeting-list"}},[(_vm.isReady)?_c('div',{staticClass:"column-format",staticStyle:{"flex":"1"}},[_c('v-data-table',{key:_vm.tableKey,staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.filteredMeetingList,"sort-by":"confirmedTime.start","sort-asc":"","disable-pagination":true,"hide-default-footer":true},on:{"click:row":function($event){return _vm.openScheduledMeeting($event.id)}},scopedSlots:_vm._u([{key:"item.confirmedTime.start",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (item.meetingStatus === 'Cancelled' ? 'cancelled' : ''))},[_vm._v(_vm._s(_vm.DateTime.fromISO(item.confirmedTime.start).toFormat("ccc") + ' ' + _vm.DateTime.fromISO(item.confirmedTime.start).toLocaleString(_vm.DateTime.DATETIME_MED)))])]}},{key:"item.meetingName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (item.meetingStatus === 'Cancelled' ? 'cancelled' : ''))},[_vm._v(_vm._s(item.meetingName))])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (item.meetingStatus === 'Cancelled' ? 'cancelled' : ''))},[_vm._v(_vm._s(item.formData.firstName)+" "+_vm._s(item.formData.lastName))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('meeting-action-menu',{attrs:{"hide-duplicate":true},on:{"view":function($event){return _vm.openScheduledMeeting(item.id)},"delete":function($event){return _vm.confirmDelete(item)}}})]}},{key:"no-data",fn:function(){return [(!_vm.scheduledMeetings.length)?_c('div',[_vm._v(" There aren't any meetings scheduled yet. ")]):_c('div',[_vm._v(" There are no meetings that match the filter criteria. ")])]},proxy:true}],null,false,2654116862)})],1):_vm._e(),(!_vm.sampleMode && !_vm.paidAccount)?_c('div',{staticClass:"column-format centered",staticStyle:{"position":"absolute","bottom":"12px","width":"100%"}},[_c('free-tier-card',{staticStyle:{"max-width":"600px","height":"120px"},attrs:{"message":"Meeting scheduler is not available on the Essentials plan. You can explore this feature, but access to scheduling meetings will be restricted."},on:{"upgrade-clicked":function($event){return _vm.$router.push('/subscription')}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }