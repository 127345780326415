<template>
	<div class="row-format" v-if="isReady" id="project-filter">
		<v-menu
			v-model="menu"
			max-height="90vh"
			max-width="250"
			attach="#project-filter"
			:close-on-content-click="false"
			:close-on-click="true"
			content-class="filter-menu-class"
			style="overflow-y: auto;"
		>
			<template v-slot:activator="{ on }">
				<div :class="`pointer row-format align-center`" v-on="on">
					<div :class="`font-14 ${!filtersAreEmpty ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{filterLabel}}</div>
					<v-icon x-small class="mx-1" :color="!filtersAreEmpty ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</div>
			</template>

			<div class="column-format">
<!--				<v-text-field-->
<!--					id="search-input"-->
<!--					v-model="filter.name"-->
<!--					autofocus-->
<!--					solo-->
<!--					flat-->
<!--					hide-details-->
<!--					dense-->
<!--					:placeholder="$t('projects.filter.name-contains')"-->
<!--					class="font-16"-->
<!--					@keypress.enter.exact="menu = false"-->
<!--				></v-text-field>-->

				<div class="filter-option-box" v-if="!singleProjectMode">
					<div class="row-format" @click="clientExpanded = !clientExpanded">
						<div class="pr-4 font-14">{{ $t('projects.filter.client-project') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							clientExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="clientExpanded">
						<v-checkbox label="[No client]" v-model="noClientAssigned" hide-details style="margin-left: 23px;" class="mb-2"></v-checkbox>
						<v-treeview
							v-model="projectFilters"
							selectable
							selected-color="primary"
							item-children="projects"
							item-text="name"
							dense
							:items="clientsProjects"
						>
							<template v-slot:label="{ item, selected, indeterminate }">
								<div style="text-align: left">
									<div
										class="row-format pointer align-center"
										@click="toggleProject(item, selected, indeterminate)"
									>
										<client-avatar
											x-small
											:client="item"
											v-if="item.isClient"
											:disable-click="true"
										></client-avatar>
										<div class="ml-2 ellipsis">{{ item.name }}</div>
									</div>
								</div>
							</template>
						</v-treeview>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format" @click="statusExpanded = !statusExpanded">
						<div class="font-14">{{ $t('projects.filter.status') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							statusExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="statusExpanded">
						<v-checkbox
							v-model="filter.status"
							v-for="status in statusList"
							:value="status.id"
							:key="status.id"
							hide-details
							dense
						>
							<template v-slot:label>
								<div class="row-format align-center" style="width: 100%">
									<div
										:style="`width:8px; height: 8px; border-radius: 2px; background-color:${status.hexColor}`"
									></div>
									<div class="status-label ml-2 ellipsis" v-html="status.label"></div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format" @click="assignedExpanded = !assignedExpanded">
						<div class="font-14">{{ $t('projects.filter.assigned') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							assignedExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="assignedExpanded" class="assigned-user ellipsis">
						<v-checkbox
							v-model="filter.assignedTo"
							v-for="user in userOptions"
							:value="user.userId"
							:key="user.userId"
							hide-details
							dense
						>
							<template v-slot:label>
								<assigned-user v-if="user.userId !== 'unassigned'" :assigned-to="user.userId"></assigned-user>
								<span v-else>{{ $t('projects.unassigned') }}</span>
							</template>
						</v-checkbox>
					</div>
				</div>

				<filter-option
						label="Active status"
						:option-list="archiveStatus"
						v-model="filter.archiveStatus"
				></filter-option>

				<div class="filter-option-box">
					<div class="row-format" @click="dateExpanded = !dateExpanded">
						<div class="font-14">{{ $t('client.filter.date-range') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							dateExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="dateExpanded">
						<v-radio-group v-model="filter.dueBy">
							<v-radio v-for="option in dateOptions" :key="option.value" :value="option">
								<template v-slot:label>
									<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
								</template>
							</v-radio>
						</v-radio-group>

						<div class="" v-if="showEarliest">
							<date-selector
								content-class="my-5"
								:date="filter.dueDate1"
								@change="filter.dueDate1 = $event"
								label="Earliest"
							></date-selector>
						</div>

						<div class="" v-if="showLatest">
							<date-selector
								content-class="my-5"
								:date="filter.dueDate2"
								@change="filter.dueDate2 = $event"
								label="Latest"
							></date-selector>
						</div>
					</div>
				</div>

				<div class="mx-3 mt-3 mb-3 column-format" style="gap: 12px; color: var(--v-secondary-base)">
					<div @click="clearFilters()" class="pointer font-14 row-format centered">
						<v-icon small color="secondary">$clear</v-icon>
						<div>{{ $t('projects.filter.clear-filter') }}</div>
					</div>
					<v-btn class="super-action" @click="menu = false" >{{
							$t('global.done')
						}}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import moment from 'moment';
	import AssignedUser from '@/components/AssignedUser';
	import DateSelector from '@/components/DateSelector';
	import FilterOption from "@/components/FilterOption";

	export default {
		name: 'ProjectFilter',

		props: ['projects', 'filter','singleProjectMode'],

		components: { DateSelector, ClientAvatar, AssignedUser, FilterOption },

		data: function () {
			return {
				isReady: false,
				menu: false,
				dateExpanded: false,
				clientExpanded: false,
				statusExpanded: false,
				assignedExpanded: false,
				noClientAssigned: false,
				projectFilters: [],
				dateOptions: [
					{ text: 'Today', value: 'today' },
					{ text: 'This week', value: 'this-week' },
					{ text: 'Next week', value: 'next-week' },
					{ text: 'This month', value: 'this-month' },
					{ text: 'Next month', value: 'next-month' },
					{ text: 'Before', value: 'before' },
					{ text: 'After', value: 'after' },
					{ text: 'Between', value: 'between' },
				],
				archiveStatus: [
					{ text: 'Active', value: false },
					{ text: 'Archived', value: true },
				],
			};
		},

		mounted() {
			this.validateFilterStructure();

			if(this.filter.projects.indexOf('null-null') > -1){
				this.noClientAssigned = true;
			}

			this.projectFilters.push(... this.filter.projects);
		},

		beforeDestroy() {},

		methods: {
			validateFilterStructure: function(){
				if(!Array.isArray(this.filter.assignedTo)){
					this.filter.assignedTo =[];
				}
				if(!Array.isArray(this.filter.status)){
					this.filter.status = [];
				}
				if(!Array.isArray(this.filter.projects)){
					this.filter.projects = [];
				}
				if(!Array.isArray(this.filter.archiveStatus)){
					this.filter.archiveStatus = [false];
				}
				this.isReady = true;
			},

			clearFilters: function () {
				this.filter.name = null;
				this.filter.dueBy = null;
				this.filter.dueDate1 = null;
				this.filter.dueDate2 = null;
				this.filter.assignedTo = [];
				this.filter.status = [];
				this.filter.projects = [];
				this.filter.archiveStatus = [false];
				this.noClientAssigned = false;
				this.projectFilters.splice(0,this.projectFilters.length)
			},

			selectAllProjects: function () {
				this.selectNoProjects();
				for (let i = 0; i < this.projects.length; i++) {
					this.filter.projects.push(this.projects[i].id);
				}
			},

			selectNoProjects: function () {
				this.projectSearch = null;
				this.filter.projects.splice(0, this.filter.projects.length);
			},

			toggleProject: function (item, selected) {
				if (!item.isClient) {
					let ix = this.filter.projects.indexOf(item.id);
					if (ix > -1) {
						this.filter.projects.splice(ix, 1);
					} else {
						this.filter.projects.push(item.id);
					}
				} else {
					for (let i = 0; i < item.projects.length; i++) {
						if (selected) {
							this.removeProject(item.projects[i].id);
						} else {
							if (this.filter.projects.indexOf(item.projects[i].id) === -1) {
								this.filter.projects.push(item.projects[i].id);
							}
						}
					}
				}
			},

			removeProject: function (id) {
				let ix = this.filter.projects.indexOf(id);
				if (ix > -1) {
					this.filter.projects.splice(ix, 1);
				}
			},

			sort: function (a, b) {
				if (a.name > b.name) {
					return 1;
				} else if (a.name < b.name) {
					return -1;
				} else {
					return 0;
				}
			},

			syncProjectFilters: function(){
				let filters = [];
				if(this.noClientAssigned){
					filters.push('null-null');
				}
				filters.push(... this.projectFilters);
				this.filter.projects.splice(0,this.filter.projects.length);
				this.filter.projects.push(... filters);
			}
		},

		watch: 	{
			noClientAssigned: function(){
				this.syncProjectFilters();
			},

			projectFilters: function(){
				this.syncProjectFilters();
			}
		},

		computed: {
			filtersAreEmpty: function () {
        if (this.filter.dueBy !== null) {
					return false;
				} else if (this.filter.assignedTo.length > 0) {
					return false;
				} else if (this.filter.status.length > 0) {
					return false;
				} else if (this.filter.projects.length > 0) {
					return false;
				}else if(this.filter.archiveStatus.indexOf(true) > -1){
					return false;
				} else {
					return true;
				}
			},

			showEarliest: function () {
				if (!this.filter || !this.filter.dueBy) {
					return false;
				}

				let values = ['before', 'after', 'between'];

				if (values.includes(this.filter.dueBy.value)) {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function () {
				if (!this.filter || !this.filter.dueBy) {
					return false;
				}

				if (this.filter.dueBy.value === 'between') {
					return true;
				} else {
					return false;
				}
			},

			statusList: function () {
				if (this.$store.state.deliverableStatusList) {
					return this.$store.state.deliverableStatusList.statusList;
				} else {
					return null;
				}
			},

			filterLabel: function () {
				let filterList = [];

				// if (this.filter.name && this.filter.name.length > 0) {
				// 	filterList.push('"' + this.filter.name + '"');
				// }

				if (this.filter.dueBy && this.filter.dueBy.value !== null) {
					let value = this.$t('projects.due') + ': ' + this.filter.dueBy.text;

					if (
						(this.filter.dueBy.value === 'before' ||
							this.filter.dueBy.value === 'after' ||
							this.filter.dueBy.value === 'between') &&
						this.filter.dueDate1
					) {
						value = value + ' ' + moment(this.filter.dueDate1).format('MMM DD');
					}

					if (this.filter.dueBy.value === 'between' && this.filter.dueDate2) {
						if (this.filter.dueDate1) {
							value = value + ' & ';
						}
						value = value + moment(this.filter.dueDate2).format('MMM DD');
					}

					filterList.push(value);
				}

				if (this.filter.status.length == 1) {
					filterList.push(this.filter.status.length + ' Status');
				}else if(this.filter.status.length > 1){
					filterList.push(this.filter.status.length + ' Statuses');
				}

				if (this.filter.assignedTo && this.filter.assignedTo.length > 0) {
					let list = [];
					this.filter.assignedTo.forEach((a) => {
						if (a === 'unassigned') {
							list.push(this.$t('projects.unassigned'));
						}
						let user = this.$store.getters.getUserById(a);
						if (user) {
							list.push(user.firstName);
						}
					});

					filterList.push(this.$t('projects.filter.assigned') + ': ' + list.join(', '));
				}

				if (this.filter.projects.length > 0) {
					filterList.push(
						this.filter.projects.length +
							' ' +
							this.$t('projects.filter.project') +
							(this.filter.projects.length > 1 ? 's' : '')
					);
				}

				if (filterList.length > 0) {
					return filterList.join(' | ');
				} else {
					return this.$t('projects.filter.filter');
				}
			},

			clientsProjects: function () {
				let clients = [];
				let map = new Map();

				let projects = JSON.parse(JSON.stringify(this.projects));
				projects.sort(this.sort);
				projects.forEach(p => p.id = p.clientId + '-' + p.id);

				for (let i = 0; i < projects.length; i++) {
					let p = projects[i];
					let c = p.client;
					delete p.client;
					if (!map.has(c.id)) {
						c.projects = [];
						c.isClient = true;
						map.set(c.id, c);
					}
					map.get(c.id).projects.push(p);
				}

				map.forEach((value) => {
					value.projects.unshift({id: value.id + '-null', name: '[No project]'});
					clients.push(value);
				});

				clients.sort(this.sort);

				console.log(clients);

				return clients;
			},

			userOptions: function () {
				const unassigned = {
					firstName: this.$t('projects.unassigned'),
					userId: 'unassigned',
				};
				const options = [...this.$store.state.usersForAccount];
				options.push(unassigned);
				return options;
			},
		},
	};
</script>

<style lang="scss">
	#project-filter {
		.v-treeview-node__root {
			margin-left: -15px !important;

			.tree-item {
			}

			.v-treeview-node__label {
				max-width: 156px;
			}
		}

		.v-treeview-node__level {
			width: 12px !important;
		}

		.assigned-user-name {
			max-width: 161px;
		}
	}
</style>

<style scoped lang="scss">
	.filter-wrapper {
		font-size: 14px;
		font-weight: 500;
		color: var(--v-primary-base);
		padding: 3px 8px 2px 6px;
		cursor: pointer;
		transition: all ease 250ms;

		&.filter-empty {
			width: 240px;
			&:hover {
				background-color: var(--v-gray_10-base);
			}
		}

		&.filter-active {
			background-color: var(--v-gray_10-base);
			color: var(--v-blue_70-base);
		}
	}

	#filterContainer {
		background-color: var(--v-white-base);
		min-width: 800px;
	}
</style>
