<template>
	<v-container fluid class="fill-height pa-0 ma-0" style="align-items: start">
		<v-row dense :class="$vuetify.breakpoint.mdAndUp ? 'fill-height' : ''">
			<v-col cols="12" md="4" :style="$vuetify.breakpoint.mdAndUp ? 'border-right: 1px solid var(--v-gray_30-base)' : ''" class="pt-6">
				<div style="max-height: calc(var(--vh) - 140px); overflow-y: auto" :class="`${$store.getters.scroll} pr-4`">
					<client-info :client="client" @edit-contact="openContactEdit($event)" @create-contact="openContactEdit({})" @edit-client="$emit('edit-client')" @unlink-accounting="$emit('unlink-accounting')"></client-info>
					<div class="font-18 text-left">Files</div>
					<file-attachments :edit-mode="true" :files="client.files" :file-service="attachmentService"></file-attachments>
				</div>
			</v-col>
			<v-col cols="12" md="8" align="center" :class="`${$vuetify.breakpoint.mdAndUp ? 'pl-5' : ''} pt-6`">
				<div style="max-height: calc(var(--vh) - 140px); overflow-y: auto; overflow-x: hidden" :class="`${$store.getters.scroll}`">
					<template v-if="$store.getters.hasFeatureAccess('projects')">
						<div class="font-18 text-left mb-3">Your client projects</div>
						<project-list class="mb-8" :client="client" :projects="projects"></project-list>
					</template>
					<div class="font-18 text-left mb-3">Your client insights</div>
					<client-insights @time-clicked="$emit('time-clicked')" @money-clicked="$emit('money-clicked')" :client="client"></client-insights>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import ClientAttachmentService from '@/modules/clients/detail/overview/ClientAttachmentService';
	import ClientInfo from '@/modules/clients/detail/overview/ClientInfo';
	import ContactEdit from '@/modules/clients/contacts/ContactEdit';
	import ProjectList from "@/modules/clients/detail/overview/ProjectList";
	import ClientInsights from "@/modules/clients/detail/overview/ClientInsights";
	import FileAttachments from "@/components/FileAttachments";

	export default {
		name: 'Overview',

		props: ['client', 'filter', 'isActive', 'currencies','projects'],

		components: {ProjectList, ClientInfo, ClientInsights, FileAttachments },

		data: function () {
			return {
				invoicingChartKey: 0,
				attachmentService: new ClientAttachmentService(this.client),
				currency: this.client.currency ? this.client.currency : this.$store.state.defaultCurrency,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openContactEdit: function (contact) {
				let binding = {
					id: contact.id,
					clientId: this.client.id
				};

				this.$store.state.globalModalController.openModal(ContactEdit, binding,  true, false, false, false).then((res) => {
					if (res) {
						let ix = this.client.contacts.findIndex(c => c.id === res.id);
						if(res.deleted){
							if(ix > -1) {
								this.client.contacts.splice(ix, 1);
							}
						}else{
							if(ix > -1) {
								this.client.contacts.splice(ix, 1,res);
							}else {
								this.client.contacts.push(res);
							}
						}
					}
				});
			},

			fixupWebsite: function (website) {
				if (website && website.startsWith('http')) {
					return website;
				} else {
					return '//' + website;
				}
			},
		},

		computed: {
			website: function () {
				if (this.client.website) {
					return this.client.website;
				} else {
					return '--';
				}
			},

			hourlyRate: function () {
				if (this.client.hourlyAmount) {
					return this.$formatters.dollars(this.client.hourlyAmount, true, true, this.client.currency);
				} else {
					return '--';
				}
			},

			phone: function () {
				if (this.client.phone) {
					return this.client.phone;
				} else {
					return '--';
				}
			},

			hasNotifications: function () {
				let types = [
					'DiscoveryCompleteNotification',
					'InvoiceApprovalNotification',
					'PaymentAppliedNotification',
					'ProposalCommentNotification',
					'ProposalSignedNotification',
				];

				return (
					this.$store.state.productivity.notifications.filter(
						(n) =>
							types.includes(n.notificationType) &&
							n.notificationPayload.client &&
							n.notificationPayload.client.id === this.client.id
					).length > 0
				);
			},
		},

		watch: {
			isActive: function (newVal) {
				if (newVal) {
					this.invoicingChartKey++;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
