<template>
	<div id="client-create" class="panel-modal" style="width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="handleClose(true)">$chevronRight</v-icon>
				<div class="brand-medium font-18">Create new client / prospect</div>
				<div class="ml-auto">
					<v-btn class="primary-action ml-1" :disabled="!isValid" @click="createClient">{{ $t('global.save') }}</v-btn>
					<v-btn text class="mr-1" @click="handleClose">{{ $t('global.cancel') }}</v-btn>
				</div>
			</div>
		</div>

		<div class="panel-modal-body show-scrollbar">
			<v-form id="clientCreate" ref="clientCreate" v-model="isValid" @keyup.enter.prevent>
				<div class="column-format gap-3">
					<div class="row-format mt-2">
						<v-menu
							bottom
							nudge-bottom="32"
							origin="top left"
							transition="scale-transition"
							:close-on-content-click="true"
						>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<client-avatar
										:arrow="editColor ? 'up' : 'down'"
										:client="client"
										:large="true"
										style="cursor: pointer; height: 52px"
										disable-click
									/>
								</div>
							</template>
							<div id="color-picker-div">
								<v-color-picker
									flat
									mode="hexa"
									show-swatches
									hide-canvas
									hide-mode-switch
									hide-inputs
									class="mx-auto"
									swatches-max-height="200"
									:swatches="palette"
									v-model="client.color"
								/>
							</div>
						</v-menu>
						<v-text-field
							:label="$t('client.client-name')"
							:placeholder="$t('client.enter-client-name') + ' (required)'"
							ref="clientName"
							autofocus
							hide-details
							dense
							outlined
							persistent-placeholder
							v-model="client.name"
							:rules="[() => !!client.name.trim() || '']"
						/>
					</div>

					<v-select
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Record type"
						v-model="client.clientType"
						:items="type"
						item-text="label"
						item-value="value"
					></v-select>

					<v-text-field
						v-model="client.leadSource"
						:label="$t('client.source')"
						hide-details
						dense
						outlined
						persistent-placeholder
					></v-text-field>

					<div class="font-16 text-left">Default contact info</div>

					<div class="row-format half">
						<v-text-field
							v-model="client.contacts[0].firstName"
							:label="$t('contact.first-name')"
							hide-details
							dense
							outlined
							persistent-placeholder
						/>

						<v-text-field
							v-model="client.contacts[0].lastName"
							:label="$t('contact.last-name')"
							hide-details
							dense
							outlined
							persistent-placeholder
						/>
					</div>

					<v-text-field
						v-model="client.contacts[0].email"
						:label="$t('contact.email')"
						validate-on-blur
						hide-details
						dense
						outlined
						persistent-placeholder
					/>

					<div style="width:100%; position: relative">
						<vue-tel-input
							v-model="phone"
							:valid-characters-only="true"
							:preferred-countries="preferredCountries"
							@input="phoneUpdated"
							class="dense-phone"
							:inputOptions="{ placeholder: 'Phone' }"
						></vue-tel-input>
						<div class="phone-label">Phone</div>
					</div>

					<div class="font-16 text-left">Business address</div>
					<v-text-field
						v-model="client.address1"
						:label="$t('contact.address1')"
						hide-details
						dense
						outlined
						persistent-placeholder
					/>

					<v-text-field
						v-model="client.address2"
						:label="$t('contact.address2')"
						hide-details
						dense
						outlined
						persistent-placeholder
					/>

					<div class="row-format half">
						<v-text-field
							v-model="client.city"
							:label="$t('contact.city')"
							hide-details
							dense
							outlined
							persistent-placeholder
						/>

						<v-text-field
							v-model="client.locality"
							:label="$t('contact.locality')"
							hide-details
							dense
							outlined
							persistent-placeholder
						/>
					</div>

					<div class="row-format half mt-n1">
						<v-text-field
							v-model="client.postal"
							:label="$t('contact.postal')"
							hide-details
							dense
							outlined
							persistent-placeholder
						/>
						<v-autocomplete
							v-model="client.country"
							:label="$t('contact.country')"
							autocomplete="new-password"
							dense
							outlined
							hide-details
							persistent-placeholder
							:items="countries.getCountriesSimple()"
							item-value="code"
							item-text="name"
						/>
					</div>
					<div class="font-16 text-left">General info</div>
					<div class="row-format half">
						<v-text-field
							v-model="client.website"
							:label="$t('client.website')"
							hide-details
							dense
							outlined
							persistent-placeholder
						/>

						<v-text-field
							hide-details
							dense
							outlined
							persistent-placeholder
							v-model="client.hourlyAmount"
							:label="$t('client.hourly-amount.label')"
							:prefix="currencyPrefix"
							min="0"
							type="number"
						></v-text-field>

						<v-text-field
								hide-details
								dense
								outlined
								persistent-placeholder
								v-model="client.taxId"
								label="Tax ID"
						></v-text-field>
					</div>

					<div class="font-12 font-gray_70 text-left pl-3 mb-n3">Notes</div>
					<editor
						v-model="notes"
						:api-key="$store.getters.getTinyMceKey"
						:inline="false"
						:init="mceConfig"
						:spellcheck="true"
					></editor>

					<custom-field
						v-for="field in clientFields"
						:key="field.id"
						mode="dense"
						:field="field"
						:value="getCustomValue(field.id)"
						@change="handleCustomFieldChange($event)"
					></custom-field>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import ClientService from './ClientService';
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import ScheduledMeetingService from '@/modules/meetings/ScheduledMeetingService';
	import ClientPalette from './ClientPalette.js';
	import ClientAvatar from '@/components/ClientAvatar';
	import Countries from '@/modules/utils/Countries.js';
	import DateTime from '@/modules/utils/HDateTime';
	import CustomField from '@/components/CustomField';
	import editor from '@tinymce/tinymce-vue';

	export default {
		name: 'ClientCreate',
		components: { ClientAvatar, CustomField, editor },
		isRightModal: true,

		data() {
			return {
				isReady: false,
				clientService: new ClientService(),
				formSubmissionService: new FormSubmissionService(),
				scheduledMeetingService: new ScheduledMeetingService(),
				clientPalette: new ClientPalette(),
				countries: new Countries(),
				isValid: false,
				phone: null,
				client: {
					name: '',
					address1: '',
					city: '',
					locality: '',
					postal: '',
					country: Countries.getCodeFromLocale(),
					phone: '',
					source: '',
					taxId: '',
					clientType: 'Client',
					customValues: [],
					contacts: [
						{
							firstName: '',
							lastName: '',
							phone: '',
							email: '',
							defaultContact: true,
							customValues: [],
						},
					],
					archive: false,
					color: '',
					hourlyAmount: null,
					paymentTerms: this.getDefaultTerms(),
				},
				notes: null,
				palette: null,
				editColor: false,

				type: [
					{ label: 'Prospect', value: 'Prospect' },
					{ label: 'Client', value: 'Client' },
				],

				rules: {
					required: (v) => !!v || 'Required',
					email: (v) => /^(.+@.+\..+)?$/.test(v) || this.$t('valid.email'),
				},

				mceConfig: {
					menubar: false,
					inline: false,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					toolbar: false,
					height: 200,
					resize: true,
					placeholder: 'Add a note...',
					forced_root_block: 'div',
					plugins: ['autolink', 'paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					default_link_target: '_blank',
					content_style:
						"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",
				},
			};
		},

		props: {
			clientList: Array,
			leadGen: {
				type: Object,
				required: false,
			},
			customValues: {
				type: Array,
				required: false,
				default: () => [],
			},
		},

		mounted() {
			this.palette = this.clientPalette.palette;
			this.initializeClient();
		},

		methods: {
			phoneUpdated(event, object) {
				this.client.phone = object.number;
				this.client.contacts[0].phone = object.number;
			},

			getDefaultTerms: function() {
				return {
					paymentDays: this.$store.state.podAccount.accountPreferences.defaultPaymentDays,
					invoicingSchedule: 'Monthly',
					latePaymentFee: this.$store.state.podAccount.accountPreferences.defaultLatePaymentFee,
					invoiceCreation: 'Manual',
					depositAmount: 0,
					depositType: 'No deposit',
					whoPaysCardFees: this.$store.state.podAccount.accountPreferences.defaultWhoPaysCardFees,
					fromProposalId: null,
					fromProposalSignedDate: null,
					updatedBy: null,
					updatedDate: null,
					firstInvoiceDate: DateTime.now()
						.startOf('month')
						.plus({ months: 1 })
						.toISODate(),
					lastInvoiceDate: null,
				};
			},

			initializeClient() {
				this.client.color = this.clientPalette.getRandomColor(this.clientColorsInUse());

				if (this.$refs.clientCreate) {
					this.$refs.clientCreate.resetValidation();
				}

				if (this.leadGen && this.leadGen.formData) {
					this.client.name = this.leadGen.formData.businessName ?? '';
					this.client.clientType = 'Prospect';
					this.client.address1 = this.leadGen.formData.address1;
					this.client.address2 = this.leadGen.formData.address2;
					this.client.city = this.leadGen.formData.city;
					this.client.locality = this.leadGen.formData.locality;
					this.client.postal = this.leadGen.formData.postal;
					this.client.phone = this.leadGen.formData.phone;
					this.phone = this.leadGen.formData.phone;
					this.client.website = this.leadGen.formData.website;
					this.client.leadSource = this.leadGen.formData.leadSource;
					this.client.taxId = this.leadGen.formData.taxId;
					this.client.contacts[0].firstName = this.leadGen.formData.firstName;
					this.client.contacts[0].lastName = this.leadGen.formData.lastName;
					this.client.contacts[0].email = this.leadGen.formData.email;
					this.client.contacts[0].phone = this.leadGen.formData.phone;
					this.client.contacts[0].role = this.leadGen.formData.role;
					this.client.contacts[0].defaultContact = true;
					this.client.contacts[0].portalAccess = this.$store.state.podAccount.accountPreferences.defaultPortalAccess;
					this.notes = this.leadGen.notes;

					if (this.leadGen.formData.answers) {
						for (let i = 0; i < this.leadGen.formData.answers.length; i++) {
							let answer = this.leadGen.formData.answers[i];
							this.mergeData(answer.fieldKey, answer.answer, this.clientFields, this.client.customValues);
							this.mergeData(
								answer.fieldKey,
								answer.answer,
								this.contactFields,
								this.client.contacts[0].customValues
							);
						}
					}

					for (let i = 0; i < this.customValues.length; i++) {
						let customValue = this.customValues[i];
						this.mergeData(customValue.mappingKey, customValue.value, this.clientFields, this.client.customValues);
						this.mergeData(
							customValue.mappingKey,
							customValue.value,
							this.contactFields,
							this.client.contacts[0].customValues
						);
					}
				} else {
					this.client.country = this.$store.state.podAccount.country;
				}

				this.client.hourlyAmount = this.$store.state.podAccount.accountPreferences.defaultHourlyRate;
				this.client.paymentTerms = this.getDefaultTerms();
				this.isReady = true;
			},

			mergeData: function(mappingKey, value, customFields, customValues) {
				let customField = customFields.find((f) => f.mappingKey === mappingKey);

				if (customField) {
					customValues.push({
						fieldId: customField.id,
						mappingKey: customField.mappingKey,
						fieldName: customField.fieldName,
						value: value,
						type: customField.type,
					});
				}
			},

			resetClient() {
				this.client = {
					name: '',
					address1: '',
					city: '',
					locality: '',
					postal: '',
					country: Countries.getCodeFromLocale(),
					phone: '',
					source: '',
					taxId: '',
					clientType: 'Client',
					customValues: [],
					contacts: [
						{
							firstName: '',
							lastName: '',
							phone: '',
							email: '',
							defaultContact: true,
							customValues: [],
						},
					],
					archive: false,
					color: '',
					hourlyAmount: null,
					paymentTerms: this.getDefaultTerms(),
				};
				this.notes = null;
			},

			createClient() {
				if (!this.$refs.clientCreate.validate()) {
					return false;
				}
				let ourClient = JSON.parse(JSON.stringify(this.client));

				if (!this.contactInPost()) {
					ourClient.contacts.splice(0, ourClient.contacts.length);
				}

				this.$store.commit('startLoading');
				this.clientService
					.postClient(ourClient, this.notes)
					.then((res) => {
						this.$onBoarding.track('create_client');
						if (this.leadGen) {
							this.updateFormData(res.data);
						} else {
							this.$emit('client-created', res.data);
							this.$emit('result', res.data);
						}
					})
					.catch((err) => {
						this.doAlert('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			updateFormData(client) {
				let data = this.leadGen.formData;
				data.clientId = client.id;
				if (this.leadGen.isFormSubmission) {
					this.formSubmissionService
						.updateFormSubmission(this.leadGen.id, data)
						.then(() => {
							this.$emit('client-created', client);
							this.$emit('result', client);
						})
						.catch((err) => {
							this.doAlert('error', err.response.data.message);
						})
						.finally(() => this.$store.commit('stopLoading'));
				} else if (this.leadGen.isScheduledMeeting) {
					this.scheduledMeetingService
						.updateScheduledMeeting(this.leadGen.id, data)
						.then(() => {
							this.$emit('client-created', client);
							this.$emit('result', client);
						})
						.catch((err) => {
							this.doAlert('error', err.response.data.message);
						})
						.finally(() => this.$store.commit('stopLoading'));
				} else {
					this.$emit('result', client);
				}
			},

			getCustomValue: function(id) {
				let value = this.client.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.client.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.client.customValues.splice(ix, 1, value);
					} else {
						this.client.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.client.customValues.push(value);
				}
			},

			clientColorsInUse() {
				return this.clientList.map((a) => a.color);
			},

			contactInPost() {
				const fields = Object.values(this.client.contacts[0]);
				for (let i = 0; i < fields.length; i++) {
					if (!this.$validations.isEmpty(fields[i])) return true;
				}
				return false;
			},

			doAlert(type, message) {
				this.$store.commit(type, message);
			},

			handleClose(force = false) {
				if (force) {
					this.$emit('close');
					this.$emit('result');
				}
			},
		},

		computed: {
			clientFields: function() {
				if (this.$store.state.clientFields) {
					return this.$store.state.clientFields.fields;
				} else {
					return [];
				}
			},

			contactFields: function() {
				if (this.$store.state.contactFields) {
					return this.$store.state.contactFields.fields;
				} else {
					return [];
				}
			},

			preferredCountries: function() {
				let countries = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ'];

				if (this.client && this.client.country) {
					countries.unshift(this.client.country);
				}

				return countries;
			},
			currencyPrefix: function() {
				return this.$formatters.currencySymbol(this.$store.state.defaultCurrency);
			},
			states: function() {
				if (this.client.country) {
					let country = this.countries.getCountries().find((c) => c.code === this.client.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},
		},
	};
</script>

<style lang="scss">
	#client-create {
		.row-format {
			column-gap: 8px;
			&.half {
				> div {
					flex: 0 1 50%;
				}
			}
		}
	}
</style>
