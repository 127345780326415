var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height column-format"},[_c('div',{staticClass:"py-3 px-5 row-format align-center",staticStyle:{"border-bottom":"1px solid var(--v-gray_30-base)"}},[_c('div',{staticClass:"font-14 brand-medium"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticStyle:{"width":"250px","color":"var(--v-gray_80-base)"},attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.search)},on:{"blur":function($event){_vm.showMenu = false},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('plus-button',{staticClass:"ml-auto",on:{"click":function($event){return _vm.addNew()}}})],1),_c('v-data-table',{class:_vm.$store.getters.scroll,staticStyle:{"cursor":"pointer"},attrs:{"items":_vm.filteredInvoiceTemplates,"fixed-header":true,"headers":_vm.headers,"hide-default-footer":true,"height":"calc(var(--vh) - 120px)","items-per-page":-1,"sort-by":"name"},on:{"click:row":function($event){return _vm.editTemplate($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DateTime.fromISO(item.created).toLocaleString(_vm.DateTime.DATE_MED))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('span',[_vm._v(_vm._s(_vm.DateTime.fromISO(item.updated).toLocaleString(_vm.DateTime.DATE_MED)))]):_c('span',[_vm._v("--")])]}},{key:"item.defaultTemplate",fn:function(ref){
var item = ref.item;
return [(item.defaultTemplate)?_c('v-icon',[_vm._v("check")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }