<template>
	<div>
		<div v-if="expenses.length === 0 && tableType" class="empty-state">
			<v-icon>$search</v-icon>
			<span v-html="noExpenseMessage"></span>
		</div>

		<div v-else class="data-table-wrapper">
			<v-data-table height="calc(100vh - 240px)"
				id="expenses-table"
				v-if="expenses"
				:class="'pointer list-view' + (dense ? ' h-dense' : '') + (outline ? ' h-outline' : '')"
				:item-class="itemClass"
				:headers="headers"
				sort-by="paidDate"
				sort-desc
				:header-props="{}"
				:items="fixedExpenses"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, -1]
				}"
				:items-per-page="itemsPerPage"
				:hide-default-footer="expenses.length <= itemsPerPage"
				@click:row="handleClick"
			>
				<template v-slot:item.amount="{ item }">
					<div v-if="item.total" class="brand-semilight">{{
							$formatters.dollars(item.total ? item.total : 0, true, true, item.currency)
						}}</div>
					<div class="font-12 font-gray_70 " v-if="item.localAmount && item.currency !== $store.state.defaultCurrency">
						{{$formatters.dollars(item.localAmount, true, true, $store.state.defaultCurrency)}}
					</div>
				</template>
				<template v-slot:item.category="{ item }">
					{{ formatCategory(item) }}
				</template>
				<template v-slot:item.description="{ item }">
					{{ item.description }}
				</template>
				<template v-slot:item.vendor="{ item }">
					{{ formatVendor(item) }}
				</template>
				<template v-slot:item.paidDate="{ item }">
					{{ formatPaid(item) }}
				</template>
				<template v-slot:item.paid="{ item }">
					{{ $formatters.yesNoFromBoolean(item.paid) }}
				</template>
				<template v-slot:item.dueDate="{ item }">
					{{ formatDue(item) }}
				</template>
				<template v-slot:item.dateCreated="{item}">
					<span v-if="item.dateCreated">{{DateTime.fromISO(item.dateCreated).toLocaleString({month:'short', day: 'numeric'})}}</span>
					<span v-else>--</span>
				</template>
				<template v-slot:item.reimbursable="{ item }">
					<div v-if="item.reimbursable && item.invoiceId">
						<span style="color: var(--v-primary-base)" class="pointer" @click.stop="routeToInvoice(item)"
							>Included in invoice #{{ item.invoiceNumber }}</span
						>
					</div>
					<div v-else-if="item.reimbursable">Not yet invoiced</div>
					<div v-else>No</div>
				</template>
				<template v-slot:item.clientId="{ item }">
					<div v-if="item.client" class="row-format" style="align-items: center">
						<client-avatar :client="item.client" left></client-avatar>
						<div v-if="item.project">{{ item.project.name }}</div>
					</div>
					<div v-else>--</div>
				</template>
				<template v-slot:item.statusLabel="{ item }">
					<div class="row-format align-center">
						<div class="brand-semilight font-12" v-html="getStatus(item)"></div>
					</div>
				</template>

				<template v-slot:item.edit="{ item }">
					<v-menu :close-on-click="true" :nudge-bottom="36">
						<template v-slot:activator="scope">
							<div class="ml-2">
								<v-btn
									v-if="scope.value"
									icon
									rounded
									color="primary"
									style="background-color: var(--v-primary-base)"
									v-on="scope.on"
								>
									<v-icon :size="20" color="white">$close</v-icon>
								</v-btn>
								<v-btn v-else icon rounded v-on="scope.on">
									<v-icon :size="20">$moreVertical</v-icon>
								</v-btn>
							</div>
						</template>
						<v-container class="bg-primary">
							<v-row>
								<v-col cols="12" align="left">
									<div>
										<v-btn text color="white" @click="handleDelete(item)">
											<v-icon left>$delete</v-icon> {{ $t('global.delete') }}
										</v-btn>
									</div>
									<div>
										<v-btn text color="white" @click="handleClick(item)">
											<v-icon left>$edit</v-icon>
											{{ $t('vendors.view-details') }}
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>
				</template>

				<template v-slot:no-data v-if="!suppressNew">
					<div @click="$emit('add-new')" class="py-5 pointer">
						<v-icon small class="mb-1">$plus</v-icon> Click to add your first expense
					</div>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from '@/components/ClientAvatar';
	import InvoiceDetails from "@/modules/invoices/InvoiceDetails";

	export default {
		name: 'ExpenseTable',

		props: {
			expenses: { type: Array, required: true },
			tableType: { type: String, required: false },
			dense: { type: Boolean, required: false, default: false },
			outline: { type: Boolean, required: false, default: false },
			suppressNew: {type: Boolean, required: false, default: false}
		},

		components: { ClientAvatar },

		data: function() {
			return {
				DateTime:DateTime,
				headers: this.getHeaders(),
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			handleClick: function(expense) {
				this.$emit('click', expense);
			},

			handleDelete: function(expense) {
				this.$emit('delete', expense);
			},

			getHeaders: function() {
				let headers = [];
				if (this.tableType && this.tableType.toUpperCase() === 'VENDOR') {
					headers = [
						{ text: this.$t('expenses.amount'), value: 'amount', class: 'nowrap' },
						{ text: this.$t('expenses.description'), value: 'description' },
						{ text: this.$t('expenses.paid'), value: 'paid', class: 'nowrap', sortable: false },
					];
					return headers;
				} else {
					headers = [
						{ text: this.$t('expenses.amount'), value: 'amount', class: 'nowrap' },
						{ text: this.$t('expenses.category'), value: 'category', class: 'nowrap' },
						{ text: this.$t('expenses.description'), value: 'description' },
						{ text: this.$t('expenses.paid'), value: 'paidDate', class: 'nowrap' },
						{ text: this.$t('expenses.due'), value: 'dueDate', class: 'nowrap' },
						{ text: this.$t('expenses.vendor'), value: 'vendor', class: 'nowrap' },
						{ text: this.$t('global.client'), value: 'clientId', class: 'nowrap' },
						{ text: this.$t('expenses.reimbursable'), value: 'reimbursable', class: 'nowrap' },
						{ text: this.$t('invoice.status'), value: 'statusLabel' },
						{ text: this.$t('expenses.created'), value: 'dateCreated' },

					];

					if (this.tableType) {
						headers.push({ text: this.$t('client.client'), value: 'clientId', class: 'nowrap' });
						headers.push({ text: '', value: 'edit', class: '' });
					}

					return headers;
				}
			},

			paidSort: function(a,b,desc){
				let dateA = a.paid ? a.paid : a.due;
				let dateB = b.paid ? b.paid : b.due;
				if(dateA && dateB){
					if(desc){
						return dateA.localeCompare(dateB);
					}else{
						return dateB.localeCompare(dateA);
					}
				}else if(dateA){
					return desc ? 1 : -1;
				}else if(dateB){
					return desc ? -1 : 1
				}else{
					return 0;
				}
			},

			routeToInvoice: function(expense) {
				let binding = {
					clientId: expense.clientId,
					id: expense.invoiceId,
				}
				this.$store.state.globalModalController.openModal(InvoiceDetails,binding,true,true);
			},

			formatPaid: function(item) {
				if (item.paidDate && item.paid) {
					return this.$formatters.formatForDaysAgo(item.paidDate);
				} else {
					return '--';
				}
			},

			formatDue: function(item) {
				if (item.dueDate) {
					return this.$formatters.formatForDaysAgo(item.dueDate);
				} else {
					return '--';
				}
			},

			formatCategory: function(item) {
				if (item.category) {
					return item.category;
				} else {
					return '--';
				}
			},

			formatVendor: function(item) {
				if (item.vendor) {
					return item.vendor.name;
				} else {
					return ' -- ';
				}
			},
			itemClass() {
				if (this.tableType && this.tableType.toUpperCase() == 'VENDOR') {
					return '';
				}
				return 'pointer';
			},

			getStatus(expense) {
				let background, color, label;

				if(expense.paid){
					background = '--v-success_10-base';
					color = '--v-success-base';
					label = 'Paid';
				}else{
					background = '--v-gray_10-base';
					color = '--v-gray_80-base';
					label = 'Not paid';
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},
		},

		computed: {
			fixedExpenses: function(){
				let result = [];
				this.expenses.forEach(e => {
					e.statusLabel = e.paid ? 'Paid' : 'Not paid';
					result.push(e);
				})
				return result;
			},

			totalSum() {
				let reducer = (accumulator, item) => {
					return accumulator + item.amount;
				};

				return this.expenses.reduce(reducer, 0);
			},

			itemsPerPage() {
				if (this.tableType && this.tableType !== 'Vendor') {
					return 10;
				} else {
					return 15;
				}
			},

			headingLabel() {
				if (this.tableType && this.tableType.toUpperCase() == 'VENDOR') {
					return this.$t('expenses.expenses');
				}
				return this.tableType ? this.$t('expenses.' + this.tableType.toLowerCase()) : '';
			},

			noExpenseMessage() {
				if (!this.tableType) return 'No expenses';
				if (this.tableType.toLowerCase() == 'categorized') return this.$t('expenses.no-categorized');
				if (this.tableType.toLowerCase() == 'uncategorized') return this.$t('expenses.no-uncategorized');
				if (this.tableType.toLowerCase() == 'vendor') return this.$t('expenses.no-vendor-expenses');
				return 'Unknown tableType';
			},
		},
	};
</script>

<style scoped lang="scss">
	.empty-state {
		border-radius: 4px;
		border: 1px solid var(--v-gray_50-base);
		background-color: var(--v-gray_20-base);
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
		.v-icon {
			margin-right: 8px;
		}
	}
</style>
