<template>
	<div class="column-format fill-height" v-if="isInitialized">
		<div class="row-format centered fill-height" style="flex: 1" v-if="!events.length">
			<empty-view
				header="No time entries yet"
				:body="
					`If you track your time for ${client.name}, your history of time entries will show up here. In addition to viewing historical entries here, you can also create new entries.`
				"
				cta="Create a time entry"
				video-header="See how it works"
				video-body="Learn how to track your time and then add those time entries to an invoice."
				video-cta="Watch the tutorial"
				video-id="3l1ThT9qGks"
				@cta-clicked="addNew()"
			></empty-view>
		</div>

		<div class="column-format gap-3 ma-3" v-if="events.length">
			<div class="row-format gap-3">
				<div class="kpi-box">
					<div class="fit text-left">Total hours</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.totalTime) }}</div>
				</div>
				<div class="kpi-box">
					<div class="row-format">
						<div class="row-format align-center fit">
							<div>{{ $t('timetrack.list.by-project') }}</div>
						</div>
					</div>
					<div class="row-format status-wrapper">
						<div
							class="status-box"
							v-for="project in metrics.projects"
							:key="project.name"
							:style="`width:${project.percentage}%; background-color: ${project.color}`"
							v-tippy="{ content: `${project.name} - ${formatSecondsToHours(project.totalTime)}` }"
						></div>
					</div>
				</div>
				<div class="kpi-box">
					<div class="fit text-left">{{ $t('timetrack.list.this-week') }}</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.timeThisWeek) }}</div>
				</div>
			</div>
			<div class="row-format gap-3" v-if="filter.timeEntryStatus.length !== 1">
				<div class="kpi-box" v-if="filter.timeEntryStatus.length === 0 || filter.timeEntryStatus.includes('BILLED')">
					<div class="row-format">
						<div class="fit text-left">Billed</div>
					</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.billed) }}</div>
				</div>
				<div class="kpi-box" v-if="filter.timeEntryStatus.length === 0 || filter.timeEntryStatus.includes('UN-BILLED')">
					<div class="row-format">
						<div class="fit text-left">Un-billed</div>
					</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.unBilled) }}</div>
				</div>
				<div
					class="kpi-box"
					v-if="filter.timeEntryStatus.length === 0 || filter.timeEntryStatus.includes('NON-BILLABLE')"
				>
					<div class="row-format">
						<div class="fit text-left">Non-billable</div>
					</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.nonBillable) }}</div>
				</div>
			</div>
		</div>

		<div v-if="events.length && !filteredEvents.length" class="mt-6">
			<empty-filtered-results></empty-filtered-results>
		</div>

		<div v-else-if="filteredEvents.length">
			<timer-event-table
				@add-new="addNew()"
				@duplicate="duplicate($event)"
				@edit-timer="editTimerEvent($event)"
				@open-invoice="openInvoice($event)"
				:events="filteredEvents"
			></timer-event-table>
		</div>
	</div>
</template>

<script>
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import DateTime from '@/modules/utils/HDateTime';
	import ClientPalette from '@/modules/clients/ClientPalette';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyView from '@/components/EmptyView';
	import EmptyFilteredResults from '@/components/EmptyFilteredResults';
	import { mapGetters } from 'vuex';
	import TimerEventTable from '@/modules/clients/detail/timetracking/TimerEventTable';

	export default {
		name: 'TimerEventList',

		props: ['client', 'isActive', 'filter'],

		components: { TimerEventTable, EmptyView, EmptyFilteredResults },

		data: function() {
			return {
				events: [],
				timeTrackService: new TimeTrackService(),
				isInitialized: false,
				DateTime: DateTime,
				clientPalette: new ClientPalette(),
			};
		},

		mounted() {
			if (this.isActive) {
				this.getTimerEvents();
			}
			this.$store.state.eventBus.$on('timer-list-reload', this.getTimerEvents);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('timer-list-reload', this.getTimerEvents);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
		},

		methods: {
			handleTimerStopEvent: function(event) {
				let timerEvent = event.message;
				if (this.client.id === timerEvent.clientId) {
					this.timeTrackService.getTimerEvent(timerEvent.id).then((res) => {
						this.events.push(res.data);
					});
				}
			},

			getTimerEvents: function() {
				this.timeTrackService.getTimerEventsFull(null, null, null, this.client.id, null, null).then((res) => {
					this.events.splice(0, this.events.length);
					this.events.push(...res.data);
					this.isInitialized = true;
				});
			},

			editTimerEvent: function(timerEvent) {
				let binding = {
					timerEvent: timerEvent,
					client: this.client,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.timerDeleted(timerEvent.id);
					} else {
						this.timerUpdated(res);
					}
				});
			},

			addNew: function(){
				this.addNewInternal(null);
			},

			addNewInternal: function(timerEvent = null) {
				let binding = {
					timerEvent: timerEvent,
					client: this.client,
				};
				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					this.timerCreated(res);
				});
			},

			duplicate: function(timerEvent) {
				timerEvent.id = null;
				this.addNewInternal(timerEvent);
			},

			timerUpdated(timerEvent) {
				if (timerEvent.clientId === this.client.id) {
					let ix = this.events.findIndex((e) => e.id === timerEvent.id);
					if (ix > -1) {
						this.events.splice(ix, 1, timerEvent);
					}
				} else {
					this.timerDeleted(timerEvent.id);
				}
			},

			timerCreated(timerEvent) {
				if (timerEvent.clientId === this.client.id) {
					this.events.push(timerEvent);
				}
			},

			timerDeleted(id) {
				let ix = this.events.findIndex((e) => e.id === id);
				if (ix > -1) {
					this.events.splice(ix, 1);
				}
			},

			createQuickInvoice: function() {
				this.$emit('quick-invoice');
			},

			openInvoice: function(invoiceId) {
				this.$emit('open-invoice', invoiceId);
			},

			formatSeconds: function(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				//let time = hours + ':' + minutes + ':' + seconds;
				let time = hours + ':' + minutes;
				return time;
			},

			formatSecondsToHours: function(seconds) {
				return Math.round(seconds / 60 / 60) + 'hr';
			},
		},

		computed: {
			...mapGetters({
				userId: 'getLoggedInUserId',
			}),

			filteredEvents: function() {
				let events = [...this.events];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let earliest, latest;

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
						earliest = DateTime.fromISO(this.filter.earliest).startOf('day');
						latest = DateTime.fromISO(this.filter.latest)
							.plus({ days: 1 })
							.minus({ seconds: 1 });
					} else if (this.filter.dateSpecifier !== 'between') {
						let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
						earliest = helper.earliest;
						latest = helper.latest;
					}
				}

				for (let i = 0; i < events.length; i++) {
					if (events[i].deliverableName) {
						events[i].deliverableOrNote = events[i].deliverableName;
					}else if(events[i].ticketName){
						events[i].deliverableOrNote = events[i].ticketName;
					} else if (events[i].notes) {
						events[i].deliverableOrNote = events[i].notes;
					}

					if (events[i].invoiceId) {
						events[i].billingStatus = 'BILLED';
					} else if (
						events[i].billable &&
						(!events[i].projectId ||
							(events[i].feeSchedule &&
								(events[i].feeSchedule.feeType === 'Hourly' ||
									(events[i].feeSchedule.feeType === 'Retainer' &&
										!!events[i].feeSchedule.retainerOverageRate))))
					) {
						events[i].billingStatus = 'UN-BILLED';
					} else {
						events[i].billingStatus = 'NON-BILLABLE';
					}
				}

				return events
					.filter((e) => {
						if (search) {
							if (
								(e.projectName && e.projectName.toLowerCase().includes(search)) ||
								(e.deliverableName && e.deliverableName.toLowerCase().includes(search)) ||
								(e.notes && e.notes.toLowerCase().includes(search))
							) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (earliest && latest) {
							let dateSent = DateTime.fromISO(e.timerStart);
							if (dateSent >= earliest && dateSent < latest) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.timeEntryStatus && this.filter.timeEntryStatus.length > 0) {
							if (this.filter.timeEntryStatus.includes(e.billingStatus)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.projectList.length > 0) {
							if (this.filter.projectList.includes(e.projectId)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			metrics: function() {
				let result = {
					totalTime: 0,
					unBilled: 0,
					billed: 0,
					nonBillable: 0,
					timeThisWeek: 0,
					projects: {},
				};

				let startOfWeek = DateTime.now().startOf('week');
				let colorsInUse = [];
				let projects = [...new Set(this.events.map((item) => (item.projectId ? item.projectId : '0')))];

				projects.forEach((p) => {
					let color = this.clientPalette.getRandomColor(colorsInUse);
					colorsInUse.push(color);
					result.projects[p] = { color: color, totalTime: 0 };
				});

				for (let i = 0; i < this.filteredEvents.length; i++) {
					let e = this.filteredEvents[i];
					result.totalTime += e.duration;

					if (e.invoiceId) {
						result.billed += e.duration;
					} else if (
						e.billable &&
						(!e.projectId ||
							(e.feeSchedule &&
								(e.feeSchedule.feeType === 'Hourly' ||
									(e.feeSchedule.feeType === 'Retainer' && !!e.feeSchedule.retainerOverageRate))))
					) {
						result.unBilled += e.duration;
					} else {
						result.nonBillable += e.duration;
					}

					if (DateTime.fromISO(e.timerStart) > startOfWeek) {
						result.timeThisWeek += e.duration;
					}

					let projectId = e.projectId ? e.projectId : '0';
					result.projects[projectId].totalTime += e.duration;
					result.projects[projectId].name = projectId === '0' ? '[No project]' : e.projectName;
				}

				for (const project in result.projects) {
					result.projects[project].percentage = Math.round(
						(result.projects[project].totalTime / result.totalTime) * 100
					);
				}

				for (const project in result.projects) {
					if (result.projects[project].totalTime === 0 || result.projects[project].percentage === 0) {
						delete result.projects[project];
					}
				}

				return result;
			},
		},

		watch: {
			isActive: function(newVal) {
				if (newVal && !this.isInitialized) {
					this.getTimerEvents();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.event-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}

	.status-wrapper {
		height: 36px;
	}

	.status-box {
		height: 100%;
		margin-right: 3px;
		border-radius: 2px;
	}

	.invoice-status {
		.default-label {
			display: block;
		}
		.hover-label {
			display: none;
		}

		&:hover {
			.default-label {
				display: none;
			}
			.hover-label {
				display: block;
			}
		}
	}

	.kpi-box {
		.create-invoice {
			display: none;
		}

		&:hover {
			.create-invoice {
				display: block;
			}
		}
	}
</style>
