import HttpClient from '@/services/HttpClient';

export default class FormSubmissionService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getFormSubmissions(clientId = null) {
        return this.httpClient.get('/formSubmissions',{
            params: {
                clientId: clientId
            }
        })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getFormSubmission(id) {
        return this.httpClient.get(`/formSubmissions/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getFormSubmissionByTemplate(templateId) {
        return this.httpClient.get(`/formSubmissions/template/${templateId}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    downloadFormSubmissionByTemplate(templateId){
        return this.httpClient
            .get(`/formSubmissions/template/${templateId}/download`,{responseType: 'blob'})
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    updateFormSubmission(id,formSubmission){
        return this.httpClient.put(`/formSubmissions/${id}`,formSubmission)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    deleteFormSubmission(id){
        return this.httpClient.delete(`/formSubmissions/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getSignedUrl(id, fileName) {
        return this.httpClient
            .get(`/formSubmissions/${id}/files?fileName=${encodeURIComponent(fileName)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}
