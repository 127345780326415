<template>
	<div id="create-invoice">
		<div class="row-format align-center mb-6 pt-6 px-6">
			<div class="brand-medium font-16">{{ heading }}</div>
			<v-icon size="20" class="ml-auto" @click="$emit('result')">$close</v-icon>
		</div>
		<div v-if="view === 'SELECT_CLIENT'" style="min-width: 400px">
			<div class="px-6">
				<select-client :key="clientRefreshKey"
					v-model="clientId"
					prepend-icon="$clients"
					label="Client"
					:hide-archive="true"
					:placeholder="$t('timetrack.current.choose-client')"
				></select-client>

				<div
					style="font-size: 14px; color: var(--v-primary-base)"
					class="pointer my-6"
					@click="newClientModal()"
					v-if="isPaidAccount || clientList.length == 0"
				>
					{{ $t('projects.create-client') }}
				</div>
			</div>

			<div style="border-top: 1px solid var(--v-gray_30-base)" class="py-2">
				<v-btn @click="handleSelectClient" width="160" class="primary-action" :disabled="nextButtonDisabled">
					{{ $t('global.next') }}
				</v-btn>
			</div>
		</div>

		<div id="select-type" v-if="view === 'SELECT_TYPE'" class="px-6 pb-6">
			<div class="pointer row-format button-box mb-2 pa-4" @click="createStandardInvoice()">
				<v-icon color="gray_80" style="align-self: flex-start">paid</v-icon>
				<div class="text-left pl-4">
					<div class="brand-medium font-16">{{ $t('invoice.invoice-types.STANDARD') }}</div>
					<div class="font-14 font-gray_80 mt-2">{{ $t('invoice.invoice-types.STANDARD-DESC') }}</div>
				</div>
			</div>
			<div class="pointer row-format button-box mb-2 pa-4" @click="view = 'DEPOSIT_AMOUNT'">
				<v-icon color="gray_80" style="align-self: flex-start">account_balance</v-icon>
				<div class="text-left pl-4">
					<div class="brand-medium font-16">{{ $t('invoice.invoice-types.DEPOSIT') }}</div>
					<div class="font-14 font-gray_80 mt-2">{{ $t('invoice.invoice-types.DEPOSIT-DESC') }}</div>
				</div>
			</div>
			<div class="pointer row-format button-box mb-2 pa-4" @click="createPaymentPlanInvoice()">
				<v-icon color="gray_80" style="align-self: flex-start">timeline</v-icon>
				<div class="text-left pl-4">
					<div class="brand-medium font-16">{{ $t('invoice.invoice-types.PAYMENTPLAN') }}</div>
					<div class="font-14 font-gray_80 mt-2">{{ $t('invoice.invoice-types.PAYMENTPLAN-DESC') }}</div>
				</div>
			</div>
			<div v-if="enableRecurring" class="pointer row-format button-box mb-2 pa-4" @click="createRecurringInvoice()">
				<v-icon color="gray_80" style="align-self: flex-start">replay_circle_filled</v-icon>
				<div class="text-left pl-4">
					<div class="brand-medium font-16">{{ $t('invoice.invoice-types.RECURRING') }}</div>
					<div class="font-14 font-gray_80 mt-2">{{ $t('invoice.invoice-types.RECURRING-DESC') }}</div>
				</div>
			</div>
			<div class="font-12 pb-2">- Or -</div>
			<v-btn block outlined small color="gray_50" @click="aiAssistant()"><span class="font-gray_80">Quick Invoice ✨Assistant</span></v-btn>
		</div>
		<div id="deposit-amount" v-if="view === 'DEPOSIT_AMOUNT'" style="width:450px">
			<div class="px-6 pb-6">
				<v-text-field
					v-model="depositAmount"
					:prefix="currencySymbol"
					hide-details
					class="h-outline"
					persistent-placeholder
					:label="$t('invoice.create-modal.deposit-label')"
					:placeholder="$t('invoice.create-modal.deposit-amount-placeholder')"
					@input="validateDepositAmount"
				></v-text-field>
			</div>

			<div style="border-top: 1px solid var(--v-gray_30-base)" class="py-2">
				<v-btn class="primary-action" width="160" :disabled="!depositAmount" @click="createDepositInvoice()">
					{{ $t('global.continue') }}
				</v-btn>
			</div>
		</div>

		<basic-modal :dialog="clientCreateDialog" v-on:close="clientCreateDialog = false" ref="clientCreateModal">
			<client-create
				v-on:close="clientCreateDialog = false"
				v-on:alert="$refs.clientCreateModal.setAlert($event)"
				v-on:client-created="clientCreated($event)"
				:is-open="clientCreateDialog"
				:clientList="clientList"
			></client-create>
		</basic-modal>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import InvoiceMixin from '@/modules/invoices/InvoiceMixin';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import BasicModal from '@/components/BasicModal';
	import SelectClient from '@/modules/clients/SelectClient';
	import ClientService from "@/modules/clients/ClientService";
	import AiChatWidget from "@/modules/ai/AiChatWidget";
	import InvoiceAssistantSample from "@/modules/invoices/InvoiceAssistantSample";

	export default {
		name: 'CreateInvoiceModal',
		components: { SelectClient, BasicModal, ClientCreate },
		mixins: [InvoiceMixin],
		props: {
			selectedClient: { type: Object, required: false },
			invoice: { type: Object, required: false },
			forceDeposit: {type: Boolean, required: false, default: false},
			enableRecurring: {type: Boolean, required: false, default: true},
		},

		data: function () {
			return {
				invoiceService: new InvoiceService(),
				clientId: null, // Selected client. May be preset using selectedClient prop (called from client detail for example)
				client: null,
				clientCreateDialog: false,
				view: null,
				depositAmount: null,
				clientRefreshKey: 0,
				clientService: new ClientService(),
				clientCurrency: this.$store.state.defaultCurrency
			};
		},

		mounted() {
			if (this.selectedClient) {
				this.clientId = this.selectedClient.id;
				if(this.forceDeposit){
					this.view = 'DEPOSIT_AMOUNT'
				}else{
					this.view = 'SELECT_TYPE';
				}
			} else {
				this.view = 'SELECT_CLIENT';
			}
		},

		methods: {
			handleSelectClient() {
				this.clientService.getClientCurrency(this.clientId).then((res) => {
					this.clientCurrency = res.data;
					this.view = 'SELECT_TYPE';
				})
			},

			validateDepositAmount: function () {
				this.depositAmount = this.depositAmount.replace(/[^\d.-]/g, '');
			},

			createInvoice(post) {
				this.$store.commit('startLoading');
				this.invoiceService
					.createInvoice(this.clientId, post)
					.then((res) => {
						const invoice = res.data;
						this.$emit('result', invoice);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						this.$emit('error', err);
					}).finally(() => this.$store.commit('stopLoading'));
			},

			createDepositInvoice() {
				let items = [];
				items.push({
					price: this.depositAmount,
					qty: 1,
					description: this.$t('invoice.create-modal.deposit-item-description'),
					taxable: this.$store.state.podAccount.accountPreferences.invoiceUseTax,
					type: 'DEPOSIT',
				});
				return this.createInvoice({ items: items, invoiceType: 'DEPOSIT' });
			},

			createStandardInvoice() {
				return this.createInvoice({ invoiceType: 'STANDARD' });
			},

			createRecurringInvoice(){
				this.$emit('result',{createRecurring: true, clientId: this.clientId});
			},

			createPaymentPlanInvoice(){
				this.$emit('result',{createPaymentPlan: true, clientId: this.clientId});
			},

			newClientModal() {
				this.clientCreateDialog = true;
			},

			clientCreated(client) {
				this.clientCreateDialog = false;
				this.clientList.push(client);
				this.clientId = client.id;
				this.clientRefreshKey++;
			},

			async aiAssistant() {
				let initialContext = [
					`You are helping a user to build an invoice for a client. You need to to get information for the line items and due date. Each line item needs a description, quantity, and rate and whether it is taxable - default for taxable is false.  Ask follow up questions until you have enough information to know the due date and line items only.`,
					'Once you have gathered enough context from the user, please output a human readable summary using clear sections and formatting and prompt the user to click the "Create" button if they are happy with the summary.',
				];
				let initialPrompt =
						'Please tell me about the invoice you need to send.  I need to know about the line items and the due date.';
				let finalizationPrompt = `Now that the user is happy with the summary, translate the information into the following JSON format. If you are unable to fulfill this request, respond only with the word "NO".  If you can satisfy the request,  respond with JSON and do not include any markdown,  metadata, explanations, or formatting. Here is an example of the JSON to return: ${JSON.stringify(
						InvoiceAssistantSample
				)}`;

				let binding = {
					maxHeight: 'calc(100vh - 350px)',
					context: initialContext,
					initialPrompt: initialPrompt,
					finalizationPrompt: finalizationPrompt,
					useCase: 'GeneralChat',
					model: 'gpt-4o',
					resultFormat: 'json',
				};

				this.$store.state.globalModalController.openModal(AiChatWidget, binding).then((res) => {
					if (res) {
						let simpleInvoice = JSON.parse(res);
						let post = {
							dateDue: simpleInvoice.dueDate,
							invoiceType: 'STANDARD' ,
							items: []
						}

						for(let i=0; i < simpleInvoice.lineItems.length; i++){
							post.items.push({
								price: simpleInvoice.lineItems[i].rate,
								qty: simpleInvoice.lineItems[i].quantity,
								description: simpleInvoice.lineItems[i].description,
								taxable: simpleInvoice.lineItems[i].taxable,
								type: 'ADHOC',
							});
						}

						this.createInvoice(post);
					}
				});
			},
		},


		filters: {},


		computed: {
			currency: function(){
				if(this.selectedClient && this.selectedClient.currency){
					return this.selectedClient.currency;
				}else{
					return this.clientCurrency;
				}
			},

			currencySymbol: function(){
				return this.$formatters.currencySymbol(this.currency);
			},

			clientSelectList() {
				let result = [];

				const values = this.clientList.map((a) => a.id);
				const names = this.clientList.map((a) => a.name);

				for (let i = 0; i < names.length; i++) {
					result.push({ text: names[i], value: values[i], disabled: false });
				}

				result.sort(function (a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				return result;
			},

			nextButtonDisabled() {
				return !this.clientId;
			},

			clientIsReady() {
				return !!this.client;
			},

			isPaidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			clientList() {
				return this.$store.getters.clients;
			},

			heading() {
				if (this.view === 'SELECT_CLIENT') {
					return this.$t('invoice.create-modal.select-client-heading');
				} else if (this.view === 'SELECT_TYPE') {
					return this.$t('invoice.invoice-type');
				} else if (this.view === 'DEPOSIT_AMOUNT') {
					return this.$t('invoice.create-modal.deposit-heading');
				} else {
					return '';
				}
			},
		},

		watch: {
			clientId: function(){

			}
		},
	};
</script>

<style lang="scss">
	#create-invoice {
		background-color: var(--v-white-base);
	}

	.button-box {
		width:282px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
