<template>
	<div style="max-height: calc(var(--vh) - 50px); overflow-y: auto" class="show-scrollbar">
		<communicator-widget :primary="true" :is-visible="communicatorVisible" v-show="focus === 'phone'"></communicator-widget>
		<notes-to-dos v-if="focus === 'productivity'"></notes-to-dos>
		<div v-show="focus === 'notifications'" class="ml-2 mt-2">
			<notifications :is-visible="notificationsVisible"></notifications>
		</div>
	</div>
</template>

<script>
	import CommunicatorWidget from '@/modules/communicator/realtime/CommunicatorWidget';
	import NotesToDos from "@/modules/home/focus/NotesToDos";
	import Notifications from "@/modules/productivity/notifications/Notifications";
	export default {
		name: 'RightNav',

		props: [],

		components: {Notifications, CommunicatorWidget, NotesToDos },

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			focus: function(){
				return this.$store.state.rightNavFocus;
			},

			communicatorVisible: function(){
				return this.$store.state.rightNavExpanded && this.focus === 'phone';
			},

			productivityVisible: function(){
				return this.$store.state.rightNavExpanded && this.focus === 'productivity';
			},

			notificationsVisible: function(){
				return this.$store.state.rightNavExpanded && this.focus === 'notifications';
			}
		},
	};
</script>

<style scoped lang="scss"></style>