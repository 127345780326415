<template>
	<div v-if="local">
		<div class="permission-dialog-div">
			<v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
				<v-icon size="20">$close</v-icon>
			</v-btn>
			<div class="modal-padding">
				<div class="row-format centered">
					<client-avatar :disable-click="true" :client="local"></client-avatar>
					<div class="ml-2">{{ local.name }}</div>
				</div>

				<div class="mt-4">
					<v-tabs v-model="tab">
						<v-tab>User access</v-tab>
						<v-tab>Custom feature access</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab">
						<v-tab-item>
							<div class="mt-7">
								<v-container fluid class="ma-0 pa-0" v-if="view === 'list'">
									<v-row class="mb-2">
										<v-col cols="2" class="brand-medium font-14" align="left">Access</v-col>
										<v-col cols="4" class="brand-medium font-14" align="left">Name</v-col>
										<v-col cols="6" class="brand-medium font-14" align="left">Email</v-col>
									</v-row>
									<v-row v-for="contact in local.contacts" :key="contact.id" align="center" class="mb-2">
										<v-col cols="2" align="left"
											><v-switch
												:disabled="!contact.email"
												v-model="contact.portalAccess"
												dense
												hide-details
												style="margin-top: 0px; width: 50px"
											></v-switch
										></v-col>
										<v-col cols="4" align="left" class="font-14"
											>{{ contact.firstName }} {{ contact.lastName }}</v-col
										>
										<v-col cols="5" align="left" class="font-14">{{ contact.email }}</v-col>
										<v-col cols="1" align="right" class="pointer" @click="editContact(contact)"
											><v-icon small class="pointer" color="primary">$edit</v-icon></v-col
										>
									</v-row>
									<v-row class="mb-2">
										<v-col cols="12" align="left">
											<div class="font-primary pointer row-format align-center" @click="addNewContact">
												<v-icon small color="primary" class="mr-1">$plus</v-icon>
												<div>Add new contact</div>
											</div>
										</v-col>
									</v-row>
								</v-container>
								<v-container fluid class="ma-0 pa-0" v-if="view === 'edit'" style="width: 100%">
									<v-row>
										<v-col cols="6">
											<v-text-field
												class="h-outline"
												hide-details
												persistent-placeholder
												label="First name"
												v-model="contactToEdit.firstName"
											></v-text-field>
										</v-col>
										<v-col cols="6">
											<v-text-field
												class="h-outline"
												hide-details
												persistent-placeholder
												label="Last name"
												v-model="contactToEdit.lastName"
											></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-text-field
												class="h-outline"
												hide-details
												persistent-placeholder
												label="Email"
												v-model="contactToEdit.email"
											></v-text-field>
										</v-col>
										<v-col cols="12"><v-btn class="primary-action" @click="editDone()">OK</v-btn></v-col>
									</v-row>
								</v-container>
							</div>
						</v-tab-item>
						<v-tab-item>
							<div class="mt-7 mb-2">
								<div v-if="clientFeatures">
									<div class="brand-medium text-left">Customized feature access:</div>
									<v-switch v-model="clientFeatures.projectsEnabled" label="Projects enabled" hide-details></v-switch>
									<v-switch v-model="clientFeatures.invoicesEnabled" label="Invoices enabled" hide-details></v-switch>
									<v-switch v-model="clientFeatures.summaryFinancialsEnabled" label="Financial reports / summary" hide-details></v-switch>
									<v-switch v-model="clientFeatures.proposalsEnabled" label="Agreements enabled" hide-details></v-switch>
									<v-switch v-model="clientFeatures.timeEnabled" label="Time worked enabled" hide-details></v-switch>
									<v-switch v-model="clientFeatures.meetingsEnabled" label="Meetings enabled" hide-details></v-switch>
									<v-switch v-model="clientFeatures.ticketsEnabled" label="Requests/tickets enabled" hide-details></v-switch>

									<div class="text-left">
										<v-btn class="mt-5" color="gray_70" outlined @click="disableCustomFeatures">Disable customized access</v-btn>
									</div>
								</div>

								<div v-else class="text-left">
									<div class="brand-medium text-left">Default access enabled</div>
									<v-btn outlined class="mt-3" color="primary" @click="enableCustomFeatures">Customize feature access</v-btn>
								</div>

							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>
		</div>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="pt-2 pb-3" v-if="view === 'list'">
			<v-btn class="secondary-action mr-2" @click="handleClose()">Cancel</v-btn>
			<v-btn class="primary-action" @click="handleSave()">Save</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import jsonPatch from 'fast-json-patch';
	import ClientService from '@/modules/clients/ClientService';
	import PortalFeaturesService from "@/modules/portal/PortalFeaturesService";

	export default {
		name: 'PermissionModal',
		components: { ClientAvatar },
		props: ['client'],

		data() {
			return {
				local: null,
				observer: null,
				clientService: new ClientService(),
				portalFeatureService: new PortalFeaturesService(),
				view: 'list',
				contactToEdit: null,
				tab: null,
				clientFeatures: null,
				features: null,
			};
		},

		mounted() {
			this.local = JSON.parse(JSON.stringify(this.client));
			this.observer = jsonPatch.observe(this.local);
			this.getFeatures();
		},

		methods: {
			handleSave() {
				if(this.clientFeatures){
					this.features.clientFeatures[this.client.id] = this.clientFeatures;
				}else{
					delete this.features.clientFeatures[this.client.id];
				}

				this.portalFeatureService.updatePortalFeatures(this.features).then(() => {
					let patch = jsonPatch.generate(this.observer);
					if (patch.length) {
						this.clientService.patchClient(this.client.id, patch).then((res) => {
							this.$emit('result', res.data);
						});
					}else{
						this.$emit('result');
					}
				});
			},

			getFeatures: function () {
				this.portalFeatureService.getPortalFeatures().then((res) => {
					this.features = res.data;
					if(this.features.clientFeatures[this.client.id]){
						this.clientFeatures = this.features.clientFeatures[this.client.id]
					}
				});
			},

			enableCustomFeatures: function(){
				this.clientFeatures = this.getDefaultFeatures();
			},

			disableCustomFeatures: function(){
				this.clientFeatures = null;
			},

			getDefaultFeatures: function(){
				return {
					projectsEnabled: this.features.projectsEnabled,
					invoicesEnabled: this.features.invoicesEnabled,
					proposalsEnabled: this.features.proposalsEnabled,
					timeEnabled: this.features.timeEnabled,
					meetingsEnabled: this.features.meetingsEnabled,
					ticketsEnabled: this.features.ticketsEnabled,
					summaryFinancialsEnabled: this.features.summaryFinancialsEnabled

				}
			},

			editContact: function (contact) {
				this.view = 'edit';
				this.contactToEdit = contact;
			},

			addNewContact: function () {
				let contact = {
					firstName: null,
					lastName: null,
					email: null,
				};

				this.local.contacts.push(contact);
				this.editContact(contact);
			},

			editDone: function () {
				this.view = 'list';
				this.contactToEdit = null;
			},

			handleClose() {
				this.$emit('result', false);
			},
		},
	};
</script>

<style lang="scss">
	.permission-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		min-width: 500px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
