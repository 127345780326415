<template>
	<div>

	</div>
</template>

<script>
export default {
	name: "DeliverableSettingsRouter",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
		let page = localStorage.getItem('PROJECT_SETTINGS_PAGE');
		if(!page || page !== 'status'){
			page = 'status';
		}
		this.$router.push('/project-settings/' + page);
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>