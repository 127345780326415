<template>
	<div class="container">
		<iframe :src="frameUrl" :id="`frame-${frameId}`" class="responsive-iframe"></iframe>
	</div>
</template>

<script>
	import iFrameResize from 'iframe-resizer/js/iframeResizer';
	import {v4 as uuid4} from 'uuid';

	export default {
		name: 'iFrameWrapper',

		props: ['frameUrl'],

		components: {},

		data: function () {
			return {
				frameId: uuid4().replaceAll("-",""),
				resizerOptions: {
					log: false,
					heightCalculationMethod: 'min',
					sizeWidth: true,
					sizeHeight: true,
				},
			};
		},

		mounted() {
			iFrameResize(this.resizerOptions, `#frame-${this.frameId}`);
			window.addEventListener('message',this.handleFrameMessage);
		},

		beforeDestroy() {
			window.removeEventListener('message',this.handleFrameMessage);
		},

		methods: {
			handleFrameMessage(e){
				if(e.data && (e.data === 'MEETING_RESCHEDULED' || e.data === 'MEETING_CANCELLED')){
					this.$emit('frame-event',e);
				}
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.container {
		width: 100%;
		min-height: 500px;
		background-color: #fff;
	}

	.responsive-iframe {
		padding: 0;
		margin: 0;
		border: 0;
		max-width: 100%;
		min-width: 100%;
	}
</style>
