<template>
	<div class="row-format">
		<v-menu
			:nudge-top="28"
			bottom
			right
			rounded
			offset-overflow
			offset-y
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<div :class="`pointer row-format align-center`" v-on="on">
					<div :class="`font-14 ${isFilterActive ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</div>
					<v-icon x-small class="mx-1" :color="isFilterActive ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</div>
			</template>

			<div class="column-format">
<!--				<v-text-field-->
<!--					@input="updated"-->
<!--					autofocus-->
<!--					solo-->
<!--					flat-->
<!--					hide-details-->
<!--					dense-->
<!--					placeholder="Search..."-->
<!--					v-model="textFilter"-->
<!--				></v-text-field>-->

				<div class="px-2 pt-3">
					<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="status">
						<v-radio label="Active" value="active"></v-radio>
						<v-radio label="Archived" value="archive"></v-radio>
						<v-radio label="All" value="all"></v-radio>
					</v-radio-group>
				</div>

				<div class="mx-3 mt-3 mb-3">
					<v-btn
						text
						block
						color="secondary"
						elevation="0"
						style="font-weight:500"
						class="mt-2"
						@click="clearFilter()"
						><v-icon small>$clear</v-icon> Clear filters</v-btn
					>
          <v-btn block color="primary" class="super-action" elevation="0" style="font-weight:500" @click="applyFilter()"
          >Apply</v-btn
          >
				</div>
			</div>
		</v-menu>
    <div class="pl-3 font-14 brand-medium">
      <input
          style="width: 85px; color: var(--v-gray_80-base)"
          @input="updated"
          type="text"
          v-model="textFilter"
          placeholder="Search..."
          @blur="showMenu=false"
      />
    </div>
	</div>
</template>

<script>
	export default {
		name: 'PermissionFilter',

		props: {},

		data() {
			return {
				showMenu: false,
				textFilter: null,
				status: 'active',
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('filter', {
					textFilter: this.textFilter,
					status: this.status
				});
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.textFilter = null;
				this.status = 'active';
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				if (this.textFilter || this.status !== 'active') {
					return true;
				} else {
					return false;
				}
			},

			filterLabel: function() {
				let label = [];

				// if (this.textFilter) {
				// 	label.push('"' + this.textFilter + '"');
				// }

				if(this.status !== 'active'){
					label.push(this.status)
				}

				if (label.length === 0) {
					return 'Filter...';
				} else {
					return label.join(' | ');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
