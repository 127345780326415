<template>
	<div class="fill-height column-format" id="accounting-report">
		<div class="py-3 px-5 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<report-filter :value="filter" v-if="filter" @updated="filterUpdated($event)"></report-filter>
			<div class="ml-auto row-format align-center gap-2">

				<v-select style="max-width: 190px" hide-details dense flat solo :items="['Month over month','Cumulative']" label="Chart mode" v-model="chartMode"></v-select>
				<v-btn class="ml-auto super-action" @click="$refs.profitAndLoss.downloadReport()">
					<div class="row-format align-center">
						<v-icon small class="mr-2">file_download</v-icon>
						<div>Download</div>
					</div>
				</v-btn>
			</div>
		</div>

		<div v-if="earliest && latest" class="show-scrollbar mt-4" style="max-height: calc(var(--vh) - 125px); overflow-y: auto">
			<div class="content-container pa-4">
				<div style="overflow-x: auto">
					<p-l-report ref="profitAndLoss" :earliest="earliest" :latest="latest" :refresh-key="refreshKey" :chart-mode="chartMode"></p-l-report>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PLReport from '@/modules/accounting/reports/PLReport';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';
	import ReportFilter from '@/modules/accounting/reports/ReportFilter';
	import FilterHelpers from '@/utils/FilterHelpers';

	export default {
		name: 'ReportViewer',

		props: [],
		mixins: [AccountingMixin],

		components: { ReportFilter, PLReport },

		data: function() {
			return {
				chartMode: 'Month over month',
				reportType: null,
				refreshKey: 0,
				filter: null,
				showMenu: false,
				filterStateKey: 'ACCOUNTING_PL_FILTER_STATE',
			};
		},

		mounted() {
			localStorage.setItem('ACCOUNTING_PAGE', 'reports');
			this.$store.commit('setBreadcrumbs', [{ to: this.$route.path, text: 'Financial reports' }]);
			this.reportType = 'PLReport';
			this.initFilter();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function() {
				this.refreshKey++;
			},

			initFilter: function() {
				try {
					let filterState = localStorage.getItem(this.filterStateKey);
					if (!this.$validations.isEmpty(filterState)) {
						this.filter = JSON.parse(filterState);
					} else {
						this.filter = {
							dateRange: 'past-6-months',
							earliest: null,
							latest: null,
						};
					}
				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}
			},

			filterUpdated: function(filter) {
				this.filter = filter;
				this.refreshKey++;
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},
		},

		computed: {
			filterLabel() {
				let current = this.dateOptions.find((v) => v.value === this.filter.dateRange);
				if (!current) return '...';
				return current.text;
			},

			earliest: function() {
				if (this.filter && this.filter.dateRange) {
					if (this.filter.dateRange !== 'between') {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateRange, false).earliest.toISODate();
					} else {
						return this.filter.earliest;
					}
				} else {
					return null;
				}
			},

			latest: function() {
				if (this.filter && this.filter.dateRange) {
					if (this.filter.dateRange !== 'between') {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateRange, false).latest.toISODate();
					} else {
						return this.filter.latest;
					}
				} else {
					return null;
				}
			},
		},
	};
</script>

<style lang="scss"></style>
