<template>
	<div class="">
		<v-btn icon class="close-dialog" @click="$emit('close')" v-if="!inListView">
			<v-icon>$close</v-icon>
		</v-btn>

		<div :class="inListView ? '' : 'modal-padding'" v-if="!modalState.inSendMode">
			<h2 class="modal-title" v-if="!inListView">{{ $t('leadgen.client-discovery-forms') }}</h2>
			<v-data-table
				:items="filteredDiscoveryTemplates"
				sort-by="name"
				:fixed-header="true"
				:headers="headers"
				height="calc(var(--vh) - 120px)"
				:items-per-page="-1"
				style="cursor: pointer"
				:hide-default-footer="true"
				@click:row="navigateToDiscoveryBuilder($event)"
				:class="$store.getters.scroll"
				group-by="folder"
			>
				<template v-slot:group.header="{ group, headers, toggle }">
					<td colspan="4" class="text-left" @click="toggle">
						<div class="row-format align-center gap-2">
							<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ? group : ' -- ' }}
						</div>
					</td>
				</template>

				<template v-slot:item.link="{ item }">
					{{ item.uniqueTemplateId }}
				</template>
				<template v-slot:item.actions="{ item }">
					<v-menu :close-on-click="true" :nudge-bottom="36">
						<template v-slot:activator="scope">
							<div class="ml-1 mr-4">
								<v-icon :size="24" v-on="scope.on">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</div>
						</template>

						<div class="more-menu">
							<div v-if="clientId" class="more-menu-item" @click="sendTemplate(item)">
								Send
							</div>
							<div class="more-menu-item" @click="navigateToDiscoveryBuilder(item)">
								Edit
							</div>
							<div class="more-menu-item" @click="$emit('download-results', item)" v-if="item.schemaVersion !== 1">
								Download results
							</div>
							<div class="more-menu-item" @click="cloneTemplate(item)">
								Duplicate
							</div>
							<div class="more-menu-item" @click="confirmDeleteTemplate(item)">
								Delete
							</div>
						</div>
					</v-menu>
				</template>

				<template v-slot:no-data>
					<search-button @click="templateLibrary()"></search-button>
				</template>
			</v-data-table>

			<div v-if="showV1Warning" class="text-left row-format align-center font-12 ml-4 gap-1 font-gray_70">
				<v-icon x-small>warning</v-icon>
				<div>Legacy forms are no longer supported in this flow and are hidden from this list</div>
			</div>

			<confirm-dialog
				v-if="templateToDelete"
				:dialog="confirmDeleteDialog"
				@confirm="deleteTemplate"
				@close="cancelDeleteTemplate"
				:persistent="false"
				heading-text="Delete template"
				:body-text="$t('discovery.delete-confirm', { template: templateToDelete.name })"
			/>
		</div>
		<div class="" v-if="modalState.inSendMode">
			<share-send-widget
				:client-id="clientId"
				mode="Send"
				:discovery-template="templateToSend"
				:mce-config-text="mceConfigText"
				:open-state="modalState.inSendMode"
				@cancel="modalState.inSendMode = false"
				@discovery-sent="handleDiscoverySent()"
			></share-send-widget>
		</div>
	</div>
</template>

<script>
	import defaultSchema from './defaultSchema.json';
	import DiscoveryTemplateService from './DiscoveryTemplateService';
	import ShareSendWidget from '@/modules/discovery/ShareSendWidget';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import AccountService from '@/modules/account/AccountService';
	import SearchButton from '@/modules/templates/library/SearchButton';
	import TemplateLibrary from '@/modules/templates/library/TemplateLibrary';
	import AiChatWidget from '@/modules/ai/AiChatWidget';
	import sampleForm from '@/modules/forms/sample-form.json';
	import schemaTypes from '@/modules/forms/SchemaTypes.json';

	export default {
		name: 'DiscoveryTemplates',

		props: ['clientId', 'discoveryOrigin', 'modalState', 'inListView', 'v2Only', 'search'],
		components: { SearchButton, ShareSendWidget, ConfirmDialog },

		data: function() {
			return {
				uniqueAccountUrl: null,
				baseUrl: process.env.VUE_APP_MEET_LINK,
				defaultSchema: defaultSchema,
				discoveryTemplateService: new DiscoveryTemplateService(),
				discoveryTemplateList: [],
				templateToDelete: null,
				templateToSend: null,
				confirmDeleteDialog: false,
				showV1Warning: false,

				mceConfigText: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: [],
					paste_as_text: true,
					toolbar: [],
				},
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$on('forms_onboarding', this.handleOnboardingRequest);

			this.getDiscoveryTemplates();
			this.getUniqueAccountUrl();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$off('forms_onboarding', this.handleOnboardingRequest);
		},

		methods: {
			handleOnboardingRequest: function(type) {
				if (type === 'search-library') {
					this.templateLibrary();
				} else {
					this.saveNewTemplate();
				}
			},

			getUniqueAccountUrl: function() {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			sendTemplate: function(template) {
				this.templateToSend = template;
				this.modalState.inSendMode = true;
			},

			handleDiscoverySent: function() {
				this.$emit('close');
			},

			handleAccountChange: function() {
				this.$emit('close');
			},

			getDiscoveryTemplates: function() {
				this.discoveryTemplateList = [];
				this.discoveryTemplate = null;

				this.discoveryTemplateService
					.getDiscoveryTemplateList()
					.then((res) => {
						let result = [...res.data];
						let dataLength = result.length;

						if (this.v2Only) {
							result = result.filter((r) => r.schemaVersion !== 1);
							this.showV1Warning = dataLength !== result.length;
						}

						if (result.length === 0) {
							this.initializeDefaultTemplate();
						} else {
							this.discoveryTemplateList = result;
							this.discoveryTemplate = this.discoveryTemplateList[0];
						}
					})
					.catch((err) => {
						this.$store.commit('error', err.response.message);
					});
			},

			saveNewTemplate: function(schemaV2 = []) {
				this.$store.commit('startLoading');
				let template = {
					id: null,
					name: 'New form',
					isDefault: false,
					schema: [],
					schemaV2: schemaV2,
					schemaVersion: 2,
					font: this.$store.state.podAccount.accountPreferences.brandingPreferences.font,
					accentColor: this.$store.state.podAccount.accountPreferences.brandingPreferences.color,
				};

				this.discoveryTemplateService
					.createDiscoveryTemplate(template)
					.then((res) => {
						this.$onBoarding.track('forms_create_form');
						this.$emit('template-selected', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					}).finally(() => this.$store.commit('stopLoading'));
			},

			async aiAssistant() {
				let initialContext = [
					`You are helping a user to build a data capture form definition that can be used with a form builder tool.`,
					`This JSON defines all of the types of elements that the form builder supports.  It is provided only for context, the user shouldn't see this info. ${JSON.stringify(
						schemaTypes
					)}`,
					`Your output to the user should be summary data about the form formatted text with headings, bullet points, and descriptions.  Do not output JSON yet.`,
					`The valid values for 'schemaMapping' are: businessName, leadSource, role, firstName, lastName, address1, address2, city, locality, postal, country, website, phone, email, taxId`,
					`The valid values for 'validateFor' are: Email, Phone`,
					`The valid values for 'id' are UUID V4`,
					'Once you have gathered enough context from the user, please output a human readable summary using clear sections and formatting.  and prompt the user to click the "Create" button if they are happy with the summary.',
				];
				let initialPrompt =
					'Please tell me a about the form so I can help set everything up. You can start by describing all of the information you want to capture.';
				let finalizationPrompt = `Please translate the form summary into JSON. If you are unable to fulfill this request, respond only with the word "NO".  If you can satisfy the request respond with JSON only. Do not include any metadata, explanations, or markdown formatting. Here is an example of the JSON format: ${JSON.stringify(
					sampleForm
				)}`;

				let binding = {
					maxHeight: 'calc(100vh - 350px)',
					context: initialContext,
					initialPrompt: initialPrompt,
					finalizationPrompt: finalizationPrompt,
					useCase: 'GeneralChat',
					model: 'gpt-4o',
					resultFormat: 'json',
				};

				this.$store.state.globalModalController.openModal(AiChatWidget, binding).then((res) => {
					if (res) {
						this.saveNewTemplate(JSON.parse(res));
					}
				});
			},

			templateLibrary: function() {
				let binding = {
					type: 'Form',
				};
				this.$store.state.globalModalController.openModal(TemplateLibrary, binding).then((template) => {
					if (template) {
						this.$onBoarding.track('forms_create_form');
						this.$emit('template-selected', template);
					}
				});
			},

			initializeDefaultTemplate: function() {
				// let template = {
				// 	id: null,
				// 	name: 'Sample Discovery Form',
				// 	isDefault: true,
				// 	schemaV2: defaultSchema,
				// 	schemaVersion: 2,
				// 	font: this.$store.state.podAccount.accountPreferences.brandingPreferences.font,
				// };
				//
				// this.discoveryTemplateService
				// 	.createDiscoveryTemplate(template)
				// 	.then((res) => {
				// 		this.discoveryTemplateList.push(res.data);
				// 	})
				// 	.catch((err) => {
				// 		this.$store.commit('error', err.response.data.message);
				// 	});
			},

			cloneTemplate: function(copyTarget) {
				let template = JSON.parse(JSON.stringify(copyTarget));
				template.id = null;
				template.name = 'Copy of: ' + template.name;

				this.discoveryTemplateService
					.createDiscoveryTemplate(template)
					.then((res) => {
						this.discoveryTemplateList.push(res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			confirmDeleteTemplate: function(template) {
				this.templateToDelete = template;
				this.confirmDeleteDialog = true;
			},

			deleteTemplate: function() {
				this.discoveryTemplateService
					.deleteDiscoveryTemplate(this.templateToDelete.id)
					.then(() => {
						this.getDiscoveryTemplates();
						this.cancelDeleteTemplate();
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			cancelDeleteTemplate: function() {
				this.templateToDelete = null;
				this.confirmDeleteDialog = false;
			},

			navigateToDiscoveryBuilder: function(template) {
				if (this.template === null) {
					this.$store.commit('error', this.$t('discovery.elect-error'));
					return;
				}

				this.$emit('template-selected', template);
			},

			fullTemplateLink: function(template) {
				if (template) {
					let commPref = this.$store.state.communicationPreferences;
					if (commPref && commPref.primaryDomain) {
						return (
							'https://' +
							commPref.primaryDomain +
							'/public/' +
							this.uniqueAccountUrl +
							'/' +
							template.uniqueTemplateId
						);
					} else {
						return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + template.uniqueTemplateId;
					}
				} else {
					return null;
				}
			},
		},

		computed: {
			folders: function() {
				return this.discoveryTemplateList
					.filter((e) => e.folder)
					.map((e) => e.folder)
					.filter((value, index, self) => self.indexOf(value) === index);
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			headers: function() {
				let result = [
					{ text: 'Name', value: 'name', class: '' },
					{ text: 'Id', value: 'link', class: '' },
				];

				if (this.discoveryOrigin !== 'clientDetail') {
					result.push({ text: '', value: 'actions', class: '', align: 'right' });
				}

				return result;
			},

			filteredDiscoveryTemplates: function() {
				if (this.search) {
					return this.discoveryTemplateList.filter((t) => {
						let search = this.search.toLowerCase();
						return t.name.toLowerCase().includes(search);
					});
				} else {
					return this.discoveryTemplateList;
				}
			},
		},

		watch: {
			folders: function() {
				this.$emit('folder-list', this.folders);
			},

			discoveryTemplateList: function(val) {
				this.$emit('template-list', val);
			},
		},
	};
</script>

<style lang="scss"></style>
