<template>
	<div class="row-format fill-height">
		<div class="left-side"><slot name="left"></slot></div>
		<div class="right-side"><slot name="right"></slot></div>
	</div>
</template>

<script>
	export default {
		name: 'WelcomeContainer',

		props: [],

		components: {},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.left-side {
		height: 100%;
		width: 40%;
	}

	.v-application.theme--light {
		.right-side {
			height: 100%;
			width: 60%;
			background-color: var(--v-primary-base);
			box-shadow: 0px 0px 16px rgba(0, 43, 82, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.3);
			border-radius: 16px;
		}
	}

	.v-application.theme--dark {
		.right-side {
			height: 100%;
			width: 60%;
			background-color: var(--v-gray_30-base);
			box-shadow: 0px 0px 16px rgba(0, 43, 82, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.3);
			border-radius: 16px;
		}
	}
</style>
