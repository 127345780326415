<template>
	<div id="vendor-filter">
<!--		<v-menu v-model="showMenu" :close-on-content-click="false">-->

<!--			<template v-slot:activator="{ on }">-->
<!--				<div :class="`pointer row-format align-center`" v-on="on">-->
<!--					<div :class="`font-14 ${!isDefaultFilter ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{filterLabel}}</div>-->
<!--					<v-icon x-small class="mx-1" :color="!isDefaultFilter ? 'secondary' : 'gray_80'">$arrowDown</v-icon>-->
<!--				</div>-->
<!--			</template>-->

			<div class="column-format open-menu">
				<v-text-field
					id="search-input"
					v-model="filterClone"
					autofocus
					solo
					flat
					hide-details
					dense
					@keyup="$emit('updated', filterClone)"
					:placeholder="$t('vendors.filter-placeholder')"
				></v-text-field>

<!--				<div class="mx-3 mt-3 mb-2">-->
<!--					<v-btn text block color="secondary" elevation="0" class="filter-clear mt-2 mb-1" @click="clearFilter()"-->
<!--					><v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn-->
<!--					>-->
<!--					<v-btn class="primary-action" block @click="showMenu = false">{{ $t('global.done') }}</v-btn>-->
<!--				</div>-->
			</div>
<!--		</v-menu>-->
		<span
			v-if="!!filter"
			class="clear-filter-in-activator"
			@click="$emit('clear-filter')"
			v-tippy="{ content: $t('global.reset-to-defaults') }"
			><v-icon size="16">$close</v-icon></span
		>
	</div>
</template>

<script>
	import { cloneDeep } from 'lodash';
	export default {
		name: 'VendorFilter',

		props: ['filter'],

		components: {},

		data: function() {
			return {
				showMenu: false,
				filterClone: '',
			};
		},

		mounted() {
			this.filterClone = cloneDeep(this.filter);
		},

		beforeDestroy() {},

		methods: {
			clearFilter: function() {
				this.$emit('clear-filter');
			},

			getStatusText(status) {
				const tmp = this.statusOptions.find((v) => v.value == status);
				if (!tmp) return '';
				return tmp.text;
			},
		},

		watch: {
			menu: function(newVal) {
				if (!newVal) {
					console.log('updating');
					this.$emit('updated');
				}
			},
		},

		computed: {
			isDefaultFilter: function(){
				if(!this.filter){
					return true;
				}else{
					return false;
				}
			},

			filterLabel: function() {
				if (!this.filter) {
					return this.$t('vendors.filter');
				}

				let filterList = [];

				if (this.filter && this.filter.length > 0) {
					filterList.push('"' + this.filter + '"');
				}

				if (filterList.length > 0) {
					return filterList.join('<span>|</span>');
				} else {
					return this.$t('invoice.filter.filter');
				}
			},
		},
	};
</script>

<style lang="scss">
	#vendor-filter {
		width: 240px;
		position: relative;
		.clear-filter-in-activator {
			position: absolute;
			right: 6px;
		}
	}
	.open-menu {
		background-color: var(--v-white-base);
		width: 240px;
		* {
			max-width: 240px;
		}
	}
</style>
