<template>
		<v-container fluid>
			<v-row>
				<v-col align="center">
					<gantt-chart
							:deliverables="deliverablesWithDates"
							:start-date="start"
							:end-date="end"
							:visible="visible"
							@edit-deliverable="$emit('edit-deliverable',$event)"
							@dates-updated="$emit('dates-updated',$event)">
					</gantt-chart>
				</v-col>
			</v-row>
		</v-container>
</template>

<script>


import GanttChart from "@/modules/projects/management/gantt/GanttChart";
import DateTime from "@/modules/utils/HDateTime";
export default {
	name: "GanttView",

	props: ['deliverables','headerHeight','visible'],

	components: {
		GanttChart
	},

	data: function () {
		return {
			startTime: 0,
		}
	},

	beforeMount(){
		this.startTime = Date.now();
	},

	mounted() {
		console.log('GanttView mounted in',Date.now()-this.startTime);
		this.$store.commit('stopLoading')
	},

	beforeDestroy() {
	},

	methods: {

	},

	computed: {
		deliverablesWithDates: function(){
			let result = [];
			for(let i=0; i < this.deliverables.length; i++){
				let d = JSON.parse(JSON.stringify(this.deliverables[i]));
				if(d.startDate || d.dueDate){
					d.start = DateTime.fromISO(d.startDate ? d.startDate : d.dueDate).startOf('day');
					d.end = DateTime.fromISO(d.dueDate ? d.dueDate : d.startDate).endOf('day');
					d.status = this.$store.getters.getDeliverableStatusById(d.statusId);
					result.push(d);
				}
			}

			return result;
		},

		start: function(){
			if(this.deliverablesWithDates.length){
				let earliest = this.deliverablesWithDates[0].start;
				for(let i=0; i < this.deliverablesWithDates.length; i++){
					if(this.deliverablesWithDates[i].start < earliest){
						earliest = this.deliverablesWithDates[i].start;
					}
				}
				return earliest.startOf('month');
			}

			return DateTime.now().startOf('month');
		},

		end: function(){
			if(this.deliverablesWithDates.length){
				let latest = this.deliverablesWithDates[0].end;
				for(let i=0; i < this.deliverablesWithDates.length; i++){
					if(this.deliverablesWithDates[i].end > latest){
						latest = this.deliverablesWithDates[i].end;
					}
				}
				return latest.endOf('month');
			}
			return DateTime.now().endOf('month');
		}
	},

}
</script>

<style scoped lang="scss">

</style>