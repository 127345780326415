<template>
	<div>
		<div v-if="isPaid">
			<div class="py-3 pl-2 pr-2 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
				<div>
					<account-user-filter slot="filter" @filter="setFilter($event)"></account-user-filter>
				</div>

				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<plus-button class="ml-auto mr-2" v-on="on"></plus-button>
					</template>

					<div class="add-new-dropdown">
						<div v-for="(item, index) in addNew" :key="index" @click="handleAddNew(item.value)" class="add-new-item">
							{{ item.label }}
						</div>
					</div>
				</v-menu>
			</div>

			<div
				v-if="communicatorEntitlement && communicatorEntitlement.currentUsed > communicatorEntitlement.maxAllowed"
				class="row-format centered pa-3 gap-3"
				style="background-color: var(--v-secondary-base); border-right: 4px;"
			>
				<v-icon color="white">warning</v-icon>
				<div style="color: var(--v-white-base)">
					You currently have more active communicator lines than you are subscribed to. Please disconnect
					{{ communicatorEntitlement.currentUsed - communicatorEntitlement.maxAllowed }} lines or
					<router-link to="/subscription/add-ons" style="color: white!important;"
						>increase your subscription</router-link
					>
					add ons to re-enable this service.
				</div>
				<v-icon color="white">warning</v-icon>
			</div>

			<div
				v-if="aiEntitlement && aiEntitlement.currentUsed > aiEntitlement.maxAllowed"
				class="row-format centered pa-3 gap-3"
				style="background-color: var(--v-secondary-base); border-right: 4px;"
			>
				<v-icon color="white">warning</v-icon>
				<div style="color: var(--v-white-base)">
					You currently have more active Moxie Assistant licenses assigned than you are subscribed to. Please disconnect
					{{ aiEntitlement.currentUsed - aiEntitlement.maxAllowed }} licenses or
					<router-link to="/subscription/add-ons" style="color: white!important;"
						>increase your subscription</router-link
					>
					add ons to re-enable this service.
				</div>
				<v-icon color="white">warning</v-icon>
			</div>

			<v-data-table
				:headers="headers"
				:items="filteredUsers"
				:hide-default-footer="true"
				height="calc(var(--vh) - 160px)"
				:fixed-header="true"
				style="cursor: pointer"
				:items-per-page="-1"
				:key="refreshKey"
			>
				<template v-slot:item.name="{ item }">
					<div class="row-format align-center">
						<assigned-user v-if="item.active" :assigned-to="item.userId" :show-name="false"></assigned-user>
						<v-avatar size="25" v-else color="gray_70">
							<span style="color: white">{{ item.name.substr(0, 1).toUpperCase() }}</span>
						</v-avatar>
						<div class="ml-2">{{ item.name }}</div>
						<v-icon small color="gray_70" class="ml-2 pointer" @click="openLink(item.email)">$email</v-icon>
					</div>
				</template>

				<template v-slot:item.email="{ item }">
					<span>{{ item.email }}</span>
				</template>

				<template v-slot:item.communicatorSubscription="{ item }">
					<div v-if="item.communicatorSubscription">
						{{ item.communicatorSubscription.phone }}
						<v-icon
							size="20"
							class="material-symbols-rounded pointer"
							color="red"
							@click.stop="disconnectPhoneLine(item)"
							v-tippy="{ content: 'Disconnect phone line' }"
							>cancel</v-icon
						>
					</div>
					<div v-else>--</div>
				</template>

				<template v-slot:item.aiEntitlement="{ item }">
					<v-menu>
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<div v-if="item.original.aiEntitlement">
									<v-icon class="material-symbols-rounded" color="primary" v-tippy="{content:'Assigned Moxie Assistant License'}">smart_toy</v-icon>
								</div>
								<div v-else>--</div>
							</div>
						</template>
						<div class="more-menu">
							<div class="more-menu-item" @click="processLicenseRequest(item)">
								{{ item.original.aiEntitlement ? 'Revoke Moxie Assistant license' : 'Grant Moxie Assistant license' }}
							</div>
						</div>
					</v-menu>
				</template>

				<template v-slot:item.userType="{ item }">
					<div v-if="item.userType === 'OWNER'">Account owner</div>
					<div v-else-if="item.userType === 'FULL_USER'">Full access</div>
					<div v-else-if="item.userType === 'RESTRICTED_USER'" class="truncate" @click="manageFeatures(item)">
						Restricted {{ getFeatureList(item.original.featureAccess) }} features
					</div>
					<div v-else-if="item.userType === 'IMPLEMENTER'">Moxie implementer</div>
					<div v-else @click="manageProjects(item)">
						<div
							v-for="projectId in item.expanded ? item.projects : item.projects.slice(0, 1)"
							:key="projectId"
							:set="(p = getProject(projectId))"
							class="row-format align-center mb-2"
						>
							<client-avatar small :client="p.client"></client-avatar>
							<div style="margin-left: -10px">{{ p.name }}</div>
							<div
								v-if="!item.expanded && item.projects.length > 1"
								class="font-gray_70 font-14 ml-3 pointer"
								@click.stop="setProjectsExpanded(item, true)"
							>
								+ {{ item.projects.length - 1 }} more
							</div>
						</div>
						<div
							v-if="item.expanded"
							class="font-gray_70 font-14 mt-1 pointer"
							@click.stop="setProjectsExpanded(item, false)"
						>
							Show less
						</div>
					</div>
				</template>

				<template v-slot:item.since="{ item }">
					<div v-if="item.active">{{ DateTime.fromISO(item.since).toFormat('DD') }}</div>
					<div v-else>Pending (invited {{ DateTime.fromISO(item.since).toFormat('DD') }})</div>
				</template>

				<template v-slot:item.action="{ item }">
					<user-action-menu
						v-if="item.userType !== 'OWNER'"
						:user="item"
						@revoke-account="revokeAccess(item)"
						@manage-projects="manageProjects(item)"
						@revoke-invite="revokeInvitation(item)"
						@manage-features="manageFeatures(item)"
					></user-action-menu>
				</template>
			</v-data-table>
		</div>
		<div class="mx-2 mt-8 row-format centered" v-else style="height: calc(var(--vh) - 100px); overflow-x: auto">
			<empty-view
				header="It’s better together!"
				body="The Essentials plan has everything you need for one person. To invite team members and get more done, upgrade to Pro or Teams."
				cta="Upgrade now!"
				video-header="See how it works"
				video-body="Learn how to invite collaborators so they can help you run everything or just work on a single project."
				video-cta="Watch the tutorial"
				video-id="sklxEyWWfjU"
				@cta-clicked="ctaClicked()"
			></empty-view>
		</div>
	</div>
</template>

<script>
	import UserService from '@/modules/users/UserService';
	import ProjectService from '@/modules/projects/ProjectService';
	import DateTime from '@/modules/utils/HDateTime';
	import AccountUserFilter from '@/modules/users/AccountUserFilter';
	import ClientAvatar from '@/components/ClientAvatar';
	import { v4 as uuid4 } from 'uuid';
	import ConfirmModal from '@/components/ConfirmModal';
	import UserActionMenu from '@/modules/users/UserActionMenu';
	import AssignedUser from '@/components/AssignedUser';
	import InviteDialog from '@/modules/users/InviteDialog';
	import ProjectListDialog from '@/modules/users/ProjectListDialog';
	import EmptyView from '@/components/EmptyView';
	import PlusButton from '@/components/PlusButton';
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import FeatureListDialog from '@/modules/users/FeatureListDialog';
	import AiAssistantService from '@/modules/ai/AiAssistantService';

	export default {
		name: 'AccountUsers',

		props: [],

		components: { EmptyView, AssignedUser, UserActionMenu, ClientAvatar, AccountUserFilter, PlusButton },

		data: function() {
			return {
				DateTime: DateTime,
				userService: new UserService(),
				projectService: new ProjectService(),
				communicatorService: new CommunicatorService(),
				aiAssistantService: new AiAssistantService(),
				communicatorEntitlement: null,
				aiEntitlement: null,
				compositeUserList: [],
				users: [],
				invitations: [],
				projects: [],
				filter: null,
				refreshKey: 0,

				headers: [
					{ text: 'Id', value: 'userId' },
					{ text: 'User', value: 'name' },
					{ text: 'Email', value: 'email' },
					{ text: 'Access', value: 'userType' },
					{ text: 'Since', value: 'since' },
					{ text: 'Moxie assistant', value: 'aiEntitlement' },
					{ text: 'Communicator', value: 'communicatorSubscription' },
					{ text: '', value: 'action', align: 'right' },
				],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'collaborators' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.initialize();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			getFeatureList: function(featureAccess) {
				let result = [];
				if (featureAccess.projects) result.push('projects');
				if (featureAccess.invoices) result.push('invoices');
				if (featureAccess.accounting) result.push('accounting');
				if (featureAccess.pipeline) result.push('pipeline');
				if (featureAccess.agreements) result.push('agreements');
				if (featureAccess.settings) result.push('settings');
				if (featureAccess.timesheets) result.push('timesheets');
				if (featureAccess.tickets) result.push('tickets');
				return result.length;
			},

			getCommunicatorEntitlement: function() {
				this.communicatorService.getEntitlementStatus().then((res) => {
					this.communicatorEntitlement = res.data;
				});
			},

			disconnectPhoneLine: function(user) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Please confirm you want to disconnect ${user.communicatorSubscription.phone}. Once this line is disconnected, it will not be available to to re-activate. <br><br>Please enter ${user.communicatorSubscription.phone} below to confirm this action.`,
					confirmText: user.communicatorSubscription.phone,
					noText: 'Cancel',
					yesText: `Disconnect`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.communicatorService
							.cancelSubscription(user.communicatorSubscription.id)
							.then(() => {
								this.initialize();
							})
							.catch((err) => this.$store.commit('error', err.response.data.message))
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			initialize: function() {
				this.getCommunicatorEntitlement();

				let userPromise = this.getUsers();
				let invitePromise = this.getInvitations();
				let projectPromise = this.getProjects();
				let communicatorPromise = this.getCommunicatorSubscriptions();
				let aiPromise = this.getAiEntitlement();

				Promise.all([userPromise, invitePromise, projectPromise, communicatorPromise, aiPromise]).then((values) => {
					let users = values[0].data;
					let invites = values[1].data;
					let projects = values[2].data;
					let communicatorSubscriptions = values[3].data;
					this.aiEntitlement = values[4].data;

					this.users.splice(0, this.users.length);
					this.users.push(...users);

					this.users.forEach((u) => {
						u.communicatorSubscription = communicatorSubscriptions.find((c) => c.userId === u.userId);
						u.aiEntitlement = this.aiEntitlement.assignedLicenses.includes(u.userId);
					});

					this.invitations.splice(0, this.invitations.length);
					this.invitations.push(...invites);

					this.projects.splice(0, this.projects.length);
					this.projects.push(...projects);
					this.projects.sort((a, b) => {
						let c = a.client.name.localeCompare(b.client.name);
						if (c === 0) {
							return a.name.localeCompare(b.name);
						} else {
							return c;
						}
					});

					this.getCompositeUserList();
				});
			},

			ctaClicked: function() {
				this.$emit('close');
				setTimeout(() => this.$router.push('/subscription'), 500);
			},

			openLink: function(email) {
				window.open(`mailto:${email}`, '_blank');
			},

			getUsers: function() {
				return this.userService.getAccountUsers(this.$store.getters.getAccountId);
			},

			getCommunicatorSubscriptions() {
				return this.communicatorService.getSubscriptionsForAccount();
			},

			getAiEntitlement() {
				return this.aiAssistantService.getEntitlementStatus();
			},

			processLicenseRequest: function(user) {
				if (user.original.aiEntitlement) {
					this.aiAssistantService
						.revokeEntitlement(user.userId)
						.then((res) => {
							this.aiEntitlement = res.data;
							let ix = this.users.findIndex(u => u.userId === user.userId);
							let foundUser = this.users[ix];
							foundUser.aiEntitlement = false;
							this.refreshKey++;
						})
						.catch((err) => {console.log(err); this.$store.commit('error', err.response.data.message)});
				} else {
					this.aiAssistantService
						.grantEntitlement(user.userId)
						.then((res) => {
							this.aiEntitlement = res.data;
							let ix = this.users.findIndex(u => u.userId === user.userId);
							let foundUser = this.users[ix];
							foundUser.aiEntitlement = true;
							this.refreshKey++;
						})
						.catch((err) => {console.log(err); this.$store.commit('error', err.response.data.message)});
				}
			},

			getInvitations: function() {
				return this.userService.getPendingInvitations(this.$store.getters.getAccountId);
			},

			getProjects: function() {
				return this.projectService.getAllActiveProjects();
			},

			handleAccountChange: function() {
				this.users.splice(0, this.users.length);
				this.invitations.splice(0, this.invitations.length);
				this.initialize();
			},

			handleAddNew: function(type) {
				let t = this.isTeams || type === 'IMPLEMENTER' ? type : 'COLLABORATOR';
				let binding = {
					type: type,
				};

				if (t === 'COLLABORATOR') {
					binding.projects = this.projects;
				}

				this.$store.state.globalModalController.openModal(InviteDialog, binding).then((res) => {
					if (res) {
						this.initialize();
					}
				});
			},

			manageProjects: function(user) {
				let binding = {
					projects: this.projects,
					user: user,
				};
				this.$store.state.globalModalController.openModal(ProjectListDialog, binding).then((res) => {
					if (res) {
						this.initialize();
					}
				});
			},

			manageFeatures: function(user) {
				let binding = {
					user: user,
				};
				this.$store.state.globalModalController.openModal(FeatureListDialog, binding).then((res) => {
					if (res) {
						this.initialize();
					}
				});
			},

			revokeAccess: function(user) {
				if (user.communicatorSubscription) {
					this.$store.commit(
						'error',
						'You cannot revoke this users access to the workspace while they have an active communicator phone number.  Please disconnect the phone number by clicking the red (X).'
					);
					return;
				}

				let binding = {
					headingText: 'Revoke access?',
					bodyText: 'Are you sure you want to revoke this users access to the workspace?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false).then((res) => {
					if (res) {
						this.userService
							.revokeAccountAccess(this.$store.getters.getAccountId, user.email)
							.then(() => {
								this.initialize();
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},

			revokeInvitation: function(invitation) {
				let binding = {
					headingText: 'Revoke invitation?',
					bodyText: 'Are you sure you want to revoke this users invitation?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false).then((res) => {
					if (res) {
						this.userService
							.revokeInvitation(this.$store.getters.getAccountId, invitation.email)
							.then(() => {
								this.initialize();
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},

			getCompositeUserList: function() {
				this.compositeUserList.splice(0, this.compositeUserList.length);

				this.users.forEach((u) => {
					let projects = [];

					if (u.projectAccess) {
						u.projectAccess.projects.forEach((p) => projects.push(p.projectId));
					}
					let user = {
						id: uuid4(),
						userId: u.userId,
						original: u,
						name: u.firstName + ' ' + u.lastName,
						email: u.email,
						userType: u.userType,
						since: u.createdAt,
						active: true,
						projects: projects,
						expanded: false,
						communicatorSubscription: u.communicatorSubscription,
					};
					this.compositeUserList.push(user);
				});

				this.invitations.forEach((i) => {
					let user = {
						id: uuid4(),
						original: i,
						name: i.email,
						email: i.email,
						userType: i.userType,
						since: i.invitedAt,
						active: false,
						projects: i.projectIds ? i.projectIds : [],
						expanded: false,
					};
					this.compositeUserList.push(user);
				});
			},

			getProject: function(projectId) {
				let project = this.projects.find((p) => p.id === projectId);
				if (project) {
					return {
						id: projectId,
						name: project.name,
						client: project.client,
					};
				} else {
					return {
						id: projectId,
						name: '[Deleted/inactive project]',
						client: {
							id: '',
							name: '??',
							color: '#D6D1CB',
						},
					};
				}
			},

			setProjectsExpanded: function(user, state) {
				let ix = this.compositeUserList.findIndex((u) => u.id === user.id);
				let u = this.compositeUserList[ix];
				u.expanded = state;
				this.compositeUserList.splice(ix, 1, u);
			},

			setFilter: function(filter) {
				this.filter = filter;
			},
		},

		computed: {
			pages: function() {
				let result = [];
				result.push({ label: 'Team members', value: 'TeamMembers' });
				return result;
			},

			addNew: function() {
				let result = [];
				if (this.isTeams) {
					result.push({ label: 'Full access team member', value: 'FULL_USER' });
					result.push({ label: 'Restricted access team member', value: 'RESTRICTED_USER' });
				}
				result.push({ label: 'Project collaborator', value: 'COLLABORATOR' });
				result.push({ label: 'Approved Moxie implementer', value: 'IMPLEMENTER' });
				return result;
			},

			filteredUsers: function() {
				if (this.filter) {
					return this.compositeUserList.filter(
						(u) => (u.name && u.name.includes(this.filter)) || (u.email && u.email.includes(this.filter))
					);
				} else {
					return this.compositeUserList;
				}
			},

			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},

			isTeams: function() {
				return this.$store.getters.isTeamAccount;
			},
		},
	};
</script>

<style scoped lang="scss">
	.empty-box {
		min-width: 320px;
		width: 320px;
		height: 330px;
	}
</style>
