var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"py-3 pl-4 pr-2 row-format align-center",staticStyle:{"border-bottom":"1px solid var(--v-gray_30-base)"}},[_c('plus-button',{staticClass:"ml-auto mr-2",on:{"click":_vm.createNewWorkspace}})],1),_c('v-data-table',{attrs:{"items":_vm.workspaces,"headers":_vm.headers,"hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.account.accountId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.account.accountId))])]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":item.account.accountLogo}})],1)]}},{key:"item.accountName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.account.accountName))])]}},{key:"item.userType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.userType.replace("_"," ").toLowerCase()))])]}},{key:"item.account.subscriptionType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSubscriptionType(item.account)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canDoAction)?_c('div',[(item.userType === 'OWNER')?_c('v-btn',{attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.confirmDeleteWorkspace(item.account)}}},[_vm._v("Delete workspace")]):_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmLeaveWorkspace(item.account)}}},[_vm._v("Leave workspace")])],1):_c('div',[_vm._v(" -- ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }