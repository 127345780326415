<template>
	<div style="max-height: calc(var(--vh) - 70px); overflow-y: auto" class="">
		<div v-if="paidAccount " style="height: calc(100% - 50px)">
			<mailboxes v-if="page === 'mailboxes'"></mailboxes>
			<subscription-router v-if="page === 'phone'"></subscription-router>
			<ticket-settings v-if="page === 'requests'"></ticket-settings>
		</div>
		<div v-if="!paidAccount" class="column-format centered mt-8 px-3">
			<free-tier-card
				style="width: 100%; height: 150px"
				message="One universal inbox for everything important - Voice, Messaging, Email. Communicator is only available on the Pro or Teams plan.  Upgrade now to claim your business phone number and connect your inbox."
				@upgrade-clicked="$router.push('/subscription')"
			></free-tier-card>

			<div class="mt-8"><a href="https://help.withmoxie.com/en/articles/6369542-communicator-overview" target="_blank">Click here to learn more about Communicator!</a></div>
		</div>
	</div>
</template>

<script>
	import FreeTierCard from '@/components/FreeTierCard';
	import Mailboxes from "@/modules/communicator/settings/Mailboxes";
	import SubscriptionRouter from "@/modules/communicator/settings/providers/SubscriptionRouter";
	import TicketSettings from "@/modules/communicator/settings/TicketSettings";

	export default {
		name: 'CommunicatorSettings',

		props: ['page'],

		components: {TicketSettings, SubscriptionRouter, Mailboxes, FreeTierCard },

		data: function () {
			return {

			};
		},

		mounted() {

		},

		beforeDestroy() {

		},

		methods: {

		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
