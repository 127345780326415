<template>
	<div class="pa-2">
		<v-container fluid>
			<v-row dense>
				<v-col cols="12">
					<v-select
						v-model="importType"
						hide-details
						class="h-outline"
						persistent-placeholder
						label="What type of data?"
						:items="definitions"
						item-value="type"
						item-text="label"
					>
					</v-select>

					<v-file-input
						hide-details
						class="h-outline"
						persistent-placeholder
						prepend-icon="attach_file"
						label="Select import file"
						accept=".csv"
						v-model="importFile"
						@change="handleInput"
					></v-file-input>

					<div v-if="parsedData.length" class="font-14 my-3">{{ parsedData.length }} records found</div>

					<v-btn class="primary-action" :disabled="!parsedData.length" @click="mapColumns">Next: Map columns</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<v-container class="mt-8" fluid>
			<v-row>
				<v-col cols="12">
					<v-data-table
						:headers="headers"
						sort-by="importDateTime"
						:sort-desc="true"
						:items="imports"
						:items-per-page="-1"
						:hide-default-footer="true"
						:disable-pagination="true"
						:mobile-breakpoint="900"
					>
						<template v-slot:item.importDateTime="{ item }">
							{{ DateTime.fromISO(item.importDateTime).toLocaleString(DateTime.DATETIME_MED) }}
						</template>

						<template v-slot:item.id="{ item }">
							<div class="row-format pointer" @click="confirmDelete(item)">
								<v-icon size="20" class="ml-auto mr-1 pointer" style="">$delete</v-icon>
							</div>
						</template>

						<template v-slot:no-data> No previous imports. </template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import Mapper from '@/modules/account/import/Mapper';
	import Papa from 'papaparse';
	import definitions from './definitions.json';
	import ImportService from '@/modules/account/import/ImportService';
	import DateTime from '@/modules/utils/HDateTime';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'Import',

		props: [],

		components: {},

		data: function() {
			return {
				importService: new ImportService(),
				definitions: definitions,
				importType: 'Client',
				importFile: null,
				parsedData: [],
				imports: [],
				DateTime: DateTime,
			};
		},

		mounted() {
			this.getImports();
		},

		beforeDestroy() {},

		methods: {
			confirmDelete: function(item) {
				if (item.successfulRecords > 0) {
					let binding = {
						headingText: 'Confirm Delete!',
						bodyText: `Are you sure you want to delete the ${item.successfulRecords} ${item.importType} records from this import?  <b>This absolutely cannot be undone.</b><br><br>Please type "Delete ${item.successfulRecords} ${item.importType}s" to confirm.`,
						confirmText: `Delete ${item.successfulRecords} ${item.importType}s`,
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((res) => {
						if (res) {
							this.confirmDeleteRelated(item);
						}
					});
				} else {
					this.$store.commit('startLoading');
					this.importService
						.deleteImport(item.id)
						.then(() => {
							this.getImports();
						})
						.catch((err) => this.$store.commit('error', err.response.data.message))
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			confirmDeleteRelated: function(item) {
				let binding = {
					headingText: 'Delete related data?',
					bodyText: `Would you also like to delete any clients or projects that were created during the import?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((res) => {
					this.$store.commit('startLoading');
					this.importService
						.deleteImport(item.id, !!res)
						.then(() => {
							this.getImports();
						})
						.catch((err) => this.$store.commit('error', err.response.data.message))
						.finally(() => this.$store.commit('stopLoading'));
				});
			},

			getImports: function() {
				this.importService.getImports().then((res) => {
					this.imports.splice(0, this.imports.length);
					this.imports.push(...res.data);
				});
			},

			handleInput: function() {
				if (!this.importFile) {
					this.parsedData.splice(0, this.parsedData.length);
					return;
				}

				let config = {
					dynamicTyping: false,
					skipEmptyLines: true,
					complete: this.parseComplete,
					quoteChar: '"',
					fastMode: false,
				};

				Papa.parse(this.importFile, config);
			},

			parseComplete: function(results) {
				this.parsedData = results.data;
			},

			mapColumns: function() {
				let binding = {
					inputData: this.parsedData,
					definition: this.definition,
				};
				this.$store.state.globalModalController.openModal(Mapper, binding, false, true).then(() => this.getImports());
			},
		},

		computed: {
			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			headers: function() {
				return [
					{ text: 'Imported on', value: 'importDateTime' },
					{ text: 'Import type', value: 'importType' },
					{ text: 'Imported by', value: 'importedBy' },
					{ text: 'Records imported', value: 'successfulRecords' },
					{ text: 'Records rejected', value: 'rejectedRecords' },
					{ text: '', value: 'id', sortable: false },
				];
			},

			definition: function() {
				return this.definitions.find((d) => d.type === this.importType);
			},
		},
	};
</script>

<style scoped lang="scss"></style>
