<template>
	<div class="column-format" style="flex: 1" v-if="isReady">
		<v-data-table
			:headers="headers"
			:items="filteredSubmissions"
			@click:row="openFormSubmission($event)"
			style="cursor: pointer"
			sort-by="submittedAt"
			sort-desc
			:disable-pagination="true"
			:hide-default-footer="true"
		>
			<template v-slot:item.submittedAt="{ item }">
				<div class="row-format align-center gap-2">
				<div>{{
						DateTime.fromISO(item.submittedAt).toFormat("ccc") + ' ' +  DateTime.fromISO(item.submittedAt).toLocaleString(DateTime.DATETIME_MED)
					}}</div>
				<v-icon color="gray_90" size="20" class="material-symbols-rounded" v-if="item.summary"  v-tippy="{theme: 'left-align'}" :content="item.summary">summarize</v-icon>
				</div>
			</template>

			<template v-slot:item.contact="{ item }">
				{{ formatContact(item) }}
			</template>

			<template v-slot:item.clientId="{ item }">
				<div class="row-format align-center" v-if="getClient(item.clientId)">
					<client-avatar small :client="getClient(item.clientId)"></client-avatar>
					<div>{{ getClient(item.clientId).name }}</div>
				</div>
				<span v-else> -- </span>
			</template>

			<template v-slot:item.action="{ item }">
				<v-menu :close-on-click="true" :nudge-bottom="36">
					<template v-slot:activator="scope">
						<div class="ml-1">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="openFormSubmission(item)">
							{{ $t('global.view-details') }}
						</div>
						<div class="more-menu-item" @click="deleteFormSubmission(item)">
							{{ $t('global.delete') }}
						</div>
					</div>
				</v-menu>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import DateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from '@/components/ClientAvatar';
	import FormSubmissionDetail from '@/modules/discovery/FormSubmissionDetail';
	import ConfirmModal from '@/components/ConfirmModal';
	import FilterHelpers from "@/utils/FilterHelpers";

	export default {
		name: 'FormSubmissionsTable',

		props: ['clientId','filter','templateId'],

		components: { ClientAvatar },

		data: function () {
			return {
				DateTime: DateTime,
				formSubmissionService: new FormSubmissionService(),
				formSubmissions: [],
				isReady: false,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.getFormSubmissions);
			this.getFormSubmissions();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getFormSubmissions);
		},

		methods: {
			getFormSubmissions: function () {
				if(this.clientId) {
					this.formSubmissionService.getFormSubmissions(this.clientId).then((res) => {
						this.formSubmissions.splice(0, this.formSubmissions.length);
						this.formSubmissions.push(...res.data);
						this.$emit('record-count', this.formSubmissions.length);
						this.isReady = true;
					});
				}else if(this.templateId){
					this.formSubmissionService.getFormSubmissionByTemplate(this.templateId).then((res) => {
						this.formSubmissions.splice(0, this.formSubmissions.length);
						this.formSubmissions.push(...res.data);
						this.$emit('record-count', this.formSubmissions.length);
						this.isReady = true;
					});
				}
			},

			openFormSubmission: function (item) {
				this.$store.state.globalModalController.openModal(FormSubmissionDetail, { id: item.id }).then((res) => {
					if (res) {
						let ix = this.formSubmissions.findIndex((f) => f.id === item.id);
						if (res.action === 'DELETED') {
							this.formSubmissions.splice(ix, 1);
						} else {
							this.formSubmissions.splice(ix, 1, res);
						}
					}
				});
			},

			deleteFormSubmission: function (item) {
				let binding = {
					headingText: 'Confirm form submission delete!',
					bodyText: 'Are you sure you want to delete this form submission?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.formSubmissionService
							.deleteFormSubmission(item.id)
							.then(() => {
								let ix = this.formSubmissions.findIndex((f) => f.id === item.id);
								this.formSubmissions.splice(ix, 1);
								this.$emit('record-count',this.formSubmissions.length);
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			formatContact: function (item) {
				let result = [];
				if (item.formData.firstName) {
					result.push(item.formData.firstName);
				}
				if (item.formData.lastName) {
					result.push(item.formData.lastName);
				}
				if (item.formData.email) {
					if (result.length) {
						result.push('-');
					}
					result.push(item.formData.email);
				}

				return result.join(' ');
			},

			getClient: function (id) {
				return this.$store.getters.clients.find((c) => c.id === id);
			},

			setView: function (view) {
				this.$router.push('/pages/' + view);
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			filteredSubmissions: function () {
				let result = [...this.formSubmissions];

				if(!this.filter){
					return result;
				}

				let search = this.filter.search ? this.filter.search.toLowerCase() : null;

				return result
						.filter((m) => {
							if (
									!search ||
									m.formData.email.toLowerCase().startsWith(search) ||
									m.formData.firstName.toLowerCase().startsWith(search) ||
									m.formData.lastName.toLowerCase().startsWith(search) ||
									m.formName.toLowerCase().includes(search)
							) {
								return true;
							} else if (search) {
								return false;
							}
						})
						.filter((m) => {
							if (this.filter.clientList && this.filter.clientList.length > 0) {
								return this.filter.clientList.includes(m.clientId);
							} else {
								return true;
							}
						})
						.filter((m) => {
							if (this.earliest && this.latest) {
								let compareDate = DateTime.fromISO(m.submittedAt);
								if (compareDate >= this.earliest && compareDate <= this.latest) {
									return true;
								} else {
									return false;
								}
							} else {
								return true;
							}
						});
			},

			earliest: function () {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest);
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return null;
				}
			},

			latest: function () {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest);
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest;
					}
				} else {
					return null;
				}
			},

			headers: function () {
				let headerArray =  [
					{ text: this.$t('discovery.form-name'), value: 'formName' },
					{ text: this.$t('discovery.submitted-at'), value: 'submittedAt' },
					{ text: this.$t('client.contact'), value: 'contact' },
				];

				if(!this.clientId){
					headerArray.push({ text: this.$t('client.client'), value: 'clientId' });
				}

				headerArray.push({ text: '', value: 'action', sortable: false, align: 'right' });
				return headerArray;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
