<template>
	<div style="background-color: var(--v-white-base)" id="main-calendar">
		<div id="calendar-header-wrapper" class="pb-3 px-2">
			<v-menu
				:nudge-bottom="0"
				:elevation="0"
				content-class="add-new-menu"
				bottom
				left
				rounded
				offset-overflow
				offset-y
				transition="slide-y-transition"
			>
				<template v-slot:activator="{ on }">
					<v-btn width="110" class="secondary-action" small v-on="on">
						<div class="font-14">{{ currentViewLabel }}</div>
						<div class="down-icon ml-auto">
							<v-icon :size="16">$arrowDown</v-icon>
						</div>
					</v-btn>
				</template>

				<div class="add-new-dropdown">
					<div v-for="(view, index) in views" :key="index" @click="changeView(view.value)" class="add-new-item">
						{{ view.label }}
					</div>
				</div>
			</v-menu>
			<div class="ml-2 row-format align-center">
				<div class="font-14 mr-4">
					<v-menu :close-on-content-click="false" :close-on-click="true" v-model="datePickerMenu">
						<template v-slot:activator="{ on }">
							<div v-on="on" class="pointer">{{ dateLabel }}</div>
						</template>
						<v-date-picker
							:first-day-of-week="$store.getters.firstDayOfWeek"
							no-title
							scrollable
							v-model="datePickerDate"
							@change="datePickerUpdated"
						></v-date-picker>
					</v-menu>
				</div>
				<div class="calendar-button px-2 mr-2" @click="goToToday()">
					<div class="font-14 brand-medium font-secondary">Today</div>
				</div>
				<div class="calendar-button mr-2" @click="changePeriod(-1)">
					<v-icon color="secondary" size="16">$chevronLeft</v-icon>
				</div>
				<div class="calendar-button" @click="changePeriod(1)">
					<v-icon color="secondary" size="16">$chevronRight</v-icon>
				</div>
			</div>
			<div class="ml-auto">
				<div class="row-format align-center">
					<v-icon size="20" class="mr-2 pointer" @click="openShareCalendar()">share</v-icon>
					<calendar-filter
						v-if="filter"
						:filter="filter"
						:categories="filterCategories"
						@reset-filter="resetFilter"
						class="mr-2"
					></calendar-filter>
					<v-menu
						nudge-top="20"
						bottom
						left
						rounded
						offset-overflow
						offset-y
						:close-on-content-click="false"
						content-class="add-new-menu"
						transition="slide-y-transition"
					>
						<template v-slot:activator="{ on }">
							<plus-button v-on="on"></plus-button>
						</template>
						<div class="add-new-dropdown">
							<div
								v-for="(item, index) in addNew"
								:key="index"
								@click="handleAddNew(item.value)"
								class="add-new-item"
							>
								{{ item.label }}
							</div>
						</div>
					</v-menu>
				</div>
			</div>
		</div>

		<div class="row-format my-3" v-if="hasAnyMetrics">
			<metric-widget
				class="m-widget"
				:metrics="timeMetricsByClient"
				@metric-clicked="handleTimeMetricClicked($event)"
			></metric-widget>
			<metric-widget
				class="m-widget"
				:metrics="paymentMetricsByClient"
				@metric-clicked="handlePaymentMetricClicked($event)"
			></metric-widget>
			<metric-widget
				class="m-widget"
				:metrics="deliverableMetricsByStatus"
				@metric-clicked="handleDeliverableMetricClicked($event)"
			></metric-widget>
		</div>

		<schedule-view
			v-if="currentView === 'Schedule'"
			:days="scheduleEvents"
			:has-metrics="hasAnyMetrics"
			@event-clicked="eventClicked({ event: $event })"
		></schedule-view>

		<div class="row-format fill-height" v-show="currentView !== 'Schedule'">
			<div
				:class="'mb-0' + (['Day', 'Week'].includes(currentView) ? ' show-scrollbar' : '')"
				:style="
					`height: calc(var(--vh) - ${hasAnyMetrics ? 200 : 130}px); min-width: ${
						calendarType === 'day' && daysEvents.length > 0 ? '50%' : '100%'
					}`
				"
			>
				<v-calendar
					:locale="$store.state.locale"
					ref="calendar"
					:key="refreshKey"
					:weekdays="weekDays"
					:type="calendarType"
					:start="startDate.toISODate()"
					:end="endDate.toISODate()"
					:event-color="getEventColor"
					:events="calendarEvents"
					:event-height="currentView === 'Two-Week' ? 28 : 20"
					:event-margin-bottom="4"
					:event-ripple="false"
					@click:event="eventClicked"
					@click:more="moreClicked"
					@contextmenu:day="showDayMenu"
					@contextmenu:time="showDayMenu"
				>
					>
					<template v-slot:day-label="{ day, weekday, date }">
						<div class="text-left font-12 pl-2" @click="dayClicked(date)">
							<span class="d-label">{{ days[weekday] }}</span> {{ day }}
						</div>
					</template>

					<template v-slot:day-label-header="{ day, weekday, date }">
						<div class="text-left font-12 pl-2" @click="dayClicked(date)">{{ days[weekday] }} {{ day }}</div>
					</template>

					<template v-slot:event="{ event, eventParsed, timed }">
						<component
							:key="getComponentName(event)"
							:is="getComponentName(event)"
							:event="event"
							:event-parsed="eventParsed"
							:timed="timed"
							:view="currentView"
						></component>
					</template>
				</v-calendar>
			</div>

			<div style="width: 50%" v-if="currentView === 'Day'" class="mt-10 mr-5">
				<div
					v-for="event in daysEvents"
					:key="event.uuid"
					class="text-left mb-2 pointer"
					@click="eventClicked({ event: event })"
				>
					<deliverable-loader class="pl-1" v-if="event.source === 'DELIVERABLE'" :event="event"></deliverable-loader>
					<ticket-kanban-card class="pl-1" v-else-if="event.source === 'TICKET'" :event="event"></ticket-kanban-card>
					<component
						v-else
						:style="`background-color: ${getEventColor(event)}; border-radius: 4px`"
						class="px-2 py-1 font-14"
						:key="getComponentName(event)"
						:is="getComponentName(event)"
						:event="event"
						:timed="false"
						:view="currentView"
					></component>
				</div>
			</div>
		</div>

		<!--v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y style="max-width: 600px">
			<v-list>
				<v-list-item>
					<v-list-item-title>Add timer</v-list-item-title>
					<v-list-item-title>Add event</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu-->

		<v-menu v-if="openDialog" v-model="openDialog" absolute top left :nudge-bottom="clickY + 150" :nudge-right="clickX + 140">
			<div style="background-color: var(--v-white-base); width: 300px" class="pa-3">
				<div class="row-format align-center px-2 mb-2">
					<div class="brand-medium">{{ DateTime.fromISO(moreEvent.date).toFormat('ccc d') }}</div>
					<v-icon class="ml-auto" color="gray_90" small @click="openDialog = false">$close</v-icon>
				</div>

				<div
					v-for="(event, index) in eventsForMore"
					:key="index"
					class="mx-1 my-1 font-14 pointer text-left"
					:style="`background-color: ${getEventColor(event)}; padding:2px; border-radius:4px`"
					@click="eventClicked({ event: event })"
				>
					<component
						:is="getComponentName(event)"
						:event="event"
						:timed="event.timed"
						:view="currentView"
						:in-more-view="true"
					></component>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';
	import CalendarService from '@/modules/calendar/CalendarService';
	import { v4 as uuidv4 } from 'uuid';
	import AggregateTimer from '@/modules/calendar/events/AggregateTimer';
	import TimerEventMixin from '@/modules/calendar/mixins/TimerEventMixin';
	import InvoicePaymentMixin from '@/modules/calendar/mixins/InvoicePaymentMixin';
	import ProposalMixin from '@/modules/calendar/mixins/ProposalMixin';
	import DeliverableMixin from '@/modules/calendar/mixins/DeliverableMixin';
	import NativeEventMixin from '@/modules/calendar/mixins/NativeEventMixin';
	import ScheduledMeetingMixin from '@/modules/calendar/mixins/ScheduledMeetingMixin';
	import TicketMixin from '@/modules/communicator/inbox/tickets/TicketMixin';
	import TicketTwoWeekMixin from '@/modules/productivity/work/TicketTwoWeekMixin';

	import TimerEvent from '@/modules/calendar/events/TimerEvent';
	import DeliverableEvent from '@/modules/calendar/events/DeliverableEvent';
	import GoogleCalendarEvent from '@/modules/calendar/events/GoogleCalendarEvent';
	import AppleCalendarEvent from '@/modules/calendar/events/AppleCalendarEvent';
	import CalDavEvent from '@/modules/calendar/events/CalDavEvent';
	import NativeCalendarEvent from '@/modules/calendar/events/NativeCalendarEvent';
	import InvoiceEvent from '@/modules/calendar/events/InvoiceEvent';
	import PaymentEvent from '@/modules/calendar/events/PaymentEvent';
	import ProposalEvent from '@/modules/calendar/events/ProposalEvent';
	import ProposalSignedEvent from '@/modules/calendar/events/ProposalSignedEvent';
	import ScheduledMeetingEvent from '@/modules/calendar/events/ScheduledMeetingEvent';
	import CurrentTimeEvent from '@/modules/calendar/events/CurrentTimeEvent';
	import BasicModal from '@/components/BasicModal';
	import CalendarFilter from '@/modules/calendar/CalendarFilter';
	import TicketEvent from '@/modules/calendar/events/TicketEvent';

	import MetricWidget from '@/modules/calendar/MetricWidget';
	import DeliverableLoader from '@/modules/calendar/details/DeliverableLoader';
	import ScheduleView from '@/modules/calendar/ScheduleView';
	import CalendarMixin from '@/modules/calendar/CalendarMixin';
	import ProjectEvent from '@/modules/calendar/events/ProjectEvent';
	import ToDoEvent from '@/modules/calendar/events/ToDoEvent';
	import MicrosoftCalendarEvent from '@/modules/calendar/events/MicrosoftCalendarEvent';
	import PlusButton from '@/components/PlusButton';
	import ShareCalendar from '@/modules/calendar/ShareCalendar';
	import TicketKanbanCard from '@/modules/calendar/details/TicketKanbanCard.vue';

	export default {
		name: 'Calendar',

		props: ['isVisible'],

		mixins: [
			CalendarMixin,
			TimerEventMixin,
			InvoicePaymentMixin,
			ProposalMixin,
			DeliverableMixin,
			NativeEventMixin,
			ScheduledMeetingMixin,
			TicketMixin,
			TicketTwoWeekMixin,
		],

		components: {
			TicketKanbanCard,
			PlusButton,
			ScheduleView,
			DeliverableLoader,
			MetricWidget,
			CalendarFilter,
			BasicModal,
			ProposalEvent,
			ProposalSignedEvent,
			GoogleCalendarEvent,
			MicrosoftCalendarEvent,
			AppleCalendarEvent,
			CalDavEvent,
			NativeCalendarEvent,
			DeliverableEvent,
			ScheduledMeetingEvent,
			TimerEvent,
			InvoiceEvent,
			PaymentEvent,
			CurrentTimeEvent,
			AggregateTimer,
			ProjectEvent,
			ToDoEvent,
			TicketEvent,
		},

		data: function() {
			return {
				DateTime: DateTime,
				today: new Date().toISOString().substr(0, 10),
				refreshKey: 0,
				calendarService: new CalendarService(),
				events: [],
				projects: [],
				startDate: DateTime.now(),
				endDate: DateTime.now(),

				dataSetStart: DateTime.now(),
				dataSetEnd: DateTime.now(),

				clickedEvent: null,
				showMenu: false,
				datePickerMenu: false,
				datePickerDate: null,
				x: 0,
				y: 0,

				views: [
					{ label: 'Day', value: 'Day' },
					{ label: 'Week', value: 'Week' },
					{ label: 'Two week', value: 'Two-Week' },
					{ label: 'Month', value: 'Month' },
					{ label: 'Schedule', value: 'Schedule' },
				],

				days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

				clickX: 0,
				clickY: 0,
				openDialog: false,
				moreEvent: null,

				runningCurrentTime: null,

				filter: this.getDefaultFilter(),

				filterCategories: [
					{ label: 'Google calendar', value: 'GOOGLE_CALENDAR', icon: '$google' },
					{ label: 'iCloud calendar', value: 'APPLE_CALENDAR', icon: '$apple' },
					{ label: 'Microsoft calendar', value: 'MICROSOFT_CALENDAR', icon: '$microsoftMono' },
					{ label: 'CalDav calendar', value: 'CALDAV_CALENDAR', icon: 'event' },
					{ label: 'Moxie calendar', value: 'NATIVE', icon: '$hummingbird' },
					{ label: 'Scheduled meetings', value: 'SCHEDULED_MEETING', icon: 'today' },
					{ label: 'Invoices', value: 'INVOICE', icon: 'request_quote' },
					{ label: 'Projects', value: 'PROJECT', icon: 'folder' },
					{ label: 'Project tasks', value: 'DELIVERABLE', icon: 'splitscreen' },
					{ label: 'Tickets', value: 'TICKET', icon: 'confirmation_number' },
					{ label: 'Agreements', value: 'PROPOSAL', icon: 'handshake' },
					{ label: 'Time worked', value: 'TIMER_EVENT', icon: 'feed' },
					{ label: 'To-dos', value: 'TODO', icon: 'task_alt' },
				],
			};
		},

		mounted() {
			this.initialize();
			this.$track.record('page-view', { module: 'calendar' });
			this.$store.state.eventBus.$on('account-changed', this.initialize);
			this.$store.state.eventBus.$on(`u-${this.$store.getters.getLoggedInUserId}.timer-stop`, this.handleTimerStopEvent);
			document.addEventListener('click', this.globalClickHandler);
			this.runCurrentTime();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
			this.$store.state.eventBus.$off(`u-${this.$store.getters.getLoggedInUserId}.timer-stop`, this.handleTimerStopEvent);
			document.removeEventListener('click', this.globalClickHandler);
		},

		methods: {
			initialize: function() {
				this.loadFilter();
				this.loadCurrentView();
			},

			datePickerUpdated: function() {
				this.startDate = DateTime.fromISO(this.datePickerDate);
				this.changePeriod(0);
				this.datePickerMenu = false;
			},

			showDayMenu(event, nativeEvent) {
				nativeEvent.preventDefault();
				this.showMenu = false;
				this.x = nativeEvent.clientX;
				this.y = nativeEvent.clientY;
				// make event useable by menuClick items
				this.clickedEvent = event;
				console.log('clickedEvent', this.clickedEvent);
				this.$nextTick(() => {
					this.showMenu = true;
				});
			},

			handleTimerStopEvent: function(event) {
				let timerEvent = event.message;
				let calendarEvent = {
					id: timerEvent.id,
					eventSource: 'TIMER_EVENT',
					summary: timerEvent.clientName,
					description: timerEvent.projectName,
					start: timerEvent.timerStart,
					end: timerEvent.timerEnd,
					dateOnly: false,
					meta: {
						clientId: timerEvent.clientId,
						timerEvent: timerEvent,
					},
				};
				this.events.push(calendarEvent);
				this.refreshKey++;
			},

			setCurrentTime: function() {
				//Implementation was doing really weird stuff
				//commented out temporarily
				// let start = DateTime.now();
				// let end = start.plus({ minutes: 15 });
				//
				// let nowEvent = {
				// 	name: 'Current time',
				// 	eventSource: 'CURRENT_TIME',
				// 	attendees: [],
				// 	details: 'Current time is ' + start.toLocaleString(DateTime.TIME),
				// 	dateOnly: false,
				// 	timed: true,
				// 	meta: {},
				// 	start: start.toISO(),
				// 	end: end.toISO(),
				// 	id: 'currentTime',
				// };
				// let ix = this.events.findIndex((e) => e.id === 'currentTime');
				// if (ix > -1) {
				// 	this.events.splice(ix, 1);
				// }
				// this.events.push(nowEvent);
			},

			runCurrentTime() {
				// Refresh time every 5 seconds
				this.runningCurrentTime = setInterval(() => {
					if (['Week', 'Day'].includes(this.currentView)) {
						this.setCurrentTime();
					}
				}, 5000);
			},

			saveCurrentView: function() {
				try {
					localStorage.setItem(this.viewStateKey, this.currentView);
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			loadCurrentView: function() {
				try {
					let view = localStorage.getItem(this.viewStateKey);
					if (!this.$validations.isEmpty(view)) {
						this.changeView(view);
					} else {
						this.changeView(this.currentView);
					}
				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}
			},

			getCalendarEvents: function(force = false) {
				if (force || this.dataSetStart > this.startDate || this.dataSetEnd < this.endDate) {
					console.log('Fetching events');
					this.dataSetStart = this.startDate.startOf('month').minus({ months: 1 });
					this.dataSetEnd = this.endDate.endOf('month').plus({ months: 1 });

					this.calendarService
						.getCalendarEvents(
							this.dataSetStart.toISODate(),
							this.dataSetEnd.toISODate(),
							null,
							this.filter.useTaskStartDates
						)
						.then((res) => {
							this.events.splice(0, this.events.length);
							this.events.push(...res.data);
							this.setCurrentTime();
							// console.log(this.events);
							this.refreshKey++;
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			changeView: function(view) {
				let views = ['Month', 'Schedule', 'Two-Week', 'Week', 'Day'];
				let prevIx = views.findIndex((v) => v === this.currentView);
				let nextIx = views.findIndex((v) => v === view);
				let today = DateTime.now();
				let focusToday = false;
				this.currentView = view;

				if (nextIx > prevIx && this.startDate <= today && this.endDate >= today) {
					focusToday = true;
				}

				if (this.currentView === 'Month' || this.currentView === 'Schedule') {
					this.startDate = this.startDate.startOf('month');
					this.endDate = this.startDate.endOf('month');
				} else if (this.currentView === 'Week') {
					if (focusToday) {
						this.startDate = today.startOf('week');
						this.endDate = today.endOf('week');
					} else {
						this.startDate = this.startDate.startOf('week');
						this.endDate = this.startDate.endOf('week');
					}
					this.startDate = this.startDate.startOf('week');
					this.endDate = this.startDate.endOf('week');
				} else if (this.currentView === 'Two-Week') {
					if (focusToday) {
						this.startDate = today.startOf('week');
						this.endDate = today.plus({ weeks: 1 }).endOf('week');
					} else {
						this.startDate = this.startDate.startOf('week');
						this.endDate = this.startDate.plus({ weeks: 1 }).endOf('week');
					}
				} else if (this.currentView === 'Day') {
					if (focusToday) {
						this.startDate = today.startOf('day');
						this.endDate = today.endOf('day');
					} else {
						this.startDate = this.startDate.startOf('day');
						this.endDate = this.startDate.endOf('day');
					}
				}
				this.getCalendarEvents();
			},

			goToToday: function() {
				this.startDate = DateTime.now();
				this.endDate = DateTime.now();
				this.changeView(this.currentView);
			},

			changePeriod: function(increment) {
				if (this.currentView === 'Month' || this.currentView === 'Schedule') {
					this.startDate = this.startDate.plus({ months: increment }).startOf('month');
					this.endDate = this.startDate.endOf('month');
				} else if (this.currentView === 'Day') {
					this.startDate = this.startDate.plus({ days: increment }).startOf('day');
					this.endDate = this.startDate.endOf('day');
				} else if (this.currentView === 'Two-Week') {
					this.startDate = this.startDate.plus({ weeks: increment }).startOf('week');
					this.endDate = this.startDate.plus({ weeks: 1 }).endOf('week');
				} else if (this.currentView === 'Week') {
					this.startDate = this.startDate.plus({ weeks: increment }).startOf('week');
					this.endDate = this.startDate.endOf('week');
				}
				this.getCalendarEvents();
			},

			globalClickHandler: function(event) {
				setTimeout(() => {
					this.clickX = event.pageX;
					this.clickY = event.pageY;
				}, 50);
			},

			dayClicked: function(date) {
				this.startDate = DateTime.fromISO(date);
				this.changeView('Day');
			},

			moreClicked: function(event) {
				setTimeout(() => {
					this.moreEvent = event;
					this.openDialog = true;
				}, 100);
			},

			getRoundedDate(minutes, d = new Date()) {
				let ms = 1000 * 60 * minutes; // convert minutes to ms
				let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);
				return roundedDate;
			},

			handleAddNew: function(type) {
				if (type === 'Invoice') {
					this.createInvoice(this.events);
				} else if (type === 'Deliverable') {
					this.createDeliverable(this.startDate, this.events);
				} else if (type === 'Time') {
					let start = DateTime.now().minus({ minutes: 30 });
					if (DateTime.now() < this.startDate || DateTime.now() > this.endDate) {
						start = this.startDate.plus({ hours: 9 });
					}
					this.addNewTimer(start, this.events);
				} else if (type === 'Proposal') {
					this.addNewProposal(this.events);
				} else if (type === 'Event') {
					let start = DateTime.fromJSDate(this.getRoundedDate(30));
					let end = start.plus({ hours: 1 });
					this.createNativeCalendarEvent(start, end, this.events);
				}
			},

			eventClicked: function(e) {
				if (e.event.source === 'TODO') {
					this.$emit('set-view', 'Focus');
				} else if (e.event.source === 'DELIVERABLE') {
					this.deliverableClicked(e.event.original.meta, this.events);
				} else if (e.event.source === 'PROJECT') {
					this.projectClicked(e.event.original.meta.project, this.events);
				} else if (e.event.source === 'INVOICE' || e.event.source === 'PAYMENT') {
					this.invoiceClicked(e.event.original.meta, this.events);
				} else if (e.event.source === 'GOOGLE_CALENDAR') {
					this.googleCalendarEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'APPLE_CALENDAR') {
					this.appleCalendarEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'CALDAV_CALENDAR') {
					this.calDavEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'MICROSOFT_CALENDAR') {
					this.microsoftCalendarEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'NATIVE') {
					this.nativeCalendarEventClicked(e.event.original.meta.nativeEvent, this.events);
				} else if (e.event.source === 'PROPOSAL' || e.event.source === 'PROPOSAL_SIGNED') {
					this.proposalClicked(e.event.original.meta, this.events);
				} else if (e.event.source === 'TIMER_EVENT') {
					this.timerEventClicked(e.event.original.meta, this.events);
				} else if (e.event.source === 'AGGREGATE_TIMER') {
					this.aggregateTimerClicked(e.event.original.meta, this.events);
				} else if (e.event.source === 'SCHEDULED_MEETING') {
					this.scheduledMeetingEventClicked(e.event.original.meta.scheduledMeeting, this.events);
				} else if (e.event.source === 'TICKET') {
					this.handleTicketClick(e.event.original.meta, this.events);
				}
			},

			doFilters: function(e) {
				return (
					this.filterByCategory(e) &&
					this.filterByClient(e) &&
					this.filterByDeliverableStatus(e) &&
					this.filterByUser(e)
				);
			},

			filterByCategory: function(e) {
				if (e.eventSource === 'CURRENT_TIME') return true;
				return this.filter.categories.includes(e.eventSource);
			},

			filterByUser: function(e) {
				let filterableTypes = ['DELIVERABLE', 'TIMER_EVENT', 'SCHEDULED_MEETING', 'TICKET'];
				if (this.filter.users && this.filter.users.length && filterableTypes.includes(e.eventSource)) {
					if (e.userId === null) {
						return false;
					} else if (Array.isArray(e.userId)) {
						return e.userId.some((item) => this.filter.users.includes(item));
					} else {
						return this.filter.users.includes(e.userId);
					}
				} else {
					return true;
				}
			},

			filterByClient: function(e) {
				if (
					this.filter.clients.length === 0 ||
					e.eventSource === 'CURRENT_TIME' ||
					e.eventSource === 'NATIVE' ||
					e.eventSource === 'GOOGLE_CALENDAR' ||
					e.eventSource === 'APPLE_CALENDAR' ||
					e.eventSource === 'CALDAV_CALENDAR'
				) {
					return true;
				} else {
					return this.filter.clients.includes(e.meta.clientId);
				}
			},

			filterByDeliverableStatus: function(e) {
				if (this.filter.deliverableStatus.length === 0 || e.eventSource !== 'DELIVERABLE') {
					return true;
				} else {
					return this.filter.deliverableStatus.includes(e.meta.statusId);
				}
			},

			incrementMapByKey: function(map, key, value) {
				map.set(key, this.ifNull(map.get(key)) + value);
			},

			secondsDiff: function(start, end) {
				let s = DateTime.fromISO(start);
				let e = DateTime.fromISO(end);
				return Math.round(e.diff(s, ['seconds']).seconds);
			},

			ifNull(val) {
				if (!val) {
					return 0;
				} else {
					return val;
				}
			},

			resetFilter: function() {
				this.filter = this.getDefaultFilter();
			},

			getDefaultFilter: function() {
				return {
					search: null,
					categories: [
						'APPLE_CALENDAR',
						'GOOGLE_CALENDAR',
						'MICROSOFT_CALENDAR',
						'CALDAV_CALENDAR',
						'NATIVE',
						'INVOICE',
						'PROJECT',
						'DELIVERABLE',
						'TICKET',
						'PROPOSAL',
						'TIMER_EVENT',
						'SCHEDULED_MEETING',
						'TODO',
					],
					clients: [],
					deliverableStatus: [],
					useTaskStartDates: true,
					users: [],
				};
			},

			loadFilter: function() {
				try {
					let filterState = localStorage.getItem(this.filterStateKey);
					if (!this.$validations.isEmpty(filterState)) {
						this.filter = JSON.parse(filterState);
					} else {
						this.filter = this.getDefaultFilter();
					}

					if (!Object.hasOwn(this.filter, 'useTaskStartDates')) {
						this.filter.useTaskStartDates = true;
					}

					if (!Object.hasOwn(this.filter, 'users')) {
						this.filter.users = [];
					}
				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}
			},

			saveFilter: function() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			openShareCalendar: function() {
				let binding = {
					categories: this.filterCategories,
				};

				this.$store.state.globalModalController.openModal(ShareCalendar, binding);
			},
		},

		computed: {
			addNew: function() {
				let result = [
					{ label: 'Task', value: 'Deliverable' },
					{ label: 'Time worked', value: 'Time' },
				];

				if (!this.isCollaborator) {
					result.push(
						...[
							{ label: 'Invoice', value: 'Invoice' },
							{ label: 'Proposal', value: 'Proposal' },
						]
					);
				}

				result.push({ label: 'Event', value: 'Event' });

				return result;
			},

			filterStateKey: function() {
				return 'CALENDAR_FILTER_STATE_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			weekDays: function() {
				if (this.$store.state.weekStartsOn === 'Sunday') {
					return [0, 1, 2, 3, 4, 5, 6];
				} else {
					return [1, 2, 3, 4, 5, 6, 0];
				}
			},

			isCollaborator: function() {
				return this.$store.getters.getUserType === 'COLLABORATOR';
			},

			eventsForMore: function() {
				if (this.moreEvent) {
					let start = DateTime.fromISO(this.moreEvent.date)
						.startOf('day')
						.toJSDate()
						.getTime();
					let end = DateTime.fromISO(this.moreEvent.date)
						.endOf('day')
						.toJSDate()
						.getTime();
					return this.calendarEvents.filter((e) => e.start.getTime() >= start && e.start.getTime() <= end);
				} else {
					return [];
				}
			},

			processedCalendarEvents: function() {
				let result = [];
				let events = [...this.events.filter(this.doFilters)];

				/* If we are looking at the month or the two week view, we will aggregate the timer events */
				if (this.currentView === 'Month' || this.currentView === 'Two-Week' || this.currentView === 'Schedule') {
					let timers = events.filter((e) => e.eventSource === 'TIMER_EVENT');
					events = events.filter((e) => e.eventSource !== 'TIMER_EVENT');

					if (this.currentView === 'Schedule') {
						result.push(...this.splitHours(timers));
					} else {
						result.push(...this.aggregateHours(timers, this.currentView === 'Two-Week'));
					}
				}

				let invoices = events.filter((e) => e.eventSource === 'INVOICE');
				events = events.filter((e) => e.eventSource !== 'INVOICE');
				result.push(...this.processInvoicesAndPayments(invoices));

				let proposals = events.filter((e) => e.eventSource === 'PROPOSAL');
				events = events.filter((e) => e.eventSource !== 'PROPOSAL');
				result.push(...this.processProposals(proposals));

				/// BEGIN FILTERING OUT DUPLICATE EVENTS BETWEEN MEETING SCHEDULER AND GOOGLE/APPLE CALENDAR
				let meetingIds = events
					.filter((e) => e.eventSource === 'SCHEDULED_MEETING')
					.map((m) =>
						m.meta.scheduledMeeting.connectedICalUid
							? m.meta.scheduledMeeting.connectedICalUid
							: m.meta.scheduledMeeting.icalUid
					);

				let calendarList = ['GOOGLE_CALENDAR', 'APPLE_CALENDAR', 'MICROSOFT_CALENDAR','CALDAV_CALENDAR'];
				let linkedEvents = events.filter((e) => calendarList.includes(e.eventSource));
				events = events.filter((e) => !calendarList.includes(e.eventSource));

				linkedEvents = linkedEvents.filter((g) => !meetingIds.includes(g.meta.iCalUID));
				events.push(...linkedEvents);
				// END FILTERING DUPLICATE EVENTS

				for (let i = 0; i < events.length; i++) {
					let e = events[i];
					let c = {
						name: e.summary,
						start: DateTime.fromISO(e.start).toJSDate(),
						end: DateTime.fromISO(e.end).toJSDate(),
						timed: !e.dateOnly,
						original: e,
						source: e.eventSource,
					};
					result.push(c);
				}

				if (
					this.$store.state.productivity &&
					this.$store.state.productivity.todo &&
					this.filter.categories.includes('TODO')
				) {
					let items = JSON.parse(JSON.stringify(this.$store.state.productivity.todo.items.filter((t) => t.dueDate)));
					for (let i = 0; i < items.length; i++) {
						let item = items[i];
						item.eventSource = 'TODO';
						let c = {
							name: item.item,
							start: DateTime.fromISO(item.dueDate).toJSDate(),
							end: DateTime.fromISO(item.dueDate).toJSDate(),
							timed: true,
							original: item,
							source: 'TODO',
						};
						result.push(c);
					}
				}

				result.forEach((r) => {
					r.uuid = uuidv4();
					r.sort = this.getEventSort(r);
				});

				result.sort((a, b) => {
					if (a.sort < b.sort) {
						return -1;
					} else if (a.sort > b.sort) {
						return 1;
					} else return 0;
				});
				return result;
			},

			calendarEvents: function() {
				let result = [...this.processedCalendarEvents];

				if (this.currentView === 'Day') {
					result = result.filter((e) => e.timed);
				}

				return result;
			},

			daysEvents: function() {
				if (this.currentView !== 'Day') {
					return [];
				}

				let start = this.startDate.toJSDate().getTime();
				let end = this.startDate.toJSDate().getTime();

				let result = [...this.processedCalendarEvents]
					.filter((e) => !e.timed)
					.filter((e) => {
						let eStart = e.start.getTime();
						let eEnd = e.end.getTime();

						if (eStart >= start && eEnd <= end) {
							//is Today
							return true;
						} else if (eStart <= start && eEnd >= end) {
							//isSome Other Day
							return true;
						} else {
							return false;
						}
					});

				return result;
			},

			scheduleEvents: function() {
				if (this.currentView !== 'Schedule') {
					return [];
				}

				let start = this.startDate.toJSDate().getTime();
				let end = this.endDate.toJSDate().getTime();
				let dates = new Map();
				let result = [];

				let events = [...this.processedCalendarEvents].filter((e) => {
					if (e.start.getTime() >= start && e.end.getTime() <= end) {
						return true;
					} else {
						return false;
					}
				});

				events.forEach((e) => {
					let date = DateTime.fromJSDate(e.start).toFormat('yyyy-MM-dd');

					if (!dates.has(date)) {
						dates.set(date, []);
					}

					e.componentName = this.getComponentName(e);
					e.backgroundColor = this.getEventColor(e);

					dates.get(date).push(e);
				});

				dates.forEach((v, k) => {
					v.sort((a, b) => a.start.getTime() - b.start.getTime());

					result.push({
						date: k,
						events: v,
					});
				});

				result.sort((a, b) => a.date.localeCompare(b.date));

				return result;
			},

			calendarType: function() {
				switch (this.currentView) {
					case 'Month':
						return 'month';
					case 'Day':
						return 'day';
					case 'Week':
						return 'week';
					case 'Two-Week':
						return 'custom-weekly';
				}
				return 'month';
			},

			currentViewLabel: function() {
				return this.views.find((v) => v.value === this.currentView).label;
			},

			dateLabel: function() {
				if (this.currentView === 'Month' || this.currentView === 'Schedule') {
					return this.startDate.toFormat('LLL y');
				} else if (this.currentView === 'Day') {
					return this.startDate.toFormat('DD');
				} else {
					return this.startDate.toFormat('LLL d') + ' - ' + this.endDate.toFormat('LLL d, y');
				}
			},

			hasAnyMetrics: function() {
				return (
					this.timeMetricsByClient.metrics.length > 0 ||
					this.deliverableMetricsByStatus.metrics.length > 0 ||
					this.paymentMetricsByClient.metrics.length > 0
				);
			},

			viewStateKey: function() {
				return 'CALENDAR_VIEW_STATE_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},

		watch: {
			startDate: function(val) {
				if (val) {
					this.datePickerDate = val.toISODate();
				}
			},

			'filter.useTaskStartDates': function() {
				this.getCalendarEvents(true);
			},

			currentView: function(val) {
				this.saveCurrentView(val);
			},

			filter: {
				deep: true,
				handler: function() {
					this.saveFilter();
				},
			},

			isVisible: function(val) {
				if (val) {
					this.refreshKey++;
				}
			},
		},
	};
</script>

<style lang="scss">
	.v-event-more {
		font-weight: 500 !important;
		font-weight: normal;
		font-size: 12px !important;
		color: var(--v-gray_80-base) !important;
	}

	.d-label {
		display: none;
	}

	.v-calendar-monthly {
		border: 0px !important;
	}

	.v-calendar-daily {
		border: 0px !important;
	}

	.v-calendar-weekly {
		border: 0px !important;
	}

	.v-calendar-daily__day:last-child {
		border-right: 1px solid var(--v-white-base) !important;
	}

	.v-calendar-daily_head-day:last-child {
		border-right: 1px solid var(--v-white-base) !important;
	}

	.v-calendar-weekly__head {
		visibility: hidden;
		height: 0px !important;
	}

	.v-calendar-daily__head {
		background-color: var(--v-white-base);
	}

	.v-calendar-daily__pane {
		background-color: var(--v-white-base);
	}

	.v-calendar-daily_head-weekday {
		visibility: hidden;
		height: 0px !important;
	}

	.v-calendar-weekly__day {
		background-color: var(--v-white-base);
		border-left: 1px solid var(--v-gray_50-base);
	}

	.v-calendar-weekly__day:first-child {
		background-color: var(--v-white-base);
		border-left: 0px solid var(--v-gray_50-base);
	}

	.v-calendar-weekly__week:nth-child(2) {
		.d-label {
			display: inline;
		}
	}

	.v-calendar-weekly__day:nth-child(7) {
		border-right: 0px !important;
	}

	.v-calendar-weekly__week:last-child {
		.v-calendar-weekly__day {
			border-bottom: 0px !important;
		}
	}

	.v-outside {
		background-color: var(--v-gray_10-base) !important;
	}

	.v-event {
		min-width: 96% !important;
		margin-left: 4px !important;
		margin-right: 4px !important;
	}

	.v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label,
	.v-calendar-daily__day.v-past .v-calendar-weekly__day-label,
	.v-calendar-weekly__day.v-past .v-calendar-weekly__day-label,
	.v-calendar-daily_head-day .v-calendar-daily_head-day-label,
	.v-calendar-daily__day .v-calendar-weekly__day-label,
	.v-calendar-weekly__day .v-calendar-weekly__day-label {
		color: var(--v-gray_80-base) !important;
	}
	// Highlight today
	.v-calendar-daily_head-day.v-present {
		//background-color: var(--v-gray_10-base) !important;
		background-color: var(--v-gray_20-base) !important;
		.v-calendar-daily_head-day-label {
			font-family: Inter;
			font-weight: 600;
			color: var(--v-secondary-base) !important;
		}
	}
	.v-calendar-daily__day.v-present {
		//background-color: var(--v-gray_10-base) !important;
		background-color: var(--v-gray_20-base) !important;
		.v-calendar-weekly__day-label {
			font-family: Inter;
			font-weight: 600;
			color: var(--v-secondary-base) !important;
		}
	}

	.v-calendar-weekly__day.v-present {
		//background-color: var(--v-gray_10-base) !important;
		background-color: var(--v-gray_20-base) !important;
		.v-calendar-weekly__day-label {
			font-family: Inter;
			font-weight: 600;
			color: var(--v-secondary-base) !important;
		}
	}
	.v-calendar-monthly .v-calendar-weekly__day.v-present {
		// Make it blue on month to differentiate from gray days in other months
		background-color: var(--v-gray_20-base) !important;
	}

	div .v-calendar-daily__body .v-event-timed-container .v-event-timed {
		border: none !important;
	}
</style>

<style scoped lang="scss">
	.invoice,
	.timer_event,
	deliverable {
		text-align: left;
		color: var(--v-black-base);
	}

	#calendar-header-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid var(--v-gray_50-base);
	}

	.calendar-button {
		height: 28px;
		min-width: 28px;
		border: 1px solid var(--v-gray_50-base);
		color: var(--v-gray_80-base);
		border-radius: 4px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.m-widget {
		flex: 1 1 0px;
		margin-left: 4px;
		margin-right: 4px;

		&:first-child {
			margin-left: 0px;
		}

		&:last-child {
			margin-right: 0px;
		}
	}
</style>
