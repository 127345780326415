<template>
	<div class="column-format wrapper" style="max-height: 100%; height: calc(100vh - 80px)">
		<div v-if="!hasTicketAccess" class="py-8">
			<h2>This feature is not enabled for your user account.</h2>
		</div>
		<div class="column-format wrapper" style="max-height: 100%; height: 100%" v-else>
			<div class="py-3 px-3 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
				<div class="row-format align-center mr-4" style="gap: 8px; flex-wrap: wrap">
					<inbox-filter v-model="inboxSearch"></inbox-filter>
					<!--generic-filter :filter="filter" :items="['All', 'Unread', 'Read']" property="read"></generic-filter-->
					<generic-filter :filter="filter" :items="['Open', 'Closed']" property="folder"></generic-filter>
					<client-filter :filter="filter" v-if="!clientId"></client-filter>
					<assigned-to-filter :filter="filter"></assigned-to-filter>
					<v-menu
						max-height="90vh"
						max-width="250"
						:close-on-content-click="false"
						:close-on-click="true"
						v-model="menu"
						content-class="filter-menu-class"
						style="overflow-y: auto"
					>
						<template v-slot:activator="{ on }">
							<div :class="`scope-button row-format align-center ${!isDefault ? 'active' : ''}`" v-on="on">
								<div v-if="filter.group !== 'None'">Group by: {{ filter.group }}</div>
								<div v-else>Group by</div>
								<v-icon small>$arrowDown</v-icon>
							</div>
						</template>
						<div class="add-new-dropdown">
							<div v-for="item in groupItems" :key="item" class="add-new-item" @click="setValue(item)">
								{{ item }}
							</div>
						</div>
					</v-menu>
				</div>
				<plus-button class="ml-auto" @click="addNew()" v-if="!clientId"></plus-button>
			</div>
			<div>
				<ticket-table
					:communications="communications"
					:snoozeOptions="snoozeOptions"
					:filter="filter"
					@open-ticket="handleTicketClick($event)"
				>
				</ticket-table>
			</div>
		</div>
		<v-dialog v-if="showCreateNew" v-model="showCreateNew" :fullscreen="false" max-width="600px" :retain-focus="false" :eager="true" :persistent="true">
			<create-ticket :client-id="clientId" @result="handleNewTicketCreated($event)"></create-ticket>
		</v-dialog>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import HDateTime from '@/modules/utils/HDateTime';
	import Ticket from '@/modules/communicator/inbox/tickets/Ticket';
	import DetailView from '@/modules/communicator/inbox/DetailView';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import InboxFilter from '@/modules/communicator/InboxFilter';
	import GenericFilter from '@/modules/communicator/inbox/GenericFilter';
	import ClientFilter from '@/modules/home/insights/ClientFilter';
	import PlusButton from '@/components/PlusButton';
	import CreateTicket from '@/modules/communicator/inbox/tickets/CreateTicket';
	import TicketTable from '@/modules/communicator/inbox/tickets/TicketTable';
	import TicketDetail from '@/modules/communicator/inbox/tickets/TicketDetail';
	import AssignedToFilter from "@/modules/communicator/inbox/AssignedToFilter";

	export default defineComponent({
		name: 'TicketView',

		props: ['clientId'],

		components: {
			CreateTicket,
			AssignedToFilter, PlusButton, Ticket, DetailView, InboxFilter, GenericFilter, ClientFilter, TicketTable },

		data: function() {
			return {
				showCreateNew: false,
				inboxSearch: null,
				timerId: 0,
				communicatorService: new CommunicatorService(),
				communicatorTicketService: new CommunicatorTicketService(),
				communicatorEmailService: new CommunicatorEmailService(),
				subscription: null,
				emailBoxes: [],
				tickets: [],
				ticketSettings: null,
				detailStack: [],
				refreshKey: 0,
				filter: {
					folder: 'Open',
					read: 'All',
					clients: [],
					assignment: 'Assigned to anyone',
					group: 'None',
					assignedTo: [],
				},
				menu: false,
				groupItems: ['None', 'Client', 'Status', 'Due Date'],
			};
		},

		mounted() {
			if (this.hasTicketAccess) {
				localStorage.setItem('COMMUNICATOR_LAST_VIEWED', 'tickets');
				this.getFilterState();
				this.getEmailBoxes();
				this.getSubscription();
				this.checkTicketFocus();
				this.setupListeners();
				this.getTickets();
				this.timerId = setInterval(() => this.refreshKey++, 60000);
			}
		},

		beforeUnmount() {
			this.stopListeners();
			clearInterval(this.timerId);
		},

		methods: {
			getEmailBoxes: function() {
				this.communicatorEmailService.getEmailBoxes().then((res) => {
					this.emailBoxes.splice(0, this.emailBoxes.length);
					this.emailBoxes.push(...res.data);
					this.forceUpdate++;
				});
			},

			getSubscription: function() {
				this.communicatorService.getSubscription().then((res) => {
					this.subscription = res.data;
					this.forceUpdate++;
				});
			},

			setupListeners: function() {
				this.setupTicketListeners();
			},

			stopListeners: function() {
				this.stopTicketListeners();
			},

			setupTicketListeners: function() {
				this.$store.state.eventBus.$on(
					this.$store.getters.getMessageChannelPrefix + '.ticketCreated',
					this.ticketNotification
				);
				this.$store.state.eventBus.$on(
					this.$store.getters.getMessageChannelPrefix + '.ticketUpdated',
					this.ticketNotification
				);
				this.$store.state.eventBus.$on(
					this.$store.getters.getMessageChannelPrefix + '.ticketComment',
					this.ticketNotification
				);
				this.$store.state.eventBus.$on(
					this.$store.getters.getMessageChannelPrefix + '.ticketDeleted',
					this.ticketNotification
				);
			},

			stopTicketListeners: function() {
				this.$store.state.eventBus.$off(
					this.$store.getters.getMessageChannelPrefix + '.ticketCreated',
					this.ticketNotification
				);
				this.$store.state.eventBus.$off(
					this.$store.getters.getMessageChannelPrefix + '.ticketUpdated',
					this.ticketNotification
				);
				this.$store.state.eventBus.$off(
					this.$store.getters.getMessageChannelPrefix + '.ticketComment',
					this.ticketNotification
				);
				this.$store.state.eventBus.$off(
					this.$store.getters.getMessageChannelPrefix + '.ticketDeleted',
					this.ticketNotification
				);
			},


			addNew: function() {
				this.showCreateNew = true;
			},

			handleNewTicketCreated: function(res){
				if(res) {
					this.ticketUpdated(res.ticket);
				}
				this.showCreateNew = false;
			},

			getTickets: function() {
				if (this.$store.getters.hasFeatureAccess('tickets')) {
					this.communicatorTicketService.getTicketSettings().then((res) => {
						this.ticketSettings = res.data;
						this.communicatorTicketService.getTickets(null, null, this.isOpen, this.clientId).then((res) => {
							this.tickets.splice(0, this.tickets.length);
							this.tickets.push(...res.data);
						});
					});
				}
			},

			checkTicketFocus() {
				let urlParams = new URLSearchParams(window.location.search);
				let ticketId = urlParams.get('ticketId');
				if (ticketId) {
					this.communicatorTicketService.getTicketDetails(ticketId).then((res) => {
						let t = res.data.ticket;
						let ticket = {
							id: t.id,
							type: 'Ticket',
							channel: 'Requests',
							date: HDateTime.fromISO(t.updated),
							payload: t,
							unread: t.unread,
							archived: t.archived,
							snoozedUntil: null,
							clientIds: [t.clientId],
							searchFields: [...this.getSearchFieldsForTicket(t)],
						};

						this.handleTicketClick(ticket);
					});
				}
			},

			ticketNotification: function(event) {
				if (event.channel.includes('ticketUpdated')) {
					let ticket = event.message;
					let ix = this.tickets.findIndex((t) => t.id === ticket.id);
					if (ix > -1) {
						this.tickets.splice(ix, 1, ticket);
					}
				} else if (event.channel.includes('ticketComment')) {
					let ticket = event.message.ticket;
					let ix = this.tickets.findIndex((t) => t.id === ticket.id);
					if (ix > -1) {
						this.tickets.splice(ix, 1, ticket);
					}
				} else if (event.channel.includes('ticketCreated')) {
					this.tickets.push(event.message.ticket);
				} else if (event.channel.includes('ticketDeleted')) {
					let ticketId = event.message;
					let ix = this.tickets.findIndex((t) => t.id === ticketId);
					if (ix > -1) {
						this.tickets.splice(ix, 1);
					}
				}
				this.refreshKey++;
			},

			ticketUpdated: function(ticket) {
				let ix = this.tickets.findIndex((t) => t.id === ticket.id);
				if (ix > -1) {
					this.tickets.splice(ix, 1, ticket);
				} else {
					this.tickets.push(ticket);
				}
				this.refreshKey++;
			},

			getSearchFieldsForTicket(ticket) {
				let result = [];

				result.push(ticket.subject);
				result.push(ticket.lastComment);
				result.push(ticket.status);

				let c = this.$store.getters.getContactByClientPortalId(ticket.clientContactId);
				if (c) {
					result.push(c.firstName + ' ' + c.lastName + ' ' + c.email + ' ' + c.phone);
				}

				return result;
			},

			isObjectSearchMatch(comm) {
				for (let i = 0; i < comm.searchFields.length; i++) {
					if (comm.searchFields[i] && comm.searchFields[i].toLowerCase().includes(this.inboxSearch.toLowerCase())) {
						return true;
					}
				}
				return false;
			},

			setFilterState: function(filter) {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			getFilterState: function() {
				try {
					let filterState = localStorage.getItem(this.filterStateKey);
					if (!this.$validations.isEmpty(filterState)) {
						this.filter = JSON.parse(filterState);
					}

					if(!Object.prototype.hasOwnProperty.call(this.filter, 'assignedTo')){
						this.filter['assignedTo'] = [];
					}

				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}
			},

			setValue: function(item) {
				this.filter.group = item;
				this.menu = false;
			},

			getClient: function(item) {
				return this.$store.getters.getClientById(item);
			},

			handleTicketClick: function(ticket) {
				let binding = {
					id: ticket.id,
				};
				this.$store.state.globalModalController.openModal(TicketDetail, binding).then((res) => {
					if (res) {
						let ix = this.tickets.findIndex((t) => t.id === res.id);

						if(res.deleted && ix > -1){
							this.tickets.splice(ix, 1);
						}else if(ix > -1){
							this.tickets.splice(ix, 1, res);
						}
					}
				});
			},

			isInAssignedToFilter: function (assignedToList) {
				if (!this.filter.assignedTo || this.filter.assignedTo.length === 0) {
					return true;
				} else if (assignedToList.length === 0 && this.filter.assignedTo.indexOf('unassigned') > -1) {
					return true;
				} else if (this.filter.assignedTo.filter(value => assignedToList.includes(value)).length > 0) {
					return true;
				} else {
					return false;
				}
			},
		},

		watch: {
			isOpen: function() {
				this.getTickets();
			},

			filter: {
				deep: true,
				handler(val) {
					this.setFilterState(val);
				},
			},
		},

		computed: {
			isDefault: function() {
				return this.filter.group === this.groupItems[0];
			},

			hasTicketAccess: function() {
				return this.$store.getters.hasFeatureAccess('tickets');
			},

			filterStateKey: function() {
				if (this.clientId) {
					return (
						'CLIENT_TICKET_FILTER_STATE_' +
						this.$store.getters.getAccountId +
						'_' +
						this.$store.getters.getLoggedInUserId
					);
				} else {
					return 'INBOX_TICKET_STATE_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
				}
			},

			isOpen: function() {
				if (this.filter.folder === 'Closed') {
					return false;
				} else {
					return true;
				}
			},

			userEmails: function() {
				return this.emailBoxes.map((e) => e.username.toLowerCase());
			},

			userId: function() {
				return this.$store.getters.getLoggedInUserId;
			},

			detailView: function() {
				if (this.detailStack.length) {
					return this.detailStack[this.detailStack.length - 1];
				} else {
					return null;
				}
			},

			snoozeOptions: function() {
				let result = [];

				let now = HDateTime.now();
				let hourOfDay = now.get('hour');
				let dayOfWeek = now.get('weekday');

				if (hourOfDay < 9) {
					result.push({ label: 'Later today', date: now.set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 14) {
					result.push({ label: 'This afternoon', date: now.set({ hour: 15, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 17) {
					result.push({ label: 'This evening', date: now.set({ hour: 18, minute: 0, second: 0, millisecond: 0 }) });
				}

				result.push({
					label: 'Tomorrow',
					date: now.plus({ day: 1 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				if (dayOfWeek < 3) {
					result.push({
						label: 'Later this week',
						date: now.set({ weekday: 5, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				if (dayOfWeek < 6) {
					result.push({
						label: 'This weekend',
						date: now.set({ weekday: 6, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				result.push({
					label: 'Next week',
					date: now.plus({ week: 1 }).set({ weekday: 1, hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				return result;
			},

			communications: function() {
				let result = [];
				//let now = HDateTime.now();

				this.tickets.forEach((t) => {
					let date = HDateTime.fromISO(t.updated);
					let snoozedUntil = t.snoozedUntil ? HDateTime.fromISO(t.snoozedUntil) : null;

					if (this.ticketSettings) {
						t.ticketType = this.ticketSettings.ticketTypes.find((type) => type.id === t.type);
					}

					result.push({
						id: t.id,
						type: 'Ticket',
						channel: 'Ticket',
						date: date > snoozedUntil ? date.toISO() : snoozedUntil.toISO(),
						payload: t,
						unread: t.unread,
						archived: t.archived,
						snoozedUntil: snoozedUntil,
						client: this.getClient(t.clientId),
						searchFields: [...this.getSearchFieldsForTicket(t)],
					});
				});


				return result
						.filter((a) => {
						if (this.filter.clients.length) {
							return this.filter.clients.includes(a.payload.clientId)
						} else {
							return true;
						}
					})
					.filter((a) => {
						return a.payload.open === this.isOpen;
					})
					.filter((a) => {
						return this.isInAssignedToFilter(a.payload.assignedTo);
					})
					.filter((a) => {
						if (this.inboxSearch) {
							return this.isObjectSearchMatch(a);
						} else {
							return true;
						}
					});
			},
		},
	});
</script>

<style scoped lang="scss">
	.wrapper {
		position: relative;
		height: 100%;
		padding-bottom: 8px;
		//height: calc(var(--vh) - 75px);
	}

	.inbox {
		//border: 1px solid var(--v-gray_50-base);
		//border-radius: 12px;
		width: 100%;
		flex: 1;
		overflow-y: scroll;
	}

	.detail-view {
		position: absolute;
		width: 100%;
		height: 100%;
		flex: 1;
		overflow-y: scroll;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: var(--v-white-base);
	}

	.communication {
		border-bottom: 1px solid var(--v-gray_50-base);
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
		//&:last-child {
		//	border: none;
		//}
	}

	.active {
		background-color: var(--v-gray_20-base) !important;
		color: var(--v-secondary-base) !important;
		border: 1px solid var(--v-gray_50-base) !important;
	}
</style>
