<template>
	<div class="metric-wrapper px-3" v-if="metrics.metrics.length > 0">
		<div class="row-format" style="width:100%">
			<div @click="$emit('metric-clicked',metric)" class="metric mr-1 pointer" v-for="metric in metrics.metrics" :key="metric.id" :style="`--width:${metric.percentage}%; --color:${metric.color}`" @mouseenter="setHover(metric)" @mouseleave="setHover(null)">
			</div>
		</div>
		<div class="ml-1 column-format text-left pointer" style="min-width:150px" @click="$emit('metric-clicked')">
			<div class="font-gray_80 brand-medium font-14 truncate-heading">{{heading}}</div>
			<div class="font-12 font-gray_80">{{subHeading}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MetricWidget",

	props: ['metrics'],

	components: {},

	data: function () {
		return {
			hoverMetric: null,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		setHover: function(metric){
			this.hoverMetric = metric;
		}
	},

	computed: {
		heading: function(){
			if(this.hoverMetric){
				return this.hoverMetric.heading;
			}else{
				return this.metrics.defaultHeading;
			}
		},

		subHeading: function(){
			if(this.hoverMetric){
				return this.hoverMetric.subheading
			}else {
				return this.metrics.defaultSubheading;
			}
		}
	},

}
</script>

<style scoped lang="scss">
	.metric-wrapper {
		background-color: var(--v-gray_20-base);
		border-radius: 4px;
		height: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width:100%
	}

	.metric {
		height: 32px;
		border-radius: 2px;
		background-color: var(--color);
		width: var(--width);
	}

	.truncate-heading {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
	}
</style>