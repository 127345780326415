<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">Hang tight, we are connecting your zoom account.</div>
	</div>
</template>

<script>
	import ZoomAuthService from '@/modules/integrations/zoom/ZoomAuthService';

	export default {
		name: 'ZoomOauthHandler',

		props: ['code'],

		components: {},

		data: function () {
			return {
				zoomAuthService: new ZoomAuthService(),
			};
		},

		mounted() {
			this.$store.commit('startLoading');
			this.connectZoom();
		},

		beforeDestroy() {},

		methods: {
			connectZoom: function () {
				let redirectUri = document.location.protocol + '//' + document.location.host + '/integrations/zoom/oauth';
				this.zoomAuthService
					.connect(this.code, redirectUri)
					.then(() => {
						this.$store.commit('info', 'Zoom account linked.');
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error connecting your Zoom account: ' + err.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
						this.$store.state.eventBus.$emit('open-settings-modal','connected-apps');
					});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
