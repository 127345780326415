<template>
	<div class="meeting-detail" v-if="meeting">
		<!--    Header-->
		<div class="header-area pl-2 pr-4">
			<div>
				<v-form v-model="nameIsValid" class="header-left row-format gap-3">
					<v-text-field
						v-model="meeting.name"
						:rules="nameRules"
						hide-details
						dense
						outlined
						:disabled="sampleMode"
						persistent-placeholder
						placeholder="Name"
            @keydown.enter.prevent="saveMeeting"
					></v-text-field>
					<div class="text-left font-14" v-if="!nameIsValid" style="color: var(--v-error-base)">
						Meeting name is not unique. Please correct.
					</div>
				</v-form>
			</div>
			<div class="header-right">
				<div class="font-14 font-gray_70 mr-3">{{ saveMessage }}</div>
				<folder-selector :folders="folders" v-model="meeting.folder"></folder-selector>
				<v-btn class="ml-3 mr-1 primary-action" elevation="0" width="96" @click="saveMeeting">
					{{ $t('global.save') }}
				</v-btn>
				<v-btn class="ml-1 super-action" elevation="0" width="96" @click="shareDialog = true">
					{{ $t('global.share') }}
				</v-btn>
				<v-btn icon class="ml-2" @click="saveMeeting(true)">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>
		<!--    Left nav-->
		<div style="height: calc(100% - 50px);" class="row-format">
			<div
				class="column-format pb-3 px-2"
				style="width: 300px; max-width:300px; min-width:300px; border-right: 1px solid var(--v-gray_30-base); height: 99%; max-height: 99%;"
			>
				<div style="height: 100%; max-height: 100%" class="column-format gap-2 show-scrollbar">
					<div class="row-format py-2" style="border-bottom: 1px solid var(--v-gray_30-base)">
						<div
							:class="`row-format align-center page-link ${tab === 'Details' ? 'page-link-active' : ''}`"
							@click="selectTab('Details')"
						>
							<v-icon class="material-symbols-rounded" size="22" :color="tab === 'Details' ? 'primary' : 'gray_50'"
								>settings</v-icon
							>
							<div class="ml-1">Settings</div>
						</div>
						<div
							:class="`row-format align-center page-link ${tab === 'Style' ? 'page-link-active' : ''}`"
							@click="selectTab('Style')"
						>
							<v-icon class="material-symbols-rounded" size="22" :color="tab === 'Style' ? 'primary' : 'gray_50'"
								>palette</v-icon
							>
							<div class="ml-1">Style</div>
						</div>
					</div>
					<div
						v-if="tab === 'Details'"
						class="py-3"
						style="font-size: 14px; border-bottom: 1px solid var(--v-gray_50-base);"
					>
						<div :class="`step-button ${view === 'overview' ? 'active' : ''}`" @click="setView('overview')">
							<div>1) Overview</div>
						</div>
						<div :class="`step-button ${view === 'availability' ? 'active' : ''}`" @click="setView('availability')">
							<div>2) Availability</div>
						</div>
						<div :class="`step-button ${view === 'questions' ? 'active' : ''}`" @click="setView('questions')">
							<div>3) Question form</div>
						</div>
						<div :class="`step-button ${view === 'confirmation' ? 'active' : ''}`" @click="setView('confirmation')">
							<div>4) Confirmation & reminder</div>
						</div>
						<div :class="`step-button ${view === 'payment' ? 'active' : ''}`" @click="setView('payment')">
							<div>5) Payment</div>
						</div>
						<div :class="`step-button ${view === 'preview' ? 'active' : ''}`" @click="setView('preview')">
							<div>6) Preview</div>
						</div>
					</div>
					<div v-if="tab === 'Style'" class="py-3">
						<scheduler-style-config
							:meeting="meeting"
							:unique-account-url="uniqueAccountUrl"
							:pod-id="podId"
							@request-save="saveMeeting()"
							:selected-tab="selectedTab"
						></scheduler-style-config>
					</div>
				</div>
			</div>
			<!--    Right nav-->
			<div
				class="pa-6 column-format align-center show-scrollbar main-form"
				style="width:100%; height:100%; max-height: 100%;"
			>
				<div style="background-color: var(--v-white-base); width: 100%; min-width: 300px; max-width:1200px;">
					<div v-if="!sampleMode && !paidAccount" class="column-format centered mb-1 px-3">
						<free-tier-card
							style="height:90px; max-width: 800px"
							message="Meeting scheduler is not available on the Essentials plan. You can explore this feature, but access to scheduling meetings will be restricted."
							@upgrade-clicked="upgradeClicked()"
						/>
					</div>
					<overview
						:meeting="meeting"
						v-show="view === 'overview'"
						:used-names="usedNames"
						:unique-account-url="uniqueAccountUrl"
						:pod-id="podId"
						:zoom-users="zoomUsers"
						:sessions-users="sessionsUsers"
						:google-users="googleUsers"
						:apple-users="appleUsers"
						:cal-dav-users="calDavUsers"
						:microsoft-users="microsoftUsers"
						:sample-mode="sampleMode"
						:pipeline-stages="pipelineStages"
					></overview>
					<availability
						:meeting="meeting"
						v-show="view === 'availability'"
						:google-users="googleUsers"
						:apple-users="appleUsers"
						:cal-dav-users="calDavUsers"
						:microsoft-users="microsoftUsers"
					></availability>
					<questions :meeting="meeting" v-show="view === 'questions'"></questions>
					<confirmation
						:key="'confirmation' + refreshKey"
						:meeting="meeting"
						v-show="view === 'confirmation'"
						:email-templates="emailTemplates"
					></confirmation>
					<payment :meeting="meeting" v-show="view === 'payment'"></payment>
					<preview
						ref="preview"
						:meeting="meeting"
						:tokenMap="tokenMap(meeting, true)"
						:tokenMapNoFake="tokenMap(meeting, false)"
						v-if="view === 'preview'"
						:unique-account-url="uniqueAccountUrl"
						:pod-id="podId"
						@selectedTab="handleSelectedTab"
						@request-save="saveMeeting()"
					></preview>
				</div>
			</div>
		</div>

		<basic-modal v-if="shareDialog" :dialog="shareDialog" :persistent="true" @close="shareDialog = false">
			<share-scheduler
				@close="shareDialog = false"
				:full-link="fullMeetingLink"
				:full-link-embed="fullMeetingLinkEmbed"
				:internal-link="internalLink"
			></share-scheduler>
		</basic-modal>
	</div>
</template>

<script>
	import MeetingService from '@/modules/meetings/MeetingService';
	import Overview from '@/modules/meetings/detail/Overview';
	import Availability from '@/modules/meetings/detail/Availability';
	import Questions from '@/modules/meetings/detail/Questions';
	import Confirmation from '@/modules/meetings/detail/Confirmation';
	import Preview from '@/modules/meetings/detail/Preview';
	import AccountService from '@/modules/account/AccountService';
	import ZoomAuthService from '@/modules/integrations/zoom/ZoomAuthService';
	import GoogleService from '@/services/integrations/GoogleService';
	import BasicModal from '@/components/BasicModal';
	import ShareScheduler from '@/modules/meetings/detail/ShareScheduler';
	import FreeTierCard from '@/components/FreeTierCard';
	import jsonPatch from 'fast-json-patch';
	import AppleCalendarService from '@/modules/integrations/apple/AppleCalendarService';
	import EmailTemplateService from '@/modules/templates/EmailTemplateService';
	import MicrosoftCalendarService from '@/modules/integrations/microsoft/MicrosoftCalendarService';
	import PipelineSettingsService from '@/modules/pipeline/settings/PipelineSettingsService';
	import SchedulerStyleConfig from '@/modules/meetings/config/SchedulerStyleConfig.vue';
	import { DateTime, Settings } from 'luxon';
	import Payment from '@/modules/meetings/detail/Payment';
	import SessionsService from '@/modules/integrations/sessions/SessionsService';
	import FolderSelector from "@/components/FolderSelector";
	import CalDavCalendarService from "@/modules/integrations/caldav/CalDavCalendarService";

	export default {
		name: 'SchedulerDetail',

		props: ['id', 'usedNames','folders'],

		components: {
			FolderSelector,
			SchedulerStyleConfig,
			Payment,
			Overview,
			Availability,
			Questions,
			Confirmation,
			Preview,
			BasicModal,
			ShareScheduler,
			FreeTierCard,
		},

		data: function() {
			return {
				selectedTab: 0,
				refreshKey: 0,
				meetingService: new MeetingService(),
				pipelineSettingsService: new PipelineSettingsService(),
				meeting: null,
				view: 'overview',
				views: ['overview', 'availability', 'questions', 'confirmation', 'preview'],
				tab: 'Details',
				uniqueAccountUrl: null,
				zoomUsers: [],
				googleUsers: [],
				sessionsUsers: [],
				appleUsers: [],
				calDavUsers: [],
				microsoftUsers: [],
				emailTemplates: [],
				pipelineStages: [],
				shareDialog: false,
				baseUrl: process.env.VUE_APP_MEET_LINK,
				hasChanges: false,
				saveMessage: null,
				income: null,
				nameIsValid: false,
				nameRules: [(v) => !!v || 'Name is required', (v) => (v && this.isUnique(v)) || 'Name is not unique'],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'scheduler-detail' });
			this.getMeeting();
			this.getUniqueUrl();
			this.getConnectedGoogleUsers();
			this.getConnectedSessionsUsers();
			this.getConnectedZoomUsers();
			this.getConnectedAppleUsers();
			this.getConnectedCalDavUsers();
			this.getConnectedMicrosoftUsers();
			this.getEmailTemplates();
			this.getPipelineStages();
		},

		beforeDestroy() {},

		methods: {
			handleSelectedTab(index) {
				this.selectedTab = index;
			},

			upgradeClicked: function() {
				this.$emit('result', 'UPGRADE');
			},

			isUnique: function() {
				if (this.usedNames.includes(this.uniqueMeetingId)) {
					return false;
				} else {
					return true;
				}
			},

			selectTab: function(tab) {
				if (tab === 'Details') {
					this.tab = tab;
				} else {
					this.tab = tab;
				}
			},

			getPipelineStages: function() {
				this.pipelineSettingsService.getPipelineStages().then((res) => {
					this.pipelineStages.splice(0, this.pipelineStages.length);
					this.pipelineStages.push(...res.data.stages);
				});
			},

			getEmailTemplates: function() {
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0, this.emailTemplates.length);
					this.emailTemplates.push(...res.data);
					this.emailTemplates.sort((a, b) => a.name.localeCompare(b.name));
				});
			},

			getUniqueUrl: function() {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			handleClose: function() {
				this.$emit('result', 'CLOSE');
			},

			getConnectedZoomUsers: function() {
				new ZoomAuthService().getUserList().then((res) => {
					this.zoomUsers.splice(0, this.zoomUsers.length);
					this.zoomUsers.push(...res.data);
				});
			},

			getConnectedAppleUsers: function() {
				new AppleCalendarService().getAllAccounts().then((res) => {
					this.appleUsers.splice(0, this.appleUsers.length);
					this.appleUsers.push(...res.data);
				});
			},

			getConnectedCalDavUsers: function() {
				new CalDavCalendarService().getAllAccounts().then((res) => {
					this.calDavUsers.splice(0, this.calDavUsers.length);
					this.calDavUsers.push(...res.data);
				});
			},

			getConnectedGoogleUsers: function() {
				new GoogleService().getUserList().then((res) => {
					this.googleUsers.splice(0, this.googleUsers.length);
					this.googleUsers.push(...res.data);
				});
			},

			getConnectedSessionsUsers: function() {
				new SessionsService().getUserList().then((res) => {
					this.sessionsUsers.splice(0, this.sessionsUsers.length);
					this.sessionsUsers.push(...res.data);
				});
			},

			getConnectedMicrosoftUsers: function() {
				new MicrosoftCalendarService().getAllConnections().then((res) => {
					this.microsoftUsers.splice(0, this.microsoftUsers.length);
					this.microsoftUsers.push(...res.data);
				});
			},

			setView: function(view) {
				this.saveMeeting();
				this.view = view;
			},

			setViewByIndex: function(index) {
				let view = this.views[index];
				if (view) {
					this.view = view;
				}
			},

			getMeeting: function() {
				this.meetingService.getMeeting(this.id).then((res) => {
					this.meeting = res.data;
					this.meeting.reminders.forEach(r => {
						if(r.before){
							r.duration.asMinutes = r.duration.asMinutes * -1;
						}
					});
					this.meeting.reminders.sort((a, b) => a.duration.asMinutes - b.duration.asMinutes);
					this.observer = jsonPatch.observe(this.meeting);
					this.hasChanges = false;
				});
			},

			saveMeeting: function(close = false) {
				return this.meetingService.updateMeeting(this.id, this.meeting).then((res) => {
					if(close){
						this.$emit('result',res.data);
						return;
					}
					this.meeting = res.data;

					this.meeting.reminders.forEach(r => {
						if(r.before){
							r.duration.asMinutes = r.duration.asMinutes * -1;
						}
					});
					this.meeting.reminders.sort((a, b) => a.duration.asMinutes - b.duration.asMinutes);

					this.refreshKey++;
					this.observer = jsonPatch.observe(this.meeting);
					this.hasChanges = false;
					this.saveMessage = 'All changes saved!';
					setTimeout(() => (this.saveMessage = null), 5000);
				}).catch((err) => this.$store.commit('error',err.response.data.message));
			},

			checkNull: function(val) {
				return val ? val : '';
			},

			tokenMap: function(meeting, fakeTime) {
				let result = new Map();
				let amount = this.currency(0, true, true, 'USD');
				if (meeting.requiredPayment && meeting.paymentEnabled) {
					amount = this.currency(meeting.requiredPayment.amount, true, true, meeting.requiredPayment.currency);
				}
				result.set('Meeting.Name', this.checkNull(meeting.name));
				result.set('Meeting.Duration', this.checkNull(meeting.meetingLength.duration));
				result.set('Meeting.Location', this.checkNull(meeting.location.type));
				result.set('Meeting.TimeUnit', this.checkNull(this.timeUnitTitleCase(meeting.meetingLength.timeUnit)));
				result.set('Meeting.ScheduledTime', this.checkNull(fakeTime ? this.getFakeTime() : null));
				result.set('Meeting.AccountName', this.checkNull(this.$store.getters.getAccountName));
				result.set('Meeting.OwnerName', this.checkNull(this.getSchedulerOwner()));
				result.set('Meeting.LocationInfo', this.checkNull(this.locationDesc()));
				result.set('Meeting.TimeZone', this.checkNull(Settings.defaultZone.name));
				result.set('Meeting.Description', this.checkNull(meeting.description));
				result.set('Payment.Amount', this.checkNull(amount));
				result.set(
					'Icons.Clock',
					`<span><i class="v-icon notranslate material-icons" aria-hidden="true">schedule</i></span>`
				);
				result.set('Icons.Mic', `<span><i class="v-icon notranslate material-icons" aria-hidden="true">mic</i></span>`);
				result.set(
					'Icons.Event',
					fakeTime ? `<span><i class="v-icon notranslate material-icons" aria-hidden="true">event</i></span>` : ''
				);
				result.set(
					'Icons.Public',
					`<span><i class="v-icon notranslate material-icons" aria-hidden="true">public</i></span>`
				);
				result.set('Icons.Info', `<span><i class="v-icon notranslate material-icons" aria-hidden="true">info</i></span>`);

				return result;
			},

			timeUnitTitleCase: function(str) {
				return str.substring(0, 1) + str.substring(1).toLowerCase();
			},

			getFakeTime: function() {
				return (
					'9:00 AM - 9:30 AM, ' +
					DateTime.now().weekdayShort +
					', ' +
					DateTime.now().monthShort +
					' ' +
					DateTime.now().day +
					', ' +
					DateTime.now().year
				);
			},

			getSchedulerOwner: function() {
				if (this.meeting.ownerUserId) {
					return (
						this.$store.getters.getUserById(this.meeting.ownerUserId).firstName +
						' ' +
						this.$store.getters.getUserById(this.meeting.ownerUserId).lastName
					);
				} else {
					return null;
				}
			},

			currency: function(amount = 0, cents = true, currencySign = true, currency = 'usd') {
				if (!amount) {
					amount = 0;
				}

				amount = amount.toString().replace(/[^-0-9.]/g, '');
				if (isNaN(amount)) amount = 0;
				const hasDecimals = amount - Math.floor(amount) !== 0;
				let minimumFractionDigits = 2;
				if (!cents || !hasDecimals) {
					minimumFractionDigits = 0;
				}
				if (currencySign) {
					return new Intl.NumberFormat(navigator.languages[0], {
						style: 'currency',
						currency: currency,
						currencySign: 'accounting',
						maximumFractionDigits: minimumFractionDigits,
					}).format(amount);
				} else {
					return new Intl.NumberFormat(navigator.languages[0], {
						style: 'currency',
						currency: currency,
						maximumFractionDigits: minimumFractionDigits,
					})
						.format(amount)
						.replace(/[^0-9.,]/g, '');
				}
			},

			locationDesc: function() {
				switch (this.meeting.location.type) {
					case 'Google':
						return 'Google meeting will be created at booking';
					case 'Microsoft':
						return 'Microsoft Teams meeting will be created at booking';
					case 'Zoom':
						return 'Zoom meeting will be created at booking';
					case 'PhoneIn':
						return 'Phone number will be provided at booking';
					case 'PhoneOut':
						return 'We will ask you for your phone number on the next screen';
					case 'InPerson':
						return 'Meeting location will be provided at booking';
					case 'Sessions':
						return 'Sessions.us meeting will be created at booking';
					case 'Custom':
						return this.meeting.location.custom;
					default:
						return 'Meeting information will be provided in invitation';
				}
			},
		},

		computed: {
			fullMeetingLinkEmbed: function() {
				return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.meeting.uniqueMeetingId;
			},

			internalLink: function() {
				return this.fullMeetingLinkEmbed + '?conflictCheck=false';
			},

			fullMeetingLink: function() {
				let commPref = this.$store.state.communicationPreferences;
				if (commPref && commPref.primaryDomain) {
					return (
						'https://' +
						commPref.primaryDomain +
						'/public/' +
						this.uniqueAccountUrl +
						'/' +
						this.meeting.uniqueMeetingId
					);
				} else {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.meeting.uniqueMeetingId;
				}
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			currentIndex: function() {
				return this.views.findIndex((v) => v === this.view);
			},

			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			uniqueMeetingId: function() {
				if (this.meeting.name) {
					let link = this.meeting.name
						.replaceAll(' ', '-')
						.toLowerCase()
						.replaceAll(/[^a-zA-Z0-9-]/g, '');
					return link;
				} else {
					return '';
				}
			},
		},

		watch: {
			meeting: {
				deep: true,
				handler() {
					const patch = jsonPatch.generate(this.observer, true);
					if (patch.length > 0) {
						this.hasChanges = true;
					}
				},
			},
		},
	};
</script>

<style scoped lang="scss">
	.meeting-detail {
		background-color: var(--v-white-base);
		height: 100vh;
		max-width: 100% !important;

		.header-area {
			height: 50px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_30-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				padding-left: 2px;
				width: 285px;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}

				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}

			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}

	.step-button {
		font-family: Inter;
		font-weight: 500;
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 20px;
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.active {
		background-color: var(--v-gray_10-base) !important;
		color: var(--v-primary-base) !important;
	}

	.fixed-column {
		position: fixed;

		overflow-y: auto;
	}
</style>
