<template>
	<div class="column-format fill-height" v-if="initialized">
		<div class="column-format centered fill-height" style="flex: 1; min-height: calc(100vh - 200px)" v-if="!opportunities.length">
			<empty-view
					header="Never have another client dry spell"
					body="Visualize the prospects you’re talking to, track notes on what they need, pitch them on proposals, work your way to a signed contract and getting paid."
					cta="Add an opportunity"
					video-header="See how it works"
					video-body="Learn how the pipeline visualizes your sales process, predicts your income, and helps you keep all of your opportunities on track to close."
					video-cta="Watch the tutorial"
					video-id="f72IvZ0Ifm8"
					@cta-clicked="createOpportunity()"
			></empty-view>
		</div>

		<div v-if="filteredOpportunities.length" class="opportunity-wrapper mt-4">
			<opportunity-list
					v-if="isReady"
					:pipeline-stages="pipelineStages"
					:pipeline-fields="pipelineFields"
					:opportunities="filteredOpportunities"
					:confidence-list="confidenceList"
					@edit="editOpportunity($event)"
					@archive="archiveOpportunity($event)"
					@delete="confirmDelete($event)"
			>
			</opportunity-list>
		</div>
	</div>
</template>

<script>
	import EmptyView from '@/components/EmptyView';
	import OpportunityMixin from "@/modules/pipeline/OpportunityMixin";
	import OpportunityList from "@/modules/pipeline/list/OpportunityList";

	export default {
		name: 'ClientOpportunities',
		props: ['client', 'isActive', 'filter'],
		mixins: [OpportunityMixin],

		components: { EmptyView, OpportunityList },

		data() {
			return {
				initialized: false,
			};
		},

		mounted() {
			this.getPipelineStages();
		},

		methods: {
			initialize: function() {
				this.getOpportunities(this.client.id).then(() => {
					this.isReady = true;
					this.initialized = true;
				});
			},

			addNew: function(){
				this.createOpportunity(null,this.client.id);
			}
		},

		computed: {
			empty: function() {
				return this.opportunities.length === 0;
			},

			unknownStage: function() {
				return {
					id: 'unknown',
					label: '[Unknown status]',
					hexColor: '#000000',
				};
			},

			filteredOpportunities: function() {
				let result = [...this.opportunities];
				let closed = ['ClosedWon', 'Complete'];

				result.forEach((o) => {
					if (o.clientId) {
						o.client = this.$store.getters.getClientById(o.clientId);
					}
					o.stage = this.pipelineStages.stages.find((s) => s.id === o.statusId);
					if (!o.stage) {
						o.stage = this.unknownStage;
					}
					o.timePeriodDetail = this.timePeriods.find((t) => t.value === o.timePeriod);
					o.sentimentObj = this.confidenceList.find((c) => c.value === o.sentiment);
					o.closed = o.stage ? closed.includes(o.stage.stageType) : false;

					if (o.workflow.length) {
						let wf = [...o.workflow];
						wf.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
						o.lastActivity = wf[0];
					}

					if (o.toDos.length) {
						let td = [...o.toDos.filter((t) => !t.complete && t.dueDate)];
						td.sort((a, b) => a.dueDate.localeCompare(b.dueDate));
						o.nextDue = td[0];
					}
				});

				return result;
			},
		},

		watch: {
			isActive: function(newVal) {
				if (newVal && !this.initialized) {
					this.initialize();
				}
			},

			'client.id': {
				deep: true,
				immediate: true,
				handler() {
					if (this.isActive) {
						this.initialize();
					}
				},
			},
		},
	};
</script>

<style lang="scss">
	.proposal-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
		font-size: 12px;
	}
</style>

<style scoped lang="scss">
	.opportunity-row-wrapper {

	}
</style>
