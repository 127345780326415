<template>
	<div :class="`${$vuetify.breakpoint.xsOnly ? 'column-format' : 'row-format'} text-left`" style="height: 100%">
		<input id="copyInput" name="copyInput"  type="hidden" />
		<div :style="`width: ${leftWidth}; height:100%;`" :class="`column-format ${$vuetify.breakpoint.xsOnly ? '' : 'mr-4 pr-4'}`">
			<div v-if="fullUser">
				<div class="font-gray_80 brand-medium mt-1 mb-2 ml-1">Add new</div>
				<focus-quick-links></focus-quick-links>
				<hr class="rule mt-2" />
			</div>
			<div style="flex: 1 1 auto; min-height: 150px; max-height: calc(var(--vh) - 200px); overflow-y: auto" class="show-scrollbar">
				<work-two-week></work-two-week>
			</div>
		</div>
		<div :style="`width: ${rightWidth}; gap: 12px`" class="mb-0 mt-1 column-format">
			<div class="right-bar show-scrollbar" style="flex-grow: 1; height: 0px; overflow-y: auto">
					<day-calendar :is-visible="isVisible"></day-calendar>
			</div>
			<div class="right-bar" v-if="hasAnyQuickLinks">
				<v-container class="font-14">
					<v-row dense>
						<v-col cols="12"><div class="font-gray_80 brand-medium font-16 mb-2">Quick links</div></v-col>
					</v-row>
					<v-row v-if="clientPortal" dense style="border-bottom: 1px solid var(--v-gray_30-base)">
						<v-col cols="5" class="row-format align-center">
							<v-icon small>public</v-icon>
							<div class="brand-medium font-gray_70 ml-1">Client portal</div>
						</v-col>
						<v-col cols="7" class="brand-bold">
							<div class="copy-link" @click="copyToClipboard('https://' + clientPortal + '/')">{{clientPortal}}</div>
						</v-col>
					</v-row>
					<v-row v-if="communicatorPhone" dense style="border-bottom: 1px solid var(--v-gray_30-base)">
						<v-col cols="5" class="row-format align-center">
							<v-icon small>phone</v-icon>
							<div class="brand-medium font-gray_70 ml-1">My phone</div>
						</v-col>
						<v-col cols="7" class="brand-bold">
							<div class="copy-link" @click="copyToClipboard(communicatorPhone)">{{communicatorPhone}}</div>
						</v-col>
					</v-row>
					<v-row v-if="meetings.length" dense>
						<v-col cols="5">
							<div class="row-format align-center">
								<v-icon small>date_range</v-icon>
								<div class="brand-medium font-gray_70 ml-1">Meeting schedulers</div>
							</div>
						</v-col>
						<v-col cols="7" class="brand-bold">
							<div v-for="(meeting,index) in filteredMeetings" :key="meeting.id + index" class="row-format copy-link">
								<div @click="copyToClipboard(fullMeetingLink(meeting))">{{meeting.name}}</div>
							</div>
							<div v-if="meetings.length > filteredMeetings.length" class="row-format">
								<div class="font-12 font-primary pointer" @click="showAllMeetings=true">+ {{meetings.length - filteredMeetings.length}} more schedulers</div>
							</div>
							<div v-if="filteredMeetings.length > 3">
								<div class="font-12 font-primary pointer" @click="showAllMeetings=false">- Hide {{meetings.length - 3}} schedulers</div>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
	import FocusQuickLinks from '@/modules/home/focus/FocusQuickLinks';
	import DayCalendar from '@/modules/home/focus/DayCalendar';
	import WorkTwoWeek from "@/modules/productivity/work/WorkTwoWeek";
	import MeetingService from "@/modules/meetings/MeetingService";
	import AccountService from "@/modules/account/AccountService";
	import libPhoneNumber from "google-libphonenumber";
	export default {
		name: 'Focus',

		props: ['isVisible','fullUser'],

		components: {WorkTwoWeek, DayCalendar, FocusQuickLinks },

		data: function () {
			return {
				meetingService: new MeetingService(),
				meetings: [],
				baseUrl: process.env.VUE_APP_MEET_LINK,
				uniqueAccountUrl: null,
				showAllMeetings: false,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {
			this.getUniqueUrl();
			this.getMeetingList();
		},

		beforeDestroy() {},

		methods: {
			copyToClipboard(value) {
				//Little bit of JavaScript magic here to copy the link out of the hidden text field
				let copyText = document.getElementById('copyInput');
				copyText.type = 'text';
				copyText.value = value;
				copyText.select();
				document.execCommand('copy');
				copyText.type = 'hidden';

				this.$store.commit('info', 'Copied to clipboard');
			},

			getMeetingList: function(){
				this.meetingService.getMeetingList().then((res) => {
					this.meetings.splice(0,this.meetings.length);
					res.data.forEach(m => {
						if(!m.ownerUserId || m.ownerUserId === this.$store.getters.getLoggedInUserId){
							this.meetings.push(m);
						}
					})
				})
			},

			getUniqueUrl: function () {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			fullMeetingLink: function (meeting) {
				let commPref = this.$store.state.communicationPreferences;
				if(commPref && commPref.primaryDomain){
					return 'https://' + commPref.primaryDomain + '/public/' + this.uniqueAccountUrl + '/' + meeting.uniqueMeetingId;
				}else {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + meeting.uniqueMeetingId;
				}
			},
		},

		computed: {
			filteredMeetings: function(){
				let result = [... this.meetings];
				if(this.showAllMeetings){
					return result;
				}else{
					return result.splice(0,3);
				}
			},

			hasAnyQuickLinks: function(){
				if(this.clientPortal || this.communicatorPhone || this.meetings.length){
					return true;
				}else{
					return false;
				}
			},

			podId: function () {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			clientPortal: function(){
				if(this.$store.state.communicationPreferences){
					return this.$store.state.communicationPreferences.primaryDomain;
				}else{
					return null
				}
			},

			communicatorPhone: function(){
				if(this.$store.state.communicatorSubscription){
					let number = this.$store.state.communicatorSubscription.numbers[0].number;
					let phone = this.PhoneUtil.parse(number);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				}else{
					return null
				}
			},

			leftWidth: function(){
				if(this.$vuetify.breakpoint.xsOnly){
					return '100%';
				}else{
					return '60%';
				}
			},
			rightWidth: function(){
				if(this.$vuetify.breakpoint.xsOnly){
					return '100%';
				}else{
					return '40%';
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.rule {
		border-top: 1px solid var(--v-gray_50-base);
		border-bottom: none;
	}

	.right-bar {
		border: 1px solid var(--v-gray_50-base);
		border-radius: 8px;
	}

	.copy-link {
		cursor: pointer;
		&:hover{
			color: var(--v-primary-base);
		}
	}
</style>
