import axios from 'axios';
import qs from 'qs';
import store from '@/store';

const getClient = () => {
	const options = {
		baseURL: process.env.VUE_APP_BASE_AUTH_URL,
		withCredentials: true,
	};

	const client = axios.create(options);

	client.interceptors.response.use(
		function(response){
			return response;
		},
		function(error){
			if(error.response.status === 423){
				store.state.eventBus.$emit('user-blocked-exception');
			}

			return Promise.reject(error);
		}
	);

	return client;
};

export default {
	deleteUser(){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.delete(`/user`,config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	updateUserEmail(toEmail){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
			params: {
				toEmail: toEmail
			}
		};

		return getClient()
			.post(`/user/updateEmail`,null,config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	updateUserPassword(newPassword){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
			params: {
				newPassword: newPassword
			}
		};

		return getClient()
			.post(`/user/updatePassword`,null,config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	verifyUpdateEmail(code){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
			params: {
				code:code
			}
		};

		return getClient()
			.post(`/user/verifyUpdateEmail`,null,config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	magicToken(token) {
		const request = {
			token,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/magicToken', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	secondFactor(token,code) {
		const request = {
			token,
			code
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/secondFactor', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	invitationToken(token) {
		const request = {
			token,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/invitation', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	credentials(email, password, reCaptchaToken){
		const request = {
			email,
			password,
			reCaptchaToken,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/credentials', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	requestMagicLink(email, reCaptchaToken, baseUrl) {
		const request = {
			email,
			baseUrl,
			reCaptchaToken,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/requestMagicLink', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	loginGoogle(id_token,reCaptchaToken) {
		const request = {
			id_token,
			reCaptchaToken
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/google', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	loginMicrosoft(accessToken,reCaptchaToken,baseUrl) {
		const request = {
			accessToken,
			reCaptchaToken,
			baseUrl
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/microsoft', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	loginApple(code,reCaptchaToken) {
		const request = {
			code,
			reCaptchaToken
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/apple', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	logout() {
		return getClient()
			.post('/auth/logout')
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	refreshToken(force=false) {
		return getClient()
			.post(`/auth/refresh?force=${force}`)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	getProfile() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get('/auth/me', config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	getUsersForAccount(account) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get(`/account/${account.accountId}/users`, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	listWorkspaces() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get(`/account`, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	provisionWorkspace(accountName) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.post(`/account?accountName=${encodeURIComponent(accountName)}`, null, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	deleteWorkspace(accountId) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.delete(`/account/${accountId}`, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	provisionNewWorkspace(name) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
			params: {
				accountName: name
			}
		};

		return getClient()
			.post(`/account`, null, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	getPortalAccessToken(clientAccess) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.post(`/account/${store.getters.getAccountId}/portalMagicToken`, clientAccess, config)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	updateAccountEntityInfo(account) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.put('/account/entityInfo', account, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	verifyUserPhone(code){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.post(`/user/phone/verify?code=${code}`, {}, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	sendVerification(){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.post(`/user/phone/send`, {},config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	trackDemographics(demographics){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		getClient()
			.post(`/user/tracking/demographics`, demographics, config)
			.catch((error) => console.log('Error tracking event', error));
	},

	trackEvent(eventName,properties){
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		getClient()
			.post(`/user/tracking/event?event=${eventName}`, properties, config)
			.catch((error) => console.log('Error tracking event', error));
	},

	getOnBoardingStatus() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get('/user/onboarding/status', config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

};
