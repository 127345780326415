<template>
	<div id="invoice-details">
		<div id="header-area" class="" v-if="invoice">
			<div class="header-left">
				<div class="mr-6 brand-medium pl-4">
					{{ $t('invoice.invoice-number-appended', { invoiceNumber: invoice.invoiceNumberFormatted }) }}
				</div>
				<div class="mr-2" @click="showModal('EVENTS')">
					<events-modal :invoice="invoice"></events-modal>
				</div>
				<div class="">
					<v-menu :close-on-click="true" :nudge-bottom="30" :disabled="!invoice.payments.length">
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" class="secondary-action mr-2" :disabled="!invoice.payments.length">
								<v-icon size="14" class="mr-1">$time</v-icon>
								<span class="body-12">{{ $t('invoice.details.payment-history') }}</span>
								<span
									v-if="invoice.payments.length"
									class="body-10 ml-2 px-1"
									style="background-color: var(--v-gray_10-base)"
									>{{ invoice.payments.length }}</span
								>
							</v-btn>
						</template>
						<payment-history :invoice="invoice" @openPayment="openPaymentDetail($event)"></payment-history>
					</v-menu>
				</div>
				<pipeline-link-widget :item-id="invoice.id" item-type="Invoice"  :client-id="invoice.clientId"></pipeline-link-widget>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<div class="row-format align-center accounting" style="position: relative" v-if="invoice.integrationKeys.quickbooksId || invoice.integrationKeys.xeroId">
					<v-icon size="20" v-if="invoice.integrationKeys.quickbooksId" v-tippy :content="`Linked to QBO record: ${invoice.integrationKeys.quickbooksId}`">$quickbooks</v-icon>
					<v-icon size="20" v-if="invoice.integrationKeys.xeroId" v-tippy:content="`Linked to Xero record: ${invoice.integrationKeys.xeroId}`">$xero</v-icon>
					<div class="unlink" @click="confirmUnlink" style="background-color: var(--v-white-base); position: absolute; right: 12px; top: -8px; border-radius: 20px"><v-icon size="20" class="pointer" color="red">cancel</v-icon></div>
				</div>
				<div class="mr-2 d-none d-md-inline-flex font-gray_70">{{ saveStatus }}</div>
				<v-btn
					class="ml-1 primary-action"
					width="96"
					@click="updateInvoice()"
					:disabled="['VOIDED', 'WRITE-OFF', 'PAID'].includes(invoice.status)"
				>
					{{ $t('global.save') }}
				</v-btn>
				<!--	@click="saveAndSend()"-->
				<v-btn class="mx-2 super-action" elevation="0" width="96" @click="selectSendMethod()">
					{{ $t('global.share') }}
				</v-btn>
				<div v-if="invoice" class="ml-1">
					<action-menu
						ref="ActionMenu"
						:invoice="invoice"
						:show-duplicate="showDuplicate"
						@paid="openPaymentDetail($event)"
						@delete-invoice="deleteInvoice($event)"
						@set-invoice="setInvoice($event)"
						@duplicate-invoice="duplicateInvoice($event)"
					></action-menu>
				</div>
				<div class="ml-2">
					<v-btn icon @click="handleClose()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
		<!-- Invoice Renderer -->
		<component
			style="max-height: calc(100vh - 60px); height: calc(100vh - 60px)"
			:is="rendererType"
			v-if="invoice && account"
			:invoice="invoice"
			:item-focus="itemFocus"
			:account="account"
			:deposit-account="depositAccount"
			:edit-mode="true"
			:force-render-index="forceRenderIndex"
			@force-save="save()"
			@pause-auto-save="pauseAutoSave($event)"
			:attachment-service="attachmentService"
			:invoice-service="invoiceService"
			:has-expenses="hasExpenses"
			:additional-tax-rates="additionalTaxRates"
			:scheduled-payments="invoice.scheduledPayments"
			@updated="updated($event)"
			@product-modal="showModal('PRODUCTS')"
			@tracked-item-modal="handleEditInvoiceItems()"
			@apply-deposit-modal="showModal('APPLY-DEPOSIT')"
			@account-info-modal="showModal('ACCOUNT-INFO')"
			@client-info-modal="showModal('CLIENT-INFO')"
			@expenses-modal="showModal('EXPENSES-MODAL')"
			@payment-terms-modal="showPaymentTermsModal()"
			@payment-instructions-modal="showModal('PAYMENT-INSTRUCTIONS')"
			@invoice-number-format-modal="showModal('INVOICE-NUMBER-FORMAT')"
			@item-hours-modal="handleItemHoursModal($event)"
			@item-price-modal="handleItemPriceModal($event)"
			@payment-action="handlePaymentAction"
			@waive-late-fee="handleWaiveLateFee"
			@payment-connect="navigateToPaymentConnect"
			@tax-rate-selected="taxRateSelected($event)"
			:key="rendererKey"
		></component>
		<basic-modal :dialog="selectedModal === 'CLIENT-FEES'" :persistent="false" ref="clientFeesModal" @close="showModal(null)">
			<client-hourly-amount-modal
				:client="client"
				@close="showModal(null)"
				@update-client-fees="
					handleUpdateClientFees($event);
					showModal(null);
				"
			>
			</client-hourly-amount-modal>
		</basic-modal>
		<modal
			:dialog="selectedModal === 'STRIPE'"
			@close="showModal(null)"
			ref="stripefModal"
			:persistent="false"
			:max-width="540"
		>
			<stripe-modal :account="account" @close="selectedModal = null"></stripe-modal>
		</modal>
		<basic-modal :dialog="selectedModal === 'ITEM-PRICE'" @close="showModal(null)" ref="itemPriceModal" :persistent="false">
			<item-price-modal
				:key="itemModalKey"
				v-if="itemModalIndex > -1"
				:invoice="invoice"
				:invoice-item="invoice.items[itemModalIndex]"
				@close="itemPriceModalClose()"
			></item-price-modal>
		</basic-modal>
		<basic-modal
			:dialog="selectedModal === 'ACCOUNT-INFO'"
			@close="showModal(null)"
			ref="accountInfoModal"
			:persistent="false"
		>
			<account-info-modal
				v-if="invoice"
				:account-info="invoice.accountInfo"
				@set-account-info="setAccountInfo"
				@close="showModal(null)"
			></account-info-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'CLIENT-INFO'" @close="showModal(null)" ref="clientInfoModal" :persistent="false">
			<client-info-modal
				v-if="invoice"
				:client-info="invoice.clientInfo"
				:client-id="invoice.clientId"
				@close="showModal(null)"
			></client-info-modal>
		</basic-modal>
		<basic-modal
			:dialog="selectedModal === 'PAYMENT-TERMS'"
			@close="showModal(null)"
			ref="paymentTermsModal"
			:persistent="false"
		>
			<payment-terms-modal
				v-if="invoice"
				:payment-terms="invoice.paymentTerms"
				:client-id="invoice.clientId"
				@close="showModal(null)"
				@delete-terms="invoice.paymentTerms = null"
				@payment-days-change="handlePaymentDaysChange"
			></payment-terms-modal>
		</basic-modal>
		<basic-modal
			:dialog="selectedModal === 'PAYMENT-INSTRUCTIONS'"
			@close="showModal(null)"
			ref="paymentInstructionsModal"
			:persistent="false"
		>
			<payment-instructions-modal
				v-if="invoice"
				:pay-instructions="invoice.payInstructions"
				:account="account"
				@close="showModal(null)"
				@save="handlePayInstructionsSave($event)"
			></payment-instructions-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'SEND'" @close="showModal(null)" ref="shareSendModal" :persistent="false" :retain-focus="false">
			<send-widget
				v-if="invoice"
				:client-id="invoice.clientId"
				:invoice-id="invoice.id"
				:open-state="selectedModal === 'SEND'"
				@alert="scope.setAlert($event)"
				@cancel="showModal(null)"
				@invoice-sent="handleInvoiceSent($event)"
			></send-widget>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'ITEM-HOURS'" @close="showModal(null)" :persistent="false">
			<item-hours-modal
				v-if="itemModalIndex > -1"
				:key="itemModalKey"
				:invoice-item="invoice.items[this.itemModalIndex]"
				:invoice="invoice"
				@save="updateItemHours($event)"
				@close="showModal(null)"
			></item-hours-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'APPLY-DEPOSIT'" @close="showModal(null)" :persistent="false">
			<apply-deposit-modal
				:invoice="invoice"
				:deposit-account="depositAccount"
				@save="save()"
				@close="showModal(null)"
			></apply-deposit-modal>
		</basic-modal>
		<!--		<basic-modal :dialog="selectedModal === 'TRACKED-OR-MANUAL'" @close="showModal(null)" :persistent="false">-->
		<!--			<tracked-or-manual-selector-->
		<!--				@close="showModal(null)"-->
		<!--				@edit-invoice-items="handleEditInvoiceItems()"-->
		<!--				@add-manual-line-item="handleAddManualLineItem()"-->
		<!--			></tracked-or-manual-selector>-->
		<!--		</basic-modal>-->
		<!--		<basic-modal :dialog="selectedModal === 'PRODUCTS'" @close="showModal(null)" :persistent="true">-->
		<!--			<products @close="showModal(null)" @select="handleSelectProduct($event)" />-->
		<!--		</basic-modal>-->
		<basic-modal
			v-if="selectedModal === 'CREATE-EDIT-INVOICE'"
			:dialog="selectedModal === 'CREATE-EDIT-INVOICE'"
			@close="showModal(null)"
			:persistent="false"
		>
			<edit-invoice-modal
				@close="showModal(null)"
				:key="createEditInvoiceKey"
				@save="save()"
				v-if="invoice"
				:invoice="invoice"
				:client="invoice.clientInfo"
			></edit-invoice-modal>
		</basic-modal>
		<basic-modal
			v-if="selectedModal === 'EXPENSES-MODAL'"
			:dialog="selectedModal === 'EXPENSES-MODAL'"
			@close="showModal(null)"
			:persistent="false"
		>
			<expenses-modal
				@close="showModal(null)"
				@save="save()"
				v-if="invoice"
				:invoice="invoice"
				:client="invoice.clientInfo"
			></expenses-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'EMAIL-OR-DOWNLOAD'" @close="showModal(null)" :persistent="false">
			<email-or-download
				:invoice="invoice"
				@close="showModal(null)"
				@invoice-sent="handleInvoiceSent($event)"
				@send-email="saveAndSend()"
			>
			</email-or-download>
		</basic-modal>
		<basic-modal v-if="selectedModal === 'PAYMENT-DETAIL'" :dialog="selectedModal === 'PAYMENT-DETAIL'" @close="showModal(null)" :persistent="false">
			<payment-details-modal
				:invoice="invoice"
				:payment="paymentToOpen"
				:edit-mode="true"
				@updated="updated($event)"
				@payment-updated="paymentUpdated($event)"
				@close="showModal(null)"
			></payment-details-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'INVOICE-NUMBER-FORMAT'" @close="showModal(null)" :persistent="false">
			<invoice-number-format-modal
				v-if="selectedModal === 'INVOICE-NUMBER-FORMAT'"
				@close="showModal(null)"
				@set-account-info="handleInvoiceNumberUpdate($event)"
			></invoice-number-format-modal>
		</basic-modal>
	</div>
</template>

<script>
	import ClientService from '../clients/ClientService';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import AccountService from '@/modules/account/AccountService';
	import jsonPatch from 'fast-json-patch';
	import InvoiceRenderer from '@/modules/invoices/InvoiceRenderer/InvoiceRenderer';
	import InvoiceRendererV2 from "@/modules/invoices/invoiceRendererV2/InvoiceRendererV2";
	import Modal from '@bit/hecticapp.common.modal';
	import BasicModal from '@/components/BasicModal';
	import AccountInfoModal from '@/modules/invoices/AccountInfoModal';
	import ClientInfoModal from '@/modules/invoices/ClientInfoModal';
	import PaymentTermsModal from '@/modules/invoices/PaymentTermsModal';
	import ItemPriceModal from '@/modules/invoices/ItemPriceModal';
	import SendWidget from '@/modules/invoices/SendWidget';
	import EventsModal from '@/modules/invoices/EventsModal';
	import StripeModal from '@/modules/integrations/stripe/StripeModal';
	import PaymentInstructionsModal from '@/modules/invoices/PaymentInstructionsModal';
	import ActionMenu from '@/modules/invoices/ActionMenu';
	import ItemHoursModal from '@/modules/invoices/ItemHoursModal';
	import ApplyDepositModal from '@/modules/invoices/ApplyDepositModal';
	import InvoiceAttachmentService from '@/modules/invoices/InvoiceAttachmentService';
	import ClientHourlyAmountModal from '@/modules/invoices/ClientHourlyAmountModal';
	import EmailOrDownload from '@/modules/invoices/EmailOrDownload';
	import { cloneDeep } from 'lodash';
	import EditInvoiceModal from '@/modules/invoices/EditInvoiceModal';
	import ExpensesModal from '@/modules/invoices/ExpensesModal';
	import PaymentHistory from '@/modules/invoices/InvoiceRenderer/PaymentHistory';
	import PaymentDetailsModal from '@/modules/invoices/InvoiceRenderer/PaymentDetailsModal';
	import InvoiceNumberFormatModal from '@/modules/invoices/InvoiceNumberFormatModal';
	import Settings from '@/modules/settings/Settings';
	import QuickbooksService from "@/modules/integrations/quickbooks/QuickbooksService";
	import XeroService from "@/modules/integrations/xero/XeroService";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'InvoiceDetails',
		components: {
			PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'),
			EmailOrDownload,
			ApplyDepositModal,
			ActionMenu,
			InvoiceRenderer,
			InvoiceRendererV2,
			Modal,
			BasicModal,
			AccountInfoModal,
			ClientInfoModal,
			PaymentTermsModal,
			ItemPriceModal,
			SendWidget,
			EventsModal,
			StripeModal,
			PaymentInstructionsModal,
			ItemHoursModal,
			EditInvoiceModal,
			ClientHourlyAmountModal,
			ExpensesModal,
			PaymentHistory,
			PaymentDetailsModal,
			InvoiceNumberFormatModal,
		},
		emits: ['close'],
		// components: { ClientAvatar },
		props: {
			// mode: { type: String, required: true }, // 'CREATE' or 'EDIT'
			clientId: { type: String, required: true },
			id: { type: String, required: true },
			quick: { type: Boolean, required: false, default: false },
			openPayment: { type: Boolean, required: false, default: false },
			payment: {type: Object, required: false},
			showDuplicate: { type: Boolean, required: false, default: true },

			// invoiceProp: { type: Object, required: false },
		},

		//props: ['value', 'label', 'placeholder', 'prependInnerIcon', 'prependIcon'],

		data: function() {
			return {
				invoiceService: new InvoiceService(),
				clientService: new ClientService(),
				accountService: new AccountService(),
				quickbooksService: new QuickbooksService(),
				xeroService: new XeroService(),
				attachmentService: null,
				invoice: null,
				observer: null,
				itemModalIndex: -1,
				itemModalKey: null,
				itemFocus: -1 /* Set description focus in renderer */,
				selectedModal: null,
				confirmDialog: null,
				client: null,
				depositAccount: null,
				account: null,
				hasExpenses: false,
				paymentToOpen: null,
				quickbooksTaxAccounts: [],
				xeroTaxAccounts: [],

				// Auto-save
				saving: false,
				saved: false,
				saveTimer: setInterval(this.autoSave, 5 * 1000),
				autoSavePaused: false,

				forceRenderIndex: 1,
				paymentTermsDefault: {
					paymentDays: 7,
					latePaymentFee: 5,
					depositAmount: 0,
					depositType: 'No deposit',
					invoiceCreation: 'Manual',
					invoicingSchedule: 'Monthly',
				},
				calculatedFields: [
					'discountDollarAmount',
					'events',
					'itemSubtotal',
					'lateFee',
					'paymentTotal',
					'payments',
					'status',
					'statusTimestamp',
					'taxAmount',
					'total',
				],
				invoiceItemTemplate: {
					description: '',
					qty: 1,
					type: 'ADHOC',
					price: 0,
					taxable: false,
					subtotal: 0,
				},
				createEditInvoiceKey: 0,
				rendererKey: 0,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'invoice-detail' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$onBoarding.track('invoices_create_invoice');
			this.getInvoice(true);
			this.getAccount();
			this.getClient();
			this.getXeroTaxAccounts();
			this.getQuickbooksTaxAccounts();
		},
		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			clearInterval(this.saveTimer);
		},

		methods: {
			handleClose() {
				this.$emit('result', this.invoice);
			},
			confirmUnlink: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to reset the relationship between this invoice and your accounting system?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.invoiceService.resetAccountingIntegrationKeys(this.clientId, this.id).then((res) => {
							this.invoice = res.data;
							this.observer = jsonPatch.observe(this.invoice);
							this.saving = false;
							this.$store.commit('success','Successfully reset relationship between invoice and accounting system.');
						})
					}
				})
			},
			handleUpdateClientFees(item) {
				this.invoice.items.forEach((i) => {
					if (i.type.toUpperCase() === 'HOURS' && i.projectId == null) {
						i.price = item.hourlyAmount;
					}
				});
			},
			handleAddManualLineItem() {
				this.invoice.items.push(cloneDeep(this.invoiceItemTemplate));
				this.itemFocus = this.invoice.items.length - 1;
				this.showModal(null);
			},
			// handleSelectProduct(product) {
			// 	console.log(product);
			// 	let item = {};
			// 	item.product = product;
			// 	item.description = product.productName;
			// 	item.price = product.rate;
			// 	item.taxable = product.taxable;
			// 	// item.unitLabel = product.unit;
			//
			// 	if (!item.hourly) {
			// 		item.qty = product.quantity;
			// 	}
			// 	this.invoice.items.push(item);
			// 	// this.itemFocus = this.invoice.items.length - 1;
			// 	this.showModal(null);
			// },
			handleEditInvoiceItems() {
				console.log('handleEditInvoiceItems', this.invoice);
				this.createEditInvoiceKey++;
				this.showModal('CREATE-EDIT-INVOICE');
			},
			getClient() {
				this.clientService.getClientDetail(this.clientId).then((res) => {
					this.client = res.data;
				});
			},
			getAccount() {
				this.accountService
					.getAccount()
					.then((res) => {
						this.account = res.data;
						// console.log('account', this.account);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// markPaid(invoice) {
			// 	this.invoice = invoice;
			// 	this.showModal();
			// },
			handleAccountChange() {
				// this.updateInvoice(false, false, true);
				this.$emit('result', null);
			},

			handlePayInstructionsSave(event) {
				this.invoice.payInstructions = event;
				this.updateInvoice();
				this.showModal(null);
			},
			getClientDepositAccount() {
				this.clientService
					.getDepositAccount(this.clientId)
					.then((res) => {
						this.depositAccount = res.data;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
			handleWaiveLateFee() {
				this.saving = true;
				this.invoiceService
					.lateFee(this.clientId, this.id, !this.invoice.lateFeeWaived)
					.then((res) => {
						this.invoice = res.data;
						console.log(this.invoice);
						this.observer = jsonPatch.observe(this.invoice);
						this.saving = false;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						this.saving = false;
					});
			},
			taxRateSelected(taxRate){
				if(taxRate.type === 'Quickbooks'){
					this.invoice.integrationTaxKeys.quickbooksId = taxRate.id;
					this.updateInvoice();
				}
				if(taxRate.type === 'Xero'){
					this.invoice.integrationTaxKeys.xeroId = taxRate.id;
					this.updateInvoice();
				}
			},
			showConfirmDialog(status) {
				this.confirmDialog = status;
			},
			navigateToPaymentConnect() {
				this.$store.state.globalModalController.openModal(Settings, { focus: 'connected-apps' }, false, true);
			},
			showModal(modal) {
				this.autoSavePaused = !!modal;
				console.log('showModal autoSavePaused', modal, this.autoSavePaused);
				this.selectedModal = modal;
			},
			showPaymentTermsModal() {
				if (!this.invoice.paymentTerms) {
					this.invoice.paymentTerms = this.paymentTermsDefault;
				}
				this.showModal('PAYMENT-TERMS');
			},
			handleInvoiceSent(invoice) {
				this.setInvoice(invoice);
				// this.invoice = invoice;
				// this.getInvoice();
			},
			handleItemPriceModal(index) {
				console.log('handleItemPriceModal fired!');
				this.itemModalIndex = index;
				this.itemModalKey = this.invoice.items[index].id;
				let item = this.invoice.items[index];
				if (item.type.toUpperCase() === 'EXPENSE') {
					// return this.showModal('EXPENSE');
				} else if (item.type.toUpperCase() === 'HOURS' && item.projectId == null) {
					this.showModal('CLIENT-FEES');
				} else {
					this.showModal('ITEM-PRICE');
				}
			},
			itemPriceModalClose() {
				this.itemModalIndex = -1;
				this.showModal(null);
				this.itemChange();
			},
			handleItemHoursModal(index) {
				this.itemModalKey = this.invoice.items[index].id;
				this.itemModalIndex = index;
				this.showModal('ITEM-HOURS');
				this.itemChange();
			},
			updateItemHours(event) {
				this.invoice.items[this.itemModalIndex].qty = event.qty;
				this.invoice.items[this.itemModalIndex].timerEventIds = event.timerEventIds;
				this.itemModalIndex = -1;
				this.showModal(null);
				this.itemChange();
			},
			openPaymentDetail(payment) {
				this.showModal('PAYMENT-DETAIL');
				this.paymentToOpen = payment;
			},
			itemChange() {
				console.log('itemChange');
				// let newItems = [];
				for (let i = 0; i < this.invoice.items.length; i++) {
					let item = this.invoice.items[i];
					item.subtotal = Math.round(item.qty * item.price * 100) / 100;
					this.invoice.items.splice(i, 1, item);
				}
				this.save();
			},
			handleInvoiceNumberUpdate(account) {
				console.log(account);
				this.setAccountInfo(account);
				this.updateInvoice(false, false, true).then(() => {
					this.refreshInvoiceNumber();
				});
			},
			refreshInvoiceNumber() {
				this.invoiceService
					.refreshInvoiceNumber(this.clientId, this.id)
					.then((res) => {
						// console.log('refreshInvoiceNumber 1', res.data.accountInfo.accountPreferences);
						// console.log('refreshInvoiceNumber 2', res.data.invoiceNumberFormatted);
						this.setInvoice(res.data);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						this.$emit('error', err);
					});
			},

			setAccountInfo(account) {
				this.account = account;
				this.forceRenderIndex++;
				const keys = Object.keys(this.invoice.accountInfo);
				let update = {};
				for (let i = 0; i < keys.length; i++) {
					update[keys[i]] = account[keys[i]];
				}
				this.invoice.accountInfo = update;
			},
			handlePaymentDaysChange() {
				if (this.invoice.dateDue == null) {
					return;
				}
				this.invoice.dateDue = this.$DateTime
					.local()
					.plus({ days: this.invoice.paymentTerms.paymentDays })
					.toISODate();
			},
			setInvoice(invoice) {
				this.invoice = invoice;
				this.observer = jsonPatch.observe(this.invoice);
			},
			duplicateInvoice(invoice) {
				this.$emit('result', {
					action: 'DUPLICATED', invoice: invoice }
				);
			},
			getInvoice() {
				this.invoiceService
					.getInvoice(this.clientId, this.id)
					.then((res) => {
						this.setInvoice(res.data);
						this.attachmentService = new InvoiceAttachmentService(this.invoice);

						if (this.invoice.status === 'DRAFT') {
							this.getClientDepositAccount();
						}

						if (this.quick) {
							this.handleEditInvoiceItems();
						}

						if(this.openPayment){
							if(this.payment){
								this.openPaymentDetail(this.payment);
							}else{
								this.$nextTick(() => this.$refs.ActionMenu.showModal('MARK-PAID'));
							}
						}

						this.invoiceService
							.getUnBilledExpenses(this.clientId, this.id)
							.then((res) => (this.hasExpenses = res.data.length > 0));
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						this.$emit('error', err);
					});
			},

			getQuickbooksTaxAccounts: function(){
				this.quickbooksService.getConnection().then((res) => {
					this.quickbooksTaxAccounts.splice(0,this.quickbooksTaxAccounts.length);
					if(res.data && res.data.companyInfo) {
						this.quickbooksTaxAccounts.push(...res.data.companyInfo.allTaxAccounts);
					}
				})
			},

			getXeroTaxAccounts: function(){
				this.xeroService.getConnection().then((res) => {
					this.xeroTaxAccounts.splice(0,this.xeroTaxAccounts.length);
					if(res.data && res.data.connection) {
						this.xeroTaxAccounts.push(...res.data.connection.allTaxAccounts);
					}
				})
			},

			parentHasClass(element, classname) {
				if (typeof element.className == 'string' && element.className.split(' ').indexOf(classname) >= 0) return true;
				return element.parentNode && this.parentHasClass(element.parentNode, classname);
			},

			autoSave() {
				// console.log('autoSave fired');
				if (this.autoSavePaused) {
					// console.log('autoSavePaused');
					return;
				}
				let focusField = document.activeElement;
				if (focusField && this.parentHasClass(focusField, 'pause-save')) {
					return;
				}
				if (this.invoice.status !== 'DRAFT') {
					return;
				}
				return this.updateInvoice(false, true);
			},

			save() {
				return this.updateInvoice(false, false);
			},

			pauseAutoSave(event) {
				this.autoSavePaused = event;
			},

			selectSendMethod() {
				this.updateInvoice();
				this.showModal('EMAIL-OR-DOWNLOAD');
			},

			saveAndSend() {
				this.updateInvoice(true, false);
			},

			updated(invoice) {
				this.invoice = invoice;
			},

			paymentUpdated: function(payment){
				let ix = this.invoice.payments.findIndex(p => p.id === payment.id);
				this.invoice.payments.splice(ix,1,payment);
			},

			async updateInvoice(send, auto, force = false) {
				if (this.saving) {
					return;
				}

				if (this.invoice.discountPercentage === '' || this.invoice.discountPercentage === null) {
					this.invoice.discountPercentage = 0;
				}

				if (this.invoice.taxPercentage === '' || this.invoice.taxPercentage === null) {
					this.invoice.taxPercentage = 0;
				}

				const patch = jsonPatch.generate(this.observer, false);
				let finalPatch = [];

				for (let i = 0; i < patch.length; i++) {
					if (!patch[i].path.startsWith('/attachments')) {
						finalPatch.push(patch[i]);
					}
				}

				if (finalPatch.length === 0 && !force) {
					if (send) this.openSendModal();
					if (!auto) this.saved = true;
					return;
				}

				if (finalPatch.length > 0 || force) {
					this.saving = true;
					await this.invoiceService
						.patchInvoice(this.clientId, this.id, finalPatch)
						.then((res) => {
							this.invoice = res.data;
							this.saving = false;
							this.saved = true;
							this.observer = jsonPatch.observe(this.invoice);
							if (send) {
								this.openSendModal();
							}
						})
						.catch((err) => {
							this.saving = false;
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			openSendModal: function(){
				if(this.invoice.status === 'DRAFT'){
					this.invoiceService.markAsSent(this.client.id,this.invoice.id).then((res) => {
						this.invoice = res.data;
						this.showModal('SEND');
					})
				}else{
					this.showModal('SEND');
				}
			},

			deleteInvoice() {
				this.$emit('result', { action: 'DELETED', id: this.invoice.id });
			},

			alert(type, message) {
				let alert = { type: type, message: message };
				this.$store.commit('alert', alert);
			},

			handlePaymentAction() {
				console.log('handlePaymentAction fired');
			},
		},
		filters: {},

		computed: {
			rendererType: function(){
				return (this.invoice && this.invoice.invoiceTemplate) ? 'InvoiceRendererV2' : 'InvoiceRenderer';
			},

			statusDisplay() {
				if (!this.invoice) return;
				return this.$t('invoice.statuses.' + this.invoice.status);
			},

			saveStatus() {
				if (this.saving) {
					return 'Saving...';
				} else if (this.saved) {
					return 'Saved.';
				}
				return '';
			},

			additionalTaxRates: function(){
				let result = [];
				if(this.quickbooksTaxAccounts.length){
					this.quickbooksTaxAccounts.forEach(t => {
						result.push({
							id: t.id,
							name: t.name,
							type: 'Quickbooks',
							rate: t.rateValue
						})
					})
				}
				if(this.xeroTaxAccounts.length){
					this.xeroTaxAccounts.forEach(t => {
						result.push({
							id: t.taxType,
							name: t.name,
							type: 'Xero',
							rate: t.effectiveRate
						})
					});
				}
				return result;
			}
		},
		watch: {
			saved: function(val) {
				if (val) {
					setTimeout(() => (this.saved = false), 2000);
				}
			},
			account: {
				deep: true,
				immediate: false,
				handler() {
					this.rendererKey++;
				},
			},
		},
	};
</script>

<style scoped lang="scss">
	.accounting {
		.unlink {
			visibility: hidden;
		}
		&:hover {
			.unlink {
				visibility: visible;
			}
		}
	}
</style>

<style lang="scss">
	#invoice-details {
		background-color: var(--v-white-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
