<template>
	<div class="column-format fill-height" v-if="initialized">
		<div class="row-format centered fill-height" style="flex: 1" v-if="!transactions.length">
			<empty-view
				header="No deposits yet"
				body="It’s a best practice to collect a deposit before you do any work for a client. If you do, you can see what you’ve collected, as well as how much of the deposit has been applied to invoices and how much credit still remains."
				cta="Request a deposit"
				video-header="See how it works"
				video-body="Learn how to request deposits and apply them to future invoices."
				video-cta="Watch the tutorial"
				video-id="VHlGLkW1suI"
				@cta-clicked="addNew()"
			></empty-view>
		</div>

		<div style="width:100%">
			<div class="ml-auto mr-3" style="width:70px;" v-if="currencies.length > 1">
				<v-select style="margin-top:0px; margin-bottom: 0px" :items="currencies" v-model="currency" dense hide-details></v-select>
			</div>
		</div>

		<div class="row-format mt-2 mb-2 px-3" v-if="initialized && transactions.length">
			<div class="kpi-box mr-3">
				<div class="fit text-left">Total collected</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(depositAccount.liabilities,true,true,currency) }}</div>
			</div>
			<div class="kpi-box mr-3">
				<div class="fit text-left">Total applied</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(depositAccount.credits,true,true,currency) }}</div>
			</div>
			<div class="kpi-box">
				<div class="fit text-left">Current balance</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(depositAccount.balance,true,true,currency) }}</div>
			</div>
		</div>

		<div v-if="transactions.length && !filteredTransactions.length" class="mt-6">
			<empty-filtered-results></empty-filtered-results>
		</div>

		<div v-else-if="filteredTransactions.length">
			<v-data-table
				v-if="initialized"
				:headers="headers"
				:items="filteredTransactions"
				:hide-default-footer="true"
				sort-by="timestamp"
				:sort-desc="true"
				height="calc(var(--vh) - 270px)"
				:fixed-header="true"
				style="cursor: pointer"
				:items-per-page="-1"
				@click:row="openInvoice($event.invoiceId)"
			>
				<template v-slot:item.timestamp="{ item }">
					<span v-if="item.timestamp" class="brand-semilight">{{
						DateTime.fromISO(item.timestamp).toFormat('DD')
					}}</span>
				</template>

				<template v-slot:item.amount="{ item }">
					<span class="brand-semilight">{{ formatAmount(item.amount, item.type, item.currency) }}</span>
				</template>

				<template v-slot:item.invoiceNumber="{ item }">
					<div class="brand-semilight">#{{ item.invoiceNumber }}</div>
				</template>

				<template v-slot:item.type="{ item }">
					<div class="brand-semilight font-12" v-html="formatType(item.type)"></div>
				</template>

				<template v-slot:item.action="{}">
					<!--div class="row-format">
						<v-icon size="20" class="ml-auto mr-1 pointer" style="">$moreHorizontal</v-icon>
					</div-->
				</template>

				<template v-slot:header.action="{}">
					<div class="row-format">
						<v-icon size="20" class="ml-auto mr-1 pointer" style="" @click="addNew()">$plus</v-icon>
					</div>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";
	import CreateInvoiceModal from '@/modules/invoices/CreateInvoiceModal';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import ClientService from '@/modules/clients/ClientService';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyView from '@/components/EmptyView';
	import EmptyFilteredResults from '@/components/EmptyFilteredResults';

	export default {
		name: 'DepositList',
		components: { EmptyView, EmptyFilteredResults },
		props: ['client', 'isActive', 'filter','currencies'],

		data() {
			return {
				clientService: new ClientService(),
				depositAccount: null,
				initialized: false,
				DateTime: DateTime,
				createInvoiceModal: false,
				invoiceDetailModal: false,
				invoiceId: null,
				currency: this.client.currency ? this.client.currency : this.$store.state.defaultCurrency,

				headers: [
					{ text: this.$t('deposit.date'), value: 'timestamp' },
					{ text: this.$t('deposit.type'), value: 'type' },
					{ text: this.$t('deposit.amount'), value: 'amount', align: 'right' },
					{ text: this.$t('deposit.invoice-number'), value: 'invoiceNumber', align: 'right' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				],
			};
		},

		mounted() {
			if (this.isActive) {
				this.getDepositAccountForClient();
			}
		},

		methods: {
			getDepositAccountForClient: function () {
				this.clientService.getDepositAccount(this.client.id,this.currency).then((res) => {
					this.depositAccount = res.data;
					this.initialized = true;
				});
			},

			openInvoice: function (id) {
				let binding = {
					id: id,
					clientId: this.client.id,
					showDuplicate: false,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true);
			},

			formatType(type) {
				let background, color, label;

				if (type === 'LIABILITY') {
					background = '--v-success_10-base';
					color = '--v-success-base';
					label = 'Deposit received';
				} else {
					background = '--v-gray_20-base';
					color = '--v-gray_80-base';
					label = 'Credit applied';
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},

			formatAmount(amount, type, currency) {
				if (type === 'CREDIT') {
					amount = amount * -1;
				}
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: currency,
					currencySign: 'accounting',
					minimumFractionDigits: 2,
				}).format(amount);
			},

			addNew: function () {
				let binding = {
					selectedClient: this.client,
					forceDeposit: true,
				};

				this.$store.state.globalModalController.openModal(CreateInvoiceModal, binding).then((res) => {
					if (res) {
						this.openInvoice(res.id);
					}
				});
			},
		},

		computed: {
			transactions: function () {
				if (this.depositAccount && this.depositAccount.transactions) {
					return this.depositAccount.transactions;
				} else {
					return [];
				}
			},

			filteredTransactions: function () {
				let result = [...this.transactions];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let earliest, latest;

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
						earliest = DateTime.fromISO(this.filter.earliest);
						latest = DateTime.fromISO(this.filter.latest);
					} else if (this.filter.dateSpecifier !== 'between') {
						let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
						earliest = helper.earliest;
						latest = helper.latest;
					}
				}

				return result
					.filter((i) => {
						if (!search || (search && i.invoiceNumber.toString().startsWith(search))) {
							return true;
						} else if (search) {
							return false;
						}
					})
					.filter((i) => {
						if (earliest && latest) {
							let dateSent = DateTime.fromISO(i.timestamp);
							if (dateSent >= earliest && dateSent < latest) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((i) => {
						if (this.filter.depositType.length) {
							if (this.filter.depositType.includes(i.type)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},
		},

		watch: {
			currency: function(){
				this.getDepositAccountForClient();
			},

			isActive: function (newVal) {
				if (newVal && !this.initialized) {
					this.getDepositAccountForClient();
				}
			},
		},
	};
</script>

<style lang="scss"></style>
