<template>
	<div>
		<div class="mt-2 mb-2" v-if="deliverable.files.length">
			<div
				v-for="file in deliverable.files"
				:key="file.fileName"
				class="row-format align-center mb-2"
			>
				<div class="row-format align-center attachment-wrapper" style="width: 100%; position: relative">
					<div class="buttonOverlay row-format align-center" style="justify-content: space-between">
						<v-icon size="20" class="pointer" @click.native="downloadFile(file)"> $download </v-icon>
						<v-icon
								size="20"
							class="pointer"
							@click.native="downloadFile(file, false)"
							v-if="isOpenableFile(file)"
							>open_in_new</v-icon
						>
						<v-icon class="pointer" size="20" @click.native="confirmDeleteFile(file)">$delete</v-icon>
					</div>
					<div style="max-height: 24px; max-width: 24px">
						<v-img contain :src="getFilePreview(file)" height="24" style="cursor: pointer"></v-img>
					</div>
					<div class="ml-2 font-14">{{ file.fileName }}</div>
				</div>
			</div>
		</div>

		<div class="" style="width: fit-content">
			<vue-dropzone
					style="background-color: var(--v-white-base)"
					ref="fileDropzone"
					id="dropzone"
					v-on:vdropzone-success="uploadSuccess"
					v-on:vdropzone-error="uploadFailure"
					v-on:vdropzone-sending="addFileUploadAuthHeader"
					:use-custom-slot="true"
					:options="dropzoneOptions"
			>
				<div style="width:fit-content"><v-icon size="14" class="mb-1">$plus</v-icon> Add file</div>
			</vue-dropzone>
		</div>

		<confirm-dialog
			:dialog="deleteFileDialog"
			@close="cancelDeleteFile()"
			@confirm="deleteFile()"
			heading-text="Confirm"
			yes-text="Delete"
			no-text="Cancel"
			:body-text="$t('projects.delete-attachment-confirm')"
		/>
	</div>
</template>

<script>
	import axios from 'axios';
	import VueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";

	export default {
		name: 'FileAttachments',

		props: ['deliverable'],

		components: {
			VueDropzone,
			ConfirmDialog,
		},

		data: function () {
			return {
				deliverableService: new ProjectDeliverableService(),
				fileToDelete: null,
				deleteFileDialog: false,
				realClick: false,
			};
		},

		mounted() {

		},

		methods: {
			confirmDeleteFile: function (file) {
				this.fileToDelete = file;
				this.deleteFileDialog = true;
			},

			cancelDeleteFile: function () {
				this.fileToDelete = null;
				this.deleteFileDialog = false;
			},

			deleteFile: function () {
				this.deliverableService
					.deleteDeliverableAttachment(this.deliverable.id, this.fileToDelete.fileName)
					.then(() => {
						this.removeFileByName(this.fileToDelete.fileName);
						this.cancelDeleteFile();
					})
					.catch((err) => {
						this.$store.commit('error', 'Error deleting file: ' + err.response.data.message);
						this.cancelDeleteFile();
					});
			},

			removeFileByName: function (fileName) {
				for (let i = 0; i < this.deliverable.files.length; i++) {
					if (this.deliverable.files[i].fileName === fileName) {
						this.deliverable.files.splice(i, 1);
						break;
					}
				}
			},

			downloadFile: function (s3File, download = true) {
				this.deliverableService.getSignedUrl(this.deliverable.id, s3File.fileName).then((res) => {
					let signedUrl = res.data;

					if (download == false) {
						window.open(signedUrl, '_blank');
					} else {
						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', s3File.fileName); //or any other extension
							document.body.appendChild(link);
							link.click();
						});
					}
				});
			},

			isOpenableFile(s3File) {
				if (
					s3File.fileType === 'JPG' ||
					s3File.fileType === 'PNG' ||
					s3File.fileType === 'GIF' ||
					s3File.fileType === 'PDF'
				) {
					return true;
				} else {
					return false;
				}
			},

			getFilePreview: function (file) {
				if (file.fileType === 'JPG' || file.fileType === 'PNG' || file.fileType === 'GIF') {
					return `${this.$store.getters.getPodUrl}api/insecure/${this.deliverable.accountId}/deliverables/${
						this.deliverable.id
					}/files/thumbnail?fileName=${encodeURIComponent(file.fileName)}&authToken=${encodeURIComponent(
						this.$store.state.accessToken
					)}`;
				}
				return file.fileIconUrl;
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function (file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.deliverable.files.push(response);
			},

			uploadFailure: function (file, response) {
				let message = response.message ? response.message : response
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},
		},

		computed: {
			dropzoneOptions: function () {
				return {
					url: `${this.$store.getters.getBaseUrlV2}/deliverables/${this.deliverable.id}/files`,
					thumbnailWidth: 50,
					maxFilesize: 100,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					includeStyling: false,
					parallelUploads: 1,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	#dropzone {
		min-height: 16px !important;
		margin: 0px;
		padding: 0px;
	}

	.iconHover {
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.drop ::v-deep .dz-message {
		margin: 15px 5px 0px 0px;
		padding: 0px 0px 0px 0px;
	}

	.buttonOverlay {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 400;
		width: 100%;
		height: 100%;
		background-color: var(--v-white-base);
		opacity: 0;
		transition: all ease 250ms;

		&:hover {
			opacity: 0.9;
		}
	}
</style>
