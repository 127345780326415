<template>
	<v-container
		v-if="ourThread"
		class="font-14"
		fluid
		@mouseenter="hovering = true"
		@mouseleave="hovering = false"
		@click="$emit('click')"
	>
		<v-row dense align="center">
			<v-col cols="3" align="left" class="row-format">
				<div v-if="!clientId">
					<client-avatar v-for="client in clients" :key="client.id" :client="client" small class="mr-1"></client-avatar>
				</div>
				<div :class="`text-truncate ${clients.length && !clientId ? 'ml-3' : ''}`">{{ participants.join(', ') }}</div>
			</v-col>
			<v-col cols="7" align="left" class="brand-semilight row-format align-center">
				<v-icon color="gray_60" class="material-symbols-rounded">{{ ourThread.unread ? 'mark_email_unread' : 'email'}}</v-icon>
				<v-icon color="gray_60" class="material-symbols-rounded" v-if="ourThread.summary"  v-tippy="{theme: 'left-align'}" :content="ourThread.summary ? marked(ourThread.summary,{breaks:true}) : ''">summarize</v-icon>
				<div :class="`ml-2 ${ourThread.unread ? 'brand-bold' : ''}`">{{ ourThread.subject }}</div>
			</v-col>
			<v-col cols="2" align="right" class="nowrap">
				<inbox-actions
					v-show="hovering"
					class="ml-auto"
					@return-to-inbox="returnToInbox()"
					@snooze="snooze($event)"
					@archive="archive()"
					@unread="markThreadAsRead()"
					:unread="ourThread.unread"
					:snooze-options="snoozeOptions"
					:folder="ourFolder"
				></inbox-actions>
				<div v-if="!hovering" style="height:32px">{{ this.$formatters.formatTimeAgo(communication.date) }}</div>
			</v-col>
		</v-row>
		<v-row dense v-if="ourThread.messageCount > 1">
			<v-col cols="12" align="left" class="row-format align-center">
				<div class="message-count row-format centered">
					<div>{{ ourThread.messageCount }}</div>
				</div>
				<div class="ml-auto multiple-messages"></div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import EmailMixin from '@/modules/communicator/inbox/email/EmailMixin';
	import marked from "marked";

	export default {
		name: 'EmailThread',

		props: ['communication', 'userEmails', 'snoozeOptions', 'folder', 'clientId'],

		components: { InboxActions, ClientAvatar },

		mixins: [EmailMixin],

		data: function() {
			return {
				marked: marked,
				communicatorEmailService: new CommunicatorEmailService(),
				hovering: false,
				content: null,
			};
		},

		mounted() {
			this.ourThread = { ...this.communication.payload };
			this.ourFolder = this.folder;
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			clients: function() {
				let result = [];
				this.communication.clientIds.forEach((c) => {
					result.push(this.$store.getters.getClientById(c));
				});
				return result;
			},

			participants: function() {
				let result = [];

				if (this.ourThread) {
					let participants = this.ourThread.participants.filter(
						(p) => !this.userEmails.includes(p.address.toLowerCase())
					);

					participants.forEach((p) => {
						let contact = this.$store.getters.getContactByEmail(p.address);
						if (contact) {
							result.push(participants.length > 1 ? contact.firstName : contact.fullName);
						} else if (p.personal && !p.personal.includes('@')) {
							result.push(participants.length > 1 ? p.personal.split(' ')[0] : p.personal);
						} else {
							result.push(p.address.split('@')[0]);
						}
					});
				}

				return result;
			},
		},
	};
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
	.message-count {
		width: 24px;
		height: 24px;
		border-radius: 12px;
		font-size: 12px;
		font-weight: 600;
		background-color: var(--v-gray_20-base);
		color: var(--v-gray_70-base);
	}

	.multiple-messages {
		height: 4px;
		width: calc(100% - 32px);
		border-top: 1px solid var(--v-gray_30-base);
		border-bottom: 1px solid var(--v-gray_30-base);
	}
</style>
