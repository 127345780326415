<template>
	<div v-if="pl">
		<p-l-chart :pl-report="pl" :mode="chartMode"></p-l-chart>
		<div class="fill-width my-8" style="border-top: 1px solid var(--v-gray_30-base);"></div>
		<table>
			<thead>
				<th class="text-left"></th>
				<th
					v-for="(month, index) in monthColumns"
					:key="'month-header' + index"
					class="text-right month-header nowrap"

				>
					{{ month.toFormat('LLL yy') }}
				</th>
				<th  class="text-right pr-4">Total</th>
			</thead>
			<tbody>
				<tr>
					<td class="text-left pt-1 semibold">Money in</td>
				</tr>
				<tr v-for="(income, index) in pl.income" :key="'income' + index" class="data-tr">
					<td class="text-left desc-cell pl-5 nowrap pr-9">
						{{ income.client.name }}
					</td>
					<td class="money-cell" v-for="(month, index) in income.months" :key="'income-month' + index">
						{{ formatDataCell(month.amount) }}
					</td>
					<td class="money-cell semibold">{{ $formatters.dollars(rowTotal('income', index),true,true,$store.state.defaultCurrency) }}</td>
				</tr>
				<tr class="data-tr">
					<td class="desc-cell text-left semibold">Total Income</td>
					<td
						v-for="(month, index) in monthColumns"
						:key="'total-income' + index"
						class="text-right money-cell semibold"
					>
						{{ $formatters.dollars(incomeTotal(index),true,true,$store.state.defaultCurrency) }}
					</td>
					<td class="money-cell bold" style="color: var(--v-primary-base)">
						{{ $formatters.dollars(allTotal('income'),true,true,$store.state.defaultCurrency) }}
					</td>
				</tr>
				<tr>
					<td class="pt-5">&nbsp;</td>
				</tr>
				<tr>
					<td class="text-left semibold">Money out</td>
				</tr>
				<tr v-for="(expense, index) in pl.expenses" :key="'expense' + index" class="data-tr">
					<td class="text-left desc-cell pl-5 nowrap" style="padding-right: 0px !important">
						{{ expense.category }}
					</td>
					<td class="money-cell" v-for="(month, index) in expense.months" :key="'total-month' + index">
						{{ formatDataCell(month.amount * -1) }}
					</td>
					<td class="money-cell semibold">{{ $formatters.dollars(rowTotal('expenses', index) * -1,true,true,$store.state.defaultCurrency) }}</td>
				</tr>
				<tr class="data-tr">
					<td class="desc-cell text-left semibold">Total Expenses</td>
					<td
						v-for="(month, index) in monthColumns"
						:key="'total-expense' + index"
						class="text-right money-cell semibold"
					>
						{{ $formatters.dollars(expenseTotal(index) * -1,true,true,$store.state.defaultCurrency) }}
					</td>
					<td class="money-cell bold" style="color: var(--v-primary-base)">
						{{ $formatters.dollars(allTotal('expenses') * -1,true,true,$store.state.defaultCurrency) }}
					</td>
				</tr>
				<tr>
					<td class="pt-5">&nbsp;</td>
				</tr>
				<tr class="data-tr">
					<td class="desc-cell text-left semibold">Profit</td>
					<td v-for="(month, index) in monthColumns" :key="'gt' + index" class="text-right money-cell semibold">
						{{ $formatters.dollars(monthTotal(index),true,true,$store.state.defaultCurrency) }}
					</td>
					<td class="money-cell bold" style="color: var(--v-primary-base)">
						{{ $formatters.dollars(allTotal('income') - allTotal('expenses'),true,true,$store.state.defaultCurrency) }}
					</td>
				</tr>
			</tbody>
		</table>
		&nbsp;
	</div>
</template>

<script>
	import ReportService from '@/modules/accounting/reports/ReportService';
	import DateTime from "@/modules/utils/HDateTime";
	import PLChart from "@/modules/accounting/reports/PLChart";

	export default {
		name: 'PLReport',

		props: ['earliest', 'latest', 'refreshKey','chartMode'],

		components: {PLChart},

		data: function () {
			return {
				reportService: new ReportService(),
				pl: null,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'accounting-profit-loss' });
			this.getPLReport();
		},

		beforeDestroy() {},

		methods: {
			getPLReport: function () {
				this.reportService.getProfitAndLoss(this.earliest, this.latest).then((res) => {
					this.pl = res.data;
				});
			},

			downloadReport: function(){
				this.$store.commit('startLoading');
				this.reportService.accountingDownload(this.earliest, this.latest).then((res) => {
					let fileURL = window.URL.createObjectURL(res.data);
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute('download', 'P&L-' + this.earliest + "-thru-" + this.latest + '.xlsx');
					document.body.appendChild(fileLink);
					fileLink.click();
				}).finally(() => this.$store.commit('stopLoading'));
			},

			monthTotal: function (month) {
				return this.incomeTotal(month) - this.expenseTotal(month);
			},

			rowTotal: function (type, index) {
				let total = 0;
				for (let i = 0; i < this.pl[type][index].months.length; i++) {
					total = total + this.pl[type][index].months[i].amount;
				}
				return total;
			},

			allTotal: function (type) {
				let total = 0;
				for (let i = 0; i < this.pl[type].length; i++) {
					for (let j = 0; j < this.pl[type][i].months.length; j++) {
						total = total + this.pl[type][i].months[j].amount;
					}
				}
				return total;
			},

			expenseTotal: function (month) {
				let totalExpense = 0;
				for (let i = 0; i < this.pl.expenses.length; i++) {
					totalExpense = totalExpense + this.pl.expenses[i].months[month].amount;
				}
				return totalExpense;
			},

			incomeTotal: function (month) {
				let totalIncome = 0;
				for (let i = 0; i < this.pl.income.length; i++) {
					totalIncome = totalIncome + this.pl.income[i].months[month].amount;
				}
				return totalIncome;
			},

			formatDataCell: function (amount) {
				if (amount === 0) {
					return ' -- ';
				} else {
					return this.$formatters.dollars(amount, true, false, this.$store.state.defaultCurrency);
				}
			},
		},

		watch: {
			refreshKey: function () {
				this.getPLReport();
			},
		},

		computed: {
			monthColumns: function () {
				if (this.pl) {
					let start = DateTime.fromISO(this.pl.earliest);
					let end = DateTime.fromISO(this.pl.latest);
					let result = [];

					while (start < end) {
						result.push(start);
						start = start.plus({months:1});
					}

					return result;
				} else {
					return [];
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	table {
		background-color: transparent;
		width: 100%;
	}
	th {
		padding: 5px 15px 5px 10px;
		//font-size: 14px;
		font-weight: 500 !important;
	}
	td {
		font-weight: 300 !important;
		font-size: 14px;
		text-align: right;
		padding: 5px 15px 5px 10px;
		&.semibold {
			font-weight: 500 !important;
		}
		&.bold {
			font-weight: 600 !important;
		}
	}

	.data-tr {
		&:hover td {
			background-color: var(--v-gray_10-base);
		}
	}

	.money-cell {
	}

	.desc-cell {
	}
</style>
