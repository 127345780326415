var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width",staticStyle:{"background":"var(--v-white-base)"}},[_c('div',{staticClass:"row-format py-3 pr-5",staticStyle:{"border-bottom":"1px solid var(--v-gray_30-base)"}},[_c('plus-button',{staticClass:"ml-auto",on:{"click":function($event){return _vm.addWorkflow()}}})],1),_c('div',[_c('v-data-table',{staticClass:"pointer",attrs:{"headers":_vm.headers,"items":_vm.workflows,"items-per-page":-1,"hide-default-footer":true},on:{"click:row":function($event){return _vm.openEditor($event)}},scopedSlots:_vm._u([{key:"item.ruleLabel",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ruleLabel))])]}},{key:"item.publishedTimestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format gap-1"},[(item.publishedTimestamp)?_c('div',[_vm._v(_vm._s(_vm.$formatters.formatForTimeAgo(item.publishedTimestamp)))]):_vm._e(),(item.draft)?_c('div',{staticClass:"font-12 font-gray_70"},[_vm._v("(In draft)")]):_vm._e()])]}},{key:"item.executions",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({content: 'View events'}),expression:"{content: 'View events'}"}],staticStyle:{"color":"var(--v-primary-base)","border":"2px solid var(--v-gray_60-base)","width":"fit-content","padding-left":"4px","padding-right":"4px","text-align":"center","border-radius":"4px"},on:{"click":function($event){$event.stopPropagation();return _vm.viewEvents(item)}}},[_vm._v(_vm._s(item.executions))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format gap-2"},[_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({content: 'Duplicate workflow'}),expression:"{content: 'Duplicate workflow'}"}],staticClass:"material-symbols-rounded",attrs:{"size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.addWorkflow(item)}}},[_vm._v("content_copy")]),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({content: 'Delete workflow'}),expression:"{content: 'Delete workflow'}"}],staticClass:"material-symbols-rounded",attrs:{"size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item)}}},[_vm._v("$delete")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }