<template>
	<div style="max-width: 400px">
		<div class="row-format align-center mb-6 pt-6 px-6">
			<div class="brand-medium font-16">{{ heading }}</div>
			<v-icon size="20" class="ml-auto" @click="$emit('result')">$close</v-icon>
		</div>
		<div style="min-width: 400px">
			<div class="px-6">
				<v-form ref="form" lazy-validation v-model="isValid">
					<div class="column-format gap-3">
						<v-text-field
							v-model="newPassword"
							:rules="rules"
							label="Password"
							type="password"
							dense
							hide-details
							persistent-placeholder
							outlined
						/>
						<v-text-field
							v-model="confirmPassword"
							:rules="confirmPasswordRules"
							label="Confirm password"
							type="password"
							persistent-placeholder
							hide-details
							dense
							outlined
						/>
						<div class="font-14 font-gray_70 row-format align-center gap-2 mb-3">
							<v-icon color="success" v-if="isValid">check</v-icon>
							<v-icon color="error" v-else>cancel</v-icon>
							<div class="text-left">Passwords must be between 8-20 characters and include 1 upper, 1 lower, 1 digit, 1 symbol</div>
						</div>
					</div>
				</v-form>
			</div>
			<div style="border-top: 1px solid var(--v-gray_30-base)" class="py-2">
				<v-btn @click="updatePassword" width="160" class="super-action" :disabled="!isValid">
					{{ $t('global.save') }}
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	export default {
		name: 'PasswordEnabled',
		components: {},

		props: {},

		data: function() {
			return {
				isValid: false,
        rules: [
          (v) => !!v || 'Password is required',
          (v) => v.length >= 8 || 'Password must be at least 8 characters long',
          (v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
					(v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
					(v) => /[0-9]/.test(v) || 'Password must contain at least one digit',
          (v) => /[-!@$#%^&*()_+|~=`{}[\]:";'<>?,./]/.test(v) || 'Password must contain at least one symbol',
        ],

				confirmPasswordRules: [
					(v) => !!v || 'Confirm password is required',
					(v) => v === this.confirmPassword || 'Passwords do not match',
				],
				newPassword: '',
				confirmPassword: '',
			};
		},

		mounted() {},

		methods: {
      updatePassword() {
				if (this.newPassword !== this.confirmPassword) {
          this.$store.commit('error', 'Passwords do not match');
					return;
				}
				this.$store.commit('startLoading');
				AuthService.updateUserPassword(this.newPassword)
					.then((res) => {
						this.$store.commit('success', 'Password updated');
						this.$emit('result',res);
					})
					.catch((error) => {
						this.$store.commit('error', error.response.data.message);
					}).finally(() => this.$store.commit('stopLoading'));
			},
		},

		filters: {},
		computed: {
			heading() {
				return 'Set password';
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.button-box {
		width: 282px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
