var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column-format mt-n2"},[_c('div',{staticClass:"row-format align-center px-5 pb-2",staticStyle:{"border-bottom":"1px solid var(--v-gray_30-base)"}},[_c('div',{staticClass:"month-name"},[_vm._v(_vm._s(_vm.monthName))]),_c('div',{staticClass:"ml-auto"},[_c('v-btn-toggle',[_c('v-btn',{staticClass:"chevron-button",attrs:{"color":"var(--v-gray_70-base)","outlined":"","small":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$chevronLeft")])],1),_c('v-btn',{staticClass:"today-button",attrs:{"color":"var(--v-black-base)","outlined":"","small":""},on:{"click":function($event){return _vm.today()}}},[_vm._v(_vm._s(_vm.$t('global.today')))]),_c('v-btn',{staticClass:"chevron-button",attrs:{"color":"var(--v-gray_70-base)","outlined":"","small":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$chevronRight")])],1)],1)],1)]),_c('div',[_c('v-calendar',{ref:"calendar",staticStyle:{"height":"calc(100vh - 165px)"},attrs:{"locale":_vm.$store.state.locale,"id":"calendar","weekdays":_vm.weekdays,"type":"month"},on:{"mouseenter:day":_vm.mouseMove,"mouseup:day":_vm.mouseUp},nativeOn:{"mouseleave":function($event){return _vm.mouseUp.apply(null, arguments)}},scopedSlots:_vm._u([{key:"day-label",fn:function(ref){
var day = ref.day;
var weekday = ref.weekday;
return [_c('div',{staticClass:"text-left font-12 pl-2"},[_c('span',{staticClass:"d-label"},[_vm._v(_vm._s(_vm.days[weekday]))]),_vm._v(" "+_vm._s(day)+" ")])]}},{key:"day-label-header",fn:function(ref){
var day = ref.day;
var weekday = ref.weekday;
return [_c('div',{staticClass:"text-left font-12 pl-2"},[_vm._v(_vm._s(_vm.days[weekday])+" "+_vm._s(day))])]}},{key:"day",fn:function(ref){
var date = ref.date;
return [_c('div',{staticClass:"day",style:(("--day-height: " + _vm.dayHeight + "px;")),on:{"click":function($event){return _vm.addDeliverable(date)}}},[_vm._l((_vm.events.get(date)),function(event,index){return _c('div',{key:event.id},[(index < _vm.maxItems || (index === _vm.maxItems && index === _vm.events.get(date).length - 1))?_c('calendar-item',{attrs:{"event":event},on:{"start-drag":function($event){return _vm.startDrag($event)},"edit-deliverable":function($event){return _vm.editDeliverable($event)}}}):_vm._e()],1)}),(_vm.events.get(date) && _vm.events.get(date).length > _vm.maxItems + 1)?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-top":_vm.dayHeight},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"hasMore pointer"},on),[_vm._v("+ "+_vm._s(_vm.events.get(date).length - _vm.maxItems)+" more")])]}}],null,true)},[_c('v-container',{staticStyle:{"background-color":"white"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"8","align":"center"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.moment(date).format('ddd')))]),_c('h2',[_vm._v(_vm._s(_vm.moment(date).format('D')))])]),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('v-icon',{staticClass:"pointer",attrs:{"small":"","color":"black"}},[_vm._v("$close")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.events.get(date)),function(event){return _c('div',{key:event.id},[_c('calendar-item',{attrs:{"event":event},on:{"start-drag":function($event){return _vm.startDrag($event)},"edit-deliverable":function($event){return _vm.editDeliverable($event)}}})],1)}),0)],1)],1)],1):_vm._e()],2)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }