<template>
	<div class="pa-8">
		<div v-if="pushEnabled">
			<v-btn block color="primary" v-if="!existingSubscription" @click="handleSubscribeToPushNotifications()">Enable browser push notifications</v-btn>
			<v-btn block color="primary" v-else @click="unsubscribe()">Disable browser push notifications</v-btn>
		</div>
		<div v-else>Your browser doesn't support push notifications.</div>
	</div>
</template>

<script>
	import { PERMISSION_STATES, askForPushPermission, subscribeUserToPush, getExistingPushSubscription, unsubscribeUserToPush } from '@/utils/Notifications';
	import PushSubscriptionService from "@/modules/account/PushSubscriptionService";

	export default {
		name: 'Notifications',

		props: [],

		components: {},

		data: function() {
			return {
				pushSubscriptionService: new PushSubscriptionService(),
				existingSubscription: null,
			};
		},

		async mounted() {
			this.existingSubscription = await getExistingPushSubscription();
		},

		beforeDestroy() {},

		methods: {
			async unsubscribe(){
				let result = await unsubscribeUserToPush();
				if(result) {
					this.pushSubscriptionService.unsubscribe(this.existingSubscription).then(() => {
						this.existingSubscription = null;
						this.$store.commit('success', 'Push notifications have been disabled');
					}).catch((err) => this.$store.commit('error',err.response.data.message));
				}else{
					this.$store.commit('error', 'Unable to unsubscribe');
				}
			},

			async handleSubscribeToPushNotifications(){
				const permissionResult = await askForPushPermission();

				if (permissionResult === PERMISSION_STATES.GRANTED) {
					this.existingSubscription = await subscribeUserToPush();
					this.pushSubscriptionService.subscribe(this.existingSubscription).then(() => {
						this.$store.commit('success', 'Push notifications have been enabled');
					}).catch((err) => this.$store.commit('error',err.response.data.message));
				}
			}
		},

		computed: {
			pushEnabled: function(){
				return 'serviceWorker' in navigator && 'PushManager' in window;
			}
		},
	};
</script>

<style scoped lang="scss"></style>