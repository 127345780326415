import { render, staticRenderFns } from "./KanbanCard.vue?vue&type=template&id=0b4d8956&scoped=true&"
import script from "./KanbanCard.vue?vue&type=script&lang=js&"
export * from "./KanbanCard.vue?vue&type=script&lang=js&"
import style0 from "./KanbanCard.vue?vue&type=style&index=0&id=0b4d8956&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4d8956",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
