<template>
	<div>
		<div v-if="subscription">
			<div class="row-format py-2 pl-2 mb-1" style="border-bottom: 1px solid var(--v-gray_30-base)">
				<div :class="`page-link ${selectedTab === 'Phone' ? 'page-link-active' : ''}`" @click="selectTab('Phone')">
					<div>Phone</div>
					<div class="umi" v-if="unreadCalls">
						<div>{{ unreadCalls }}</div>
					</div>
				</div>
				<div :class="`page-link ${selectedTab === 'Texts' ? 'page-link-active' : ''}`" @click="selectTab('Texts')">
					<div>Messages</div>
					<div class="umi" v-if="unreadThreads && selectedTab !== 'Texts'">
						<div>{{ unreadThreads }}</div>
					</div>
				</div>
			</div>

			<div class="column-format" style="flex-grow: 1; background-color: var(--v-white-base)">
				<phone
						:is-visible="isVisible && selectedTab === 'Phone'"
						v-show="selectedTab === 'Phone'"
						:subscription="subscription"
						:primary="primary"
				></phone>
				<recent-calls
						:is-visible="isVisible && selectedTab === 'Phone'"
						v-show="selectedTab === 'Phone'"
						:subscription="subscription"
						:primary="primary"
						@call="callNumber($event)"
				></recent-calls>
				<messages
						:is-visible="isVisible && selectedTab === 'Texts'"
						v-show="selectedTab === 'Texts'"
						:subscription="subscription"
						:primary="primary"
				></messages>
			</div>
		</div>
		<div v-else>
			<div class="pa-5 text-left">
				<div class="font-20 brand-medium font-primary mb-5">Virtual business phone</div>
				<img src="/images/communicator/communicator.png" width="250" class="">
				<div class="font-14 my-5">Your personal phone doesn’t need to double as your work phone. With Moxie Communicator, you’ll get a virtual business phone number so you can make calls, record calls, have text message conversations, and refer back to what was said—all in your browser or mobile app.</div>
				<div @click="$router.push('/communicator/phone')" class="pointer brand-bold font-14 font-primary">Claim your number now</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Messages from '@/modules/communicator/realtime/messages/Messages';
	import Phone from '@/modules/communicator/realtime/voice/Phone';
	import RecentCalls from '@/modules/communicator/realtime/recents/RecentCalls';
	import libPhoneNumber from 'google-libphonenumber';

	export default {
		name: 'CommunicatorWidget',

		props: ['primary', 'secondaryIcon', 'isVisible'],

		components: { RecentCalls, Phone, Messages },

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {
			if (this.primary) {
				this.$store.state.eventBus.$on('communicator-place-call', this.placeCall);
			}
		},

		beforeDestroy() {
			if (this.primary) {
				this.$store.state.eventBus.$off('communicator-place-call', this.placeCall);
			}
		},

		methods: {
			placeCall: function (phone) {
				this.selectTab('Phone');
				this.$store.state.rightNavExpanded = true;
				this.$store.state.rightNavFocus = 'phone';
				this.$store.state.communicator.keyPadEntry = phone;
				this.$store.state.communicator.phoneIsValid = true;
				this.$store.state.communicator.showExpanded = true;
			},

			matchesAnyCountryCode: function (countryCodes, phone) {
				for (let i = 0; i < countryCodes.length; i++) {
					if (phone.startsWith(countryCodes[i])) {
						return true;
					}
				}
				return false;
			},

			selectTab: function (tab) {
				this.$store.state.communicator.selectedTab = tab;
			},

			callNumber: function (number) {
				this.$store.state.communicator.keyPadEntry = number;
				this.$store.state.communicator.phoneIsValid = true;
				this.selectTab('Phone');
			},
		},

		computed: {
			selectedTab: function () {
				return this.$store.state.communicator.selectedTab;
			},

			subscription: function () {
				return this.$store.state.communicatorSubscription;
			},

			unreadCalls: function () {
				return this.$store.state.calls.filter((c) => c.unread).length;
			},

			unreadThreads: function () {
				return this.$store.state.threads
					.map((t) => t.unread)
					.reduce((currentValue, previousValue) => currentValue + previousValue, 0);
			},
		},
	};
</script>

<style scoped lang="scss">
	.option {
		position: relative;
		cursor: pointer;
		width: 56px;
		height: 46px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 4px 4px 0px 0px;
		border: 0px;
	}

	.active {
		background-color: var(--v-white-base);
	}

	.phone-umi {
		background-color: var(--v-accent-base);
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height: 20px;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		position: relative;
		top: -5px;
		right: 1px;
	}

	.umi {
		background-color: var(--v-accent-base);
		color: var(--v-black-base);
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height: 20px;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		position: relative;
		top: -8px;
		right: 9px;
	}
</style>
