<template>
	<div class="row-format" id="project-filter">
		<v-menu
			v-model="menu"
			max-height="90vh"
			max-width="250"
			attach="#project-filter"
			:close-on-content-click="false"
			:close-on-click="true"
			content-class="filter-menu-class"
			style="overflow-y: auto;"
		>

			<template v-slot:activator="{ on }">
				<div :class="`pointer row-format align-center`" v-on="on">
					<div :class="`font-14 ${!isDefaultFilter ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{filterLabel}}</div>
					<v-icon x-small class="mx-1" :color="!isDefaultFilter ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</div>
			</template>

			<div class="column-format">
				<div class="filter-option-box">
					<div>
						<v-radio-group v-model="filter.dateRange">
							<v-radio v-for="option in dateOptions" :key="option.value" :value="option.value">
								<template v-slot:label>
									<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
								</template>
							</v-radio>
						</v-radio-group>

						<div class="" v-if="showEarliest">
							<date-selector
								content-class="my-5"
								:date="filter.earliest"
								@change="filter.earliest = $event"
								label="Earliest"
							></date-selector>
						</div>

						<div class="" v-if="showLatest">
							<date-selector
								content-class="my-5"
								:date="filter.latest"
								@change="filter.latest = $event"
								label="Latest"
							></date-selector>
						</div>
					</div>
				</div>

				<div class="mx-3 mt-3 mb-2">
					<v-btn text block color="secondary" elevation="0" class="filter-clear mb-2"
					><v-icon small>$clear</v-icon> {{ $t('projects.filter.clear-filter') }}</v-btn
					>
					<v-btn block class="super-action" @click="menu = false">{{
							$t('global.done')
						}}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import HDateTime from "@/modules/utils/HDateTime";
	import DateSelector from '@/components/DateSelector';
	import FilterHelpers from "@/utils/FilterHelpers";

	export default {
		name: 'ReportFilter',

		props: ['value'],

		components: { DateSelector },

		data: function () {
			return {
				filter: JSON.parse(JSON.stringify(this.value)),
				menu: false,
				dateOptions: FilterHelpers.getDateOptions([
					'this-month',
					'last-month',
					'this-quarter',
					'last-quarter',
					'past-30-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'between',
				]),
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			clearFilters: function () {
				this.filter.dateRange = 'past-6-months';
				this.filter.earliest = null;
				this.filter.latest = null;
			},
		},

		watch: {
			filter: {
				deep: true,
				handler: function(){
					this.$emit('updated',this.filter);
				}
			}
		},

		computed: {
			showEarliest: function () {
				if (!this.filter || !this.filter.dateRange) {
					return false;
				}

				let values = ['between'];

				if (values.includes(this.filter.dateRange)) {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function () {
				if (!this.filter || !this.filter.dateRange) {
					return false;
				}

				if (this.filter.dateRange === 'between') {
					return true;
				} else {
					return false;
				}
			},

			isDefaultFilter: function(){
				if(this.filter.dateRange === 'past-6-months'){
					return true;
				}else{
					return false;
				}
			},

			filterLabel: function () {
				let filterList = [];

				if (this.filter.dateRange) {
					let value = this.filter.dateRange === 'between' ? 'Between' : this.dateOptions.find(d => d.value === this.filter.dateRange).text;

					if ((this.filter.dateRange === 'between') && this.filter.earliest) {
						value = value + ' ' + HDateTime.fromISO(this.filter.earliest).toLocaleString({month:'short',day:'numeric'});
					}

					if (this.filter.dateRange === 'between' && this.filter.latest) {
						if (this.filter.earliest) {
							value = value + ' & ';
						}
						value = value + HDateTime.fromISO(this.filter.latest).toLocaleString({month:'short',day:'numeric'});
					}

					filterList.push(value);
				}

				return filterList.join(" ");
			},
		}
	};
</script>

<style lang="scss">
	#project-filter {
		.v-treeview-node__root {
			margin-left: -15px !important;

			.tree-item {
			}

			.v-treeview-node__label {
				max-width: 156px;
			}
		}

		.v-treeview-node__level {
			width: 12px !important;
		}

		.assigned-user-name {
			max-width: 161px;
		}
	}
</style>

<style scoped lang="scss">
	.filter-wrapper {
		font-size: 14px;
		font-weight: 500;
		color: var(--v-primary-base);
		padding: 3px 8px 2px 6px;
		cursor: pointer;
		transition: all ease 250ms;

		&.filter-empty {
			width: 240px;
			&:hover {
				background-color: var(--v-gray_10-base);
			}
		}

		&.filter-active {
			background-color: var(--v-gray_10-base);
			color: var(--v-blue_70-base);
		}
	}

	#filterContainer {
		background-color: var(--v-white-base);
		min-width: 800px;
	}
</style>
