<template>
	<div class="cardWrapper">
		<div v-if="deliverable.emptyCard" class="blankCard mx-0 my-2 pl-4 pr-0 pt-2" @click.stop="$emit('add-new-empty')">
			<v-icon size="34">$duplicate</v-icon>
			<span class="font-grey">{{ $t('projects.add-first-deliverable') }}</span>
		</div>
		<div v-else class="card pa-4 mb-2">
			<div class="column-format flex-grow-1" style="justify-content: space-between">
				<div class="row-format align-center">
					<client-avatar small :client="deliverable.client" v-if="deliverable.client"></client-avatar>
					<v-icon color="gray_30" v-else>check_box_outline_blank</v-icon>
					<div class="font-12 column-format text-truncate" style="margin-left: 6px;">
						<div class="brand-bold">{{ deliverable.client ? deliverable.client.name : '' }}</div>
						<div v-if="projectName" class="mt-n1">{{ projectName }}</div>
					</div>
					<v-icon
						color="success"
						small
						class="ml-auto"
						v-if="deliverable.invoiceId"
						v-tippy="{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
						>attach_money</v-icon
					>
				</div>
				<div class="font-14">
					<div>{{ deliverableName }}</div>
					<div class="font-12 font-gray_80" v-for="field in customFields" v-show="getCustomValue(field.id)" :key="field.id">{{field.name}}: {{getCustomValue(field.id)}}</div>
				</div>
				<div class="row-format align-center" style="gap: 8px">
					<div class="font-12 font-grey row-format align-center" v-if="deliverable.dueDate || deliverable.startDate">
						{{ timeline }}
					</div>
					<div class="row-format align-center">
						<div
							class="font-12 font-grey pointer row-format align-center"
							v-if="deliverable.tasks.length > 0"
							@click.stop="showTasks = !showTasks"
						>
							<v-icon size="16">check_circle_outline</v-icon>
							<div class="ml-1">{{ getTaskCountLabel(deliverable.tasks) }}</div>
						</div>
						<div
							class="font-12 font-grey pointer row-format align-center"
							v-if="deliverable.tasks.length === 0"
							@click.stop="addFirstTask()"
						>
							<v-icon size="16">check_circle_outline</v-icon>
							<v-icon size="16">add</v-icon>
						</div>
					</div>
					<div class="ml-auto row-format align-center gap-2">
						<v-icon v-if="deliverable.archived" size="20" color="gray_70" v-tippy="{ content: 'Archived' }"
							>lock</v-icon
						>
						<div class="ml-auto row-format align-center">
							<assigned-user
								v-for="(assignedTo, index) in deliverable.assignedToList"
								:key="assignedTo"
								:show-name="false"
								:small="true"
								:assigned-to="assignedTo"
								:style="
									`${
										index > 0 ? 'margin-left: -6px' : ''
									}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
								"
							></assigned-user>
						</div>
					</div>
				</div>
			</div>

			<div class="mt-3" v-show="showTasks && deliverable.tasks.length > 0">
				<tasks ref="tasks" v-model="deliverable.tasks" @empty="showTasks = false" @change="updateTasks($event)"></tasks>
			</div>
		</div>

		<div v-if="deliverable === 12" class="card mb-2">
			<div class="card-wrapper">
				<div class="row-format mb-2 ">
					<div v-if="highlightColor" :style="highlightColor"></div>
					<div
						v-if="deliverable.project"
						class="project-name ellipsis"
						:style="
							`${
								highlightColor
									? 'border-left: 0px; border-top-left-radius: 0px; border-bottom-left-radius: 0px'
									: ''
							}`
						"
					>
						{{ deliverable.project.name }}
					</div>
					<v-icon
						small
						class="ml-auto"
						color="success"
						v-if="deliverable.invoiceId"
						v-tippy="{ content: `Invoice: ${deliverable.invoiceNumber}` }"
						>attach_money</v-icon
					>
				</div>
				<div class="deliverable-name mb-2 row-format">
					<div>{{ deliverable.name }}</div>
					<div class="ml-auto row-format align-center" v-if="showStatus">
						<div v-if="showStatus">{{ status.label }}</div>
						<div
							class="ml-2"
							:style="`width:20px; height: 20px; border-radius: 4px; background-color: ${status.hexColor}`"
						></div>
					</div>
				</div>
				<div class="row-format align-center">
					<client-avatar v-if="deliverable.client" :client="deliverable.client" small></client-avatar>
					<assigned-user class="ml-2" :show-name="false" :assigned-to="deliverable.assignedTo"></assigned-user>
					<div style="margin-left: auto" class="row-format align-center">
						<div class="font-12 font-grey row-format align-center" v-if="deliverable.dueDate">
							<v-icon size="12" class="mb-1">event</v-icon>
							<div>
								{{ DateTime.fromISO(deliverable.dueDate).toLocaleString({ month: 'short', day: 'numeric' }) }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
  import DateTime from '@/modules/utils/HDateTime';
	import ClientAvatar from '@/components/ClientAvatar';
	import AssignedUser from '@/components/AssignedUser';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';

	export default {
		name: 'KanbanCard',

		props: ['deliverable', 'showStatus'],

		components: {
			AssignedUser,
			ClientAvatar,
			Tasks,
		},

		data: function() {
			return {
				DateTime: HDateTime,
				showTasks: false,
				deliverableService: new ProjectDeliverableService(),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('kanban-card-show-tasks', this.setShowTasks);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('kanban-card-show-tasks', this.setShowTasks);
		},

		methods: {
			expandTasks: function() {
				this.showTasks = true;
			},

			collapseTasks: function() {
				this.showTasks = false;
			},

			getCustomValue: function(id) {
				let value = this.deliverable.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			getTaskCountLabel: function(tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					for (let i = 0; i < tasks.length; i++) {
						if (tasks[i].complete) {
							completedCount++;
						}
					}

					return `${completedCount}/${taskCount}`;
				}
			},

			setShowTasks: function(showTasks) {
				this.showTasks = showTasks;
			},

			addFirstTask: function() {
				this.$refs.tasks.addNewTask(0);
				this.showTasks = true;
			},

			updateTasks() {
				const patch = [{ op: 'replace', path: '/tasks', value: this.deliverable.tasks }];
				this.deliverableService.patchDeliverable(this.deliverable.id, patch).catch((err) => {
					console.log(err);
				});
			},
		},

		computed: {
			projectName: function(){
				if(this.deliverable.name && this.deliverable.project){
					return this.deliverable.project.name;
				}else{
					return null;
				}
			},

			deliverableName: function(){
				if(this.deliverable.name){
					return this.deliverable.name;
				}else if(this.deliverable.project){
					return this.deliverable.project.name;
				}else{
					return null;
				}
			},

      timeline: function() {
        if (this.deliverable.startDate && this.deliverable.dueDate) {
          let start = DateTime.fromISO(this.deliverable.startDate).toLocaleString({
            month: 'short',
            day: 'numeric',
          });
          let due = DateTime.fromISO(this.deliverable.dueDate).toLocaleString({
            month: 'short',
            day: 'numeric',
          });
          return `${start} - ${due}`;
        } else if (this.deliverable.startDate) {
          let start = DateTime.fromISO(this.deliverable.startDate).toLocaleString({
            month: 'short',
            day: 'numeric',
          });
          return `Start ${start}`;
        } else if (this.deliverable.dueDate) {
          let due = DateTime.fromISO(this.deliverable.dueDate).toLocaleString({
            month: 'short',
            day: 'numeric',
          });
          return `Due ${due}`;
        } else {
          return null;
        }
      },

			status: function() {
				return this.$store.getters.getDeliverableStatusById(this.deliverable.statusId);
			},

			customFields: function(){
				return this.$store.state.deliverableFields.fields.filter(f => f.showOnCard);
			},

			highlightColor: function() {
				if (
					this.deliverable.project &&
					this.deliverable.project.hexColor &&
					this.deliverable.project.hexColor.toLowerCase() !== '#ffffff00'
				) {
					return `background-color: ${this.deliverable.project.hexColor}; width:5px; border-top-left-radius: 4px; border-bottom-left-radius: 4px`;
				} else {
					return '';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.card-wrapper {
		padding: 8px 8px 8px 8px;
		&:active {
			cursor: grabbing;
		}
	}

	.card {
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		box-sizing: border-box;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		min-height: 160px;
		&:hover {
			border: 1px solid var(--v-gray_50-base);
		}
	}

	.blankCard {
		background-color: var(--v-white-base);
		height: 144px;
		border: 1px solid var(--v-gray_50-base);
		box-sizing: border-box;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.project-name {
		font-size: 12px;
		border: 1px solid var(--v-gray_30-base);
		color: var(--v-gray_80-base);
		padding: 0px 6px;
		border-radius: 4px;
		width: fit-content;
		//width: fit-content;
		//white-space: nowrap;
		//text-overflow: ellipsis;
	}

	.deliverable-name {
		font-size: 14px;
		color: var(--v-gray_90-base);
	}

	.task-box {
		padding: 2px 6px;
		border-radius: 2px;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.no-select {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
</style>
