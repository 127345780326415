<template>
	<div>
<!--		<v-menu-->
<!--			v-model="menuOpen"-->
<!--			max-height="90vh"-->
<!--			max-width="250"-->
<!--			:close-on-content-click="false"-->
<!--			:close-on-click="true"-->
<!--			content-class="filter-menu-class"-->
<!--			style="overflow-y: auto"-->
<!--		>-->
<!--			<template v-slot:activator="{ on }">-->
<!--				<div-->
<!--						:class="`scope-button row-format align-center ${!isDefaultFilter ? 'active' : ''}`"-->
<!--						v-on="on"-->
<!--				>-->
<!--					<div>{{ isDefaultFilter ? filterLabel : filterDescription }}</div>-->
<!--					<v-icon small>$arrowDown</v-icon>-->
<!--				</div>-->
<!--			</template>-->

<!--			<div class="column-format open-menu">-->
<!--				<v-text-field-->
<!--					id="search-input"-->
<!--					v-model="filter"-->
<!--					autofocus-->
<!--					solo-->
<!--					flat-->
<!--					hide-details-->
<!--					dense-->
<!--					placeholder="Filter..."-->
<!--					@input="updated"-->
<!--					class="font-16"-->
<!--					@keypress.enter.exact="menuOpen = false"-->
<!--				></v-text-field>-->

        <div class="pl-3 font-14 brand-medium">
          <input
              style="width: 75px; color: var(--v-gray_80-base)"
              @input="updated"
              type="text"
              v-model="filter"
              placeholder="Search..."
          />
        </div>

<!--				<div class="mx-3 mt-3 mb-2">-->
<!--					<v-btn text block color="secondary" class="filter-clear mb-2" @click="clearFilter()"-->
<!--						><v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn-->
<!--					>-->
<!--					<v-btn class="primary-action" @click="menuOpen = false" block>{{-->
<!--						$t('global.done')-->
<!--					}}</v-btn>-->
<!--				</div>-->
<!--			</div>-->
<!--		</v-menu>-->
	</div>
</template>

<script>
	export default {
		name: 'InboxFilter',

		props: ['value'],

		components: {},

		data: function () {
			return {
				menuOpen: false,
				filter: this.value,
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			clearFilter: function () {
				this.filter = null;
				this.updated();
			},
			updated: function () {
				this.$emit('input', this.filter);
			},
		},

		computed: {
			filterLabel() {
				return 'Search';
			},

			filterDescription() {
				let l = [];
				let f = this.filter;

				if (f && f.length > 0) {
					l.push('"' + f + '"');
				}

				if (l.length) {
					return l.join(' &bull; ');
				} else {
					return null;
				}
			},

			isDefaultFilter() {
				if (this.filterDescription === null) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.active {
		background-color: var(--v-blue_5-base)!important;
		color: var(--v-blue_70-base)!important;
		border: 1px solid var(--v-blue_5-base)!important;
	}

	.scope-button {
		background-color: var(--v-white-base);
		color: var(--v-gray_80-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 12px;
		padding: 4px 8px;
		width:fit-content;
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
		white-space: nowrap;

		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}
</style>
