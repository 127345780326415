<template>
	<div class="contact-card row-format align-center py-5" @click="$emit('edit')">
		<div class="ml-3 mr-2 column-format align-center">
			<v-avatar color="black" size="40">
				<span class="font-18" style="color: var(--v-white-base)">{{ getInitials(contact) }}</span>
			</v-avatar>
		</div>

		<div class="column-format text-left ml-1 gap-2">
			<div class="column-format mr-2">
				<div class="font-16 brand-medium">{{ contact.firstName }} {{ contact.lastName }}</div>
				<div class="font-12" style="word-wrap: anywhere">{{ contact.role }}</div>
				<div class="font-12" style="word-wrap: anywhere">{{ contact.email }}</div>
				<div class="font-12" style="word-wrap: anywhere">{{ contact.phone }}</div>
				<div class="font-12" style="word-wrap: anywhere" v-for="(field,index) in customFields" :key="index">{{field.value}}</div>
			</div>
			<div class="font-12 brand-medium font-primary row-format align-center gap-1" v-if="client" @click="routeToClient">
				<client-avatar :client="client" :x-small="true" :disable-click="true"></client-avatar>
				<div>{{client.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";
	export default {
		name: 'ContactCard',

		props: ['contact'],

		components: {ClientAvatar},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getInitials: function (contact) {
				let initials = '';
				if (contact.firstName) {
					initials += contact.firstName.substr(0, 1).toUpperCase();
				}
				if (contact.lastName) {
					initials += contact.lastName.substr(0, 1).toUpperCase();
				}
				if (initials) {
					return initials;
				} else {
					return '--';
				}
			},

			routeToClient: function (event) {
				event.stopPropagation();
				this.$router.push(`/client/${this.client.id}`);
			},
		},

		computed: {
			client: function () {
				if (this.contact.clientId) {
					return this.$store.state.clientCache.find((c) => c.id === this.contact.clientId);
				} else {
					return null;
				}
			},

			customFields: function(){
				let result = [];
				this.$store.state.contactFields.fields.filter(c => c.showOnCard).forEach(f => {
						let v = this.contact.customValues.find(cv => cv.fieldId === f.id);
						if(v){
							result.push({
								name: f.name,
								value: v.value
							})
						}
				})
				return result;
			}
		},
	};
</script>

<style scoped lang="scss">
	.long-text {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.contact-card {
		height: 150px;
		max-height: 150px;
		width: 300px;
		border: 1px solid var(--v-gray_30-base);
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		margin-right: 2px;
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
