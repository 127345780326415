<template>
	<div class="column-format fill-height pointer" @click.stop="$emit('click')">
		<div class="pa-4">
			<div class="font-16 brand-medium font-gray_70">Time worked</div>
			<span class="font-24 brand-bold">{{ $formatters.formatSeconds(timeWorkedInPeriod) }}</span> this {{ retainerUnit }}
			<span v-if="maxHours"> / {{ maxHours }} max</span>
		</div>
		<div class="pa-4 mt-auto row-format align-center" style="background-color: var(--v-white-base); height: 44px">
			<div>
				<span class="brand-bold">{{ $formatters.formatSeconds(totalTimeWorked) }} total worked</span>
			</div>
		</div>
	</div>
</template>

<script>
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateTime from '@/modules/utils/HDateTime';
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';

	export default {
		name: 'ProjectTimeWorked',

		props: ['project'],

		components: {},

		data: function() {
			return {
				timeTrackService: new TimeTrackService(),
				timeWorkedDetail: [],
			};
		},

		mounted() {
			this.getTimeWorkedDetailsForProject();
		},

		beforeDestroy() {},

		methods: {
			getTimeWorkedDetailsForProject: function() {
				this.timeTrackService.getTimerEventsFull(null, null, null, null, this.project.id, null).then((res) => {
					this.timeWorkedDetail.splice(0, this.timeWorkedDetail.length);
					this.timeWorkedDetail.push(...res.data);
				});
			},
		},

		computed: {
			maxHours: function() {
				return this.project.feeSchedule.estimateMax;
			},

			retainerStart: function(){
				return this.project.feeSchedule.retainerStart;
			},

			retainerPeriod: function() {
				let ru = this.retainerUnit;
				let period;

				if(ru === 'two weeks'){
					period = 'last-two-weeks';
				}else if(ru === 'six months'){
					period = 'past-6-months';
				}else{
					period = 'this-' + this.retainerUnit;
				}

				return FilterHelpers.getEarliestAndLatest(period, false);
			},

			retainerUnit: function() {
				let unit = 'Month';

				if (this.project.feeSchedule) {
					switch (this.project.feeSchedule.retainerSchedule) {
						case 'Weekly': {
							unit = 'Week';
							break;
						}
						case 'Bi-Weekly': {
							unit = 'Two weeks';
							break;
						}
						case 'Monthly': {
							unit = 'Month';
							break;
						}
						case 'Quarterly': {
							unit = 'Quarter';
							break;
						}
						case 'Bi-Annually': {
							unit = 'Six months';
							break;
						}
						case 'Annually': {
							unit = 'Year';
							break;
						}
					}
				}

				return unit.toLowerCase();
			},

			timeWorkedInPeriod: function() {
				let period = this.retainerPeriod;
				let duration = 0;

				console.log(period);

				if (period && this.timeWorkedDetail.length) {
					for (let i = 0; i < this.timeWorkedDetail.length; i++) {
						let start = DateTime.fromISO(this.timeWorkedDetail[i].timerStart);
						if (start >= period.earliest && start <= period.latest) {
							duration += this.timeWorkedDetail[i].duration;
						}
					}
				}

				return duration;
			},

			totalTimeWorked: function() {
				let duration = 0;

				for (let i = 0; i < this.timeWorkedDetail.length; i++) {
					duration += this.timeWorkedDetail[i].duration;
				}

				return duration;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
