import HDateTime from '@/modules/utils/HDateTime';
import RecurringEventService from '@/modules/recurrence/RecurringEventService';

export default {
	data: function() {
		return {
			eventService: new RecurringEventService(),
		};
	},

	methods: {
		getRecurringInvoices: function(clientId = null) {
			return this.eventService.getRecurringInvoices(clientId);
		},

		getRecurringDeliverables: function(projectId) {
			return this.eventService.getRecurringDeliverables(projectId);
		},

		getRecurringExpenses: function() {
			return this.eventService.getRecurringExpenses();
		},

		getRecurringPayments: function() {
			return this.eventService.getRecurringPayments();
		},

		getRecurringEvent: function(id) {
			return this.eventService.getRecurringEvent(id);
		},

		saveRecurringEvent: function(event, object, eventType) {
			if (event.id) {
				return this.updateRecurringEvent(event, object, eventType);
			} else {
				return this.createRecurringEvent(event, object, eventType);
			}
		},

		createRecurringEvent: function(event, object, eventType) {
			event = this.fixupEvent(event, object, eventType);
			return this.eventService.createRecurringEvent(event);
		},

		updateRecurringEvent: function(event, object, eventType) {
			event = this.fixupEvent(event, object, eventType);
			return this.eventService.updateRecurringEvent(event.id, event);
		},

		deleteRecurringEvent: function(id) {
			return this.eventService.deleteRecurringEvent(id);
		},

		formatInterval: function(interval) {
			if(!interval){
				return '--'
			}
			if (interval.duration === 1) {
				return 'Every ' + interval.timeUnit.toLowerCase().substr(0, interval.timeUnit.length - 1);
			} else {
				return 'Every ' + interval.duration + ' ' + interval.timeUnit?.toLowerCase();
			}
		},

		fixupEvent(event, object, eventType) {
			event.eventType = eventType;

			if (eventType === 'INVOICE') {
				event.invoice = object;
			} else if (eventType === 'DELIVERABLE') {
				event.deliverable = object;
			} else if (eventType === 'EXPENSE') {
				event.expense = object;
			} else if (eventType === 'INCOME') {
				event.income = object;
			}

			return event;
		},

		initRecurring: function(type) {
			const startDate = HDateTime.now();

			return {
				eventType: type,
				name: null,
				enabled: true,
				status: 'READY',
				startDate: startDate.toISODate(),
				endDate: null,
				interval: { duration: 1, timeUnit: 'MONTHS' },
				recurrenceType: 'MONTHLY',
				monthlyWeekOfMonth: Math.ceil(startDate.day / 7),
				monthlyDayOfWeek: startDate.weekday,
				weeklyDays: [],
			};
		},

		getLabel: function(event) {
			let days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

			if (event.recurrenceType === 'DAILY') {
				return 'Daily';
			} else if (event.recurrenceType === 'WEEKLY') {
                return 'Weekly on ' + event.weeklyDays.map(index => days[index-1].substring(0,3)).join(", ");
			} else if (event.recurrenceType === 'MONTHLY') {
				let weekText = ['first', 'second', 'third', 'fourth', 'last'];
				return "Monthly on the " + weekText[event.monthlyWeekOfMonth - 1] + ' ' + days[event.monthlyDayOfWeek-1].substring(0,3);
			} else if (event.recurrenceType === 'ANNUALLY') {
				return 'Annually';
			} else {
                return this.formatInterval(event.interval);
			}
		},
	},
};
