<template>
	<v-dialog
		:value="dialog"
		transition="fade"
		:persistent="persistent"
		scrollable
		@click:outside="!persistent ? handleClose() : ''"
		@keydown.esc="handleClose()"
		max-width="320"
	>
		<div class="confirm-dialog-div">
			<v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
				<v-icon size="20">$close</v-icon>
			</v-btn>
			<div class="modal-padding">
				<div class="modal-title text-center mb-2 mt-4" v-if="headingText">
					<span class="pr-1">
						<v-icon :color="severity" class="mt-n1" size="24">{{ icon }}</v-icon>
					</span>
					{{ headingText }}
				</div>
				<div v-else class="mt-6"></div>
				<div class="modal-subtitle text-center" v-if="bodyText" v-html="bodyText"></div>
			</div>
			<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
				<div class="modal-footer">
					<v-btn v-if="!hideNo" min-width="100" class="super-action mr-1" @click.prevent="handleClose()">{{
						noText
					}}</v-btn>
					<v-btn
						type="submit"
						min-width="100"
						elevation="0"
						class="primary-action ml-1"
						@click.prevent="handleSubmit()"
					>
						{{ yesText }}
					</v-btn>
				</div>
			</v-form>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		name: 'ConfirmDialog',

		props: {
			severity: { type: String, required: false, default: 'warning' }, // info, warning, error, success
			icon: { type: String, required: false, default: '$alert' }, // $alert, $info, etc.
			headingText: String,
			bodyText: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false, default: 'Yes' },
			noText: { type: String, required: false, default: 'No' },
			dialog: Boolean,
			persistent: { type: Boolean, default: false },
		},

		data() {
			return {};
		},

		mounted() {},

		methods: {
			handleSubmit() {
				this.$emit('confirm');
			},
			handleClose() {
				this.$emit('close');
			},
		},
	};
</script>

<style lang="scss">
	.confirm-dialog-div {
		background-color: var(--v-white-base) !important;
		color: var(--v-black-base) !important;
		position: relative;
		border-radius: 4px !important;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
