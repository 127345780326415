<template>
	<div class="column-format fill-height" v-if="initialized">

		<div class="row-format mt-2 mb-2 px-3" v-if="invoices.length">
			<div class="kpi-box mr-3">
				<div class="fit text-left">Total invoiced <span class="font-gray_70">({{filteredInvoices.length}} invoices)</span></div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(totalInvoiced,true,true,currency) }}</div>
			</div>
			<div class="kpi-box mr-3">
				<div class="fit text-left">Total due</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(totalDue,true,true,currency) }}</div>
			</div>
			<div class="kpi-box mr-3">
				<div class="fit text-left">Avg days to pay</div>
				<div class="font-24 brand-medium fit mt-1">{{ avgDaysToPay }}</div>
			</div>
		</div>

		<div class="mx-3 fill-height">
			<v-tabs v-model="invoiceTab">
				<v-tab key="0">Invoice list</v-tab>
				<v-tab key="1">Recurring invoices</v-tab>
				<v-tab key="2">Payment plans</v-tab>
			</v-tabs>

			<div v-show="invoiceTab === 1">
				<recurring-invoice-list :client="client" ref="RecurringInvoice"></recurring-invoice-list>
			</div>

			<div v-if="invoiceTab === 2">
				<payment-plan-list :filter="filter" ref="PaymentPlan" :client="client"></payment-plan-list>
			</div>

			<div v-show="invoiceTab === 0" class="fill-height">
				<div class="row-format centered fill-height" style="flex: 1" v-if="!invoices.length">
					<empty-view
							header="No invoices yet"
							:body="
					`You haven’t sent any invoices to ${client.name} yet. Send a manual invoice or set up automatic invoices -- Moxie follows your schedule and adds your work to the invoice, and you just approve it before sending.`
				"
							cta="Send an invoice"
							video-header="See how it works"
							video-body="Learn how to add the work you’ve done into an invoice. You can also see how to set up automatic invoices."
							video-cta="Watch the tutorial"
							video-id="VHlGLkW1suI"
							@cta-clicked="addNew()"
					></empty-view>
				</div>

				<div v-if="invoices.length && !filteredInvoices.length" class="mt-6">
					<empty-filtered-results></empty-filtered-results>
				</div>

				<div v-else-if="filteredInvoices.length">
					<v-data-table
							:headers="headers"
							:items="filteredInvoices"
							:hide-default-footer="true"
							sort-by="dateCreated"
							:sort-desc="true"
							height="calc(var(--vh) - 270px)"
							:fixed-header="true"
							@click:row="openInvoice($event.id)"
							style="cursor: pointer;"
							:items-per-page="-1"
					>
						<template v-slot:item.dateCreated="{ item }">
							<span v-if="item.dateCreated" class="brand-semilight">{{ DateTime.fromISO(item.dateCreated).toFormat('DD') }}</span>
						</template>

						<template v-slot:item.amountDue="{ item }">
							<div v-if="item.total" class="brand-semilight">{{
									$formatters.dollars(item.amountDue ? item.amountDue : 0, true, true, item.currency)
								}}</div>
						</template>

						<template v-slot:item.dateSent="{ item }">
							<span v-if="item.dateSent" class="brand-semilight">{{ DateTime.fromISO(item.dateSent).toFormat('DD') }}</span>
						</template>

						<template v-slot:item.dateDue="{ item }">
							<span v-if="item.dateDue" class="brand-semilight">{{ DateTime.fromISO(item.dateDue).toFormat('DD') }}</span>
						</template>

						<template v-slot:item.invoiceNumberFormatted="{ item }">
							<span class="brand-semilight">{{ item.invoiceNumberFormatted }}</span>
						</template>

						<template v-slot:item.total="{ item }">
							<div class="brand-semilight">{{
									$formatters.dollars(item.total ? item.total : 0, true,true, item.currency)
								}}</div>
							<div class="font-12 font-gray_70 " v-if="item.localTotal && item.currency !== currency">
								{{$formatters.dollars(item.localTotal ? item.localTotal : 0, true, true, currency)}}
							</div>
						</template>

						<template v-slot:item.status="{ item }">
							<div class="row-format align-center">
								<div class="brand-semilight font-12" v-html="getStatus(item)"></div>
								<v-icon class="ml-2" size="20" v-if="item.integrationKeys.quickbooksId">$quickbooks</v-icon>
								<v-icon class="ml-2" size="20" v-if="item.integrationKeys.xeroId">$xero</v-icon>
							</div>
						</template>

						<template v-slot:header.action="{}">
							<div class="row-format">
								<div class="ml-auto" style="width:70px;" v-if="currencies.length > 1">
									<v-select v-if="currencies.length > 1" style="margin-top:0px; margin-bottom: 0px" :items="currencies" v-model="currency" dense hide-details></v-select>
								</div>
							</div>
						</template>

						<template v-slot:item.action="{ item }">
							<action-menu
									:invoice="item"
									:send-enable="true"
									:show-view="true"
									:is-list="true"
									class="action-menu"
									@delete-invoice="removeInvoice($event)"
									@set-invoice="addOrUpdateInvoice($event)"
									@duplicate-invoice="invoiceCreated($event)"
									@view-detail="openInvoice($event.id)"
							></action-menu>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import DateTime from "@/modules/utils/HDateTime";
	import ActionMenu from '@/modules/invoices/ActionMenu';
	import CreateInvoiceModal from '@/modules/invoices/CreateInvoiceModal';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyView from '@/components/EmptyView';
	import EmptyFilteredResults from '@/components/EmptyFilteredResults';
	import MetricService from "@/modules/reports/metrics/MetricService";
	import RecurringInvoiceList from "@/modules/clients/detail/invoices/RecurringInvoiceList";
	import PaymentPlanList from "@/modules/clients/detail/invoices/PaymentPlanList";

	export default {
		name: 'InvoiceList',
		components: {RecurringInvoiceList, ActionMenu, EmptyView, EmptyFilteredResults, PaymentPlanList },
		props: ['client', 'isActive', 'filter','currencies'],

		data() {
			return {
				invoiceTab: 0,
				invoiceService: new InvoiceService(),
				metricService: new MetricService(),
				invoices: [],
				initialized: false,
				DateTime: DateTime,
				invoiceInitKey: null,
				createInvoiceModal: false,
				invoiceDetailModal: false,
				invoiceId: null,
				currency: this.client.currency ? this.client.currency : this.$store.state.defaultCurrency
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('onboarding_create_invoice',this.handleOnBoardingRequest);

			if (this.isActive) {
				this.getInvoicesForClient();
			}
			if(this.$route.query && this.$route.query.t){
				this.invoiceTab = Number(this.$route.query['t']);
				this.$router.replace(this.$route.path + '?v=Invoices');
			}
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('onboarding_create_invoice',this.handleOnBoardingRequest);
		},

		methods: {
			handleOnBoardingRequest: function(){
				this.addNew();
			},

			getInvoicesForClient: function() {
				this.invoiceService.getInvoices(this.client.id, null, null, this.currency).then((res) => {
					this.invoices.splice(0, this.invoices.length);
					this.invoices.push(...res.data);
					this.initialized = true;
				}).finally(() => this.$store.commit('stopLoading'));
			},

			invoiceCreated: function(invoice) {
				this.invoices.push(invoice);
				this.openInvoice(invoice.id);
				this.createInvoiceModal = false;
			},

			openInvoice: function(id, quick = false) {
				let binding = {
					clientId: this.client.id,
					id: id,
					quick: quick,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, false, true).then((res) => {
					if (!res) return;
					let ix = this.invoices.findIndex((i) => i.id === res.id);
					if (res.action && res.action === 'DELETED') {
						if(ix > -1) {
							this.invoices.splice(ix, 1);
						}
					} else if (res.action && res.action === 'DUPLICATED') {
						this.invoices.push(res.invoice);
						return this.openInvoice(res.invoice.id);
					} else if (ix > -1) {
						this.invoices.splice(ix, 1, res);
					}else{
						this.invoices.push(res);
					}
					this.$store.state.eventBus.$emit('timer-list-reload');
				});
			},

			addNew: function() {
				let binding = {
					selectedClient: this.client,
				};

				this.$store.state.globalModalController.openModal(CreateInvoiceModal, binding).then((res) => {
					if (res) {
						if(res.createRecurring) {
							this.invoiceTab = 1;
							this.$refs.RecurringInvoice.createRecurringInvoice(this.client.id);
						}else if(res.createPaymentPlan){
							this.invoiceTab = 2;
							this.$refs.PaymentPlan.createPaymentPlan(this.client.id);
						}else {
							this.invoiceTab = 0;
							this.openInvoice(res.id);
						}
					}
				});
			},


			addNewQuick: function(type = 'STANDARD') {
				let post = { invoiceType: type };

				return this.invoiceService.createInvoice(this.client.id, post).then((res) => {
					let invoice = res.data;
					this.openInvoice(invoice.id, true);
				});
			},

			removeInvoice: function(invoiceId) {
				let ix = this.invoices.findIndex((i) => i.id === invoiceId);
				if (ix > -1) {
					this.invoices.splice(ix, 1);
					this.$store.state.eventBus.$emit('timer-list-reload');
				}
			},

			addOrUpdateInvoice: function(invoice) {
				let ix = this.invoices.findIndex((i) => i.id === invoice.id);
				if (ix > -1) {
					this.invoices.splice(ix, 1, invoice);
				} else {
					this.invoices.push(invoice);
				}
			},

			formatDate(date) {
				return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
			},

			getStatus(invoice) {
				let background, color, label;

				switch (invoice.status) {
					case 'PAID': {
						background = '--v-success_10-base';
						color = '--v-gray_80-base';
						label = 'Paid';
						break;
					}

					case 'PARTIAL':
					case 'SENT': {
						if (DateTime.fromISO(invoice.dateDue).endOf('day') < DateTime.now()) {
							background = '--v-alert_10-base';
							color = '--v-gray_80-base';
							label = 'Late';
						} else if (invoice.status === 'PARTIAL') {
							background = '--v-warning_10-base';
							color = '--v-gray_80-base';
							label = invoice.status === 'PARTIAL' ? 'Partially paid' : 'Pending';
						} else {
							background = '--v-blue_5-base';
							color = '--v-gray_80-base';
							label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
						}

						break;
					}

					default: {
						background = '--v-gray_20-base';
						color = '--v-gray_80-base';
						label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
						break;
					}
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},

			handleRowClick: function(invoice) {
				console.log('open invoice in modal' + invoice);
			},
		},

		computed: {
			totalInvoiced: function(){
				let ignore = ['DRAFT','VOIDED'];
				return this.filteredInvoices
						.filter(i => !ignore.includes(i.status))
						.reduce((sum, invoice) => {
							if(invoice.currency === this.currency){
								return sum + invoice.total;
							}else {
								return sum + invoice.localTotal;
							}
						}, 0);
			},

			totalDue: function(){
				let payable = ['SENT','PARTIAL','PENDING'];
				return this.filteredInvoices
						.filter(i => payable.includes(i.status))
						.reduce((sum, invoice ) => {
							if(invoice.currency === this.currency){
								return sum + invoice.amountDue;
							}else {
								return sum + invoice.localAmountDue;
							}
						}, 0);
			},

			avgDaysToPay: function(){
				let totalDaysToPay = 0;
				let count = 0;
				this.filteredInvoices.forEach(i => {
					if(i.dateSent && i.datePaid) {
						count++;
						let dateSent = DateTime.fromISO(i.dateSent);
						let datePaid = DateTime.fromISO(i.datePaid);
						let diff = datePaid.diff(dateSent, ["days"]).days;
						totalDaysToPay = totalDaysToPay + diff;
					}
				})
				let result =  Math.ceil(totalDaysToPay / count);
				if(isNaN(result)){
					return 0;
				}else{
					return result;
				}
			},

			filteredInvoices: function() {
				let result = [...this.invoices];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let earliest, latest;
				let lateStatus = ['SENT', 'PARTIAL'];

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
						earliest = DateTime.fromISO(this.filter.earliest);
						latest = DateTime.fromISO(this.filter.latest);
					} else if (this.filter.dateSpecifier !== 'between') {
						let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
						earliest = helper.earliest;
						latest = helper.latest;
					}
				}

				return result
					.filter((i) => {
						if (!search || (search && i.invoiceNumber.toString().startsWith(search))) {
							return true;
						} else if (search) {
							return false;
						}
					})
					.filter((i) => {
						if (earliest && latest) {
							if (!i.dateSent && !i.dateDue && !i.dateCreated) {
								return false;
							} else {
								let dateSent = DateTime.fromISO(i.dateSent);
								let dateDue = DateTime.fromISO(i.dateDue);
								let dateCreated = DateTime.fromISO(i.dateCreated);
								if (dateSent >= earliest && dateSent < latest) {
									return true;
								}else if(dateDue >= earliest && dateDue < latest) {
									return true;
								}else if(!i.dateSent && !i.dateDue && (dateCreated >= earliest && dateCreated < latest)){
									return true;
								} else {
									return false;
								}
							}
						} else {
							return true;
						}
					})
					.filter((i) => {
						if (this.filter.invoiceStatus.length) {
							let lateSearch = this.filter.invoiceStatus.includes('LATE');
							if (this.filter.invoiceStatus.includes(i.status)) {
								return true;
							} else if (lateSearch && lateStatus.includes(i.status)) {
								let dateDue = DateTime.fromISO(i.dateDue);
								if (dateDue < DateTime.now()) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			headers: function() {
				let result = [
					{ text: this.$t('invoice.amount'), value: 'total' },
					{ text: this.$t('invoice.amount-due'), value: 'amountDue' },
					{ text: this.$t('invoice.invoice-number'), value: 'invoiceNumberFormatted' },
					{ text: this.$t('invoice.date-created'), value: 'dateCreated' },
					{ text: this.$t('invoice.date-sent'), value: 'dateSent' },
					{ text: this.$t('invoice.date-due'), value: 'dateDue' },
					{ text: this.$t('invoice.status'), value: 'status' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				return result;
			},
		},

		watch: {
			currency: function(){
				this.$store.commit('startLoading')
				this.getInvoicesForClient();
			},

			invoiceTab: function(newVal){
				if(newVal === 0){
					this.getInvoicesForClient();
				}
			},

			isActive: function(newVal) {
				if (newVal) {
					this.getInvoicesForClient();
				}
			},
		},
	};
</script>

<style lang="scss">
	.invoice-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}
</style>

<style scoped lang="scss">
	.action-menu ::v-deep {
		.primary-action {
			border: 0px !important;
		}
		.menu-activator {
			border: 0px !important;
		}
	}
</style>
