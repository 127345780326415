<template>
	<div id="products-widget">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding pb-4">
			<div class="modal-title" style="">
				<v-text-field autofocus hide-details dense solo flat placeholder="Filter products & services..." style="max-width: 300px" v-model="filter"></v-text-field>
			</div>
			<products-table ref="ProductTable" :currency="currency" @set-products="$emit('set-products',$event)" @close="$emit('close')" @select="$emit('select',$event)" :filter="filter"></products-table>
			<div class="text-left" style="height: 32px;line-height:32px;">
				<v-btn class="primary-action ml-n2" text style="width: 140px" @click="$refs.ProductTable.createNewProduct()">
					<v-icon size="14">$plus</v-icon>
					<span class="ml-1">{{ $t('global.create-new') }}</span>
				</v-btn>
			</div>
		</div>
		<div class="modal-footer text-center">
			<v-btn class="primary-action" style="width: 140px" @click="$emit('close')">
				{{ $t('global.close') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import ProductsTable from "@/modules/invoices/InvoiceRenderer/ProductsTable";

	export default {
		name: 'Products',
		components: {ProductsTable },
		props: ['currency'],

		data() {
			return {
				filter: null
			};
		},

		mounted() {
		},

		methods: {},

		computed: {},

		watch: {},
	};
</script>

<style lang="scss">
	#products-widget {
		font-family: Inter, sans-serif!important;
		min-width: 420px;
		background-color: var(--background);
		.v-data-table .v-data-table__wrapper {
			table tbody tr td {
				padding-top: 12px !important;
				padding-bottom: 4px !important;
			}
		}
		.edit-icon, .delete-icon {
			//--icon-color: red;
			//svg {
			//	color: var(--v-gray_80-base);
			//}
		}
	}
	#edit-product {
		width: 420px;
		div.h-outline {
			height: 52px;
			margin-top: 4px;
			margin-bottom: 8px;
			width: 100%;
			align-items: center;
			display: flex;
		}
		div.half {
			gap: 12px;
			> div {
				flex: 0 1 auto;
			}
		}
	}
	div.product-name {
		max-width: 260px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
	}
</style>
