<template>
	<div v-if="expenseCopy" id="expense-detail">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h3 class="modal-title">{{ expenseHeading }} - {{ $formatters.dollars(total, true, true, expenseCopy.currency) }}</h3>

			<v-container fluid class="px-0 mt-2 mb-0">
				<v-row>
					<v-col cols="12" sm="7" class="pr-sm-2 pt-0" >
						<div class="column-format">
							<div class="row-format gap-2">
								<amount-input
									class="h-outline"
									hide-details
									:label="$t('expenses.amount-label')"
									:placeholder="$t('expenses.amount-placeholder')"
									:prefix="currencySymbol"
									type="text"
									v-model="expenseCopy.amount"
									:disabled="invoicedDisabled || isPlaidExpense || isLockedExpense"
									persistent-placeholder
								></amount-input>
								<v-text-field
									class="h-outline"
									hide-details
									label="Tax rate"
									placeholder="Tax %"
									suffix="%"
									type="number"
									v-model="expenseCopy.taxRate"
									:disabled="invoicedDisabled"
									persistent-placeholder
								></v-text-field>
							</div>
							<div class="row-format gap-2">
								<div style="width:47%">
									<v-autocomplete
										hide-details
										persistent-placeholder
										class="h-outline"
										:label="$t('account.currency')"
										:items="currencies"
										item-text="value"
										item-value="value"
										:disabled="invoicedDisabled || isPlaidExpense || isLockedExpense"
										v-model="expenseCopy.currency"
									>
										<template v-slot:item="{ item }">
											{{ item.label }}
										</template>
									</v-autocomplete>
								</div>
								<v-checkbox
									:disabled="expenseCopy.taxRate === 0"
									hide-details
									dense
									v-model="expenseCopy.taxInclusive"
									label="Tax inclusive"
								></v-checkbox>
							</div>
						</div>

						<div v-if="expenseCopy.currency !== $store.state.defaultCurrency">
							<v-text-field class="h-outline" persistent-placeholder hide-details label="Exchange rate (optional)" v-model="expenseCopy.exchangeRate" type="number" step="0.00001">
								<template v-slot:append>
									<span class="font-12 font-gray_70 text-left pr-2">({{$formatters.dollars(expenseCopy.localAmount)}} {{$store.state.defaultCurrency}})</span>
								</template>
							</v-text-field>
						</div>

						<v-textarea
							class="h-outline"
              focus
							hide-details
							:label="$t('expenses.description-label')"
							:placeholder="$t('expenses.description-placeholder')"
							v-model="expenseCopy.description"
							:auto-grow="true"
							rows="1"
							persistent-placeholder
						></v-textarea>

						<v-autocomplete
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="$t('expenses.category-label')"
							:placeholder="$t('expenses.category-placeholder')"
							:items="expenseCategoriesPlusClear"
							item-text="category"
							item-label="category"
							v-model="expenseCopy.category"
							@change="categoryChanged"
						>
							<template v-slot:item="{ item }">
								<div class="row-format" style="width:100%">
									<div>{{ item.category }}</div>
									<div v-if="item.custom" class="ml-auto pointer" @click.stop="confirmCategoryDelete(item)">
										x
									</div>
								</div>
							</template>
						</v-autocomplete>

						<v-autocomplete
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="$t('expenses.vendor-label')"
							:placeholder="$t('expenses.vendor-placeholder')"
							v-model="expenseCopy.vendorId"
							:items="vendorsPlusClear"
							item-text="name"
							item-value="id"
							@change="vendorChanged"
						></v-autocomplete>

						<v-select
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="$t('expenses.paid-question')"
							:placeholder="$t('expenses.paid-question')"
							:items="paidOptions"
							:disabled="isPlaidExpense || isLockedExpense"
							v-model="expenseCopy.paid"
							@value="togglePaid($event)"
						></v-select>

						<date-selector
							v-if="expenseCopy.paid"
							clearable
							hide-details
							persistent-placeholder
							:label="$t('expenses.date')"
							:disabled="isPlaidExpense || isLockedExpense"
							:date="expenseCopy.paidDate"
							@change="expenseCopy.paidDate = $event"
						></date-selector>

						<date-selector
							v-else
							class="h-outline"
							clearable
							hide-details
							persistent-placeholder
							:label="$t('expenses.due-date')"
							:disabled="isPlaidExpense || isLockedExpense"
							:date="expenseCopy.dueDate"
							@change="expenseCopy.dueDate = $event"
						></date-selector>
            <v-textarea
                class="h-outline mt-2"
                hide-details
                :label="$t('expenses.notes-label')"
                v-model="expenseCopy.notes"
                :auto-grow="true"
                rows="3"
                persistent-placeholder
            ></v-textarea>

						<v-row v-if="expenseCopy.plaidAccountName">
							<v-col cols="4" align="left" class="mt-3 grey--text">{{ $t('expenses.source-account') }}</v-col>
							<v-col cols="8" align="left" class="mt-3 grey--text">
								{{ expenseCopy.plaidAccountName }}
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="5" class="pl-sm-2 pt-0">
						<v-autocomplete
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="$t('client.client')"
							:placeholder="$t('expenses.link-to-client')"
							:disabled="client != null || invoicedDisabled"
							v-model="expenseCopy.clientId"
							:items="clientsPlusClear"
							item-text="name"
							item-value="id"
							@change="clientChanged"
							ref="clientId"
						></v-autocomplete>

						<div v-if="expenseCopy.clientId">
							<v-autocomplete
								class="h-outline"
								hide-details
								persistent-placeholder
								:label="$t('projects.project')"
								:placeholder="$t('expenses.link-to-project')"
								:disabled="noClientDisabled || invoicedDisabled"
								v-model="expenseCopy.projectId"
								:items="clientProjectsPlusClear"
								item-text="name"
								item-value="id"
								@change="projectChanged"
								no-data-text="No projects for selected client"
							></v-autocomplete>

							<div class="h-outline d-flex justify-start align-center font-14 px-2 py-1 mb-2">
								<v-simple-checkbox
									v-model="expenseCopy.reimbursable"
									color="var(--v-primary-base)"
								></v-simple-checkbox>
								<label class="font-gray_90">{{ $t('expenses.reimburse-status') }}</label>
							</div>

							<div v-if="expenseCopy.reimbursable">
								<amount-input
									class="h-outline"
									hide-details
									label="Markup percentage to client"
									suffix="%"
									type="number"
									v-model="expenseCopy.markupPercent"
									:disabled="invoicedDisabled || isLockedExpense"
									persistent-placeholder
								></amount-input>
								<div class="body-12 mt-2 mb-3 gray_80--text text-left" v-if="expenseCopy.markupPercent">
									+{{ $formatters.dollars(markup, true, true, expenseCopy.currency) }} markup =
									{{ $formatters.dollars(totalWithMarkup, true, true, expenseCopy.currency) }}
								</div>
							</div>

							<div
								v-if="expenseCopy.id && expenseCopy.reimbursable"
								class="body-12 mt-2 mb-3 gray_80--text text-left"
							>
								<div v-if="expenseCopy.invoiceId && expenseCopy.invoiceNumber">
									{{ $t('expenses.invoiced-label', { invoiceNumber: expenseCopy.invoiceNumber }) }}
								</div>
								<div v-else>
									{{ $t('expenses.not-yet-invoiced') }}
								</div>
							</div>
						</div>

						<div v-if="expenseCopy.id !== null" style="border-top: 1px solid var(--v-gray_30-base)" class="mt-3 pt-3">
							<div class="text-left gray_80--text">Attachments</div>
							<file-attachments
								:files="expenseCopy.attachments"
								:file-service="attachmentService"
							></file-attachments>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div style="border-top: 1px solid var(--v-gray_50-base)" class="mt-2 pa-2">
			<v-btn icon class="delete mr-2" @click="deleteExpenseDialog = true" v-if="expenseCopy.id && !expenseCopy.invoiceId">
				<v-icon>$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action" :width="$vuetify.breakpoint.smAndUp ? '160' : '128'" @click="saveExpense">
				{{ $t(expenseCopy.id === null ? 'global.create' : 'global.save') }}
			</v-btn>
		</div>

		<modal :dialog="deleteExpenseDialog" :persistent="true" :max-width="290" @close="deleteExpenseDialog = false">
			<div class="pt-8">
				<v-container>
					<v-row>
						<v-col cols="12">
							{{ $t('expenses.delete-confirm') }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="mt-3">
							<v-btn class="primary-action mr-2" @click="deleteExpenseDialog = false">
								{{ $t('global.no') }}
							</v-btn>
							<v-btn class="ml-2" elevation="0" @click="deleteExpense">
								{{ $t('global.yes') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</modal>
	</div>
</template>

<script>
	import modal from '@bit/hecticapp.common.modal';
	import ExpenseService from '@/modules/accounting/expenses/ExpenseService';
	import DateTime from '@/modules/utils/HDateTime';
	import DateSelector from '@/components/DateSelector';
	import expenseCategories from '../../../assets/data/expense-categories.json';
	import ExpenseAttachmentService from '@/modules/accounting/expenses/ExpenseAttachmentService';
	import FileAttachments from '@/components/FileAttachments';
	import { cloneDeep } from 'lodash';
	import SimpleTextInput from '@/components/SimpleTextInput';
	import currencies from '@/assets/data/currencies.json';
	import ExpenseMixin from '@/modules/accounting/expenses/ExpenseMixin';
	import VendorDetail from '@/modules/accounting/vendors/VendorDetail';
	import ConfirmModal from '@/components/ConfirmModal';
	import AmountInput from '@/components/AmountInput.vue';

	export default {
		name: 'ExpenseDetail',

		props: ['expense', 'inClients', 'inProjects', 'inVendors', 'client'],

		mixins: [ExpenseMixin],

		components: { AmountInput, FileAttachments, DateSelector, modal },

		data: function() {
      return {
        deleteExpenseDialog: false,
        expenseCategories: expenseCategories,
        customExpenseCategories: null,
        expenseService: new ExpenseService(),
        expenseCopy: this.initialize(),
        currencies: currencies,
        attachmentService: new ExpenseAttachmentService(this.expense)
      };
		},

		mounted() {
			this.initRelatedData();
			this.getCustomExpenseCategories();
			if (!this.expenseCopy.id) {
				this.createExpense();
			}else{
				this.setExchangeRate();
			}
		},

		beforeDestroy() {},

		methods: {
			setExchangeRate: function(){
				if(!this.expenseCopy.exchangeRate && this.expenseCopy.localAmount && this.expenseCopy.amount){
					this.expenseCopy.exchangeRate = Number(this.expenseCopy.localAmount / this.expenseCopy.amount).toFixed(6);
				}
			},

			initRelatedData: function() {
				if (this.inClients) {
					this.clients.push(...this.inClients);
				} else {
					this.getClients();
				}
				if (this.inProjects) {
					this.projects.push(...this.inProjects);
				} else {
					this.getProjects();
				}
				if (this.inVendors) {
					this.vendors.push(...this.inVendors);
				} else {
					this.getVendors();
				}
			},

			getCustomExpenseCategories: function() {
				this.expenseService.getCategories().then((res) => {
					this.customExpenseCategories = res.data;
				});
			},

			initialize: function() {
				let result;
				if (this.expense) {
					result = JSON.parse(JSON.stringify(this.expense));
				} else {
					result = {
						id: null,
						vendorId: null,
						clientId: this.client ? this.client.id : null,
						projectId: null,
						amount: 0,
						currency: this.$store.state.defaultCurrency,
						billNo: null,
						category: null,
						paidDate: DateTime.now().toISODate(),
						dueDate: null,
						paid: true,
						description: null,
						attachments: [],
						invoiceId: null,
						invoiceNumber: null,

						vendor: null,
						client: null,
						project: null,
						reimbursable: false,
						markupPercent: 0,
					};
				}

				if (result.currency) {
					result.currency = result.currency.toUpperCase();
				}

				return result;
			},

			clientChanged: function(value) {
				if (this.expenseCopy.clientId !== value) {
					this.expenseCopy.projectId = null;
				}
				if (value === 'clear') {
					this.expenseCopy.clientId = null;
					this.expenseCopy.projectId = null;
				}
			},

			projectChanged: function(value) {
				if (value === 'clear') {
					this.expenseCopy.projectId = null;
				}
			},

			categoryChanged: function(value) {
				if (!value) {
					this.expenseCopy.category = null;
				} else if (value.trim() === '--Clear--') {
					this.expenseCopy.category = null;
				} else if (value.trim() === '+ Add new') {
					let binding = {
						label: 'Add new category',
						bodyText: null,
					};
					this.$store.state.globalModalController.openModal(SimpleTextInput, binding, false, false).then((res) => {
						if (res) {
							this.customExpenseCategories.categories.push(res);
							this.updateCustomCategories();
							this.expenseCopy.category = res;
						} else {
							this.expenseCopy.category = null;
						}
					});
				}
			},

			confirmCategoryDelete: function(item) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to remove this custom category?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.customExpenseCategories.categories.findIndex((c) => c === item.category);
						if (ix > -1) {
							this.customExpenseCategories.categories.splice(ix, 1);
							this.updateCustomCategories();
						}
					}
				});
			},

			vendorChanged: function(value) {
				if (value === 'clear') {
					this.expenseCopy.vendorId = null;
				} else if (value.trim() === '+ Add new') {
					let binding = {
						vendor: {
							contact: {},
						},
						vendors: this.vendors,
					};
					this.$store.state.globalModalController.openModal(VendorDetail, binding, false, false).then((res) => {
						if (res) {
							this.vendors.push(res);
							this.expenseCopy.vendorId = res.id;
							this.$emit('new-vendor', res);
						}
					});
				}
			},

			togglePaid: function(value) {
				this.expenseCopy.paid = value;
				if (value && this.expenseCopy.paidDate === null) {
					this.expenseCopy.paidDate = DateTime.now().toISODate();
				} else if (!value) {
					this.expenseCopy.paidDate = null;
				}
			},

			saveExpense: function() {
				if (!this.expenseCopy.markupPercent) {
					this.expenseCopy.markupPercent = 0;
				} else {
					this.expenseCopy.markupPercent = parseFloat(this.expenseCopy.markupPercent);
				}

				if (this.expenseCopy.id === null) {
					this.createExpense();
				} else {
					this.updateExpense();
				}
			},

			updateCustomCategories: function() {
				this.expenseService.updateCategories(this.customExpenseCategories).then((res) => {
					this.customExpenseCategories = res.data;
				});
			},

			createExpense: function() {
				this.expenseService
					.createExpense(this.expenseCopy)
					.then((res) => {
						this.expenseCopy = res.data;
						this.attachmentService.setExpense(this.expenseCopy);
						this.$emit('created', res.data);
						this.setExchangeRate();
						this.$store.commit('success','Expense created');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			updateExpense: function() {
				this.expenseService
					.updateExpense(this.expenseCopy.id, this.expenseCopy)
					.then((res) => {
						this.expenseCopy = res.data;
						this.$emit('updated', res.data);
						this.setExchangeRate();
						this.$store.commit('success','Expense update');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			deleteExpense: function() {
				if (this.expenseCopy.id === null) {
					this.$emit('close');
					this.$emit('result');
				} else {
					this.expenseService
						.deleteExpense(this.expenseCopy.id)
						.then(() => {
							this.$emit('deleted', this.expenseCopy.id);
							this.$emit('close');
							this.$emit('result', { action: 'DELETED' });
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			handleClose: function() {
				this.$emit('close');
				this.$emit('result',this.expenseCopy);
			},
		},

		watch: {
			'expenseCopy.currency': function(){
				this.expenseCopy.exchangeRate = null;
			}
		},

		computed: {
			total: function() {
				if (this.expenseCopy.taxInclusive) {
					return parseFloat(this.expenseCopy.amount);
				} else {
					return (
						parseFloat(this.expenseCopy.amount) +
						parseFloat(this.expenseCopy.amount) * (parseFloat(this.expenseCopy.taxRate) / 100)
					);
				}
			},

			markup: function() {
				if (this.expenseCopy.markupPercent) {
					return (this.total * parseFloat(this.expenseCopy.markupPercent)) / 100;
				} else {
					return 0;
				}
			},

			totalWithMarkup() {
				return this.total + this.markup;
			},

			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.expenseCopy.currency);
			},

			clientsPlusClear: function() {
				let tmp = cloneDeep(this.clients);
				tmp.sort((a, b) => a.name.localeCompare(b.name));
				if (this.expenseCopy.clientId) {
					tmp.unshift({ name: '--Clear--', id: 'clear' });
				}
				return tmp;
			},

			isPlaidExpense: function() {
				return !!this.expenseCopy.plaidTransactionId;
			},

			isLockedExpense: function() {
				return this.expenseCopy.locked;
			},

			clientProjectsPlusClear: function() {
				if (this.expenseCopy.clientId && this.projects) {
					let tmp = this.projects.filter((p) => p.clientId === this.expenseCopy.clientId);
					tmp.sort((a, b) => a.name.localeCompare(b.name));
					if (this.expenseCopy.projectId) {
						tmp.unshift({ name: '--Clear--', id: 'clear' });
					}
					return tmp;
				} else {
					return [];
				}
			},

			expenseCategoriesPlusClear: function() {
				let tmp = [];
				expenseCategories.forEach((c) => {
					tmp.push({
						category: c,
						custom: false,
					});
				});

				if (this.customExpenseCategories) {
					this.customExpenseCategories.categories.forEach((c) => {
						tmp.push({
							category: c,
							custom: true,
						});
					});
				}

				if (this.expenseCopy && this.expenseCopy.category) {
					if (!tmp.find((c) => c.category === this.expenseCopy.category)) {
						tmp.push({
							category: this.expenseCopy.category,
							custom: false,
						});
					}
				}

				tmp.sort((a, b) => a.category.localeCompare(b.category));

				if (this.expenseCopy && this.expenseCopy.category) {
					tmp.unshift({ category: '--Clear--', custom: false });
				}

				tmp.push({ category: '+ Add new', custom: false });

				return tmp;
			},

			vendorsPlusClear: function() {
				let tmp = cloneDeep(this.vendors);
				if (this.expenseCopy.vendorId) {
					tmp.unshift({ name: '--Clear--', id: 'clear' });
				}
				tmp.push('+ Add new');
				return tmp;
			},

			expenseHeading: function() {
				if (!this.expenseCopy || this.expenseCopy.id === null) {
					return this.$t('expenses.create-expense');
				} else {
					return this.$t('expenses.update-expense');
				}
			},

			// divMaxHeight: function() {
			// 	return this.$vuetify.breakpoint.height - 300;
			// },

			paidOptions() {
				let item = [];
				item.push({ text: this.$t('expenses.paid-question-yes'), value: true });
				item.push({ text: this.$t('expenses.paid-question-no'), value: false });
				return item;
			},
			noClientDisabled() {
				if (this.expenseCopy.clientId) return false;
				return true;
			},
			invoicedDisabled() {
				if (!this.expenseCopy) return true;
				if (this.expenseCopy.invoiceId && this.expenseCopy.invoiceNumber) return true;
				return false;
			},
		},
	};
</script>

<style lang="scss">
	#expense-detail {
		max-width: 684px;
		background-color: var(--v-white-base);
		.disabled {
			opacity: 0.5;
		}
	}
</style>