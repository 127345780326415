<template>
	<v-container class="pa-0 pa-0">
		<v-row dense>
			<v-col cols="12" class="px-2" v-if="$store.getters.hasInvoiceAccess">
				<div class="insight-box pointer fill-height column-format" @click.stop="$emit('money-clicked')">
					<div class="row-format header-row py-3" style="background-color: var(--v-gray_10-base);">
						<div class="col col-12 text-left font-gray_80">
							<div class="font-14 font-gray_70">Payment received</div>
							<div class="font-gray_90"><span class="font-24 brand-medium truncate">{{$formatters.dollars(totalReceived,false,true,client.currency)}}</span> / {{$formatters.dollars(outstanding,false,true,client.currency)}} outstanding</div>
						</div>
					</div>
					<div class="row-format mt-auto" style="background-color: var(--v-white-base); border-top: 1px solid var(--v-gray_30-base)">
						<div class="col col-12 py-2">
							<invoicing-chart :client="client" :currency="client.currency" @total-invoiced="totalInvoiced = $event" @total-received="totalReceived = $event"></invoicing-chart>
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" class="px-2 mt-2">
				<div class="insight-box pointer fill-height column-format" @click.stop="$emit('time-clicked')">
					<div class="row-format header-row py-3" style="background-color: var(--v-gray_10-base);">
						<div class="col col-12 text-left font-gray_80">
							<div class="font-14 font-gray_70">Total hours worked</div>
							<div class="font-gray_90"><span class="font-24 brand-medium truncate">{{$formatters.formatSeconds(totalHours)}}</span> / {{$formatters.formatSeconds(thisMonthHours)}} this month</div>
						</div>
					</div>
					<div class="row-format mt-auto" style="background-color: var(--v-white-base); border-top: 1px solid var(--v-gray_30-base)">
						<div class="col col-12 py-2">
							<time-tracking-chart :client="client" @total-hours="totalHours = $event" @this-month="thisMonthHours = $event"></time-tracking-chart>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import InvoicingChart from "@/modules/clients/detail/overview/InvoicingChart";
import TimeTrackingChart from "@/modules/clients/detail/overview/TimeTrackingChart";
export default {
	name: "ClientInsights",

	props: ['client'],

	components: {TimeTrackingChart, InvoicingChart},

	data: function () {
		return {
			totalInvoiced: 0,
			totalReceived: 0,
			totalHours: 0,
			thisMonthHours: 0,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {
		outstanding: function(){
			return this.totalInvoiced - this.totalReceived;
		}
	},

}
</script>

<style scoped lang="scss">
	.insight-box {
		border: 1px solid var(--v-gray_30-base);
		background-color: var(--v-gray_10-base);
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
		border-radius: 16px;
		font-size: 14px;
		overflow: hidden;
		flex-grow: 1;
		width: 100%;
	}
</style>
