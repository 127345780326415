<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">Hang tight, we are connecting your Microsoft account.</div>
	</div>
</template>

<script>
	import MicrosoftCalendarService from '@/modules/integrations/microsoft/MicrosoftCalendarService';
	import MicrosoftCommunicatorService from '@/modules/integrations/microsoft/MicrosoftCommunicatorService';
	import MicrosoftCustomEmailService from '@/modules/integrations/microsoft/MicrosoftCustomEmailService';

	export default {
		name: 'ZoomOauthHandler',

		props: ['code'],

		components: {},

		data: function () {
			return {
				calendarService: new MicrosoftCalendarService(),
				communicatorService: new MicrosoftCommunicatorService(),
				customEmailService: new MicrosoftCustomEmailService(),
				redirectUri: document.location.protocol + '//' + document.location.host + '/integrations/microsoft/oauth',
			};
		},

		mounted() {
			this.$store.commit('startLoading');
			try {
				this.connectMicrosoft();
			} catch (err) {
				console.log(err);
				this.$store.commit('stopLoading');
			}
		},

		beforeDestroy() {},

		methods: {
			connectMicrosoft: function () {
				let type = localStorage.getItem('MICROSOFT_OAUTH_TYPE');
				let scope = localStorage.getItem('MICROSOFT_OAUTH_SCOPE');

				localStorage.removeItem('MICROSOFT_OAUTH_TYPE');
				localStorage.removeItem('MICROSOFT_OAUTH_SCOPE');

				if (type === 'Calendar') {
					this.connectCalendar(scope);
				} else if (type === 'CustomEmail') {
					this.connectCustomEmail(scope);
				} else if (type.startsWith('Communicator')) {
					let parts = type.split(':');
					if (parts.length === 1) {
						this.connectCommunicator(scope);
					} else {
						this.reconnectCommunicator(parts[1], scope);
					}
				}
			},

			connectCommunicator: function (scope) {
				this.communicatorService
					.connect(this.code, this.redirectUri, scope)
					.then((res) => {
						console.log(res);
						this.$store.commit('info', 'Microsoft account linked.');
						this.$router.push(`/communicator/mailboxes`);
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error connecting your Microsoft account: ' + err.data.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			reconnectCommunicator: function (id, scope) {
				this.communicatorService
					.reconnect(id, this.code, this.redirectUri, scope)
					.then((res) => {
						console.log(res);
						this.$store.commit('info', 'Microsoft account linked re-established.');
						this.$router.push(`/communicator/mailboxes`);
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error re-connecting your Microsoft account: ' + err.data.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			connectCustomEmail: function () {
				this.customEmailService
					.connect(this.code, this.redirectUri)
					.then((res) => {
						console.log(res);
						this.$store.commit('info', 'Microsoft account linked.');
						this.$router.push(`/portal?v=Email`);
						this.$onBoarding.track('connect_email');
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error connecting your Microsoft account: ' + err.data.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			connectCalendar: function (scope) {
				this.calendarService
					.connect(this.code, this.redirectUri, scope)
					.then(() => {
						this.$store.commit('info', 'Microsoft account linked.');
						this.$onBoarding.track('connect_calendar');
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error connecting your Microsoft account: ' + err.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
						this.$store.state.eventBus.$emit('open-settings-modal', 'connected-apps');
					});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
