<template>
	<div
		@click="handleClick"
		:class="`pointer row-format align-center mx-2 ${active ? 'nav-link-active' : ''} nav-link${
			expanded ? '-expanded' : ''
		}`"
	>
		<div v-if="expanded" class="row-format align-center pa-3" style="max-width: 100%"  :content="client.name">
			<client-avatar x-small :client="client" :disable-click="true" :inline="true"></client-avatar>
			<div v-if="expanded" :class="`ml-2 nav-label truncate ${active ? 'active' : ''}`">{{ client.name }}</div>
		</div>
		<div v-else class="row-format align-center pa-3" style="max-width: 100%"  :content="client.name" v-tippy="{ placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window'}">
			<client-avatar x-small :client="client" :disable-click="true" :inline="true"></client-avatar>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	export default {
		name: 'FavoriteLink',

		props: ['client'],

		components: { ClientAvatar },

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClick: function (event) {
				if(event && (event.ctrlKey || event.metaKey)){
					window.open(`/client/${this.client.id}`,'_blank');
				}else {
					this.$router.push(`/client/${this.client.id}`);
				}
			},
		},

		computed: {
			expanded: function () {
				return this.$store.state.leftNavExpanded;
			},
			active: function () {
				return this.$route.path.startsWith(`/client/${this.client.id}`);
			},
		},
	};
</script>

<style scoped lang="scss">
	.nav-label {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: var(--v-black-base);
	}

	.active {
		color: var(--v-primary-base) !important;
		background-color: var(--v-white-base);
		border: none;
	}

	.nav-link-active {
		background-color: var(--v-white-base);
	}

	.nav-link {
		width: 44px;
		height: 38px;
		border-radius: 8px;
	}

	.nav-link-expanded {
		width: 208px;
		height: 38px;
		border-radius: 8px;
	}
</style>
