<template>
	<div class="column-format fill-height" v-if="isReady">
		<div class="py-3 px-3 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<vendor-filter
					v-if="vendors.length"
					slot="filter"
					:filter="filter"
					:is-default-filter="!!filter"
					@clear-filter="filter = ''"
					@updated="saveFilter($event)"
				/>
			<plus-button class="ml-auto" @click="createVendor()"></plus-button>
		</div>


		<div class="column-format show-scrollbar" style="flex: 1">
			<!--//* Empty View -->
			<div v-if="vendors.length === 0" class="row-format centered ma-8" style="height: calc(var(--vh) - 100px)">
				<empty-view
					header="Tame your books!"
					body="Get your finances under control with one place to collect and manage your expenses, vendors, and business accounts. “Tax filing was never so smooth” — Future you."
					cta="Add your first vendor"
					video-header="See how it works"
					video-body="Learn how to create expenses and vendors, as well as how to read a profit & loss report. "
					video-cta="Watch the tutorial"
					video-id="lOS8CCgWhTg"
					@cta-clicked="createVendor()"
				></empty-view>
			</div>

			<div v-else class="data-table-wrapper" id="vendor-table-wrapper">
				<div class="show-scrollbar">
					<v-data-table
						id="vendors-table"
						v-if="vendors"
						class="list-view"
						fixed-header
						:headers="headers"
						:items="filteredVendors"
						:items-per-page="-1"
						height="calc(var(--vh) - 190px)"
						:hide-default-footer="true"
					>
						<template v-slot:item="{ item }">
							<tr style="cursor: pointer" class="text-left" :key="item.id" @click="editVendor(item)">
								<td>{{ item.name }}</td>
								<td>{{ formatVendorContact(item.contact) }}</td>
								<td v-html="formatVendorAddress(item)"></td>
								<td>{{ formatBalanceDue(item) }}</td>
							</tr>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>

		<basic-modal
			v-if="vendorDetailDialog"
			:key="vendorToEdit.id"
			:dialog="vendorDetailDialog"
			@close="vendorDetailDialog = false"
			ref="editModal"
		>
			<vendor-detail
				:vendor="vendorToEdit"
				:vendors="vendors"
				@created="handleCreate($event)"
				@updated="handleUpdate($event)"
				@delete-vendor-dialog="handleDeleteVendor($event)"
				@close="vendorDetailDialog = false"
			></vendor-detail>
		</basic-modal>
		<confirm-dialog
			:dialog="deleteVendorDialog"
			icon="$vendor"
			:heading-text="$t('vendors.delete-confirm-heading')"
			:body-text="$t('vendors.delete-confirm-body')"
			@confirm="
				deleteVendor(deletingVendor);
				deleteVendorDialog = false;
			"
			@close="deleteVendorDialog = false"
		></confirm-dialog>
	</div>
</template>

<script>
	import VendorDetail from '@/modules/accounting/vendors/VendorDetail';
	import VendorMixin from '@/modules/accounting/vendors/VendorMixin';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';
	import BasicModal from '@/components/BasicModal';
	import VendorFilter from '@/modules/accounting/vendors/VendorFilter';
	import EmptyView from '@/components/EmptyView';
	import PlusButton from "@/components/PlusButton";

	export default {
		name: 'Vendors',

		props: [],

		components: { VendorDetail, BasicModal, ConfirmDialog, VendorFilter, EmptyView, PlusButton },
		mixins: [VendorMixin, AccountingMixin],

		data: function () {
			return {
				headers: [
					{ text: this.$t('vendors.table-header.vendor-name'), value: 'name', class: 'nowrap' },
					{ text: this.$t('vendors.table-header.contact'), value: 'category', class: 'nowrap' },
					{ text: this.$t('global.address'), value: 'address', class: 'nowrap', sortable: false },
					{ text: this.$t('vendors.table-header.balance-due'), value: 'paid', class: 'nowrap' },
				],
				itemsPerPage: 15,
				deleteVendorDialog: false,
				deletingVendor: null,
				filter: '',
			};
		},

		mounted() {
			localStorage.setItem('ACCOUNTING_PAGE','vendors');
			this.$track.record('page-view', { module: 'accounting-vendors' });
			this.$store.state.eventBus.$on('account-changed', this.getVendors);
			this.getVendors();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getVendors);
		},

		methods: {
			saveFilter(event) {
				console.log(event);
				this.filter = event;
			},
			handleDeleteVendor(vendor) {
				this.deletingVendor = vendor;
				this.deleteVendorDialog = true;
			},
			goToExpenses() {
				this.$router.push('/expenses');
			},
			formatVendorContact(contact) {
				if (!contact) return '';
				if (contact.email) return contact.email;
				return ((contact.firstName ?? '') + ' ' + (contact.lastName ?? '')).trim();
			},
			formatVendorAddress(vendor) {
				let lines = [];
				lines.push(((vendor.address1 ?? '') + ' ' + (vendor.address2 ?? '')).trim());
				let cityState = [];
				if (vendor.city) cityState.push(vendor.city);
				if (vendor.locality) cityState.push(vendor.locality);
				lines.push((cityState.join(', ') + ' ' + (vendor.postal ?? '')).trim());
				return lines
					.filter((line) => line)
					.join('<br>')
					.trim();
			},
			formatBalanceDue(vendor) {
				// console.log(vendor);
				return this.$formatters.dollars(vendor.balanceDue, true, true, this.$store.state.defaultCurrency);
			},
		},

		computed: {
			filteredVendors() {
				return this.vendors.filter((vendor) => {
					// search filter
					if (this.$validations.isEmpty(this.filter) || this.$validations.isEmpty(vendor.name)) {
						return true;
					} else if (vendor.name.toLowerCase().includes(this.filter.toLowerCase())) {
						return true;
					} else if (vendor.contact) {
						if (
							vendor.contact.lastName &&
							vendor.contact.lastName.toLowerCase().includes(this.filter.toLowerCase())
						) {
							return true;
						} else if (
							vendor.contact.email &&
							vendor.contact.email.toLowerCase().includes(this.filter.toLowerCase())
						) {
							return true;
						} else if (vendor.city && vendor.city.toLowerCase().includes(this.filter.toLowerCase())) {
							return true;
						} else if (vendor.postal && vendor.postal.toLowerCase().includes(this.filter.toLowerCase())) {
							return true;
						}
					}
					return false;
				});
			},
		},
	};
</script>

<style lang="scss">

</style>
