import { render, staticRenderFns } from "./MetricWidget.vue?vue&type=template&id=fc4dcedc&scoped=true&"
import script from "./MetricWidget.vue?vue&type=script&lang=js&"
export * from "./MetricWidget.vue?vue&type=script&lang=js&"
import style0 from "./MetricWidget.vue?vue&type=style&index=0&id=fc4dcedc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc4dcedc",
  null
  
)

export default component.exports