<template>
	<div>
		<div class="row-format py-2 pl-2 mb-1" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<div :class="`page-link ${tab === 'ToDos' ? 'page-link-active' : ''}`" @click="selectTab('ToDos')">
				<div>To-dos</div>
			</div>
			<div :class="`page-link ${tab === 'Notes' ? 'page-link-active' : ''}`" @click="selectTab('Notes')">
				<div>Notes</div>
			</div>
			<div :class="`page-link ${tab === 'Schedule' ? 'page-link-active' : ''}`" @click="selectTab('Schedule')">
				<div>Schedule</div>
			</div>
		</div>
		<to-do :manage-events="true" ref="ToDos" v-show="tab === 'ToDos'"></to-do>
		<user-notes :manage-events="true" ref="Notes" v-show="tab === 'Notes'"></user-notes>
		<day-calendar :right-bar="true" ref="Schedule" v-show="tab === 'Schedule'" style="text-align: left!important"></day-calendar>
	</div>
</template>

<script>
	import UserNotes from '@/modules/productivity/notes/UserNotes';
	import ToDo from '@/modules/productivity/todo/ToDo';
	import DayCalendar from "@/modules/home/focus/DayCalendar";

	export default {
		name: 'NotesToDos',

		props: [],

		mixins: [],

		components: {
			DayCalendar,
			ToDo,
			UserNotes,
		},

		data: function () {
			return {
				show: false,
				tab: null
			};
		},

		mounted() {
			this.getInitialValue();
		},

		beforeDestroy() {},

		methods: {
			selectTab: function(value){
				this.tab = value;
			},

			getInitialValue: function(){
				let result = localStorage.getItem('NOTES_TODOS_TAB');
				if(result){
					this.tab = result;
				}
			},
		},

		watch: {
			tab: function(val){
				localStorage.setItem('NOTES_TODOS_TAB',val);
			}
		},

		computed: {},
	};
</script>

<style lang="scss">
</style>
