<template>
	<div v-if="isReady">
		<v-data-table
			id="products-data-table"
			:items-per-page="25"
			:hide-default-footer="filteredProducts.length <= 25"
			:hide-default-header="false"
			:headers="headers"
			:items="filteredProducts"
			item-key="id"
			no-data-text="Add your first product or service"
			@click:row="selectProduct($event)"
			:mobile-breakpoint="0"
			:item-class="itemClass"
			group-by="folder"
		>
			<template v-slot:group.header="{ group, headers, toggle }">
				<td colspan="5" class="text-left" @click="toggle">
					<div class="row-format align-center gap-2">
						<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ?  group : ' -- ' }}
					</div>
				</td>
			</template>

			<template v-slot:item.productName="{ item }">
				<div class="product-name">{{ item.productName }}</div>
			</template>
			<template v-slot:item.rate="{ item }">
				<div>{{ getRateForList(item) }}</div>
			</template>
			<!--template v-slot:item.unit="{ item }">
				<div>{{ item.unit }}</div>
			</template-->
			<template v-slot:item.hourly="{ item }">
				<div class="text-center">
					<v-icon v-if="item.hourly" small>{{ $t('product.check') }}</v-icon>
				</div>
			</template>
			<template v-slot:item.taxable="{ item }">
				<div class="text-center">
					<v-icon v-if="item.taxable" small>{{ $t('product.check') }}</v-icon>
				</div>
			</template>
			<template v-slot:item.deposit="{ item }">
				<div class="text-center">
					<v-icon v-if="item.deposit" small>{{ $t('product.check') }}</v-icon>
				</div>
			</template>
			<template v-slot:item.manage="{ item }">
				<div class="text-right mt-n1">
					<v-btn icon class="edit-icon" @click.stop="editProduct(item)">
						<v-icon color="gray_50" size="20">$edit</v-icon>
					</v-btn>
					<v-btn icon class="delete-icon" @click.stop="deleteProduct(item)">
						<v-icon color="var(--v-gray_70-base)" size="20">$delete</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<basic-modal v-if="productModal" :dialog="productModal" @close="productModal = false" :persistent="true">
			<div id="edit-product" class="">
				<div class="modal-padding column-format gap-3">
					<v-form v-model="isValid" @submit.prevent>
						<div class="modal-title row-format gap-2 align-center">
							<div>{{ $t('product.create-product-title') }}</div>
							<div class="ml-auto">
								<folder-selector :folders="folders" v-model="editingProduct.folder"></folder-selector>
							</div>
						</div>
						<v-text-field
							hide-details
							outlined
							dense
							autofocus
							persistent-placeholder
							:placeholder="$t('product.product-name-placeholder')"
							:label="$t('product.product-name-label')"
							v-model="editingProduct.productName"
							:rules="[(value) => !!value || 'Required.']"
						/>

						<v-textarea
							v-model="editingProduct.description"
							hide-details
							persistent-placeholder
							outlined
							dense
							:placeholder="$t('product.product-description')"
              spellcheck="true"
							:rows="9"
							class="my-3"
						></v-textarea>

						<amount-input
							hide-details
							outlined
							dense
							persistent-placeholder
							:prefix="currencySymbol()"
							:placeholder="$t('product.rate-placeholder')"
							:label="$t('product.rate-label')"
							v-model="editingProduct.rate"
							:rules="[(value) => !!value || 'Required.']"
						/>

						<div class="column-format gap-2 mt-3">
							<div class="d-flex justify-start align-start">
								<v-simple-checkbox v-model="editingProduct.deposit" />
								<label class="font-14 font-gray_80 ml-n1"  v-tippy="{content:'Used to flag a line item as being part of a prepaid deposit'}">{{ $t('product.deposit-label') }}</label>
							</div>
							<div class="d-flex justify-start align-start" v-if="!editingProduct.deposit">
								<v-simple-checkbox v-model="editingProduct.hourly" @click="handleHourlyClick()" />
								<label class="font-14 font-gray_80 ml-n1">{{ $t('product.hourly-label') }}</label>
							</div>
							<div class="d-flex justify-start align-start">
								<v-simple-checkbox v-model="editingProduct.taxable" />
								<label class="font-14 font-gray_80 ml-n1">{{ $t('product.taxable-label') }}</label>
							</div>
						</div>

						<div class="mt-2 mb-4" style="border-bottom: 1px solid var(--v-gray_50-base); width:100%; min-height: 2px;"></div>

						<div class="row-format gap-4">
							<v-autocomplete
									hide-details
									persistent-placeholder
									dense
									outlined
									:placeholder="$t('account.currency')"
									:items="currencies"
									item-text="label"
									item-value="value"
									v-model="newCurrency"
							>
							</v-autocomplete>
							<v-btn style="min-height: 40px" outlined color="secondary" :disabled="!newCurrency" @click="addCurrency">+ Add new rate</v-btn>
						</div>
						<div class="mt-4 column-format gap-4">
							<div v-for="(currencyRate,index) in editingProduct.currencyRates" :key="currencyRate.currency" class="row-format gap-4 align-center">
								<v-text-field
										hide-details
										outlined
										dense
										persistent-placeholder
										:prefix="currencySymbol(currencyRate.currency)"
										:suffix="currencyRate.currency"
										:placeholder="$t('product.rate-placeholder')"
										:label="$t('product.rate')"
										v-model="currencyRate.rate"
										:rules="[(value) => !!value || 'Required.']"
								/>
								<v-icon class="material-symbols-rounded pointer" @click="deleteCurrencyRate(index)">delete</v-icon>
							</div>
						</div>
					</v-form>
				</div>
				<div class="modal-footer">
					<v-btn class="secondary-action mr-1" style="width: 140px" @click="productModal = false">
						{{ $t('global.cancel') }}
					</v-btn>
					<v-btn
						class="primary-action ml-1"
						style="width: 140px"
						:disabled="!editingProduct.productName || !isValid"
						@click="save()"
					>
						{{ $t('global.save') }}
					</v-btn>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	import ProductService from '@/modules/products/ProductService';
	import { v4 as uuidv4 } from 'uuid';
	import BasicModal from '@/components/BasicModal';
	import ConfirmModal from '@/components/ConfirmModal';
	import { DateTime } from 'luxon';
	import currencies from '@/assets/data/currencies.json';
  import AmountInput from "@/components/AmountInput.vue";
	import FolderSelector from "@/components/FolderSelector";

	export default {
		name: 'ProductsTable',
		components: {FolderSelector, BasicModal, AmountInput },
		props: ['currency','filter'],

		data() {
			return {
				currencies: currencies,
				productService: new ProductService(),
				DateTime: DateTime,
				isValid: false,
				products: [],
				isReady: false,
				productModal: false,
				editingProduct: {},
				newCurrency: null,
				headers: [
					{ text: this.$t('product.headers.product-name'), name: 'productName', value: 'productName', sortable: true },
					{ text: this.$t('product.headers.rate'), name: 'rate', value: 'rate', sortable: true, align: 'right' },
					//{ text: this.$t('product.headers.unit'), name: 'unit', value: 'unit', sortable: true },
					{ text: this.$t('product.headers.hourly'), name: 'hourly', value: 'hourly', sortable: true, align: 'center' },
					{
						text: this.$t('product.headers.taxable'),
						name: 'taxable',
						value: 'taxable',
						sortable: true,
						align: 'center',
					},
					{ text: '', name: 'manage', value: 'manage', sortable: false },
				],

				search: null,
				// productActions: [
				// 	{ action: 'edit', label: 'Edit' },
				// 	{ action: 'select', label: 'Select' },
				// 	{ action: 'assignProject', label: 'Select and assign to project...' },
				// ],
				product: null,
				// projectId: '',

				// DateTime: DateTime,
			};
		},

		mounted() {
			this.getProducts();
		},

		methods: {
			addCurrency: function(){
				let ix = this.editingProduct.currencyRates.findIndex(c => c.currency === this.newCurrency);
				if(ix  === -1) {
					this.editingProduct.currencyRates.push({
						currency: this.newCurrency,
						rate: 0
					});
					this.editingProduct.currencyRates.sort((a,b) => a.currency.localeCompare(b.currency));
				}
			},

			deleteCurrencyRate: function(index){
				this.editingProduct.currencyRates.splice(index,1);
			},

			currencySymbol: function (currency = this.$store.state.defaultCurrency) {
				return this.$formatters.currencySymbol(currency);
			},

			getRateForList: function(product){
				let currencyRate = product.currencyRates.find(c => c.currency === this.currency);
				let rate = product.rate;

				if(currencyRate){
					rate = currencyRate.rate;
				}

				return this.$formatters.dollars(rate,true,true,this.currency,4);
			},

			getProducts: function() {
				this.productService.getProducts().then((res) => {
					this.products.splice(0, this.products.length);
					this.products.push(...res.data);
					this.$emit('set-products', this.products);
					this.isReady = true;
				});
			},

			cancel: function() {
				this.$emit('close');
				setTimeout(() => {
					this.view = 'PRODUCTS';
				}, 100);
			},
			save: function() {
				if (this.editingProduct.hourly) {
					this.editingProduct.unit = 'Hours';
				}
				if (this.editingProduct.isNew) {
					this.productService.postProduct(this.editingProduct).then((res) => {
						this.products.unshift(res.data);
						this.editingProduct = {};
						this.productModal = false;
						this.$onBoarding.track('invoices_create_product');
						this.selectProduct(res.data);
					});
				} else {
					this.productService.updateProduct(this.editingProduct.id, this.editingProduct).then((res) => {
						let index = this.products.findIndex((p) => p.id === this.editingProduct.id);
						if (index > -1) {
							this.products.splice(index, 1, res.data);
						}
						this.productModal = false;
						// console.log(this.products);
					});
				}
			},
			createNewProduct: function() {
				this.editingProduct = this.initNewProduct();
				this.productModal = true;
			},
			editProduct: function(product) {
				this.editingProduct = product;
				this.productModal = true;
			},
			deleteProduct: function(product) {
				let binding = {
					headingText: this.$t('global.confirm'),
					bodyText: this.$t('product.delete-confirm-body'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.productService.deleteProduct(product.id).then((res) => {
							let index = this.products.findIndex((p) => p.id === product.id);
							if (index > -1) {
								this.products.splice(index, 1);
							}
							console.log(res);
						});
					}
				});
			},
			handleHourlyClick: function() {
				// if (this.editingProduct.hourly) {
				// 	this.editingProduct.unit = 'Hours';
				// } else {
				// 	this.editingProduct.unit = null;
				// }
			},

			newId: function() {
				return uuidv4().replace(/-/g, '');
			},
			initNewProduct: function() {
				return {
					hourly: false,
					id: this.newId(),
					productName: '',
					rate: 0,
					taxable:
						this.$store.state.podAccount.accountPreferences.invoiceUseTax &&
						this.$store.state.podAccount.accountPreferences.defaultTaxRate > 0,
					unit: 'SVC',
					isNew: true,
					currencyRates: []
				};
			},
			selectProduct: function(product) {
				this.$emit('select', { product: product });
			},
			selectProductAndProject: function(product) {
				this.getProjects();
				this.product = product;
				this.view = 'PROJECTS';
			},
			selectProject: function(project) {
				this.$emit('select', { product: this.product, project: project });
				this.view = 'PROJECTS';
			},
			itemClass() {
				return 'pointer';
			},
		},

		computed: {
			folders: function(){
				return this.products.filter(e => e.folder).map(e => e.folder).filter((value, index, self) => self.indexOf(value) === index);
			},

			filteredProducts: function(){
				if(this.products && this.filter) {
					return this.products.filter(p =>
						(p.productName && p.productName.toLowerCase().includes(this.filter.toLowerCase())) ||
						(p.folder && p.folder.toLowerCase().includes(this.filter.toLowerCase()))
					);
				}else{
					return this.products;
				}
			}
		},

		watch: {},
	};
</script>

<style lang="scss">
	#products-widget {
		min-width: 420px;
		background-color: var(--background);
		.v-data-table .v-data-table__wrapper {
			table tbody tr td {
				padding-top: 12px !important;
				padding-bottom: 4px !important;
			}
		}
		.edit-icon,
		.delete-icon {
			//--icon-color: red;
			//svg {
			//	color: var(--v-gray_80-base);
			//}
		}
	}
	#edit-product {
		width: 550px;
		min-width: 550px;
		div.half {
			gap: 12px;
			> div {
				flex: 0 1 auto;
			}
		}
	}
	div.product-name {
		max-width: 260px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
