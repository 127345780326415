<template>
	<div class="column-format fill-height">
		<div class="py-3 px-5 row-format align-center " style="border-bottom: 1px solid var(--v-gray_30-base)">
			<div>
				<proposal-filter
					v-if="filterReady"
					slot="filter"
					class=""
					:key="refreshKey"
					v-model="filter"
					@clear-filter="initFilter()"
					@updated="saveCurrentFilter($event)"
				></proposal-filter>
			</div>
			<plus-button class="ml-auto" @click="handleAddNew"></plus-button>
		</div>

		<div class="show-scrollbar fill-height mx-3">
			<proposal-list :proposal-id="id" :filter="filter" :is-active="true" ref="ProposalList" />
		</div>
	</div>
</template>

<script>
	import ProposalList from '@/modules/clients/detail/proposals/ProposalList';
	import ProposalFilter from '@/modules/proposals/ProposalFilter';
	import PlusButton from '@/components/PlusButton';

	export default {
		name: 'ProposalsMain',

		props: ['id'],

		components: { ProposalList, ProposalFilter, PlusButton },

		data() {
			return {
				refreshKey: 0,
				newProposalCounter: 0,
				filter: {},
				filterDefault: {
					search: null,
					clients: [],
					dateSpecifier: null,
					earliest: null,
					latest: null,
					proposalStatus: [],
					proposalType: []
				},
				filterReady: false,
			};
		},
		mounted() {
			this.getSelectedFilters();
			if (this.id) {
				// Open proposal?
			}
		},
		methods: {
			saveCurrentFilter(event) {
				this.filter = event;
				try {
					sessionStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			getSelectedFilters() {
				try {
					this.filter = JSON.parse(sessionStorage.getItem(this.filterStateKey));
					if (!this.filter) {
						this.saveCurrentFilter(this.filterDefault);
					}

					if(!this.filter.proposalType){
						this.filter.proposalType = [];
					}

					this.filterReady = true;
				} catch (err) {
					console.log('Error reading filter preferences from local storage.',err);
				}
			},

			initFilter() {
				console.log('init');
				this.saveCurrentFilter(this.filterDefault);
				this.refreshKey++;
			},

			handleAddNew() {
				this.$refs.ProposalList.addNew(true);
			},

			upgradeClicked: function() {
				this.$router.push('/subscription');
			},
		},

		watch: {},

		computed: {
			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},
			filterStateKey: function() {
				return 'PROPOSAL_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},
		},
	};
</script>

<style lang="scss">
	#proposals-main {
		height: calc(var(--vh) - 100px);
		.subheading-links {
			margin: 0 16px;
			height: 64px;
			//border: 1px solid red;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 32px;
			.selected {
				font-weight: 600;
			}
			.not-selected {
				color: var(--v-primary-base);
				cursor: pointer;
			}
		}
	}
</style>
