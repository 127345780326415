<template>
	<div class="fill-height column-format">

		<div class="py-3 px-5 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<report-filter slot="filter" current-view="Payment" v-model="filter" @clear-filter="clearFilter()"></report-filter>

			<v-menu
					:nudge-bottom="0"
					:elevation="0"
					content-class="add-new-menu"
					bottom
					left
					rounded
					offset-overflow
					offset-y
					transition="slide-y-transition"
			>
				<template v-slot:activator="{ on }">
					<plus-button class="ml-auto" v-on="on"></plus-button>
				</template>
				<div class="add-new-dropdown">
					<div
							v-for="(item, index) in addNew"
							:key="index"
							@click="handleNewPayment(item.value)"
							class="add-new-item"
					>
						{{ item.label }}
					</div>
				</div>
			</v-menu>
		</div>

		<div v-if="!invoices.length && !income.length" class="row-format centered fill-height" style="height: calc(var(--vh) - 100px)">
			<empty-view
					header="No payments yet"
					:body="
					`You haven’t sent any invoices or logged any payments yet. `
				"
					cta="Send an invoice"
					video-header="See how it works"
					video-body="Learn how to get paid for your work. You can also see how to set up automatic invoices."
					video-cta="Watch the tutorial"
					video-id="lOS8CCgWhTg"
					@cta-clicked="handleNewPayment()"
			></empty-view>
		</div>

		<div v-else>
			<div id="invoice-table-and-chart" class="show-scrollbar">
				<recurring-payment-list class="pa-2" ref="RecurringPayment"></recurring-payment-list>

				<div v-if="isReady">
					<payment-table :payments="filteredPayments" @open-invoice="openPayment($event)"></payment-table>
				</div>
			</div>
		</div>

		<basic-modal :dialog="newPayment">
			<new-payment @result="handleResult($event)"></new-payment>
		</basic-modal>

		<basic-modal :dialog="outstandingInvoice">
			<outstanding-invoices @result="outstandingInvoiceResult($event)"></outstanding-invoices>
		</basic-modal>
	</div>
</template>

<script>
	import ReportFilter from '@/modules/accounting/invoices/ReportFilter';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import FilterHelpers from '@/utils/FilterHelpers';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import DateTime from '@/modules/utils/HDateTime';
	import PaymentTable from '@/modules/accounting/payments/PaymentTable';
	import BasicModal from '@/components/BasicModal';
	import NewPayment from '@/modules/accounting/payments/NewPayment';
	import OutstandingInvoices from '@/modules/accounting/invoices/OutstandingInvoices';
	import PaymentDetail from '@/modules/accounting/payments/PaymentDetail';
	import IncomeService from '@/modules/accounting/payments/IncomeService';
	import PlusButton from "@/components/PlusButton";
	import EmptyView from "@/components/EmptyView";
	import RecurringPaymentList from "@/modules/accounting/payments/RecurringPaymentList";

	export default {
		name: 'PaymentList',

		components: {RecurringPaymentList, OutstandingInvoices, NewPayment, BasicModal, PaymentTable,  ReportFilter, PlusButton, EmptyView },

		mixins: [AccountingMixin],

		data: function () {
			return {
				invoiceService: new InvoiceService(),
				incomeService: new IncomeService(),
				invoices: [],
				income: [],
				filter: this.emptyFilter(),
				isReady: false,
				DateTime: DateTime,
				newPayment: false,
				outstandingInvoice: false,
			};
		},

		mounted() {
			localStorage.setItem('ACCOUNTING_PAGE','payments');
			this.$track.record('page-view', { module: 'payment-list' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.loadSavedFilter();
			this.getInvoices();
			this.getIncome();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleNewPayment: function (type) {
				if(type === 'RECURRING'){
					this.$refs.RecurringPayment.addNew();
				}else {
					this.newPayment = true;
				}
			},

			handleResult: function (event) {
				this.newPayment = false;
				if (event === 'apply-to-invoice') {
					this.outstandingInvoice = true;
				} else if (event === 'other-income') {
					this.openPaymentDetail(null);
				}
			},

			openPaymentDetail: function (payment) {
				this.$store.state.globalModalController.openModal(PaymentDetail, { payment: payment }).then((res) => {
					if (res) {
						let ix = this.income.findIndex((i) => i.id === res.id);
						if (res.action && res.action === 'DELETED') {
							this.income.splice(ix, 1);
						} else if (ix > -1) {
							this.income.splice(ix, 1, res);
						} else {
							this.income.push(res);
						}
					}
				});
			},

			outstandingInvoiceResult: function (invoice) {
				this.outstandingInvoice = false;
				if (invoice) {
					let binding = {
						clientId: invoice.clientId,
						id: invoice.id,
						openPayment: true,
						showDuplicate: false,
					};
					this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true).then((res) => {
						if (!res) return;
						if (res.action && res.action === 'DELETED') {
							this.removeInvoice(res.id);
						} else {
							this.addOrUpdateInvoice(res);
						}
					});
				}
			},

			getInvoices: function () {
				this.invoiceService.getInvoices().then((res) => {
					this.invoices.splice(0, this.invoices.length);
					this.invoices.push(...res.data);
					this.isReady = true;
				});
			},

			getIncome: function () {
				this.incomeService.getIncomeList().then((res) => {
					this.income.splice(0, this.income.length);
					this.income.push(...res.data);
				});
			},

			openPayment: function (payment) {
				if (payment.type === 'Invoice') {
					this.openInvoice(payment);
				} else {
					this.openPaymentDetail(payment);
				}
			},

			openInvoice: function (payment) {
				let binding = {
					clientId: payment.client.id,
					id: payment.invoiceId,
					openPayment: true,
					payment: payment,
					showDuplicate: false,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, false, true).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.removeInvoice(res.id);
					} else {
						this.addOrUpdateInvoice(res);
					}
				});
			},

			removeInvoice: function (invoiceId) {
				let ix = this.invoices.findIndex((i) => i.id === invoiceId);
				if (ix > -1) {
					this.invoices.splice(ix, 1);
				}
			},

			addOrUpdateInvoice: function (invoice) {
				let ix = this.invoices.findIndex((i) => i.id === invoice.id);
				if (ix > -1) {
					this.invoices.splice(ix, 1, invoice);
				} else {
					this.invoices.push(invoice);
				}
			},

			handleAccountChange: function () {
				this.isReady = false;
				this.$nextTick(() => {
					this.filter = this.emptyFilter();
					this.loadSavedFilter();
				});
			},

			clearFilter: function () {
				this.filter = this.emptyFilter();
			},

			emptyFilter: function () {
				return {
					search: null,
					dateSpecifier: null,
					earliest: null,
					latest: null,
					paymentSource: [],
					clientList: [],
					invoiceStatus: [],
					timeEntryStatus: [],
				};
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			loadSavedFilter() {
				try {
					let filterString = localStorage.getItem(this.filterStateKey);
					if (filterString) {
						this.filter = JSON.parse(filterString);
					} else {
						this.filter = this.emptyFilter();
					}
				} catch (err) {
					console.log('Error reading filter preferences from local storage.', err);
				} finally {
					this.isReady = true;
				}
			},
		},

		watch: {
			filter: {
				deep: true,
				handler() {
					this.saveCurrentFilter();
				},
			},
		},

		computed: {
			addNew: function () {
				let result = [];
				result.push({ label: 'Payment', value: 'PAYMENT' });
				result.push({ label: 'Recurring payment', value: 'RECURRING' });
				return result;
			},

			filteredPayments: function () {
				let result = [...this.payments];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;

				return result
					.filter((i) => {
						if (!search || (search && i.invoiceNumberFormatted.toLowerCase().includes(search))) {
							return true;
						} else if (search) {
							return false;
						}
					})
					.filter((i) => {
						if (this.filter.clientList && this.filter.clientList.length > 0) {
							if (i.client) {
								return this.filter.clientList.includes(i.client.id);
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((i) => {
						if (this.filter.paymentSource && this.filter.paymentSource.length > 0) {
							return this.filter.paymentSource.includes(i.type);
						} else {
							return true;
						}
					})
					.filter((i) => {
						if (this.earliest && this.latest) {
							let compareDate = DateTime.fromISO(i.datePaid).endOf('day');
							if (compareDate >= this.earliest && compareDate <= this.latest) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			payments: function () {
				let payments = [];

				this.invoices.forEach((i) => {
					i.payments.forEach((p) => {
						p.client = i.clientInfo;
						p.invoiceNumberFormatted = i.invoiceNumberFormatted;
						p.invoiceId = i.id;
						p.type = 'Invoice';
						payments.push(p);
					});
				});

				this.income.forEach((i) => {
					i.invoiceNumberFormatted = i.identifier;
					i.datePaid = i.date;
					i.type = 'Income';
					payments.push(i);
				});

				return payments;
			},

			earliest: function () {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return DateTime.now().minus({ months: 2 }).startOf('month');
				}
			},

			latest: function () {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest.endOf('day');
					}
				} else {
					return DateTime.now().endOf('month');
				}
			},

			filterStateKey: function () {
				return 'PAYMENT_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},
		},
	};
</script>

<style lang="scss">

</style>
