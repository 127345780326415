import { render, staticRenderFns } from "./EmailContactSelector.vue?vue&type=template&id=32b237ea&scoped=true&"
import script from "./EmailContactSelector.vue?vue&type=script&lang=js&"
export * from "./EmailContactSelector.vue?vue&type=script&lang=js&"
import style0 from "./EmailContactSelector.vue?vue&type=style&index=0&id=32b237ea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b237ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VCombobox})
