var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.expenses.length === 0 && _vm.tableType)?_c('div',{staticClass:"empty-state"},[_c('v-icon',[_vm._v("$search")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.noExpenseMessage)}})],1):_c('div',{staticClass:"data-table-wrapper"},[(_vm.expenses)?_c('v-data-table',{class:'pointer list-view' + (_vm.dense ? ' h-dense' : '') + (_vm.outline ? ' h-outline' : ''),attrs:{"height":"calc(100vh - 240px)","id":"expenses-table","item-class":_vm.itemClass,"headers":_vm.headers,"sort-by":"paidDate","sort-desc":"","header-props":{},"items":_vm.fixedExpenses,"footer-props":{
				'items-per-page-options': [5, 10, 15, 25, 50, -1]
			},"items-per-page":_vm.itemsPerPage,"hide-default-footer":_vm.expenses.length <= _vm.itemsPerPage},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
			var item = ref.item;
return [(item.total)?_c('div',{staticClass:"brand-semilight"},[_vm._v(_vm._s(_vm.$formatters.dollars(item.total ? item.total : 0, true, true, item.currency)))]):_vm._e(),(item.localAmount && item.currency !== _vm.$store.state.defaultCurrency)?_c('div',{staticClass:"font-12 font-gray_70"},[_vm._v(" "+_vm._s(_vm.$formatters.dollars(item.localAmount, true, true, _vm.$store.state.defaultCurrency))+" ")]):_vm._e()]}},{key:"item.category",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCategory(item))+" ")]}},{key:"item.description",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.vendor",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatVendor(item))+" ")]}},{key:"item.paidDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPaid(item))+" ")]}},{key:"item.paid",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatters.yesNoFromBoolean(item.paid))+" ")]}},{key:"item.dueDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDue(item))+" ")]}},{key:"item.dateCreated",fn:function(ref){
			var item = ref.item;
return [(item.dateCreated)?_c('span',[_vm._v(_vm._s(_vm.DateTime.fromISO(item.dateCreated).toLocaleString({month:'short', day: 'numeric'})))]):_c('span',[_vm._v("--")])]}},{key:"item.reimbursable",fn:function(ref){
			var item = ref.item;
return [(item.reimbursable && item.invoiceId)?_c('div',[_c('span',{staticClass:"pointer",staticStyle:{"color":"var(--v-primary-base)"},on:{"click":function($event){$event.stopPropagation();return _vm.routeToInvoice(item)}}},[_vm._v("Included in invoice #"+_vm._s(item.invoiceNumber))])]):(item.reimbursable)?_c('div',[_vm._v("Not yet invoiced")]):_c('div',[_vm._v("No")])]}},{key:"item.clientId",fn:function(ref){
			var item = ref.item;
return [(item.client)?_c('div',{staticClass:"row-format",staticStyle:{"align-items":"center"}},[_c('client-avatar',{attrs:{"client":item.client,"left":""}}),(item.project)?_c('div',[_vm._v(_vm._s(item.project.name))]):_vm._e()],1):_c('div',[_vm._v("--")])]}},{key:"item.statusLabel",fn:function(ref){
			var item = ref.item;
return [_c('div',{staticClass:"row-format align-center"},[_c('div',{staticClass:"brand-semilight font-12",domProps:{"innerHTML":_vm._s(_vm.getStatus(item))}})])]}},{key:"item.edit",fn:function(ref){
			var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":true,"nudge-bottom":36},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c('div',{staticClass:"ml-2"},[(scope.value)?_c('v-btn',_vm._g({staticStyle:{"background-color":"var(--v-primary-base)"},attrs:{"icon":"","rounded":"","color":"primary"}},scope.on),[_c('v-icon',{attrs:{"size":20,"color":"white"}},[_vm._v("$close")])],1):_c('v-btn',_vm._g({attrs:{"icon":"","rounded":""}},scope.on),[_c('v-icon',{attrs:{"size":20}},[_vm._v("$moreVertical")])],1)],1)]}}],null,true)},[_c('v-container',{staticClass:"bg-primary"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('div',[_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$delete")]),_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")],1)],1),_c('div',[_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$edit")]),_vm._v(" "+_vm._s(_vm.$t('vendors.view-details'))+" ")],1)],1)])],1)],1)],1)]}},(!_vm.suppressNew)?{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-5 pointer",on:{"click":function($event){return _vm.$emit('add-new')}}},[_c('v-icon',{staticClass:"mb-1",attrs:{"small":""}},[_vm._v("$plus")]),_vm._v(" Click to add your first expense ")],1)]},proxy:true}:null],null,true)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }