<template>
	<div class="filter-option-box">
		<div class="row-format align-center" @click="toggleExpanded()">
			<div class="font-14">{{ label }}</div>
			<div v-if="!expanded && value && value.length > 0" class="active-count ml-1">{{value.length}}</div>
			<v-icon style="margin-left: auto; margin-top: 1px" small>{{ expanded ? '$arrowUp' : '$arrowDown' }}</v-icon>
		</div>
		<div v-if="expanded">
			<v-checkbox
				dense
				hide-details
				v-model="ourValue"
				v-for="option in optionList"
				:key="option.value"
				:value="option.value"
				@change="updated"
			>
				<template v-slot:label>
					<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
				</template>
			</v-checkbox>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FilterOption',

		props: ['label', 'optionList', 'value'],

		components: {},

		data: function () {
			return {
				ourValue: null,
				expanded: false,
			};
		},

		mounted() {
			this.setOurValue();
		},

		beforeDestroy() {},

		methods: {
			setOurValue: function(){
				if(this.value){
					this.ourValue = JSON.parse(JSON.stringify(this.value))
				}else{
					this.ourValue = []
				}
			},

			updated: function(){
				this.$emit('input',this.ourValue);
				this.$emit('change');
			},

			toggleExpanded: function(){
				this.expanded = !this.expanded;
			}
		},

		watch: {
			value: {
				deep: true,
				handler(val){
					this.ourValue = JSON.parse(JSON.stringify(val));
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.active-count {
		width:14px;
		height: 14px;
		background-color: var(--v-gray_20-base);
		border-radius: 2px;
		font-size: 10px;
		font-family: Inter;
	}
</style>
