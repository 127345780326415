<template>
	<div>

	</div>
</template>

<script>
export default {
	name: "SettingsRouter",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
		let page = localStorage.getItem('PIPELINE_SETTINGS_PAGE');
		if(!page){
			page = 'stages';
		}
		this.$router.push('/pipeline-settings/' + page);
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>