import Stages from "@/modules/pipeline/settings/Stages";
import DefaultToDos from "@/modules/pipeline/settings/DefaultToDos";
import SettingsRouter from "@/modules/pipeline/settings/SettingsRouter";
import Pipeline from "@/modules/leadgen/Pipeline";
import PipeLineV2 from "@/modules/pipeline/PipeLineV2";
import PipelineRouter from "@/modules/pipeline/PipelineRouter";

export default [
	{
		path: '/pipeline',
		name: 'pipelineRouter',
		component: PipelineRouter,
	},
	{
		path: '/pipeline/legacy',
		name: 'legacyPipeline',
		component: Pipeline,
	},
	{
		path: '/pipeline/opportunities',
		name: 'pipeline',
		component: PipeLineV2,
		props: true
	},
	{
		path: '/pipeline/forecast',
		name: 'pipelineForecast',
		component: PipeLineV2,
	},
	{
		path: '/pipeline-settings',
		name: 'settingsRouter',
		component: SettingsRouter,
		title: 'Settings router',
		props: true
	},
	{
		path: '/pipeline-settings/stages',
		name: 'pipelineSettings',
		component: Stages,
		title: 'Stages',
		props: true
	},
	{
		path: '/pipeline-settings/toDos',
		name: 'defaultToDos',
		component: DefaultToDos,
		title: 'To dos',
		props: true
	},

];
