<template>
	<div
		@click="$emit('click', $event)"
		:style="formStyle"
    :id="item.id"
    v-if="isReady"
		:key="refreshKey"
    :class="completed ? 'completed-form-embed' : ''"
	>
		<div v-if="item.discoveryTemplate && schema.length">
			<div v-for="(item, index) in pages[currentPage]" :key="item.id">
				<item-renderer
						ref="item"
						:index="index + ((currentPage + 1) * 1000)"
						:item="item"
						:styles="styles"
						:font="font"
						:button-props="buttonProps"
						:disabled="!clientMode || agreement.fullyExecuted"
						:current-page="currentPage + 1"
						:page-count="pages.length"
						:account-logo="accountLogo"
						:active="false"
						:results="results"
            :known-data="knownData"
						@next-page="nextPage()"
						@previous-page="previousPage()"
						@input="handleInput($event)"
				></item-renderer>
			</div>
			<div v-if="isLastPage">
				<div class="row-format gap-2 pt-2 px-2" :style="`width: 100%; justify-content: ${justification}`">
					<v-btn
							v-if="pages.length > 1"
							class="elevation-0"
							:color="styles.buttonColor"
							:style="`font-size: var(--form-font-size) !important; ${
							buttonProps.block ? 'width: 49%!important;' : ''
						}`"
							:x-small="buttonProps.xSmall"
							:small="buttonProps.small"
							:large="buttonProps.large"
							:x-large="buttonProps.xLarge"
							@click="previousPage"
					>
						<v-icon class="mr-1" :color="styles.buttonTextColor">{{ styles.prevPageIcon }}</v-icon>
						<span :style="`font-family: ${font}; ; text-transform: none; color: ${styles.buttonTextColor}`">{{ styles.prevPageText }}</span>
					</v-btn>
				</div>
			</div>
		</div>
		<div v-else-if="form && schema.length === 0" class="row-format centered" style="height:100%; border: 1px solid var(--v-gray_20-base); min-height: 100px">
			<div class="font-gray_60 row-format align-center">
				<v-icon class="material-symbols-rounded" color="gray_60">warning</v-icon>
				<div>Selected form has no content</div>
			</div>
		</div>
		<div v-else class="row-format centered" style="height:100%; border: 1px solid var(--v-gray_20-base); min-height: 100px">
			<div class="font-gray_60 row-format align-center">
				<v-icon class="material-symbols-rounded" color="gray_60">description</v-icon>
				<div>Please select an embedded form template</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ItemRenderer from '@/modules/forms/v2/schema/ItemRenderer';
	import ResponsiveMixin from "./ResponsiveMixin";
	export default {
		name: 'FormEmbed',

		props: ['agreement','item', 'accountLogo','editMode','clientMode','signer','isMobile','pageWidth'],

		components: { ItemRenderer },

		mixins: [ResponsiveMixin],

		data: function() {
			return {
				currentPage: 0,
				results: [],
        isReady: false,
				refreshKey: 0,
        completed: false,
        knownData: null,
			};
		},

		mounted() {
      this.setKnownData();
      if(this.item.formData) {
				this.formatPreviousAnswers();
			}else{
				this.loadPartialForm();
			}
    },

		beforeDestroy() {},

		methods: {
      setKnownData: function(){
        let signer = this.signer && this.signer.signerType !== "USER" ? this.signer : null

        this.knownData = {
          firstName: signer?.firstName,
          lastName: signer?.lastName,
          email: signer?.email,
          phone: this.agreement?.client?.phone,
          businessName: this.agreement?.client?.name,
          website: this.agreement?.client?.website,
          address1: this.agreement?.client?.address1,
          address2: this.agreement?.client?.address2,
          city: this.agreement?.client?.city,
          locality: this.agreement?.client?.locality,
          postal: this.agreement?.client?.postal,
          country: this.agreement?.client?.country,
        }
      },

      loadPartialForm: function(){
        try {
          let partialResult = localStorage.getItem(this.savedFormKey);
          if (partialResult) {
            this.results.push(...JSON.parse(partialResult));
          }
        }catch(err){
          console.log(err);
        }
        this.isReady = true;
      },

			finalize: function() {
        return new Promise((resolve,reject) => {
					if(this.schema.length === 0){
						resolve();
						return;
					}

          if (!this.doValidation() || this.currentPage < (this.pages.length-1)) {
            document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
            document.getElementById(this.item.id).classList.add("red-border");
            reject();
            return false;
          }else{
            document.getElementById(this.item.id).classList.remove("red-border");
          }

          this.results.sort((a, b) => a.index.toString().localeCompare(b.index.toString()));

          let formResult = {};
          formResult.answers = [];
          let files = [];

          for (let i = 0; i < this.results.length; i++) {
            let item = this.results[i].item;
            let value = this.results[i].value;

            if (item.schemaMapping) {
              formResult[item.schemaMapping] = Array.isArray(value) ? value.join(', ') : value;
              //continue;
            }

            let answer;
            if (item.type === 'FileInput') {
              files.push(...value);
              answer = JSON.stringify(value.map((f) => f.name));
            } else {
              answer = Array.isArray(value) ? value.join(', ') : value;
            }

            formResult.answers.push({
              id: item.id,
              fieldKey: item.fieldName,
              fieldType: item.type,
              question: item.question,
              answer: answer,
            });
          }

          this.item.formData = formResult;
          this.item.fileUploads = files;
          resolve();
        })
			},

			doValidation: function() {
				let invalidCount = 0;
				for (let i = 0; i < this.$refs.item.length; i++) {
					if (!this.$refs.item[i].validate()) {
						invalidCount++;
					}
				}
				return !invalidCount;
			},

			nextPage: function() {
				if (!this.doValidation()) {
					this.errorText = 'Please correct the errors in your form';
					this.showError = true;
					return;
				}
				this.currentPage++;
			},

			previousPage: function() {
				this.currentPage--;
			},

			handleInput: function(event) {
				let ix = this.results.findIndex((r) => r.item.id === event.item.id);
				if (ix > -1) {
					this.results.splice(ix, 1, event);
				} else {
					this.results.push(event);
				}

        try {
          localStorage.setItem(this.savedFormKey, JSON.stringify(this.results));
        }catch(err){
          console.log(err);
        }
			},

			formatPreviousAnswers: function(){
				let items = this.item.discoveryTemplate.schemaV2.filter(this.isInputType);
				let formData = this.item.formData;
				let index = 0;
				let currentPage = 0;

				for(let i=0; i < items.length; i++){
					let item = items[i];

					if(item.type === 'NewPage'){
						index = 0;
						currentPage = currentPage+1;
						continue;
					}

					if(item.type === 'Container'){
						for(let j=0; j < item.columns.length; j++){
							let columnSchemaItems = item.columns[j].items.filter(this.isInputType);
							for(let k=0; k < columnSchemaItems.length; k++){
								let resultIndex = (index++) + ((currentPage + 1) * 1000)
								this.processItem(formData,columnSchemaItems[k],resultIndex);
							}
						}
					}else{
						let resultIndex = (index++) + ((currentPage + 1) * 1000)
						this.processItem(formData,item,resultIndex);
					}
				}
				this.isReady = true;
        this.completed = true;
				this.$nextTick(() => this.refreshKey++);
			},

			processItem(formData,item,resultIndex){
				let value = null;

        let answer = formData.answers.find(a => a.id === item.id);
        if(answer){
          if(item.type === 'Checkbox') {
            value = [];
            if (answer.answer) {
              answer.answer.split(",").forEach(a => value.push(a.trim()));
            }
          }else if(item.type === 'FileInput'){
            //answer.answer = JSON.parse(answer.answer);
            // value = [];
            // for(let i=0; i < answer.answer.length; i++){
            // 	let fileName = answer.answer[i];
            // 	let s3File = this.formSubmission.files.find(f => f.fileName === fileName);
            // 	value.push(s3File);
            // }
          }else {
            value = answer.answer;
          }
        }else if(item.schemaMapping) {
          value = formData[item.schemaMapping];
        }

				this.results.push({
					item: item,
					index: resultIndex,
					value: value,
				});
			},

			isInputType(item){
				let type = item.type;
				return type === 'FileInput' || type === 'Checkbox' || type === 'DateInput' || type === 'Radio' || type === 'Select' || type === 'TextArea' || type === 'TextInput' || type === 'Container' || type === 'NewPage';
			},
		},

		computed: {
      savedFormKey: function(){
        return `form-${this.agreement.id}-${this.item.attachedFormId}`;
      },

			formStyle: function(){
				let style = `margin: ${this.item.padding}px; padding-top: ${this.item.pt}px; padding-bottom: ${this.item.pb}px; padding-left: ${this.padding(this.item.pl)}px; padding-right: ${this.padding(this.item.pr)}px;`

				if(this.item.borderColor){
					style = style + `border-color: ${this.item.borderColor}; `
				}
				if(this.item.borderWidth){
					style = style + `border-style: solid; border-width: ${this.item.borderWidth}px; `
				}
				if(this.item.borderRadius){
					style = style + `border-radius: ${this.item.borderRadius}px; `
				}
				return style;
			},

			isLastPage: function() {
				if (this.currentPage === this.pages.length - 1) {
					return true;
				} else {
					return false;
				}
			},

			pages: function() {
				let pages = [[]];
				for (let i = 0; i < this.schema.length; i++) {
					pages[pages.length - 1].push(this.schema[i]);
					if (this.schema[i].type === 'NewPage') {
						pages.push([]);
					}
				}
				return pages;
			},

			form: function(){
				return this.item.discoveryTemplate;
			},

			styles: function() {
				return this.form ? this.form.formSettingsV2 : {};
			},

			schema: function() {
				return this.form ? this.form.schemaV2 : [];
			},

			font: function(){
				return this.form ? this.form.font : null;
			},

			buttonProps: function() {
				let result = {
					xSmall: this.styles.buttonSize === 'X-Small',
					small: this.styles.buttonSize === 'Small',
					large: this.styles.buttonSize === 'Large',
					xLarge: this.styles.buttonSize === 'X-Large',
					block: this.styles.buttonStyle === 'Block',
				};

				return result;
			},

			justification: function() {
				if (this.styles.submitAlignment === 'JUSTIFIED') {
					return 'space-between';
				} else if (this.styles.submitAlignment === 'LEFT') {
					return 'flex-start';
				} else if (this.styles.submitAlignment === 'RIGHT') {
					return 'flex-end';
				} else {
					return 'center';
				}
			},
		},
	};
</script>

<style lang="scss">
  .completed-form-embed {
    .v-input {
      textarea {
        color: var(--v-black-base) !important;
      }

      input {
        color: var(--v-black-base) !important;
      }

      .v-select__selection--disabled {
        color: var(--v-black-base) !important;
      }

      .v-label--is-disabled {
        color: var(--v-black-base) !important;
      }
    }
  }
</style>
