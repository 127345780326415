<template>
	<div
		:style="`font-family: Inter!important; min-width: 300px; background-color: var(--v-white-base); width:${menuWidth}px;`"
		class="column-format gap-3 line-item-editor"
	>
		<div class="row-format px-4">
			<v-icon class="ml-auto pointer pt-2" color="gray_70" @click="$emit('cancel')">close</v-icon>
		</div>
		<div class="row-format gap-2 px-4">
			<div class="column-format" style="width:60%">
				<div class="column-format gap-2" style="height: fit-content; width:100%">
					<editor
							v-if="showEditor"
						v-model="item.description"
						:inline="false"
						:init="mceConfig"
						class="no-wrap-editor"
						style="width: 100%"
					>
					</editor>
					<project-select
						class="mt-2 pointer"
						:clientId="invoice.clientId"
						:item="item"
						:use-button="true"
						:editable="!projectDisabled"
						@select-project="assignProjectToItem($event)"
					/>
				</div>
			</div>
			<div class="column-format" style="width: 40%">
				<div class="column-format gap-2" style="height: fit-content; width:100%">
					<v-text-field
						type="number"
						dense
						hide-details
						outlined
						persistent-placeholder
						v-model="item.qty"
						@change="qtyChanged"
						label="Quantity"
					></v-text-field>
					<v-text-field
						type="number"
						dense
						hide-details
						outlined
						persistent-placeholder
						:prefix="$formatters.currencySymbol(invoice.currency)"
						v-model="item.price"
						label="Rate"
					></v-text-field>
					<v-checkbox
						dense
						hide-details
						persistent-placeholder
						class="mt-n2"
						v-model="item.taxable"
						label="Taxable"
					></v-checkbox>
				</div>
			</div>
		</div>
		<div class="row-format centered py-3 gap-2" style="border-top: 1px solid var(--v-gray_30-base)">
			<v-btn icon @click="$emit('delete', lineItem)"
				><v-icon size="22" color="gray_80" class="material-symbols-rounded">delete</v-icon></v-btn
			>
			<v-btn class="super-action" style="min-width: 150px" @click="save">{{ $t('global.ok') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import editor from '@tinymce/tinymce-vue';
	import ProjectSelect from '@/modules/invoices/InvoiceRenderer/ProjectSelect';
	export default {
		name: 'LineItemEditor',

		props: ['invoice', 'lineItem', 'menuWidth'],

		components: { ProjectSelect, editor },

		data: function() {
			return {
				showEditor: false,
				item: { ...this.lineItem },
				quantityUpdated: false,
				mceConfig: {
					menubar: false,
					inline: false,
					paste_as_text: false,
					browser_spellcheck: true,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Description',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: '',
					plugins: ['autolink', 'paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: false,
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					default_link_target: '_blank',
					toolbar: [
						'fontsizeselect | ' +
							'bold italic underline | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist',
					],
					content_style:
						"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",
				},
			};
		},

		mounted() {
			this.$nextTick(() => this.showEditor = true);
		},

		beforeDestroy() {},

		methods: {
			save: function() {
				this.$emit('save', this.item);
			},

			assignProjectToItem(project) {
				if (project) {
					this.item.projectId = project.id;
					this.item.projectName = project.name;
				} else {
					this.item.projectId = null;
					this.item.projectName = null;
				}
			},

			qtyChanged: function() {
				this.quantityUpdated = true;
			},
		},

		computed: {
			projectDisabled: function() {
				return (
					this.lineItem.type === 'RETAINER' ||
					this.lineItem.type === 'HOURS' ||
					this.lineItem.type === 'PROJECT' ||
					this.lineItem.type === 'OVERAGE'
				);
			},

			qtyDisabled: function() {
				return this.lineItem.type === 'RETAINER' || this.lineItem.type === 'HOURS' || this.lineItem.type === 'OVERAGE';
			},
		},
	};
</script>

<style lang="scss"></style>
