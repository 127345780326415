<template>
	<div>
		<audio src="/media/ringback/US_ringback_tone.mp3" loop="true" style="display: none" id="Ringback"></audio>
	</div>
</template>

<script>
	import {Device} from '@twilio/voice-sdk';
	import TwilioService from "@/modules/communicator/realtime/twilio/TwilioService";
	import ConfirmModal from "@/components/ConfirmModal";
	import {v4 as uuid4} from "uuid";

	export default {
		name: 'TwilioSoftPhone',

		props: [],

		components: {},

		data: function() {
			return {
				twilioService: new TwilioService(),
				device: null,
				activeCall: null,
				cancelId: uuid4()
			};
		},

		mounted() {
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			stopRingBack: function(){
				document.getElementById("Ringback").pause();
				document.getElementById("Ringback").currentTime = 0;
			},

			startRingBack: function(){
				document.getElementById("Ringback").play();
			},

			muteCall(){
				if(this.activeCall){
					this.activeCall.mute(true);
				}
			},

			unMuteCall(){
				if(this.activeCall){
					this.activeCall.mute(false);
				}
			},

			initialize: function(){
				this.twilioService.getDeviceToken().then((res) => {
					this.device = new Device(res.data);
					this.device.register();
					this.device.on('registered',() => this.deviceRegistered());
					this.device.on('tokenWillExpire',() => this.tokenWillExpire());
					this.device.on('incoming', (call) => this.incomingCall(call));
					this.device.on('error', (twilioError) => {
						console.log('An error has occurred: ', twilioError);
						this.tokenWillExpire();
					});
				});
			},

			deviceRegistered: function(){
				console.log('device is registered');
			},

			tokenWillExpire: function(){
				console.log('Refreshing token');
				this.twilioService.getDeviceToken().then((res) => {
					this.device.updateToken(res.data);
				});
			},

			incomingCall: function(call){
				if(this.activeCall){
					call.ignore();
				}

				this.activeCall = call;
				this.setupCallEvents();

				let phone = call.customParameters.get("from");
				let contact = this.$store.getters.getContactByPhone(phone);
				let whoIsCalling;

				if(contact){
					whoIsCalling = contact.fullName + " (" + phone + ")";
				}else{
					whoIsCalling = phone;
				}

				let binding = {
					severity: 'success',
					icon: '$phone',
					headingText: 'Incoming call',
					bodyText: `Call from ${whoIsCalling} - do you wish to accept?`,
					timeOut: 30,
					yesText: 'Answer',
					noText: 'Ignore',
					persistent: true,
					forceTimeoutEventId: this.cancelId,
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding, false, false, true).then((res) => {
					if(res){
						call.accept();
					}else{
						call.ignore();
					}
				});
			},

			makeCall: function(phoneToDial){
				this.device.connect({params:{To: phoneToDial}}).then((call) => {
					this.activeCall = call;
					this.$emit('call-active',true);
					this.$emit('call-up',this.activeCall);
					this.setupCallEvents();
					this.startRingBack();
				});
			},

			setupCallEvents: function(){
				this.activeCall.on('disconnect',() => {
					this.$emit('call-ringing',false);
					this.$emit('call-active',false);
					this.activeCall = null;
					this.$emit('call-up',null);
					this.stopRingBack();
				});

				this.activeCall.on('accept',() => {
					this.$emit('call-ringing',false);
					this.$emit('call-active',true);
					this.$emit('call-up',this.activeCall);
					this.stopRingBack();
				});

				this.activeCall.on('cancel',() => {
					this.$emit('call-ringing',false);
					this.$emit('call-active',false);
					this.$store.state.eventBus.$emit(this.cancelId);
					this.stopRingBack();
				})
			},

			hangupCall: function(){
				this.device.disconnectAll();
			},

			dtmf: function(digit){
				if(this.activeCall){
					let dtmf;
					switch(digit){
						case "star": {dtmf = '*'; break;}
						case "pound": {dtmf = '#'; break;}
						default: {dtmf = digit;}
					}
					this.activeCall.sendDigits(dtmf);
				}
			},

			startRecording: function(){
				return this.toggleRecording(true);
			},

			pauseRecording: function(){
				return this.toggleRecording(false);
			},

			toggleRecording(recordingEnabled){
				if(this.activeCall) {
					return this.twilioService.toggleRecording(this.activeCall.parameters.CallSid, recordingEnabled, this.activeCall._direction);
				}else{
					return new Promise.reject(new Error('no active call'));
				}
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
