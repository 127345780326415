<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.quickbooks.desc') }}</div>
				<div v-if="paidAccount">
					<connected v-if="isConnected"></connected>
					<div v-else class="font-primary brand-medium pointer" @click="initializeOAuth">Connect Quickbooks Online</div>
				</div>
				<div v-else>
					<div class="font-14 pointer" @click="$router.push('/subscription')"><v-icon small color="warning">$alert</v-icon>This integration is not available on the Essentials plan.  If you would like to connect your Quickbooks account, please upgrade to Pro or Teams.</div>
				</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div class="mb-6 font-14 py-2 px-2" style="background-color: var(--v-white-base); border-radius: 8px">
				<div class="mb-2"><span class="brand-medium">Quickbooks company:</span> {{ quickbooks.companyInfo.companyName }}</div>
				<v-autocomplete
					hide-details
					persistent-placeholder
					class="h-outline"
					label="Income account"
					:items="revenueAccounts"
					item-text="name"
					item-value="id"
					return-object
					v-model="incomeAccount"
					@change="saveConfig"
				></v-autocomplete>
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Payment processing expense account"
						:items="expenseAccounts"
						item-text="name"
						item-value="id"
						return-object
						v-model="expenseAccount"
						@change="saveConfig"
				></v-autocomplete>
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Un-deposited funds account"
						:items="assetAccounts"
						item-text="name"
						item-value="id"
						return-object
						v-model="unDepositedFundsAccount"
						@change="saveConfig"
				></v-autocomplete>
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Default sales tax account"
						:items="taxRates"
						item-text="name"
						item-value="id"
						return-object
						v-model="salesTaxAccount"
						@change="saveConfig"
				></v-autocomplete>
			</div>
			<v-btn class="secondary-action mr-1" @click="confirmDisconnect"><v-icon small>$delete</v-icon>Disconnect</v-btn>
			<v-btn class="secondary-action mr-1" @click="confirmReset"><v-icon small>lock_reset</v-icon>Reset integration</v-btn>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import QuickbooksService from '@/modules/integrations/quickbooks/QuickbooksService';

	export default {
		name: 'Quickbooks',

		props: [],

		components: { Connected },

		data: function () {
			return {
				quickbooksService: new QuickbooksService(),
				isConnected: false,
				expanded: false,
				isReady: false,
				quickbooks: null,
				chartOfAccounts: [],
				taxRates: [],
				incomeAccount: null,
				expenseAccount: null,
				salesTaxAccount: null,
				unDepositedFundsAccount: null
			};
		},

		mounted() {
			this.checkConnected();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.isConnected = false;
				this.checkConnected();
			},

			checkConnected: function () {
				this.quickbooksService
					.getConnection()
					.then((res) => {
						if (res.data) {
							this.isConnected = true;
							this.quickbooks = res.data;
							this.incomeAccount = this.quickbooks.companyInfo.incomeAccount;
							this.expenseAccount = this.quickbooks.companyInfo.expenseAccount;
							this.salesTaxAccount = this.quickbooks.companyInfo.salesTaxAccount;
							this.unDepositedFundsAccount = this.quickbooks.companyInfo.unDepositedFundsAccount;
						} else {
							this.isConnected = false;
						}
					})
					.finally(() => (this.isReady = true));
			},

			saveConfig: function(){
				this.quickbooksService.saveSettings({
					companyInfo: {
						incomeAccount: this.incomeAccount,
						expenseAccount: this.expenseAccount,
						salesTaxAccount: this.salesTaxAccount,
						unDepositedFundsAccount: this.unDepositedFundsAccount
					}
				}).then((res) => this.quickbooks = res.data)
			},

			getChartOfAccounts: function () {
				this.quickbooksService.getChartOfAccounts().then((res) => {
					this.chartOfAccounts.splice(0, this.chartOfAccounts.length);
					this.chartOfAccounts.push(...res.data);
				});
			},

			getSalesTaxRates: function () {
				this.quickbooksService.getTaxRates().then((res) => {
					this.taxRates.splice(0, this.taxRates.length);
					this.taxRates.push(...res.data);
				});
			},

			initializeOAuth: function () {
				let redirectUri = document.location.protocol + '//' + document.location.host + '/integrations/quickbooks/oauth';
				this.quickbooksService.initializeOAuth(redirectUri).then((res) => {
					window.location.href = res.data;
				});
			},

			confirmReset: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to reset your Quickbooks integration? You should only do this if you have connected a new Quickbooks account.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.quickbooksService.reset().then(() => {
							this.$store.commit('info','Quickbooks integration has been reset.')
						}).finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			confirmDisconnect: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your Quickbooks account?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.quickbooksService.revoke().then(() => {
							this.isConnected = false;
							this.expanded = false;
						});
					}
				});
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			expandedAndConnected: function () {
				return this.expanded && this.isConnected;
			},

			revenueAccounts: function () {
				return this.chartOfAccounts.filter((a) => a.classification === 'REVENUE' || a.classification === 'ASSET');
			},

			expenseAccounts: function () {
				return this.chartOfAccounts.filter((a) => a.classification === 'EXPENSE');
			},

			assetAccounts: function () {
				return this.chartOfAccounts.filter((a) => a.classification === 'ASSET');
			},
		},

		watch: {
			expandedAndConnected: function (val) {
				if (val) {
					this.getChartOfAccounts();
					this.getSalesTaxRates();
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
