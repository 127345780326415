<template>
	<div id="deliverable-table-wrapper">
		<input id="copyToClip" name="copyToClip" value="" type="hidden" />
		<table id="deliverable-table" class="mt-n4 table">
			<template v-for="(group, index) in deliverableGroupings">
				<tbody :key="index">
					<tr v-if="group.groupKey">
						<td
							:colspan="(singleProjectMode ? 9 : 10) + customFields.length"
							:style="index > 0 ? 'padding-top: 36px' : ''"
							class="group-heading"
						>
							{{ group.groupLabel }}
						</td>
					</tr>
					<tr>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="!singleProjectMode">Project</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`"><div class="pl-1">Name</div></td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Status</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-for="field in customFields" :key="field.id">{{ field.name }}</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Priority</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Assigned</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Start</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Due</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Comments</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">Subtasks</td>
						<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">&nbsp;</td>
					</tr>
					<tr v-for="deliverable in group.deliverables" :key="deliverable.id" class="font-14 text-left">
						<td v-if="!singleProjectMode">
							<div class="row-format align-center" @click="editDeliverable(deliverable)">
								<client-avatar
									:client="deliverable.client"
									:small="true"
									v-if="deliverable.client"
								></client-avatar>
								<v-icon color="gray_30" size="24" v-else>check_box_outline_blank</v-icon>
								<div class="ml-2 pointer">{{ deliverable.project ? deliverable.project.name : '' }}</div>
							</div>
						</td>
						<td>
							<div class="row-format align-center">
							<v-icon
									color="success"
									small
									v-if="deliverable.invoiceId"
									v-tippy="{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
							>attach_money</v-icon>
							<div contenteditable="true" class="pa-1" @blur="setName(deliverable, $event)">
								{{ deliverable.name }}
							</div>
							</div>
						</td>
						<td>
							<div class="row-format align-center gap-2">
								<v-menu :close-on-click="true" :close-on-content-click="true">
									<template v-slot:activator="{ on }">
										<div
											v-on="on"
											class="deliverableStatus row-format align-center pointer"
											:style="`--status-color:${deliverable.status.hexColor}`"
										>
											<div class="statusBox"></div>
											<div class="text-no-wrap">{{ deliverable.status.label }}</div>
										</div>
									</template>
									<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
										<div
											v-for="status in $store.state.deliverableStatusList.statusList"
											:key="status.id"
											class="py-1 pointer"
											@click="setStatus(deliverable, status)"
										>
											<div
												class="deliverableStatus row-format align-center highlight-hover"
												:style="`--status-color:${status.hexColor}`"
											>
												<div class="statusBox"></div>
												<div>{{ status.label }}</div>
											</div>
										</div>
									</div>
								</v-menu>
								<v-icon
									v-if="deliverable.archived"
									class="ml-auto"
									size="20"
									color="gray_70"
									v-tippy="{ content: 'Archived' }"
									>lock</v-icon
								>
							</div>
						</td>
						<td v-for="field in customFields" :key="field.id">
							<custom-field
								:field="field"
								:value="getCustomValue(deliverable, field.id)"
								@change="handleCustomFieldChange(deliverable, $event)"
							></custom-field>
						</td>
						<td>
							<input
								type="number"
								class="numberInput"
								oninput="this.value=(parseInt(this.value)||0)"
								v-model="deliverable.priority"
								step="1"
								@change="setPriority(deliverable)"
							/>
						</td>
						<td>
							<div style="width: fit-content">
								<v-menu :close-on-click="true" :close-on-content-click="false">
									<template v-slot:activator="{ on }">
										<div class="ml-auto pointer" v-on="on">
											<div v-if="deliverable.assignedToList.length" class="row-format align-center">
												<assigned-user
													v-on="on"
													v-for="(assignedTo, index) in deliverable.assignedToList"
													:key="assignedTo"
													:show-name="false"
													:small="true"
													:assigned-to="assignedTo"
													:style="
														`${
															index > 0 ? 'margin-left: -6px' : ''
														}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
													"
												></assigned-user>
											</div>
											<div v-else>
												--
											</div>
										</div>
									</template>
									<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
										<div
											v-for="user in usersForProject(deliverable.projectId)"
											:key="user.userId"
											class="row-format align-center text-left py-1 pointer"
										>
											<input
												@change="setAssignedToList(deliverable)"
												style="width: 20px; height: 20px; border-radius: 4px"
												type="checkbox"
												:value="user.userId"
												v-model="deliverable.assignedToList"
											/>
											<assigned-user
												class="mx-2"
												v-if="user.userId"
												:assigned-to="user.userId"
												:show-name="true"
											></assigned-user>
										</div>
									</div>
								</v-menu>
							</div>
						</td>
						<td>
							<date-selector
								:micro="true"
								micro-font="font-14"
								:date="deliverable.startDate"
								label="&nbsp;"
								@change="setStartDate(deliverable, $event)"
							></date-selector>
						</td>
						<td>
							<date-selector
								:micro="true"
								micro-font="font-14"
								:date="deliverable.dueDate"
								label="&nbsp;"
								@change="setDueDate(deliverable, $event)"
							></date-selector>
						</td>
						<td>
							<v-menu :close-on-click="true" :close-on-content-click="false">
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer" style="position: relative" v-if="deliverable.comments.length">
										<v-icon color="gray_70" size="20">comment</v-icon>
										<div
											class="row-format centered brand-bold"
											style="
												position: absolute;
												top: -6px;
												left: 12px;
												width: 16px;
												height: 16px;
												border-radius: 10px;
												color: var(--v-white-base);
												background-color: var(--v-secondary-base);
											"
										>
											<div class="font-12">{{ deliverable.comments.length }}</div>
										</div>
									</div>
									<div v-on="on" class="pointer" v-else>&nbsp;</div>
								</template>
								<div
									style="
										background-color: var(--v-white-base);
										min-width: 400px;
										max-width: 400px;
										min-height: 200px;
									"
									class="pa-4"
								>
									<activity
										:deliverable="deliverable"
										:project="deliverable.project"
										:client="deliverable.client"
										:user-list="usersByProject[deliverable.projectId]"
									></activity>
								</div>
							</v-menu>
						</td>
						<td>
							<v-menu :close-on-click="true" :close-on-content-click="false">
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer">
										<div v-if="deliverable.tasks.length">{{ getTaskCountLabel(deliverable.tasks) }}</div>
										<div v-else>&nbsp;</div>
									</div>
								</template>
								<div
									class="pa-4"
									style="min-width: 300px; max-width: 400px; background-color: var(--v-white-base)"
								>
									<tasks
										v-model="deliverable.tasks"
										:detail-view="true"
										@change="setTasks(deliverable, $event)"
									></tasks>
								</div>
							</v-menu>
						</td>
						<td>
							<div class="row-format centered">
								<v-btn icon @click="$emit('edit-deliverable', deliverable)">
									<v-icon class="material-symbols-rounded" size="20">edit</v-icon>
								</v-btn>
								<v-menu :close-on-content-click="true" :close-on-click="true" bottom nudge-bottom="30">
									<template v-slot:activator="scope">
										<v-btn icon v-on="scope.on" class="">
											<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
										</v-btn>
									</template>
									<div class="more-menu">
										<div class="more-menu-item" @click="$emit('edit-deliverable', deliverable)">
											View details
										</div>
										<div class="more-menu-item" @click="getShareLink(deliverable)">Share</div>
										<div class="more-menu-item" @click="confirmDuplicate(deliverable)">Duplicate</div>
										<div class="more-menu-item" @click="toggleArchive(deliverable)">
											{{ deliverable.archived ? 'Unarchive' : 'Archive' }}
										</div>
										<div class="more-menu-item" @click="confirmDeleteDeliverable(deliverable)">Delete</div>
									</div>
								</v-menu>
							</div>
						</td>
					</tr>
					<tr>
						<td colspan="10" style="border:none" class="footer">
							<div class="pointer" style="width: fit-content" @click="addDeliverable(group)">+ Add task</div>
						</td>
					</tr>
				</tbody>
			</template>
		</table>
	</div>
</template>

<script>
	import KanbanMixin from './ProjectMixin';
	import DateTime from '@/modules/utils/HDateTime';
	import ClientAvatar from '@/components/ClientAvatar';
	import AssignedUser from '@/components/AssignedUser';
	import DateSelector from '@/components/DateSelector';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import Activity from '@/modules/projects/deliverable/Activity';
	import DeliverableMixin from '@/modules/projects/deliverable/DeliverableMixin';
	import ConfirmModal from '@/components/ConfirmModal';
	import CustomField from '@/components/CustomField';

	export default {
		name: 'DeliverableList',
		mixins: [KanbanMixin, DeliverableMixin],

		props: [
			'deliverables',
			'allProjects',
			'projects',
			'updateFlag',
			'columns',
			'filter',
			'isCollaborator',
			'hasProjects',
			'currentView',
			'headerHeight',
			'tableSort',
			'singleProjectMode',
		],

		components: {
			CustomField,
			Activity,
			Tasks,
			DateSelector,
			AssignedUser,
			ClientAvatar,
		},

		data: function() {
			return {
				deliverableService: new ProjectDeliverableService(),
				expanded: {},
				viewChange: 0,
				dragDeliverableId: null,
				isReady: false,
				DateTime: DateTime,
				startTime: 0,
			};
		},

		beforeMount(){
			this.startTime = Date.now();
		},

		mounted() {
			console.log('DeliverableList mounted in',Date.now()-this.startTime);
			this.$store.commit('stopLoading')
			this.$track.record('page-view', { module: 'project-list' });
			this.$nextTick(() => this.setupResizer());
		},

		methods: {
			setupResizer: function() {
				// Query the table
				const table = document.getElementById('deliverable-table');

				// Query all headers
				const cols = table.querySelectorAll('th');

				const createResizableColumn = function(col, resizer) {
					// Track the current position of mouse
					let x = 0;
					let w = 0;

					const mouseDownHandler = function(e) {
						// Get the current mouse position
						x = e.clientX;

						// Calculate the current width of column
						const styles = window.getComputedStyle(col);
						w = parseInt(styles.width, 10);

						// Attach listeners for document's events
						document.addEventListener('mousemove', mouseMoveHandler);
						document.addEventListener('mouseup', mouseUpHandler);
						resizer.classList.add('resizing');
					};

					const mouseMoveHandler = function(e) {
						// Determine how far the mouse has been moved
						const dx = e.clientX - x;

						// Update the width of column
						col.style.width = `${w + dx}px`;
					};

					// When user releases the mouse, remove the existing event listeners
					const mouseUpHandler = function() {
						document.removeEventListener('mousemove', mouseMoveHandler);
						document.removeEventListener('mouseup', mouseUpHandler);
						resizer.classList.remove('resizing');
					};

					resizer.addEventListener('mousedown', mouseDownHandler);
				};

				// Loop over them
				[].forEach.call(cols, function(col) {
					// Create a resizer element
					const resizer = document.createElement('div');
					resizer.classList.add('resizer');

					// Set the height
					resizer.style.height = `${col.offsetHeight}px`;

					// Add a resizer element to the column
					col.appendChild(resizer);

					// Will be implemented in the next section
					createResizableColumn(col, resizer);
				});
			},

			formatDate: function(date) {
				if (date) {
					return DateTime.fromISO(date).toLocaleString({ month: 'short', day: 'numeric' });
				} else {
					return '--';
				}
			},

			getTaskCountLabel: function(tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					for (let i = 0; i < tasks.length; i++) {
						if (tasks[i].complete) {
							completedCount++;
						}
					}

					return `${completedCount}/${taskCount}`;
				}
			},

			getStyleVal: function(elm, css) {
				return window.getComputedStyle(elm, null).getPropertyValue(css);
			},

			addDeliverable: function(group) {
				let defaults = {
					id: null,
					defaultStatusId: this.$store.state.deliverableStatusList.statusList[0].id,
				};

				if (group.groupKey === 'projectId') {
					let project = this.projects.find((p) => p.id === group.groupValue);
					defaults.project = project;
					defaults.client = project.client;
				} else if (group.groupKey === 'clientId') {
					defaults.client = this.$store.getters.getClientById(group.groupValue);
					defaults.project = this.projects.find((p) => p.clientId === group.groupValue);
				} else if (group.groupKey === 'statusId') {
					defaults.defaultStatusId = group.groupValue;
				} else if (group.groupKey === 'assignedTo') {
					defaults.defaultAssignedTo = group.groupValue;
				}

				this.$emit('add-deliverable-defaults', defaults);
			},

			editDeliverable: function(deliverable) {
				this.$emit('edit-deliverable', deliverable);
			},

			addProjectDeliverable: function(project) {
				this.$emit('add-project-deliverable', project);
			},

			setName: function(deliverable, event) {
				if (event.target && event.target.innerText) {
					deliverable.name = event.target.innerText;
					event.target.innerText = deliverable.name;
					this.setPropertyAndUpdate(deliverable, 'name', deliverable.name);
				}
			},

			setStatus: function(deliverable, status) {
				this.setPropertyAndUpdate(deliverable, 'statusId', status.id);
			},

			toggleArchive: function(deliverable) {
				this.setPropertyAndUpdate(deliverable, 'archived', !deliverable.archived);
			},

			setStartDate: function(deliverable, date) {
				this.setPropertyAndUpdate(deliverable, 'startDate', date);
			},

			setDueDate: function(deliverable, date) {
				this.setPropertyAndUpdate(deliverable, 'dueDate', date);
			},

			setTasks: function(deliverable, tasks) {
				this.setPropertyAndUpdate(deliverable, 'tasks', tasks);
			},

			setPriority: function(deliverable) {
				this.setPropertyAndUpdate(deliverable, 'priority', deliverable.priority);
			},

			getCustomValue: function(deliverable, id) {
				let value = deliverable.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(deliverable, value) {
				let ix = deliverable.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						deliverable.customValues.splice(ix, 1, value);
					} else {
						deliverable.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					deliverable.customValues.push(value);
				}
				this.deliverableService.updateDeliverable(deliverable.id, deliverable);
			},

			setAssignedToList: function(deliverable) {
				this.setPropertyAndUpdate(deliverable, 'assignedToList', deliverable.assignedToList);
			},

			setPropertyAndUpdate: function(deliverable, property, value) {
				let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);

				if (ix > -1) {
					let d = this.deliverables[ix];
					d[property] = value;
					this.deliverables.splice(ix, 1, d);
				}

				const patch = [{ op: 'replace', path: `/${property}`, value: value }];
				this.deliverableService.patchDeliverable(deliverable.id, patch).catch((err) => {
					console.log(err);
				});
			},

			incrementView: function() {
				this.viewChange++;
			},

			sortByStatusAndDueDateAndName: function(a, b) {
				let result = this.sortByStatus(a, b);
				if (result === 0) {
					result = this.sortByDueDate(a, b);
					if (result === 0) {
						result = this.sortByName(a, b);
					}
				}
				return result;
			},

			sortByNameAndDueDateAndStatus: function(a, b) {
				let result = this.sortByName(a, b);
				if (result === 0) {
					result = this.sortByDueDate(a, b);
					if (result === 0) {
						result = this.sortByStatus(a, b);
					}
				}
				return result;
			},

			sortByDueDateAndNameAndStatus: function(a, b) {
				let result = this.sortByDueDate(a, b);
				if (result === 0) {
					result = this.sortByName(a, b);
					if (result === 0) {
						result = this.sortByStatus(a, b);
					}
				}
				return result;
			},

			sortByDueDateAndName: function(a, b) {
				let result = this.sortByDueDate(a, b);
				if (result == 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByStatus: function(a, b) {
				let ai = this.columns.findIndex((c) => c.id === a.statusId);
				let bi = this.columns.findIndex((c) => c.id === b.statusId);

				if (ai < bi) {
					return -1;
				} else if (ai > bi) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByDueDate: function(a, b) {
				if (a.dueDate && b.dueDate) {
					return a.dueDate.localeCompare(b.dueDate);
				} else if (a.dueDate) {
					return -1;
				} else if (b.dueDate) {
					return 1;
				} else {
					return 0;
				}
			},

			usersForProject: function(projectId) {
				let project = this.allProjects.find((p) => p.id === projectId);
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					let simple = { firstName: user.firstName, lastName: user.lastName, userId: user.userId, email: user.email };

					if (user.userType === 'OWNER' || user.userType === 'FULL_USER' || user.userType === 'IMPLEMENTER') {
						users.push(simple);
					}else if(user.userType === 'RESTRICTED_USER' && user.featureAccess && user.featureAccess.projects){
						users.push(simple);
					} else if (user.userType === 'COLLABORATOR' && project) {
						for (let j = 0; j < user.projectAccess.projects.length; j++) {
							if (user.projectAccess.projects[j].projectId === project.id) {
								users.push(simple);
								break;
							}
						}
					}
				}

				if (project && project.portalAccess === 'Full access') {
					this.$store.state.contacts
						.filter((c) => c.clientId === project.clientId && c.portalAccess)
						.forEach((c) => {
							users.push({ firstName: c.firstName, lastName: c.lastName, userId: c.clientPortalUserId });
						});
				}

				return users;
			},

			getGroupings: function() {
				let result = [];
				if (!this.filter.group || this.filter.group === 'None') {
					result.push({
						groupKey: null,
						groupValue: null,
						groupLabel: null,
						deliverables: [],
					});
				} else if (this.filter.group === 'Status') {
					this.$store.state.deliverableStatusList.statusList.forEach((s) => {
						result.push({
							groupKey: 'statusId',
							groupValue: s.id,
							groupLabel: s.label,
							deliverables: [],
						});
					});
				} else if (this.filter.group === 'Client') {
					this.$store.getters.clients.forEach((c) => {
						result.push({
							groupKey: 'clientId',
							groupValue: c.id,
							groupLabel: c.name,
							deliverables: [],
						});
					});

					result.push({
						groupKey: 'clientId',
						groupValue: null,
						groupLabel: '[No client]',
						deliverables: [],
					});
				} else if (this.filter.group === 'Project') {
					let projects = [...this.deliverables.map((d) => d.project)];
					projects = projects.filter((p) => p);
					projects = projects.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
					projects.sort((a, b) => a.name.localeCompare(b.name));
					projects.forEach((p) => {
						result.push({
							groupKey: 'projectId',
							groupValue: p.id,
							groupLabel: p.name,
							deliverables: [],
						});
					});

					result.push({
						groupKey: 'projectId',
						groupValue: null,
						groupLabel: '[No project]',
						deliverables: [],
					});
				} else if (this.filter.group.startsWith('Custom:')) {
					let id = this.filter.group.split(':')[1];
					let field = this.$store.state.deliverableFields.fields.find((f) => f.id === id);
					if (field) {
						field.options.forEach((o) => {
							result.push({
								groupKey: `${this.filter.group}`,
								groupValue: o,
								groupLabel: o,
								deliverables: [],
							});
						});
						result.push({
							groupKey: `${this.filter.group}`,
							groupValue: null,
							groupLabel: `[No ${field.name}]`,
							deliverables: [],
						});
					}
				}

				return result;
			},

			confirmDeleteDeliverable: function(deliverable) {
				this.$emit('confirm-delete', deliverable);
			},

			confirmDuplicate: function(deliverable) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to duplicate this deliverable?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.processDuplicateDeliverable(deliverable).then((res) => {
							this.deliverables.push(res.data);
						});
					}
				});
			},

			archiveDeliverable: function() {},

			getShareLink: function(deliverable) {
				let shareLink = this.getDeliverableShareLink(deliverable);
				//JavaScript magic here to copy the link out of the hidden text field
				let copyText = document.getElementById('copyToClip');
				copyText.type = 'text';
				copyText.value = shareLink;
				copyText.select();
				document.execCommand('copy');
				copyText.type = 'hidden';

				this.$store.commit('info', 'Copied to clipboard!');
			},
		},

		computed: {
			customFields: function() {
				return this.$store.state.deliverableFields.fields.filter((f) => f.showOnCard);
			},

			usersByProject: function() {
				let result = {};

				for (let i = 0; i < this.allProjects.length; i++) {
					result[this.allProjects[i].id] = this.usersForProject(this.projects[i]);
				}

				return result;
			},

			deliverableGroupings: function() {
				let deliverables = JSON.parse(JSON.stringify(this.deliverables));
				let fields = this.$store.state.deliverableFields.fields;

				deliverables.forEach((r) => {
					r.status = this.$store.getters.getDeliverableStatusById(r.statusId);
					r.events = [];
					r.clientId = r.client ? r.client.id : null;
					r.assignedTo = r.assignedTo > 0 || r.assignedTo < 0 ? r.assignedTo : null;

					fields.forEach((f) => {
						let val = r.customValues.find((c) => c.fieldId === f.id);
						r[`Custom:${f.id}`] = val ? val.value : null;
					});
				});

				let groupings = this.getGroupings();

				groupings.forEach((g) => {
					g.deliverables.push(...deliverables.filter((d) => !g.groupKey || d[g.groupKey] === g.groupValue));
				});

				let result = groupings.filter((g) => g.deliverables.length);

				return result;
			},
		},

		watch: {
			statusFilter: function() {
				setTimeout(this.incrementView, 20);
			},
		},
	};
</script>

<style lang="scss">
	.table th {
		position: relative;
	}

	.resizer {
		/* Displayed at the right side of column */
		position: absolute;
		top: 0;
		right: 0;
		width: 5px;
		cursor: col-resize;
		user-select: none;
	}

	.resizer:hover,
	.resizing {
		background-color: var(--v-primary-base);
	}
</style>

<style scoped lang="scss">
	#deliverable-table {
		text-align: left;
		border-collapse: collapse;
		width: 100%;

		.heading {
			padding: 8px;
			font-size: 12px;
			font-weight: 600;
			margin: 0px;
			border-bottom: 1px solid var(--v-gray_20-base);
			border-right: 1px solid var(--v-gray_20-base);
			background-color: var(--v-gray_10-base);

			&:first-of-type {
				padding-left: 20px !important;
			}

			&:last-of-type {
				padding-right: 12px !important;
			}
		}

		.heading-dark {
			background-color: var(--v-gray_20-base)!important;
			border-bottom: 1px solid var(--v-gray_10-base);
			border-right: 1px solid var(--v-gray_10-base);
		}

		.group-heading {
			color: var(--v-primary-base);
			font-weight: 600;
			&:hover {
				background-color: var(--v-white-base);
			}
		}

		.footer {
			color: var(--v-gray_70-base);
			font-weight: 600;
			font-size: 14px;
			&:hover {
				background-color: var(--v-white-base);
			}
		}

		thead {
			tr {
				th {
					padding: 8px;
					font-size: 12px;
					font-weight: 600;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_20-base);
					border-right: 1px solid var(--v-gray_20-base);

					&:first-of-type {
						padding-left: 20px !important;
					}

					&:last-of-type {
						padding-right: 12px !important;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_20-base);
					border-right: 1px solid var(--v-gray_20-base);

					&:first-of-type {
						padding-left: 20px !important;
					}
					&:last-of-type {
						padding-right: 12px !important;
					}
				}

				&:hover {
					td {
						background-color: var(--v-gray_10-base);
					}
				}
			}
		}
	}

	.border-right {
		border-right: 1px solid var(--v-gray_30-base);
	}

	.deliverableStatus {
		white-space: nowrap;

		.statusBox {
			margin-right: 8px;
			width: 14px;
			height: 14px;
			border-radius: 2px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}

	.highlight-hover {
		&:hover {
			color: var(--v-primary-base);
		}
	}

	div[contenteditable='true']:focus {
		outline: 1px solid var(--v-gray_30-base);
		background-color: var(--v-white-base);
	}

	.numberInput {
		margin: 0px;
		padding: 0px;
		max-width: 50px;
		color: var(--v-black-base);
	}

	.numberInput:focus {
		outline: none;
	}
</style>
