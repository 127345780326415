<template>
	<div>
		<div v-if="recurringExpenses.length" class="my-4">
			<div class="text-left">
				<v-btn class="secondary-action" @click="showExpenses = !showExpenses">
					+{{ recurringExpenses.length }} {{ $t('recurring.expenses.count-heading') }}
					<v-icon class="ml-1" small>{{ showExpenses ? '$chevronUp' : '$chevronDown' }}</v-icon>
				</v-btn>
			</div>
			<div v-if="showExpenses" class="h-outline mt-2 mb-8">
				<v-data-table
					:headers="recurringExpenseTableHeaders"
					:hide-default-footer="true"
					:items="recurringExpenses"
					:disable-pagination="true"
					@click:row="openExpense($event.id)"
					class="pointer"
				>
					<template v-slot:item.amount="{ item }">
						{{ $formatters.dollars(getTotal(item.expense), true, true, item.expense.currency) }}
					</template>
					<template v-slot:item.startDate="{ item }">
						{{ DateTime.fromISO(item.startDate).toLocaleString(DateTime.DATE_MED) }}
						<span v-if="item.endDate">
							thru {{ DateTime.fromISO(item.endDate).toLocaleString(DateTime.DATE_MED) }}</span
						>
					</template>
					<template v-slot:item.nextRunTime="{ item }">
						<span v-if="item.nextRunTime && item.enabled">{{
							DateTime.fromISO(item.nextRunTime)
								.setZone($store.state.podAccount.timeZone)
								.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
						}}</span>
						<span v-else>--</span>
					</template>
					<template v-slot:item.status="{ item }">
						<span v-if="item.enabled" style="text-transform: capitalize">{{ item.status.toLowerCase() }}</span>
						<span v-else>Disabled</span>
					</template>
					<template v-slot:item.label="{ item }">
						<v-icon size="14" style="margin-bottom:1px; margin-right:4px">autorenew</v-icon>{{ getLabel(item) }}
					</template>
				</v-data-table>
			</div>
		</div>

		<basic-modal
			v-if="recurringExpenseDetailDialog"
			:dialog="recurringExpenseDetailDialog"
			@close="recurringExpenseDetailDialog = false"
			:persistent="true"
		>
			<recurring-expense
				:id="recurringExpenseId"
				@close="recurringExpenseDetailDialog = false"
				@result="recurringEventSaved($event)"
				@deleted="recurringEventDeleted($event)"
				@new-vendor="$emit('new-vendor', $event)"
			/>
		</basic-modal>
	</div>
</template>

<script>
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import RecurringExpense from '@/modules/accounting/expenses/RecurringExpense';
	import BasicModal from '@/components/BasicModal';
	import {DateTime} from 'luxon';

	export default {
		name: 'RecurringExpenseList',

		props: [],

		mixins: [RecurringEventMixin],

		components: { BasicModal, RecurringExpense },

		data: function () {
			return {
				recurringExpenseDetailDialog: false,
				recurringExpenseId: null,
				recurringExpenses: [],
				DateTime: DateTime,
				showExpenses: false,
			};
		},

		mounted() {
			this.initRecurringExpenses();
		},

		beforeDestroy() {},

		methods: {
			addNew: function () {
				this.recurringExpenseDetailDialog = true;
				this.recurringExpenseId = null;
			},

			openExpense: function (id) {
				this.recurringExpenseDetailDialog = true;
				this.recurringExpenseId = id;
			},

			initRecurringExpenses: function () {
				this.getRecurringExpenses().then((res) => {
					this.recurringExpenses.splice(0, this.recurringExpenses.length);
					this.recurringExpenses.push(...res.data);
				});
			},

			recurringEventSaved: function (event) {
				let ix = this.recurringExpenses.findIndex((r) => r.id === event.id);
				if (ix > -1) {
					this.recurringExpenses.splice(ix, 1, event);
				} else {
					this.recurringExpenses.push(event);
				}
				this.recurringExpenseDetailDialog = false;
			},

			recurringEventDeleted: function (id) {
				let ix = this.recurringExpenses.findIndex((r) => r.id === id);
				if (ix > -1) {
					this.recurringExpenses.splice(ix, 1);
				}
				this.recurringExpenseDetailDialog = false;
			},

			getTotal: function(expense){
				if(expense.taxInclusive){
					return expense.amount;
				}else{
					return parseFloat(expense.amount) + (parseFloat(expense.amount) * (parseFloat(expense.taxRate) / 100));
				}
			}
		},

		computed: {
			recurringExpenseTableHeaders: function () {
				let headers = [
					{ text: this.$t('expenses.amount'), value: 'amount', class: 'nowrap' },
					{ text: this.$t('expenses.description'), value: 'expense.description' },
					{ text: this.$t('recurring.start-date'), value: 'startDate' },
					{ text: this.$t('recurring.repeat-every'), value: 'label' },
					{ text: this.$t('recurring.next-run-time'), value: 'nextRunTime' },
					{ text: this.$t('invoice.status'), value: 'status' },
				];

				return headers;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
