<template>
	<div class="column-format fill-height" style="" v-if="$store.state.selectedUserAccount">
		<div :class="`row-format align-center mx-2 mt-2 nav-link${$store.state.leftNavExpanded ? '-expanded' : ''}`" style="margin-bottom: 2px">
			<div
				class="row-format align-center px-3 pb-3 pt-2 pointer"
				style="width: 100%; min-height: 56px; min-width: 56px"
				id="moxie-logo-link"
				@click="$router.push('/getting-started')"
			>
				<logo style="max-height: 28px; margin-left: -10px" v-if="$store.state.leftNavExpanded"></logo>
				<icon style="max-height: 28px; margin-left: -10px" v-else @click="$router.push('/getting-started')"></icon>
				<v-icon
					v-if="$store.state.leftNavExpanded"
					style="transform: rotate(180deg)"
					size="20"
					color="gray_90"
					class="ml-auto pointer material-symbols-rounded"
					@click.stop="setLeftNavExpanded(false)"
					>view_sidebar</v-icon
				>
			</div>
		</div>
		<div style="max-height: calc(var(--vh) - 114px); overflow-y: auto; padding-top: 6px">
			<!--v-menu offset-x nudge-left="10" nudge-bottom="10"  v-model="onboardingMenu" style="z-index: 100;" v-if="$store.state.showOnBoardingWidget">
				<template v-slot:activator="{ on }">
					<div
						v-if="$store.state.leftNavExpanded"
						v-on="on"
						style="color: var(--v-black-base); background-color: var(--v-white-base); border: 1px solid var(--v-primary-base); width:208px; margin-left: 8px; margin-right: 8px; border-radius: 4px; min-height: 40px; "
						class="mb-3 py-2 text-left row-format pointer"
					>
						<v-icon color="primary" class="pl-3 pr-2" size="20">waving_hand</v-icon>
						<div>
							<div class="font-14 brand-medium">Welcome to Moxie</div>
							<div class="font-12">Getting started guides</div>
						</div>
						<v-icon color="black" class="ml-auto mr-1" size="26">arrow_right</v-icon>
					</div>

					<div
						v-else
						v-on="on"
						style="color: var(--v-black-base); background-color: var(--v-white-base); border: 1px solid var(--v-primary-base); width:44px; margin-left: 8px; margin-right: 8px; border-radius: 4px; min-height: 40px; "
						class="mb-3 py-2 text-left row-format pointer"
						v-tippy="{content:'Account setup', placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"
					>
						<v-icon color="primary" class="pl-3 pr-2" size="20">waving_hand</v-icon>
					</div>
				</template>
				<div
					v-if="onboardingMenu"
					class="pa-4 column-format"
					style="background-color: var(--v-white-base); max-height: calc(100vh - 200px); overflow-y: auto; border: 0 solid var(--v-primary-base); border-radius: 8px"
				>
					<on-boarding-launcher></on-boarding-launcher>
				</div>
			</v-menu-->
			<nav-link label="Home" icon="other_houses" route="/home"></nav-link>
			<nav-link
				label="Clients"
				icon="group"
				route="/clients"
				:disable-active="isInFavoriteLink"
				v-if="$store.getters.hasFeatureAccess('clients')"
				:altRoutes="['/client']"
			></nav-link>
			<nav-link
				label="Projects"
				icon="folder_special"
				route="/projects"
				:altRoutes="['/project']"
				v-if="$store.getters.hasFeatureAccess('projects')"
			></nav-link>
			<nav-link label="Tickets" icon="local_activity" route="/tickets" v-if="$store.getters.hasFeatureAccess('tickets')"></nav-link>
			<nav-link label="Inbox" icon="inbox" route="/inbox" v-if="$store.getters.hasFeatureAccess('any')"></nav-link>
			<nav-link
				label="Pipeline"
				icon="filter_alt"
				route="/pipeline"
				v-if="$store.getters.hasFeatureAccess('pipeline')"
			></nav-link>
			<nav-link
				label="Accounting"
				icon="request_quote"
				route="/accounting"
				v-if="$store.getters.hasFeatureAccess('accounting')"
			></nav-link>
			<nav-link
				label="Agreements"
				icon="handshake"
				route="/agreements"
				v-if="$store.getters.hasFeatureAccess('agreements')"
			></nav-link>
			<nav-link
				label="Timesheets"
				icon="pending_actions"
				route="/timesheets"
				v-if="$store.getters.hasFeatureAccess('timesheets')"
			></nav-link>
			<div v-if="!isAccountCollaborator">
				<div class="separator my-2"></div>
				<nav-link label="Favorites" icon="favorite"></nav-link>
				<favorite-link v-for="client in favoriteClients" :key="client.id" :client="client"></favorite-link>
			</div>
		</div>

		<div
			class="mt-auto py-2 pointer row-format centered font-14 brand-medium"
			@click="$router.push('/subscription')"
			v-if="showTrialBanner && $store.state.leftNavExpanded"
			style="color: var(--v-white-base);background-color: var(--v-secondary-base);"
		>
			<div>{{ daysInTrial }} {{ daysInTrial !== 1 ? 'Days' : 'Day' }} remaining in your trial</div>
		</div>
		<div v-else class="mt-auto">&nbsp;</div>

		<v-menu offset-x nudge-left="10" content-class="workspace-menu">
			<template v-slot:activator="{ on }">
				<div class="workspace-settings row-format align-center pa-3" v-on="on"  id="workspace-settings-link">
					<div
						v-if="!$store.state.leftNavExpanded"
						style="margin-left: 6px"
						class="row-format centered"
						:content="`Workspace settings`"
						v-tippy="{
							placement: 'right',
							arrow: true,
							arrowType: 'round',
							animation: 'fade',
							delay: 50,
							boundary: 'window',
						}"
					>
						<v-avatar size="20" color="accent">
							<span class="brand-bold font-12">{{ accountInitials }}</span>
						</v-avatar>
					</div>

					<div v-else style="margin-left: 6px" class="row-format centered">
						<v-avatar size="20" color="accent">
							<span class="brand-bold font-12">{{ accountInitials }}</span>
						</v-avatar>
					</div>

					<div class="nav-label nav-label-black ml-2" v-if="$store.state.leftNavExpanded">Workspace settings</div>
					<v-icon class="ml-auto" color="black" small v-if="$store.state.leftNavExpanded">$chevronRight</v-icon>
				</div>
			</template>

			<settings-nav></settings-nav>
		</v-menu>
	</div>
</template>

<script>
	import NavLink from '@/views/nav/NavLink';
	import FavoriteLink from '@/views/nav/FavoriteLink';
	import SettingsNav from '@/views/nav/settings/SettingsNav';
	import logo from '@/assets/logo';
	import icon from '@/assets/icon';
	import { DateTime } from 'luxon';
	//import OnBoardingLauncher from '@/modules/onboarding/OnBoardingLauncher';

	export default {
		name: 'LeftNav',

		props: [],

		components: { SettingsNav, FavoriteLink, NavLink, logo, icon },

		data: function() {
			return {
				onboardingMenu: false,
			};
		},

		mounted() {
			this.getLeftNavExpanded();
		},

		beforeDestroy() {},

		methods: {
			setLeftNavExpanded: function(state) {
				this.$store.state.leftNavExpanded = state;

				let leftNavState = {
					state: state,
				};

				localStorage.setItem('LEFT_NAV_STATE', JSON.stringify(leftNavState));
			},

			getLeftNavExpanded: function() {
				let strState = localStorage.getItem('LEFT_NAV_STATE');
				if (strState) {
					let leftNavState = JSON.parse(strState);
					this.$store.state.leftNavExpanded = leftNavState.state;
				}
			},
		},

		computed: {
			showTrialBanner: function() {
				if (this.$store.getters.getAccount.pricingVersion > 1 && this.daysInTrial > -1) {
					return true;
				} else {
					return false;
				}
			},

			daysInTrial: function() {
				if (this.$store.getters.getAccount && this.$store.getters.getAccount.trialEndsAt) {
					let trialEndsAt = DateTime.fromISO(this.$store.getters.getAccount.trialEndsAt);
					let now = DateTime.now();
					if (trialEndsAt > now) {
						return Math.round(trialEndsAt.diff(now, ['days']).days);
					} else {
						return -1;
					}
				} else {
					return -1;
				}
			},

			favoriteClients: function() {
				let clients = [...this.$store.getters.clients];
				clients = clients.filter((c) => this.$store.state.favoriteClients.includes(c.id));
				clients.sort((a, b) => a.name.localeCompare(b.name));
				return clients;
			},

			accountInitials() {
				let ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in', '-'];
				let accountName = this.$store.getters.getAccountName;
				if (!accountName) {
					return '?';
				}
				let words = this.$store.getters.getAccountName.toLowerCase().split(' ');
				let ret = [];
				for (let i = 0; i < words.length; i++) {
					if (ignoreMe.indexOf(words[i]) > -1) continue;
					ret[i] = words[i].substring(0, 1);
					if (ret.length == 3) break;
				}
				return ret.join('').toUpperCase();
			},

			accountLogo() {
				return this.$store.getters.getAccountLogo;
			},

			isFullUser() {
				return this.$store.getters.isAccountFullUser;
			},

			isAccountCollaborator() {
				return this.$store.getters.isAccountCollaborator;
			},

			isInFavoriteLink: function() {
				for (let i = 0; i < this.favoriteClients.length; i++) {
					if (this.$route.path.startsWith(`/client/${this.favoriteClients[i].id}`)) {
						return true;
					}
				}
				return false;
			},
		},
	};
</script>

<style lang="scss">
	.workspace-menu {
		filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
		box-shadow: none !important;
		border-radius: 8px !important;
		position: absolute;
		bottom: 20px !important;
		top: unset !important;
		background-color: var(--v-white-base);
		z-index: 1000 !important;
	}
</style>

<style scoped lang="scss">
	.separator {
		border: 1px solid rgba(0, 0, 0, 0.1);
		height: 0px;
		width: 100%;
	}

	.nav-link {
		width: 44px;
		height: 38px;
		border-radius: 4px;
	}

	.nav-link-expanded {
		width: 208px;
		height: 38px;
		border-radius: 4px;
	}

	.nav-label {
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
		//letter-spacing: -0.01em;
		color: var(--v-primary-base);
	}

	.nav-label-black {
		color: var(--v-black-base);
	}

	.workspace-settings {
		cursor: pointer;
		padding: 0px 8px;

		width: 100%;
		height: 52px;

		/* Blues/Royal Blue 05 */

		background: var(--v-gray_10-base);
		border-top: 1px solid rgba(0, 0, 0, 0.02);
		box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
	}
</style>
