<template>
	<div class="column-format fill-height" v-if="isInitialized">
		<div class="py-3 px-5 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<div>
				<time-track-list-filter :filter="filter" v-if="filter" @clear-filter="clearFilter"></time-track-list-filter>
			</div>
			<plus-button class="ml-auto" @click="addNew()"></plus-button>
		</div>
		<div class="row-format centered fill-height" style="height: calc(var(--vh) - 150px)" v-if="!events.length">
			<empty-view
				header="No time entries yet"
				:body="
					`If you track your time, your history of time entries will show up here. In addition to viewing historical entries here, you can also create new entries.`
				"
				cta="Create a time entry"
				video-header="See how it works"
				video-body="Learn how to track your time and then add those time entries to an invoice."
				video-cta="Watch the tutorial"
				video-id="3l1ThT9qGks"
				@cta-clicked="addNew()"
			></empty-view>
		</div>

		<div class="column-format gap-3 ma-3" v-if="events.length">
			<div class="row-format gap-3">
				<div class="kpi-box">
					<div class="fit text-left">Total hours</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.totalTime) }}</div>
				</div>
				<div class="kpi-box">
					<div class="row-format">
						<div class="row-format align-center fit">
							<div>{{ $t('timetrack.list.by-client') }}</div>
						</div>
					</div>
					<div class="row-format status-wrapper">
						<div
							class="status-box"
							v-for="client in metrics.clients"
							:key="client.name"
							:style="`width:${client.percentage}%; background-color: ${client.color}`"
							v-tippy="{ content: `${client.name} - ${formatSecondsToHours(client.totalTime)}` }"
						></div>
					</div>
				</div>
				<div class="kpi-box">
					<div class="fit text-left">{{ $t('timetrack.list.this-week') }}</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.timeThisWeek) }}</div>
				</div>
			</div>
			<div class="row-format gap-3" v-if="filter.timeEntryStatus.length !== 1">
				<div class="kpi-box" v-if="filter.timeEntryStatus.length === 0 || filter.timeEntryStatus.includes('BILLED')">
					<div class="row-format">
						<div class="fit text-left">Billed</div>
					</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.billed) }}</div>
				</div>
				<div class="kpi-box" v-if="filter.timeEntryStatus.length === 0 || filter.timeEntryStatus.includes('UN-BILLED')">
					<div class="row-format">
						<div class="fit text-left">Un-billed</div>
					</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.unBilled) }}</div>
				</div>
				<div
					class="kpi-box"
					v-if="filter.timeEntryStatus.length === 0 || filter.timeEntryStatus.includes('NON-BILLABLE')"
				>
					<div class="row-format">
						<div class="fit text-left">Non-billable</div>
					</div>
					<div class="font-24 brand-medium fit">{{ formatSeconds(metrics.nonBillable) }}</div>
				</div>
			</div>
		</div>

		<div v-if="events.length && !filteredEvents.length" class="mt-6">
			<empty-filtered-results></empty-filtered-results>
		</div>

		<div v-else-if="filteredEvents.length">
			<timer-event-table
				@add-new="addNew()"
				@duplicate="duplicate($event)"
				@edit-timer="editTimerEvent($event)"
				:events="filteredEvents"
				:group-by="filter.groupBy ? filter.groupBy.field : null"
			></timer-event-table>
		</div>
	</div>
</template>

<script>
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import DateTime from '@/modules/utils/HDateTime';
	import ClientPalette from '@/modules/clients/ClientPalette';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyView from '@/components/EmptyView';
	import EmptyFilteredResults from '@/components/EmptyFilteredResults';
	import { mapGetters } from 'vuex';
	import TimerEventTable from '@/modules/timetracking/TimerEventTable';
	import TimeTrackListFilter from '@/modules/timetracking/TimeTrackListFilter';
	import PlusButton from '@/components/PlusButton';

	export default {
		name: 'TimerEventList',

		props: [],

		components: { TimerEventTable, EmptyView, EmptyFilteredResults, PlusButton, TimeTrackListFilter },

		data: function() {
			return {
				events: [],
				timeTrackService: new TimeTrackService(),
				isInitialized: false,
				DateTime: DateTime,
				clientPalette: new ClientPalette(),
				filter: this.getDefaultFilter(),
				firstPassIgnore: true,
			};
		},

		mounted() {
			this.loadSavedFilter();
			this.getTimerEvents();
			this.$store.state.eventBus.$on('timer-list-reload', this.getTimerEvents);
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('timer-list-reload', this.getTimerEvents);
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			clearFilter: function() {
				this.filter = this.getDefaultFilter();
			},

			handleAccountChange: function() {
				this.loadSavedFilter();
				this.getTimerEvents();
			},

			getDefaultFilter: function() {
				let result = {
					search: null,
					dateSpecifier: 'this-month',
					earliest: null,
					latest: null,
					timeEntryStatus: [],
					clients: [],
					users: [],
					groupBy: null,
				};

				return result;
			},

			getTimerEvents: function() {
				if (this.events.length) {
					this.$store.commit('startLoading');
				}

				let customGroupField = this.filter.groupBy && this.filter.groupBy.customField ? this.filter.groupBy.field : null;

				this.timeTrackService
					.getTimerEventsFull(null, this.earliest.toISO(), this.latest.toISO(), null, null, null, customGroupField)
					.then((res) => {
						this.events.splice(0, this.events.length);
						this.events.push(...res.data);
						this.isInitialized = true;
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			editTimerEvent: function(timerEvent) {
				let binding = {
					timerEvent: timerEvent,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.timerDeleted(timerEvent.id);
					} else {
						this.timerUpdated(res);
					}
				});
			},

			addNew: function(timerEvent = null) {
				let binding = {
					timerEvent: timerEvent,
				};
				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					this.timerCreated(res);
				});
			},

			duplicate: function(timerEvent){
				timerEvent.id = null;
				this.addNew(timerEvent);
			},

			timerUpdated(timerEvent) {
				let ix = this.events.findIndex((e) => e.id === timerEvent.id);
				if (ix > -1) {
					this.events.splice(ix, 1, timerEvent);
				}
			},

			timerCreated(timerEvent) {
				this.events.push(timerEvent);
			},

			timerDeleted(id) {
				let ix = this.events.findIndex((e) => e.id === id);
				if (ix > -1) {
					this.events.splice(ix, 1);
				}
			},

			formatSeconds: function(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				//let time = hours + ':' + minutes + ':' + seconds;
				let time = hours + ':' + minutes;
				return time;
			},

			formatSecondsToHours: function(seconds) {
				return Math.round(seconds / 60 / 60) + 'hr';
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			loadSavedFilter() {
				try {
					let filterString = localStorage.getItem(this.filterStateKey);
					if (filterString) {
						this.filter = JSON.parse(filterString);
						if (!Object.hasOwn(this.filter, 'groupBy')) {
							this.filter.groupBy = null;
						}
					} else {
						this.filter = this.getDefaultFilter();
					}
				} catch (err) {
					console.log('Error reading filter preferences from local storage.', err);
				}
			},
		},

		computed: {
			...mapGetters({
				userId: 'getLoggedInUserId',
			}),

			filterStateKey: function() {
				return 'TIMESHEET_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			dateKey: function() {
				return this.earliest.toISO() + '-' + this.latest.toISO();
			},

			earliest: function() {
				let earliest;
				if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
					earliest = DateTime.fromISO(this.filter.earliest).startOf('day');
				} else if (this.filter.dateSpecifier !== 'between') {
					let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
					earliest = helper.earliest;
				} else {
					earliest = DateTime.now().startOf('day');
				}

				return earliest;
			},

			latest: function() {
				let latest;

				if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
					latest = DateTime.fromISO(this.filter.latest)
						.plus({ days: 1 })
						.minus({ seconds: 1 });
				} else if (this.filter.dateSpecifier !== 'between') {
					let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
					latest = helper.latest;
				} else {
					latest = DateTime.now().startOf('day');
				}

				return latest;
			},

			filteredEvents: function() {
				let events = [...this.events];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;

				for (let i = 0; i < events.length; i++) {
					if (events[i].deliverableName) {
						events[i].deliverableOrNote = events[i].deliverableName;
					}else if(events[i].ticketName) {
						events[i].deliverableOrNote = events[i].ticketName;
					} else if (events[i].notes) {
						events[i].deliverableOrNote = events[i].notes;
					}

					if (events[i].invoiceId) {
						events[i].billingStatus = 'BILLED';
					} else if (
						events[i].billable &&
						(!events[i].projectId ||
							(events[i].feeSchedule &&
								(events[i].feeSchedule.feeType === 'Hourly' ||
									(events[i].feeSchedule.feeType === 'Retainer' &&
										!!events[i].feeSchedule.retainerOverageRate))))
					) {
						events[i].billingStatus = 'UN-BILLED';
					} else {
						events[i].billingStatus = 'NON-BILLABLE';
					}
				}

				events = events
					.filter((e) => {
						if (search) {
							if (
								(e.clientName && e.clientName.toLowerCase().includes(search)) ||
								(e.projectName && e.projectName.toLowerCase().includes(search)) ||
								(e.deliverableName && e.deliverableName.toLowerCase().includes(search)) ||
								(e.notes && e.notes.toLowerCase().includes(search))
							) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.timeEntryStatus && this.filter.timeEntryStatus.length > 0) {
							if (this.filter.timeEntryStatus.includes(e.billingStatus)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.clients && this.filter.clients.length > 0) {
							if (this.filter.clients.includes(e.clientId)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.users && this.filter.users.length > 0) {
							if (this.filter.users.includes(e.userId)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});

				events.forEach((e) => {
					e.client = this.$store.getters.getClientById(e.clientId);
				});

				return events;
			},

			groupBy: function() {
				return this.filter.groupBy;
			},

			metrics: function() {
				let result = {
					totalTime: 0,
					unBilled: 0,
					billed: 0,
					nonBillable: 0,
					timeThisWeek: 0,
					clients: {},
				};

				let startOfWeek = DateTime.now().startOf('week');
				let colorsInUse = [];
				let clients = [...new Set(this.events.map((item) => (item.clientId ? item.clientId : '0')))];

				clients.forEach((p) => {
					let color = this.clientPalette.getRandomColor(colorsInUse);
					colorsInUse.push(color);
					result.clients[p] = { color: color, totalTime: 0 };
				});

				for (let i = 0; i < this.filteredEvents.length; i++) {
					let e = this.filteredEvents[i];
					result.totalTime += e.duration;

					if (e.invoiceId) {
						result.billed += e.duration;
					} else if (
						e.billable &&
						(!e.projectId ||
							(e.feeSchedule &&
								(e.feeSchedule.feeType === 'Hourly' ||
									(e.feeSchedule.feeType === 'Retainer' && !!e.feeSchedule.retainerOverageRate))))
					) {
						result.unBilled += e.duration;
					} else {
						result.nonBillable += e.duration;
					}

					if (DateTime.fromISO(e.timerStart) > startOfWeek) {
						result.timeThisWeek += e.duration;
					}

					let clientId = e.clientId ? e.clientId : '0';
					result.clients[clientId].totalTime += e.duration;
					result.clients[clientId].name = clientId === '0' ? '[No client]' : e.clientName;
				}

				for (const client in result.clients) {
					result.clients[client].percentage = Math.round((result.clients[client].totalTime / result.totalTime) * 100);
				}

				for (const client in result.clients) {
					if (result.clients[client].totalTime === 0 || result.clients[client].percentage === 0) {
						delete result.clients[client];
					}
				}

				return result;
			},
		},

		watch: {
			filter: {
				deep: true,
				handler: function() {
					this.saveCurrentFilter();
				},
			},

			groupBy: function(newValue) {
				if (this.firstPassIgnore) {
					this.firstPassIgnore = false;
					return;
				}
				if (newValue && newValue.customField) {
					this.getTimerEvents();
				}
			},

			dateKey: function() {
				this.getTimerEvents();
			},
		},
	};
</script>

<style scoped lang="scss">
	.event-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}

	.status-wrapper {
		height: 36px;
	}

	.status-box {
		height: 100%;
		margin-right: 3px;
		border-radius: 2px;
	}

	.invoice-status {
		.default-label {
			display: block;
		}
		.hover-label {
			display: none;
		}

		&:hover {
			.default-label {
				display: none;
			}
			.hover-label {
				display: block;
			}
		}
	}

	.kpi-box {
		min-width: 150px;

		.create-invoice {
			display: none;
		}

		&:hover {
			.create-invoice {
				display: block;
			}
		}
	}
</style>
