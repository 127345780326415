<template>
	<div>
		<v-data-table
			item-key="id"
			class="list-view"
			fixed-header
			:headers="headers"
			:items="products"
			:hide-default-footer="true"
			:items-per-page="-1"
			height="calc(var(--vh) - 150px)"
			@click:row="openProduct($event)"
			style="cursor: pointer"
		>
			<template v-slot:item.active="{ item }">
				{{ $formatters.yesNoFromBoolean(item.active) }}
			</template>

			<template v-slot:item.prices="{ item }">
				<div v-for="price in item.prices" :key="price.id">
					<span :style="!price.active ? 'text-decoration: line-through' : ''">{{ formatPrice(price) }}</span>
				</div>
			</template>

			<template v-slot:no-data>
				{{ $t('stripe.subscription.no-products') }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import StripeSubscriptionService from '@/modules/integrations/stripe/subscriptions/StripeSubscriptionService';
	import StripeProduct from '@/modules/integrations/stripe/subscriptions/StripeProduct';

	export default {
		name: 'StripeProducts',

		props: [],

		components: {},

		data: function () {
			return {
				stripeSubscriptionService: new StripeSubscriptionService(),
				products: [],
			};
		},

		mounted() {
			this.getProducts();
		},

		beforeDestroy() {},

		methods: {
			getProducts: function () {
				this.stripeSubscriptionService.getProducts().then((res) => {
					this.products.splice(0, this.products.length);
					this.products.push(...res.data);
				});
			},

			openProduct: function (product) {
				this.$store.state.globalModalController
					.openModal(StripeProduct, { product: product }, false, false)
					.then((res) => {
						if (res) {
							let ix = this.products.findIndex((p) => p.id === product.id);
							if (ix > -1) {
								this.products.splice(ix, 1, res);
							} else {
								this.products.push(res);
							}
						}
					});
			},

			formatPrice: function (price) {
				let amount = this.$formatters.dollars(price.unitAmount / 100, true, true, price.currency);
				if (price.intervalCount == 1) {
					return amount + ' / ' + price.interval.toLowerCase();
				} else {
					return amount + ' every ' + price.intervalCount + ' ' + price.interval.toLowerCase() + 's';
				}
			},

			addNew: function () {
				let product = {
					id: null,
					name: null,
					description: null,
					active: true,
					prices: [
						{
							id: null,
							currency: this.$store.state.defaultCurrency,
							active: true,
							interval: 'Month',
							intervalCount: 1,
							amount: 0,
						},
					],
				};
				this.openProduct(product);
			},
		},

		computed: {
			headers: function () {
				return [
					{ text: this.$t('stripe.subscription.product-name'), value: 'name' },
					{ text: this.$t('stripe.subscription.product-desc'), value: 'description' },
					{ text: this.$t('stripe.subscription.product-price'), value: 'prices' },
					{ text: this.$t('global.active'), value: 'active' },
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
