<template>
	<div class="column-format fill-height">
		<div>
			<div class="py-3 px-5 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
				<div class="pr-6">
<!--					<v-menu-->
<!--							:nudge-top="28" max-width="400"-->
<!--							bottom-->
<!--							right-->
<!--							rounded-->
<!--							offset-overflow-->
<!--							offset-y-->
<!--							:close-on-content-click="false"-->
<!--							content-class="filter-menu-class"-->
<!--							transition="slide-y-transition"-->
<!--							v-model="showMenu"-->
<!--					>-->
<!--						<template v-slot:activator="{ on }">-->
<!--							<div :class="`pointer row-format align-center`" v-on="on">-->
<!--								<div :class="`font-14 ${search ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{ search ? `"${search}"` : 'Filter' }}</div>-->
<!--								<v-icon x-small class="mx-1" :color="search ? 'secondary' : 'gray_80'">$arrowDown</v-icon>-->
<!--							</div>-->
<!--						</template>-->

<!--						<div class="column-format">-->
<!--							<v-text-field-->
<!--									autofocus-->
<!--									solo-->
<!--									flat-->
<!--									hide-details-->
<!--									dense-->
<!--									@blur="showMenu=false"-->
<!--									placeholder="Search..."-->
<!--									v-model="search"-->
<!--							>-->
<!--								<template v-slot:append><v-icon small class="pointer" @click="search=null">$clear</v-icon></template>-->
<!--							</v-text-field>-->
<!--						</div>-->
<!--					</v-menu>-->
          <div class="font-14 brand-medium">
            <input
                style="width: 85px; color: var(--v-gray_80-base)"
                @input="updated"
                type="text"
                v-model="search"
                placeholder="Search..."
                @blur="showMenu=false"
            />
          </div>
				</div>
				<plus-button class="ml-auto" @click="addNew"></plus-button>
			</div>
		</div>
		<div
			style="height: calc(var(--vh) - 116px); max-height: calc(var(--vh) - 116px); overflow-y: auto"
			:class="`pa-0 ${$store.getters.scroll}`"
		>
			<div class="row-format pa-4" style="flex-wrap: wrap; gap: 12px">
				<contact-card
					v-for="contact in filteredContacts"
					:key="contact.id"
					:contact="contact"
					@edit="openContact(contact)"
				></contact-card>
			</div>
		</div>
	</div>
</template>

<script>
	import ContactService from '@/modules/clients/contacts/ContactService';
	import ContactCard from '@/modules/clients/contacts/ContactCard';
	import ContactEdit from '@/modules/clients/contacts/ContactEdit';
	import PlusButton from "@/components/PlusButton";

	export default {
		name: 'ContactList',

		props: [],

		components: { ContactCard, PlusButton },

		data: function () {
			return {
				contactService: new ContactService(),
				contacts: [],
				search: null,
				showMenu: false,
			};
		},

		mounted() {
			this.getContactList();
			this.$store.state.eventBus.$on(
				this.$store.getters.getMessageChannelPrefix + '.contact-update',
				this.updateContactHandler
			);
			this.$store.state.eventBus.$on(
				this.$store.getters.getMessageChannelPrefix + '.contact-delete',
				this.deleteContactHandler
			);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(
				this.$store.getters.getMessageChannelPrefix + '.contact-update',
				this.updateContactHandler
			);
			this.$store.state.eventBus.$off(
				this.$store.getters.getMessageChannelPrefix + '.contact-delete',
				this.deleteContactHandler
			);
		},

		methods: {
      updated: function() {
        this.$emit('updated', this.filter);
      },

			updateContactHandler: function (event) {
				let contact = event.message;
				let ix = this.contacts.findIndex((c) => c.id === contact.id);
				if (ix > -1) {
					this.contacts.splice(ix, 1, contact);
				} else {
					this.contacts.push(contact);
				}
				this.contacts.sort(this.sortList);
			},

			deleteContactHandler: function (event) {
				let deletedContact = event.message;
				let ix = this.contacts.findIndex((contact) => contact.id === deletedContact.id);
				if (ix > -1) {
					this.contacts.splice(ix, 1);
				}
			},

			getContactList: function () {
				this.contactService.getContactList().then((res) => {
					this.contacts.splice(0, this.contacts.length);
					this.contacts.push(...res.data);
					this.contacts.sort(this.sortList);
				});
			},

			sortList: function (a, b) {
				let aSort = this.getSortString(a);
				let bSort = this.getSortString(b);
				return aSort.localeCompare(bSort);
			},

			getSortString: function (contact) {
				if (contact.firstName) {
					return contact.firstName;
				} else if (contact.lastName) {
					return contact.lastName;
				} else if (contact.email) {
					return contact.email;
				} else {
					return '';
				}
			},

			addNew: function () {
				this.$store.state.globalModalController.openModal(ContactEdit, { id: null }).then((res) => {
					if (res) {
						let ix = this.contacts.findIndex(c => c.id === res.id);
						if(ix > -1){
							this.contacts.splice(ix,1,res);
						}else {
							this.contacts.push(res);
						}
						this.contacts.sort(this.sortList);
					}
				});
			},

			openContact: function (contact) {
				this.$store.state.globalModalController.openModal(ContactEdit, { id: contact.id }).then((res) => {
					if (res) {
						let ix = this.contacts.findIndex((c) => c.id === res.id);
						if (res.deleted) {
							this.contacts.splice(ix, 1);
						} else {
							this.contacts.splice(ix, 1, res);
						}
						this.contacts.sort(this.sortList);
					}
				});
			},
		},

		computed: {
			filteredContacts: function () {
				if (this.search) {
					return this.contacts.filter((c) => {
						let search = this.search.toLowerCase();
						let numeric = this.search.replace(/\D/g, '');
						let fullName = c.firstName + ' ' + c.lastName;

						if (fullName.toLowerCase().includes(search)) {
							return true;
						} else if (c.email && c.email.toLowerCase().includes(search)) {
							return true;
						} else if (c.phone && numeric && c.phone.toLowerCase().includes(numeric)) {
							return true;
						}

						let customValues = c.customValues;

						for(let j=0; j < customValues.length; j++){
							let cv = customValues[j];
							if(cv.value && cv.value.toString().toLowerCase().includes(this.search.toLowerCase())){
								return true;
							}
						}

						return false;
					});
				} else {
					return this.contacts;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
input::placeholder {
  color: var(--v-gray_60-base);
}
textarea:focus,
input:focus {
  outline: 0;
}
</style>
