<template>
	<v-container class="ma-0 px-6 py-3 project-row" fluid @click="$emit('click',$event)">
		<v-row dense>
			<v-col cols="5" class="row-format align-center gap-2">
				<client-avatar :client="project.client"></client-avatar>
				<div class="column-format text-left text-truncate" style="line-height: 18px;; overflow-y: hidden">
					<div class="font-18 truncate">{{project.name}}</div>
					<div class="font-14 font-gray_70 truncate">{{project.client.name}}</div>
				</div>
			</v-col>
			<v-col cols="4" class="text-left">
				<div class="font-14" v-if="project.timeline">
					{{project.timeline}}
				</div>
				<div v-if="project.nextDueDate" class="text-left font-14 font-gray_70" style="width: fit-content"  v-tippy="{content: project.nextDueDate.name}">
					<div >Next task: {{project.nextDueDate.date}}</div>
				</div>
			</v-col>
			<v-col cols="2" class="text-left">
				<div class="font-14 font-gray_70" v-if="isFullUser">{{project.fees}}</div>

				<div class="row-format align-center">
					<assigned-user :style="`border-radius: 100px; border: 2px solid var(--v-white-base); z-index: ${5-index}`" v-for="(collaborator,index) in collaborators" :key="collaborator.userId" :assigned-to="collaborator.userId" :show-name="false"></assigned-user>
				</div>
			</v-col>
			<v-col cols="1" class="row-format align-center">
				<v-menu
						bottom
						left
						rounded
						offset-overflow
						offset-y
						:close-on-content-click="true"
						:close-on-click="true"
						v-if="isFullUser"
				>
					<template v-slot:activator="scope">
						<div class="ml-auto">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>

					<div class="more-menu">
						<div class="more-menu-item" @click.stop="$emit('click')">View details</div>
						<div class="more-menu-item" @click.stop="$emit('edit')">Edit</div>
						<div class="more-menu-item" @click.stop="$emit('mark-complete')">Mark complete</div>
					</div>
				</v-menu>
			</v-col>
		</v-row>
		<!--v-row dense>
			<v-col cols="12">
				<div style="width:100%; height: 8px; min-height: 8px;">
				<deliverable-by-status style="opacity: 0.5;" :project="project" :deliverables="project.deliverables" v-if="project.deliverables.length" :rounded="false"></deliverable-by-status>
				<div class="mt-1" v-else style="height: 100%; width:100%; border-radius: 2px; background-color: var(--v-gray_20-base)" content="No deliverables" v-tippy="{placement : 'top',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"></div>
				</div>
			</v-col>
		</v-row-->

	</v-container>
</template>

<script>
import AssignedUser from "@/components/AssignedUser";
import ClientAvatar from "@/components/ClientAvatar";

export default {
	name: "ProjectRow",

	props: ['project'],

	components: {ClientAvatar, AssignedUser},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {
		collaborators: function(){
			return this.$store.state.usersForAccount.filter(u => u.userType === 'COLLABORATOR' && u.projectAccess.projects.findIndex(p => p.projectId === this.project.id) > -1);
		},

		isFullUser(){
			return this.$store.getters.isAccountFullUser;
		},
	},

}
</script>

<style scoped lang="scss">
	.project-row {
		cursor: pointer;
		border-bottom: 1px solid var(--v-gray_30-base);

		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>