<template>
	<div class="fill-height column-format">
		<div class="py-3 px-5 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<report-filter v-model="filter" current-view="Money" @clear-filter="clearFilter()"></report-filter>
			<div class="row-format gap-2 ml-2">
				<scope-button scope="DRAFT" :is-active="filter.invoiceStatus.includes('DRAFT')" label="Draft" @scope-update="updateScope"></scope-button>
				<scope-button scope="SENT" :is-active="filter.invoiceStatus.includes('SENT')" label="Sent" @scope-update="updateScope"></scope-button>
				<scope-button scope="PARTIAL" :is-active="filter.invoiceStatus.includes('PARTIAL')" label="Partial" @scope-update="updateScope"></scope-button>
				<scope-button scope="PAID" :is-active="filter.invoiceStatus.includes('PAID')" label="Paid" @scope-update="updateScope"></scope-button>
				<scope-button scope="LATE" :is-active="filter.invoiceStatus.includes('LATE')" label="Late" @scope-update="updateScope"></scope-button>
				<scope-button scope="VOIDED" :is-active="filter.invoiceStatus.includes('VOIDED')" label="Voided" @scope-update="updateScope"></scope-button>
				<scope-button scope="WRITE-OFF" :is-active="filter.invoiceStatus.includes('WRITE-OFF')" label="Write Off" @scope-update="updateScope"></scope-button>
			</div>

			<plus-button class="ml-auto" @click="$refs.InvoiceTable.addNew()"></plus-button>
		</div>

		<invoice-list ref="InvoiceTable" :filter="filter" v-if="isReady"></invoice-list>
	</div>
</template>

<script>
	import ReportFilter from '@/modules/accounting/invoices/ReportFilter';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';
	import InvoiceList from "@/modules/accounting/invoices/InvoiceList";
	import PlusButton from "@/components/PlusButton";
	import ScopeButton from "@/modules/home/insights/ScopeButton";

	export default {
		name: 'InvoiceMain',

		components: { InvoiceList, ReportFilter, PlusButton, ScopeButton },

		mixins: [AccountingMixin],

		data: function () {
			return {
				filter: this.emptyFilter(),
				isReady: false,
			};
		},

		mounted() {
			localStorage.setItem('ACCOUNTING_PAGE','invoices');
			this.$track.record('page-view', { module: 'invoice-list' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.loadSavedFilter();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.isReady = false;
				this.$nextTick(() => {
					this.filter = this.emptyFilter();
					this.loadSavedFilter();
				});
			},

			updateScope: function(scope){
				let ix = this.filter.invoiceStatus.indexOf(scope);
				if(ix > -1){
					this.filter.invoiceStatus.splice(ix,1)
				}else{
					this.filter.invoiceStatus.push(scope);
				}
			},

			clearFilter: function () {
				this.filter = this.emptyFilter();
			},

			emptyFilter: function () {
				return {
					search: null,
					dateSpecifier: null,
					earliest: null,
					latest: null,
					clientList: [],
					invoiceStatus: [],
					timeEntryStatus: [],
				};
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			loadSavedFilter() {
				try {
					let filterString = localStorage.getItem(this.filterStateKey);
					if (filterString) {
						this.filter = JSON.parse(filterString);
					} else {
						this.filter = this.emptyFilter();
					}
				} catch (err) {
					console.log('Error reading filter preferences from local storage.', err);
				} finally {
					this.isReady = true;
				}
			},
		},

		watch: {
			filter: {
				deep: true,
				handler() {
					this.saveCurrentFilter();
				},
			},
		},

		computed: {
			filterStateKey: function() {
				return 'INVOICE_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},
		},
	};
</script>

<style lang="scss">
</style>
