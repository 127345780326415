<template>
	<div class="row-format" style="gap: 8px; width: fit-content">
		<div v-if="inInbox" class="row-format align-center">
			<v-menu
				v-model="snoozeMenu"
				:close-on-content-click="false"
				:close-on-click="true"
				content-class="filter-menu-class"
				style="overflow-y: auto"
			>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon v-tippy="{ content: 'Snooze' }">
						<v-icon :size="size" class="material-symbols-rounded" color="black">snooze</v-icon>
					</v-btn>
				</template>

				<div class="column-format text-left font-14" style="width: 220px">
					<div class="pt-2 pl-5 font-16 brand-medium">Snooze until</div>
					<div class="add-new-dropdown" v-if="snoozeOptions">
						<div
							class="add-new-item row-format"
							v-for="option in snoozeOptions"
							:key="option.id"
							@click="handleCustomSnooze(option.date)"
						>
							<div>{{ option.label }}</div>
							<div class="ml-auto">
								{{ option.date.toFormat('ccc') }} {{ option.date.toLocaleString(HDateTime.TIME_SIMPLE) }}
							</div>
						</div>
						<div class="add-new-item row-format align-center" @click="customSnoozeDateTime">
							<div>Pick date & time</div>
							<v-icon color="secondary" class="ml-auto">$calendarClock</v-icon>
						</div>
					</div>
				</div>
			</v-menu>

			<v-btn icon @click.stop="$emit('unread')" v-tippy="{ content: unread ? 'Mark read' : 'Mark unread' }">
				<v-icon :size="size" color="black" class="material-symbols-rounded">{{
					unread ? 'mark_email_read' : 'mark_email_unread'
				}}</v-icon>
			</v-btn>
			<v-btn icon @click.stop="$emit('archive')" v-tippy="{ content: 'Archive' }">
				<v-icon :size="size" color="black" class="material-symbols-rounded">archive</v-icon>
			</v-btn>
			<pipeline-link-widget
				v-if="itemType"
				:item-id="itemId"
				:item-type="itemType"
				:icon-only="true"
			></pipeline-link-widget>
		</div>
		<div v-else class="row-format align-center">
			<v-btn icon @click.stop="$emit('return-to-inbox')" v-tippy="{ content: 'Return to inbox' }">
				<v-icon color="black" class="material-symbols-rounded" :size="size">forward_to_inbox</v-icon>
			</v-btn>
			<pipeline-link-widget
				v-if="itemType"
				:item-id="itemId"
				:item-type="itemType"
				:icon-only="true"
			></pipeline-link-widget>
		</div>

		<basic-modal v-if="customMenu" :dialog="customMenu" @close="customMenu = false" :persistent="false">
			<snooze-picker @result="handleCustomSnooze($event)"></snooze-picker>
		</basic-modal>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
	import BasicModal from '@/components/BasicModal';
	import SnoozePicker from '@/modules/communicator/inbox/SnoozePicker';

	export default {
		name: 'InboxActions',

		props: {
			size: {
				type: Number,
				required: false,
				default: 22,
			},
			unread: {
				type: Boolean,
				required: false,
				default: true,
			},
			folder: {
				type: String,
				required: true,
			},
			snoozeOptions: {
				type: Array,
				required: true,
			},
			itemType: {
				type: String,
				required: false,
			},
			itemId: {
				type: String,
				required: false,
			},
		},

		components: { PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'), SnoozePicker, BasicModal },

		data: function() {
			return {
				HDateTime: HDateTime,
				snoozeMenu: false,
				customMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			customSnoozeDateTime: function() {
				this.customMenu = true;
			},
			handleCustomSnooze: function(date) {
				this.customMenu = false;
				this.snoozeMenu = false;
				this.$emit('snooze', date);
			},
		},

		computed: {
			inInbox: function() {
				return this.folder === 'Inbox' || this.folder === 'Open';
			},
		},
	};
</script>

<style scoped lang="scss">
	.action {
		width: 32px;
		height: 32px;
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			border: 1px solid var(--v-gray_50-base);
		}
	}
</style>
