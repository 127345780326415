<template>
	<div>
		<div v-if="!paidAccount" class="column-format centered mx-3 mt-5 mb-4 px-3">
			<free-tier-card
				style="height: 90px; width: 100%"
				message="Client portal is not available on the Essentials plan. You can explore this feature, but access by your clients will be restricted."
				@upgrade-clicked="upgradeClicked()"
			/>
		</div>
		<customization v-show="currentView === 'Customization'" ref="customization"></customization>
		<custom-domains v-show="currentView === 'Domains'" ref="domains"></custom-domains>
		<permissions v-if="currentView === 'Permissions'" ref="permissions" :filter="filter"></permissions>
		<features v-if="currentView === 'Features'" ref="features"></features>
		<custom-email v-show="currentView === 'Email'" ref="email"></custom-email>
	</div>
</template>

<script>
	import CustomDomains from '@/modules/portal/CustomDomains';
	import Permissions from '@/modules/portal/Permissions';
	import CustomEmail from '@/modules/portal/CustomEmail';
	import FreeTierCard from '@/components/FreeTierCard';
	import Customization from "@/modules/portal/customization/Customization";
	import Features from "@/modules/portal/customization/Features";

	export default {
		name: 'PortalConfig',

		props: ['v'],

		components: {Customization, CustomEmail, Permissions, CustomDomains, FreeTierCard, Features },

		data: function () {
			return {
				currentView: 'Customization',
				pages: [
					{ label: this.$t('portal.branding'), value: 'Customization' },
					{ label: this.$t('portal.permissions'), value: 'Permissions' },
					{ label: this.$t('portal.custom-domains'), value: 'Domains' },
					{ label: this.$t('portal.custom-email'), value: 'Email' },
					{ label: this.$t('portal.request-settings'), value: 'Requests' },
				],
			};
		},

		mounted() {
			if (this.v) {
				this.currentView = this.v;
			}
			this.$track.record('page-view', { module: 'portal-' + this.currentView.toLowerCase() });
		},

		beforeDestroy() {},

		beforeRouteLeave (to, from, next) {
			if(this.currentView === 'Customization'){
				this.$refs.customization.beforeRouteLeave(to,from,next);
			}else{
				next();
			}
		},

		methods: {
			upgradeClicked: function () {
				this.$router.push('/subscription');
			},

			setView: function (view) {
				this.currentView = view;
				this.$track.record('page-view', { module: 'portal-' + this.currentView.toLowerCase() });
				window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
			},
		},

		watch: {
			v: function (newVal) {
				this.setView(newVal);
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
