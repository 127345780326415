<template>
	<div class="row-format send-button align-center" :style="`min-width: ${width}px`">
		<div @click="handleClick()" :class="`send row-format align-center ${disabled ? 'disabled' : ''}`">
			<div style="text-align: center; width:100%">{{ label }}</div>
		</div>
		<v-menu :close-on-click="true" :close-on-content-click="true" :disabled="disabled">
			<template v-slot:activator="{ on }">
				<div :class="`later row-format align-center ${disabled ? 'disabled' : ''}`" v-on="on">
					<v-icon class="material-symbols-rounded" :color="disabled ? 'gray_30' : 'primary'">arrow_drop_down</v-icon>
				</div>
			</template>
			<div class="column-format text-left font-14" style="width: 220px; background-color: var(--v-white-base)">
				<div class="pt-2 pl-5 font-16 brand-medium">Schedule send</div>
				<div class="add-new-dropdown" v-if="sendLaterOptions">
					<div
						class="add-new-item row-format"
						v-for="option in sendLaterOptions"
						:key="option.id"
						@click="handleCustomSnooze(option.date)"
					>
						<div>{{ option.label }}</div>
						<div class="ml-auto">
							{{ option.date.toFormat('ccc') }} {{ option.date.toLocaleString(HDateTime.TIME_SIMPLE) }}
						</div>
					</div>
					<div class="add-new-item row-format align-center" @click="customSnoozeDateTime">
						<div>Pick date & time</div>
						<v-icon color="secondary" class="ml-auto">$calendarClock</v-icon>
					</div>
				</div>
			</div>
		</v-menu>
		<basic-modal v-if="customMenu" :dialog="customMenu" @close="customMenu = false" :persistent="false">
			<snooze-picker @result="handleCustomSnooze($event)"></snooze-picker>
		</basic-modal>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
	import BasicModal from '@/components/BasicModal';
	import SnoozePicker from '@/modules/communicator/inbox/SnoozePicker';

	export default {
		name: 'SendEmailButton',

		props: {
			label: {
				type: String,
				required: false,
				default: 'Send',
			},
			width: {
				type: Number,
				required: false,
				default: 160,
			},
			disabled: {
				type: Boolean,
				required: false,
				default: false,
			},
		},

		components: { BasicModal, SnoozePicker },

		data: function() {
			return {
				customMenu: false,
				HDateTime: HDateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClick: function(){
				if(!this.disabled){
					this.$emit('click');
				}
			},

			customSnoozeDateTime: function() {
				this.customMenu = true;
			},

			handleCustomSnooze: function(selection) {
				if(!this.disabled && selection){
					this.$emit('click',selection.toISO());
				}
				this.customMenu = false;
			},
		},

		computed: {
			sendLaterOptions: function() {
				let result = [];

				let now = HDateTime.now();
				let hourOfDay = now.get('hour');
				let dayOfWeek = now.get('weekday');

				if (hourOfDay < 8) {
					result.push({ label: 'This morning', date: now.set({ hour: 8, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 9) {
					result.push({ label: 'Later today', date: now.set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 14) {
					result.push({ label: 'This afternoon', date: now.set({ hour: 15, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 17) {
					result.push({ label: 'This evening', date: now.set({ hour: 18, minute: 0, second: 0, millisecond: 0 }) });
				}

				result.push({
					label: 'Tomorrow',
					date: now.plus({ day: 1 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				if (dayOfWeek < 3) {
					result.push({
						label: 'Later this week',
						date: now.set({ weekday: 5, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				if (dayOfWeek < 6) {
					result.push({
						label: 'This weekend',
						date: now.set({ weekday: 6, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				result.push({
					label: 'Next week',
					date: now.plus({ week: 1 }).set({ weekday: 1, hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.send-button {
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		cursor: pointer;
		min-height: 32px;
		height: 32px;
		font-size:14px;
		font-weight: 500;
		color: var(--v-primary-base);
		background-color: var(--v-white-base);

		.disabled {
			color: var(--v-gray_50-base)!important;
			cursor: default!important;

			&:hover {
				background-color: var(--v-white-base)!important;
			}
		}

		.send {
			padding-left: 8px;
			padding-right: 8px;
			height: 100%;
			border-radius: 4px 0 0 4px;
			flex-grow: 1;

			&:hover {
				background-color: var(--v-gray_20-base);
			}
		}

		.later {
			border-left: 1px solid var(--v-gray_30-base);
			padding-right: 8px;
			padding-left: 8px;
			height: 100%;
			border-radius: 0 4px 4px 0;
			flex-grow: 0;
			&:hover {
				background-color: var(--v-gray_20-base);
			}
		}
	}
</style>
