var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('div',{staticClass:"column-format",staticStyle:{"flex":"1"}},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.filteredSubmissions,"sort-by":"submittedAt","sort-desc":"","disable-pagination":true,"hide-default-footer":true},on:{"click:row":function($event){return _vm.openFormSubmission($event)}},scopedSlots:_vm._u([{key:"item.submittedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center gap-2"},[_c('div',[_vm._v(_vm._s(_vm.DateTime.fromISO(item.submittedAt).toFormat("ccc") + ' ' + _vm.DateTime.fromISO(item.submittedAt).toLocaleString(_vm.DateTime.DATETIME_MED)))]),(item.summary)?_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({theme: 'left-align'}),expression:"{theme: 'left-align'}"}],staticClass:"material-symbols-rounded",attrs:{"color":"gray_90","size":"20","content":item.summary}},[_vm._v("summarize")]):_vm._e()],1)]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatContact(item))+" ")]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [(_vm.getClient(item.clientId))?_c('div',{staticClass:"row-format align-center"},[_c('client-avatar',{attrs:{"small":"","client":_vm.getClient(item.clientId)}}),_c('div',[_vm._v(_vm._s(_vm.getClient(item.clientId).name))])],1):_c('span',[_vm._v(" -- ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":true,"nudge-bottom":36},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c('div',{staticClass:"ml-1"},[_c('v-btn',_vm._g({staticClass:"menu-activator",attrs:{"icon":""}},scope.on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(_vm._s(scope.value ? '$arrowUp' : '$moreHorizontal'))])],1)],1)]}}],null,true)},[_c('div',{staticClass:"more-menu"},[_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.openFormSubmission(item)}}},[_vm._v(" "+_vm._s(_vm.$t('global.view-details'))+" ")]),_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.deleteFormSubmission(item)}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")])])])]}}],null,false,3906010056)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }