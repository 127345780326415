<template>
	<div class="column-format wrapper" style="max-height: 100%; height: 100%">
		<div v-if="detailView" class="detail-view px-2">
			<detail-view
				:clientId="clientId"
				:key="`detail-${detailView.id}`"
				:communication="detailView"
				:user-emails="userEmails"
				:email-boxes="emailBoxes"
				@back="clearDetailView"
				@email-thread-updated="emailThreadUpdated($event)"
				@recent-call-updated="recentCallUpdated($event)"
				@message-thread-updated="messageThreadUpdated($event)"
				@view-communication="setDetailView($event)"
				:snooze-options="snoozeOptions"
				:folder="filter.folder"
				:in-modal="false"
			></detail-view>
		</div>

		<div class="py-3 px-3 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<div class="row-format align-center mr-4" style="gap: 8px; flex-wrap: wrap">
				<inbox-filter v-model="rawSearch" @input="onSearchChange"></inbox-filter>
				<generic-filter :filter="filter" :items="['All', 'Unread', 'Read']" property="read"></generic-filter>
				<client-filter :filter="filter" v-if="!clientId"></client-filter>
				<channel-filter :filter="filter"></channel-filter>
				<generic-filter :filter="filter" :items="['Inbox', 'Snoozed', 'Archived']" property="folder"></generic-filter>
				<div style="border-right: 2px solid var(--v-gray_50-base); height: 20px" class="mx-2"></div>
				<scope-button
					scope="today"
					:active-scope="filter.dateSpecifier"
					label="Today"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="this-week"
					:active-scope="filter.dateSpecifier"
					label="This week"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="last-week"
					:active-scope="filter.dateSpecifier"
					label="Last week"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="month-to-date"
					:active-scope="filter.dateSpecifier"
					label="This month"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="last-month"
					:active-scope="filter.dateSpecifier"
					label="Last month"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="between"
					:active-scope="filter.dateSpecifier"
					label="Custom dates"
					@scope-update="updateScope"
				></scope-button>
				<div v-if="filter.dateSpecifier === 'between'" class="row-format">
					<scope-date-selector :date="filter.earliest" @change="setEarliest($event)"></scope-date-selector>
					<div class="mx-1">-</div>
					<scope-date-selector :date="filter.latest" @change="setLatest($event)"></scope-date-selector>
				</div>
			</div>

			<div class="ml-auto row-format align-center">
				<pagination v-model="currentPage" :total-pages="totalPages" v-if="totalPages > 0"></pagination>
				<div style="border-left: 1px solid var(--v-gray_50-base);" class="pl-3 ml-2">
					<plus-button v-if="!clientId"  @click="newEmail()" ></plus-button>
				</div>
			</div>
		</div>
		<div class="inbox brand-semilight">
			<div style="height: 100%; overflow-y: scroll" :class="$store.getters.scroll" :key="refreshKey">
				<div v-for="comm in paginatedItems" :key="comm.id" class="communication py-2 px-0">
					<component
						:is="comm.type"
						:communication="comm"
						:clientId="clientId"
						:email-boxes="emailBoxes"
						:subscription="subscription"
						:user-emails="userEmails"
						@click="setDetailView(comm)"
						@email-thread-updated="emailThreadUpdated($event)"
						@recent-call-updated="recentCallUpdated($event)"
						@message-thread-updated="messageThreadUpdated($event)"
						:snooze-options="snoozeOptions"
						:folder="filter.folder"
					></component>
				</div>
				<div v-if="!communications.length" class="mt-12">No conversations.</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import DateTime from '@/modules/utils/HDateTime';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmailThread from '@/modules/communicator/inbox/email/EmailThread';
	import MessageThread from '@/modules/communicator/inbox/messages/MessageThread';
	import RecentCall from '@/modules/communicator/inbox/calls/RecentCall';
	import Editor from '@tinymce/tinymce-vue';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import CommunicatorMessageService from '@/modules/communicator/CommunicatorMessageService';
	import CommunicatorCallService from '@/modules/communicator/CommunicatorCallService';
	import DetailView from '@/modules/communicator/inbox/DetailView';
	import NewEmail from '@/modules/communicator/inbox/email/NewEmail';
	import ClientFilter from '@/modules/home/insights/ClientFilter';
	import ScopeButton from '@/modules/home/insights/ScopeButton';
	import ScopeDateSelector from '@/modules/home/insights/ScopeDateSelector';
	import ChannelFilter from '@/modules/communicator/inbox/ChannelFilter';
	import GenericFilter from '@/modules/communicator/inbox/GenericFilter';
	import HDateTime from '@/modules/utils/HDateTime';
	import Ticket from '@/modules/communicator/inbox/tickets/Ticket';
	import PlusButton from '@/components/PlusButton';
	import InboxFilter from '@/modules/communicator/InboxFilter';
	import {debounce} from "lodash";
	import Pagination from "@/components/Pagination";

	export default {
		name: 'Inbox',

		props: ['emailBoxes', 'subscription', 'clientId'],

		components: {
			Pagination,
			InboxFilter,
			GenericFilter,
			ChannelFilter,
			ClientFilter,
			ScopeButton,
			ScopeDateSelector,
			DetailView,
			EmailThread,
			MessageThread,
			RecentCall,
			Ticket,
			Editor,
			PlusButton,
		},

		data: function() {
			return {
				filter: this.emptyFilter(),
				inboxSearch: null,
				rawSearch: null,

				communicatorService: new CommunicatorService(),
				communicatorEmailService: new CommunicatorEmailService(),
				communicatorMessageService: new CommunicatorMessageService(),
				communicatorCallService: new CommunicatorCallService(),
				emailThreads: [],
				messageThreads: [],
				recentCalls: [],
				detailStack: [],
				refreshKey: 0,
				timerId: null,
				currentPage: 1,
				pageSize: 20,
				debouncer: debounce(this.updateSearch,500)
			};
		},

		mounted() {
			this.getFilterState();
			this.initialize();
		},

		beforeDestroy() {
			this.shutDown();
		},

		methods: {
			updateSearch() {
				this.inboxSearch = this.rawSearch;
				this.currentPage = 1;
			},

			onSearchChange(){
				this.debouncer();
			},

			initialize: function() {
				this.setupListeners();
				this.getData();
				this.timerId = setInterval(() => this.refreshKey++, 60000);
			},

			shutDown: function() {
				this.stopListeners();
				clearInterval(this.timerId);
			},

			getFilterState: function() {
				try {
					let filterState = localStorage.getItem(this.filterStateKey);
					if (!this.$validations.isEmpty(filterState)) {
						this.filter = JSON.parse(filterState);
					} else {
						this.filter = this.emptyFilter();
					}
				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}
			},

			setFilterState: function(filter) {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			clearFilter: function() {
				this.filter = this.emptyFilter();
			},

			emptyFilter: function() {
				return {
					read: 'All',
					folder: 'Inbox',
					dateSpecifier: 'this-week',
					earliest: null,
					latest: null,
					clients: [],
					channels: [],
				};
			},

			setupListeners: function() {
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorEmailThread`, this.emailThreadNotification);
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorCallDetail`, this.recentCallNotification);
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessageThread`, this.messageThreadNotification);
			},

			stopListeners: function() {
				this.$store.state.eventBus.$off(`u-${this.userId}.communicatorEmailThread`, this.emailThreadNotification);
				this.$store.state.eventBus.$off(`u-${this.userId}.communicatorCallDetail`, this.recentCallNotification);
				this.$store.state.eventBus.$off(`u-${this.userId}.communicatorMessageThread`, this.messageThreadNotification);
			},

			setDetailView: function(comm) {
				let ix = this.detailStack.findIndex((d) => d.id === comm.id);
				if (ix > -1) {
					this.detailStack.splice(ix, 1);
				}
				this.detailStack.push(comm);
			},

			clearDetailView: function() {
				this.detailStack.pop();
			},

			addNew: function(clientId = null) {
				this.newEmail(clientId);
			},

			newEmail: function(clientId = null) {
				this.$store.state.globalModalController
					.openModal(NewEmail, { clientId: clientId }, true, true, false, true)
					.then((res) => {
						if (res) {
							let thread = res.thread;
							this.emailThreads.push(thread);
							this.refreshKey++;
						}
					});
			},

			emailThreadNotification: function(event) {
				this.emailThreadUpdated(event.message);
			},

			recentCallNotification: function(event) {
				this.recentCallUpdated(event.message);
			},

			messageThreadNotification: function(event) {
				this.messageThreadUpdated(event.message);
			},

			emailThreadUpdated: function(thread) {
				thread.date = HDateTime.fromISO(thread.lastMessageTime);
				thread.clientIds = [...this.getClientsForEmailThread(thread)];
				thread.searchFields = [...this.getSearchFieldsForEmailThread(thread)];

				let ix = this.emailThreads.findIndex((t) => t.id === thread.id);
				if (ix > -1) {
					this.emailThreads.splice(ix, 1, thread);
				} else {
					this.emailThreads.push(thread);
				}
				this.refreshKey++;
			},

			recentCallUpdated: function(recentCall) {
				recentCall.date = HDateTime.fromISO(recentCall.startTime);
				recentCall.clientIds = [...this.getClientForRecentCall(recentCall)];
				recentCall.searchFields = [...this.getSearchFieldsForRecentCall(recentCall)];

				let ix = this.recentCalls.findIndex((t) => t.id === recentCall.id);
				if (ix > -1) {
					this.recentCalls.splice(ix, 1, recentCall);
				} else {
					this.recentCalls.push(recentCall);
				}
				this.refreshKey++;
			},

			messageThreadUpdated: function(thread) {
				thread.date = HDateTime.fromISO(thread.lastMessage);
				thread.clientIds = [...this.getClientsForMessageThread(thread)];
				thread.searchFields = [...this.getSearchFieldsForMessageThread(thread)];

				let ix = this.messageThreads.findIndex((t) => t.id === thread.id);
				if (ix > -1) {
					this.messageThreads.splice(ix, 1, thread);
				} else {
					this.messageThreads.push(thread);
				}
				this.refreshKey++;
			},

			getData: function() {
				this.currentPage = 1;
				this.getEmailThreads();
				this.getMessageThreads();
				this.getRecentCalls();
			},

			getEmailThreads: function() {
				this.communicatorEmailService
					.getEmailThreads(this.earliest.toISO(), this.latest.toISO(), this.archived, this.clientId)
					.then((res) => {
						this.emailThreads.splice(0, this.emailThreads.length);
						this.emailThreads.push(...res.data);
						this.emailThreads.forEach((e) => {
							e.date = HDateTime.fromISO(e.lastMessageTime);
							e.clientIds = [...this.getClientsForEmailThread(e)];
							e.searchFields = [...this.getSearchFieldsForEmailThread(e)];
						});
					});
			},

			getMessageThreads: function() {
				this.communicatorMessageService
					.getMessageThreads(this.earliest.toISO(), this.latest.toISO(), this.archived, this.clientId)
					.then((res) => {
						this.messageThreads.splice(0, this.messageThreads.length);
						this.messageThreads.push(...res.data);
						this.messageThreads.forEach((m) => {
							m.date = HDateTime.fromISO(m.lastMessage);
							m.clientIds = [...this.getClientsForMessageThread(m)];
							m.searchFields = [...this.getSearchFieldsForMessageThread(m)];
						})
					});
			},

			getRecentCalls: function() {
				this.communicatorCallService
					.getCallDetails(this.earliest.toISO(), this.latest.toISO(), this.archived, this.clientId)
					.then((res) => {
						this.recentCalls.splice(0, this.recentCalls.length);
						this.recentCalls.push(...res.data);
						this.recentCalls.forEach((c) => {
							c.date = HDateTime.fromISO(c.startTime);
							c.clientIds = [...this.getClientForRecentCall(c)];
							c.searchFields = [...this.getSearchFieldsForRecentCall(c)];
						});
					});
			},

			updateScope: function(scope) {
				if (scope !== this.filter.dateSpecifier) {
					this.filter.dateSpecifier = scope;
					this.getData();
				}
			},

			setEarliest: function(earliest) {
				this.filter.earliest = earliest;
				this.getData();
			},

			setLatest: function(latest) {
				this.filter.latest = latest;
				this.getData();
			},

			getClientForRecentCall(call) {
				let contact;
				if (call.direction === 'Inbound') {
					contact = this.$store.getters.getContactByPhone(call.from);
				} else {
					contact = this.$store.getters.getContactByPhone(call.to);
				}

				if (contact) {
					return [contact.clientId];
				} else {
					return [];
				}
			},

			getClientsForMessageThread(thread) {
				let clientIds = [];
				thread.participants.map((p) => {
					let contact = this.$store.getters.getContactByPhone(p.phone);
					if (contact && contact.clientId) {
						clientIds.push(contact.clientId);
					}
				});
				return clientIds;
			},

			getClientsForEmailThread(thread) {
				let participants = thread.participants.filter((p) => !this.userEmails.includes(p.address.toLowerCase()));
				let result = new Set();
				participants.forEach((p) => {
					let contact = this.$store.getters.getContactByEmail(p.address);
					if (contact && contact.clientId) {
						result.add(contact.clientId);
					}
				});
				return Array.from(result);
			},

			getSearchFieldsForEmailThread(thread) {
				let result = [];
				result.push(thread.subject);
				thread.participants.forEach((p) => {
					let c = this.$store.getters.getContactByEmail(p.address);
					if (c) {
						result.push(c.firstName + ' ' + c.lastName + ' ' + c.email);
					} else {
						result.push(p.personal + ' ' + p.address);
					}
				});
				return result;
			},

			getSearchFieldsForMessageThread(thread) {
				let result = [];
				result.push(thread.lastMessageText);
				thread.participants.forEach((p) => {
					let c = this.$store.getters.getContactByPhone(p.phone);
					if (c) {
						result.push(c.firstName + ' ' + c.lastName + ' ' + c.email + ' ' + c.phone);
					} else {
						result.push(p.phone);
					}
				});
				return result;
			},

			getSearchFieldsForRecentCall(call) {
				let result = [];

				let p = call.direction === 'Inbound' ? call.from : call.to;
				let c = this.$store.getters.getContactByPhone(p);

				if (c) {
					result.push(c.firstName + ' ' + c.lastName + ' ' + c.email + ' ' + c.phone);
				} else {
					result.push(p);
				}

				if (call.transcription && call.transcription.text) {
					result.push(call.transcription.text);
				}

				return result;
			},

			isObjectSearchMatch(comm) {
				for (let i = 0; i < comm.searchFields.length; i++) {
					if (comm.searchFields[i] && comm.searchFields[i].toLowerCase().includes(this.inboxSearch.toLowerCase())) {
						return true;
					}
				}
				return false;
			},

			changePage(step) {
				const nextPage = this.currentPage + step;
				if (nextPage > 0 && nextPage <= this.totalPages) {
					this.currentPage = nextPage;
				}
			},
		},

		watch: {
			archived: function() {
				this.getData();
			},
			filter: {
				handler: function(val) {
					this.setFilterState(val);
				},
				deep: true,
			},
		},

		computed: {
			filterStateKey: function() {
				if (this.clientId) {
					return (
						'CLIENT_INBOX_FILTER_STATE_' +
						this.$store.getters.getAccountId +
						'_' +
						this.$store.getters.getLoggedInUserId
					);
				} else {
					return 'INBOX_FILTER_STATE_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
				}
			},

			userId: function() {
				return this.$store.getters.getLoggedInUserId;
			},

			detailView: function() {
				if (this.detailStack.length) {
					return this.detailStack[this.detailStack.length - 1];
				} else {
					return null;
				}
			},

			snoozeOptions: function() {
				let result = [];

				let now = HDateTime.now();
				let hourOfDay = now.get('hour');
				let dayOfWeek = now.get('weekday');

				if (hourOfDay < 8) {
					result.push({ label: 'This morning', date: now.set({ hour: 8, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 9) {
					result.push({ label: 'Later today', date: now.set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 14) {
					result.push({ label: 'This afternoon', date: now.set({ hour: 15, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 17) {
					result.push({ label: 'This evening', date: now.set({ hour: 18, minute: 0, second: 0, millisecond: 0 }) });
				}

				result.push({
					label: 'Tomorrow',
					date: now.plus({ day: 1 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				if (dayOfWeek < 3) {
					result.push({
						label: 'Later this week',
						date: now.set({ weekday: 5, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				if (dayOfWeek < 6) {
					result.push({
						label: 'This weekend',
						date: now.set({ weekday: 6, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				result.push({
					label: 'Next week',
					date: now.plus({ week: 1 }).set({ weekday: 1, hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				return result;
			},

			userEmails: function() {
				return this.emailBoxes.map((e) => e.username.toLowerCase());
			},

			totalPages() {
				return Math.ceil(this.communications.length / this.pageSize);
			},

			paginatedItems() {
				const start = (this.currentPage - 1) * this.pageSize;
				const end = start + this.pageSize;
				return this.communications.slice(start, end);
			},

			communications: function() {
				let result = [];

				this.emailThreads.forEach((e) => {
					let snoozedUntil = e.snoozedUntil ? HDateTime.fromISO(e.snoozedUntil) : null;

					result.push({
						id: e.id,
						type: 'EmailThread',
						channel: 'Emails',
						date: e.date > snoozedUntil ? e.date.toISO() : snoozedUntil.toISO(),
						payload: e,
						unread: e.unread,
						archived: e.archived,
						snoozedUntil: snoozedUntil,
						clientIds: e.clientIds,
						searchFields: e.searchFields,
					});
				});

				this.messageThreads.forEach((m) => {
					let snoozedUntil = m.snoozedUntil ? HDateTime.fromISO(m.snoozedUntil) : null;

					result.push({
						id: m.id,
						type: 'MessageThread',
						channel: 'Messages',
						date: m.date > snoozedUntil ? m.date.toISO() : snoozedUntil.toISO(),
						payload: m,
						unread: m.unread,
						archived: m.archived,
						snoozedUntil: snoozedUntil,
						clientIds: m.clientIds,
						searchFields: m.searchFields
					});
				});

				this.recentCalls.forEach((c) => {
					let snoozedUntil = c.snoozedUntil ? HDateTime.fromISO(c.snoozedUntil) : null;

					result.push({
						id: c.id,
						type: 'RecentCall',
						channel: 'Calls',
						date: c.date > snoozedUntil ? c.date.toISO() : snoozedUntil.toISO(),
						payload: c,
						unread: c.unread,
						archived: c.archived,
						snoozedUntil: snoozedUntil,
						clientIds: c.clientIds,
						searchFields: c.searchFields,
					});
				});

				result.sort((a, b) => {
					if (this.filter.folder === 'Snoozed') {
						return a.date.localeCompare(b.date);
					} else {
						return b.date.localeCompare(a.date);
					}
				});

				let now = HDateTime.now();

				result = result.filter((a) => {
					if (this.filter.folder !== 'Archived' && a.archived) {
						return false;
					}
					if (this.filter.folder === 'Snoozed') {
						if (a.snoozedUntil && a.snoozedUntil > now) {
							return true;
						} else {
							return false;
						}
					} else if (this.filter.folder === 'Inbox') {
						if (a.snoozedUntil && a.snoozedUntil > now) {
							return false;
						} else {
							return true;
						}
					} else if (this.filter.folder === 'Archived') {
						return a.archived;
					} else {
						return true;
					}
				});

				result = result.filter((a) => {
					if (this.filter.read === 'All') {
						return true;
					} else if (this.filter.read === 'Read' && !a.unread) {
						return true;
					} else if (this.filter.read === 'Unread' && a.unread) {
						return true;
					} else {
						return false;
					}
				});

				result = result.filter((a) => {
					if (this.filter.channels && this.filter.channels.length) {
						for (let i = 0; i < this.filter.channels.length; i++) {
							let channel = this.filter.channels[i];
							channel = channel === 'All Requests' ? 'Requests' : channel;
							if (channel === 'Requests' && a.channel.includes(channel)) {
								return true;
							}
						}
						return this.filter.channels.includes(a.channel);
					} else {
						return true;
					}
				});

				result = result.filter((a) => {
					if (this.filter.clients.length) {
						let intersection = a.clientIds.filter((x) => this.filter.clients.includes(x));
						return intersection.length > 0;
					} else {
						return true;
					}
				});

				result = result.filter((a) => {
					if (this.inboxSearch) {
						return this.isObjectSearchMatch(a);
					} else {
						return true;
					}
				});

				return result;
			},

			inbox: function() {
				if (this.filter.folder === 'Inbox') {
					return true;
				} else {
					return false;
				}
			},

			archived: function() {
				if (this.filter.folder === 'Archived') {
					return true;
				} else {
					return false;
				}
			},

			earliest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return DateTime.now()
						.minus({ months: 2 })
						.startOf('month');
				}
			},

			latest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest.endOf('day');
					}
				} else {
					return DateTime.now().endOf('month');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.wrapper {
		position: relative;
		height: 100%;
		padding-bottom: 8px;
		//height: calc(var(--vh) - 75px);
	}

	.inbox {
		//border: 1px solid var(--v-gray_50-base);
		//border-radius: 12px;
		width: 100%;
		flex: 1;
		overflow-y: scroll;
	}

	.detail-view {
		position: absolute;
		width: 100%;
		height: 100%;
		flex: 1;
		overflow-y: scroll;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: var(--v-white-base);
	}

	.communication {
		border-bottom: 1px solid var(--v-gray_50-base);
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_10-base);
		}
		//&:last-child {
		//	border: none;
		//}
	}
</style>
