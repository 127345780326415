<template>
	<div>
	</div>
</template>

<script>

	import NotificationService from "@/modules/productivity/notifications/NotificationService";

	export default {
		name: 'NotificationStateManager',

		props: [],

		components: {

		},

		data: function () {
			return {
				notificationService: new NotificationService(),
			};
		},

		mounted() {
			this.getNotifications();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$on(`u-${this.userId}.notification`, this.handleNewNotification);
			this.$store.state.eventBus.$on(`u-${this.userId}.notificationEvent`, this.handleNotificationEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$off(`u-${this.userId}.notification`, this.handleNewNotification);
			this.$store.state.eventBus.$off(`u-${this.userId}.notificationEvent`, this.handleNotificationEvent);
		},

		methods: {
			handleAccountChange: function () {
				this.getNotifications();
			},

			getNotifications: function () {
				this.notificationService
					.getNotifications()
					.then((res) => {
						this.notifications.splice(0, this.notifications.length);
						this.notifications.push(...res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.message.data);
					});
			},

			deleteAll: function () {
				this.notificationService.deleteNotifications();
			},

			markAllAsRead: function () {
				this.notificationService.markAllAsRead();
			},

			handleNewNotification(event) {
				if (event.message.accountId !== this.$store.getters.getAccountId) {
					return;
				}

				this.notifications.push(event.message);
			},

			handleNotificationEvent(event) {
				if (event.message.accountId !== this.$store.getters.getAccountId) {
					return;
				}

				let eventType = event.message.eventType;
				let id = event.message.id;

				if (eventType === 'DELETE_ALL') {
					this.notifications.splice(0, this.notifications.length);
				} else {
					for (let i = 0; i < this.notifications.length; i++) {
						if (eventType === 'READ' && this.notifications[i].id === id) {
							this.$store.state.notifications[i].read = true;
							break;
						} else if (eventType === 'READ_ALL') {
							this.notifications[i].read = true;
						} else if (eventType === 'DELETE' && this.notifications[i].id === id) {
							this.notifications.splice(i, 1);
							break;
						}
					}
				}
			},
		},

		computed: {
			userId: function () {
				return this.$store.getters.getLoggedInUserId;
			},

			notifications: function () {
				return this.$store.state.productivity.notifications;
			},
		},

		watch: {

		},
	};
</script>

<style scoped lang="scss">
</style>
