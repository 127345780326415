<template>
	<div>
		<v-menu
			:nudge-top="28" max-width="300"
			bottom
			right
			rounded
			offset-overflow
			offset-y
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<div :class="`pointer row-format align-center`" v-on="on">
					<div :class="`font-14 ${isFilterActive ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</div>
					<v-icon x-small class="mx-1" :color="isFilterActive ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</div>
			</template>

			<div class="column-format">
<!--				<v-text-field-->
<!--					@input="updated"-->
<!--					autofocus-->
<!--					solo-->
<!--					flat-->
<!--					hide-details-->
<!--					dense-->
<!--					placeholder="Search..."-->
<!--					v-model="filter.search"-->
<!--				></v-text-field>-->

<!--				<filter-option-->
<!--						:label="$t('client.filter.client-list')"-->
<!--						:option-list="clientList"-->
<!--						v-model="filter.clientList"-->
<!--						@change="updated"-->
<!--				></filter-option>-->
         <div style="max-height: calc(90vh - 90px); overflow-y: auto" class="pa-3">
          <v-checkbox
              dense
              hide-details
              v-model="filter.clientList"
              v-for="client in clientList"
              :key="client.value"
              :value="client.value"
              @change="updated"
          >
            <template v-slot:label>
              <span style="font-size: 14px" class="font-14 brand-semilight">{{ client.text }}</span>
            </template>
          </v-checkbox>
         </div>
        <div class="mx-3 mb-3 mt-3">
					<v-btn
						text
						block
						color="secondary"
						elevation="0"
						style="font-weight: 500"
						class="mt-2"
						@click="clearFilter()"
						><v-icon small>$clear</v-icon> Clear Filters</v-btn
					>
          <v-btn class="super-action mt-1" @click="showMenu = false" color="primary" block>{{
              $t('global.done')
            }}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	//import FilterOption from "@/components/FilterOption";

	export default {
		name: 'ProjectListFilter',

		props: ['filter'],

		components: {
      //FilterOption
    },

		data() {
			return {
				showMenu: false,
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.filter.search = null;
				this.filter.clientList.splice(0,this.filter.clientList.length);
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			clientList: function () {
				let result = [];
				this.$store.getters.clients.forEach((c) => {
					result.push({
						text: c.name,
						value: c.id,
					});
				});
				return result;
			},

			isFilterActive: function() {
				return this.filter.clientList.length;
			},

			filterLabel: function() {
				let label = [];

				// if (this.filter.search) {
				// 	label.push('"' + this.filter.search + '"');
				// }
        //
				// if (this.filter.clientList && this.filter.clientList.length) {
				// 	label.push(`${this.filter.clientList.length} clients`);
				// }

				if (label.length === 0) {
					return 'Client';
				} else {
					return label.length + 'Client' + (label.length > 1 ? 's' : '');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
