<template>
	<div>
<!--		<v-menu-->
<!--			:nudge-top="28"-->
<!--			bottom-->
<!--			right-->
<!--			rounded-->
<!--			offset-overflow-->
<!--			offset-y-->
<!--			:close-on-content-click="false"-->
<!--			content-class="filter-menu-class"-->
<!--			transition="slide-y-transition"-->
<!--			v-model="showMenu"-->
<!--		>-->
<!--			<template v-slot:activator="{ on }">-->
<!--				<div :class="`pointer row-format align-center`" v-on="on">-->
<!--					<div :class="`font-14 ${isFilterActive ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</div>-->
<!--					<v-icon x-small class="mx-1" :color="isFilterActive ? 'secondary' : 'gray_80'">$arrowDown</v-icon>-->
<!--				</div>-->
<!--			</template>-->

			<div class="column-format">
				<v-text-field
					@input="updated"
					autofocus
					solo
					flat
					hide-details
					dense
					placeholder="Search..."
					v-model="textFilter"
				></v-text-field>

<!--				<div class="mx-3 mt-3 mb-3">-->
<!--					<v-btn-->
<!--							text-->
<!--							block-->
<!--							color="secondary"-->
<!--							class="mb-2"-->
<!--							@click="clearFilter()"-->
<!--					><v-icon small>$clear</v-icon> Clear Filters</v-btn-->
<!--					>-->
<!--					<v-btn block class="primary-action" @click="applyFilter()"-->
<!--						>Apply</v-btn-->
<!--					>-->
<!--				</div>-->
			</div>
<!--		</v-menu>-->
	</div>
</template>

<script>
	export default {
		name: 'AccountUserFilter',

		props: {},

		data() {
			return {
				showMenu: false,
				textFilter: null,
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('filter', this.textFilter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.textFilter = null;
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				if (this.textFilter) {
					return true;
				} else {
					return false;
				}
			},

			filterLabel: function() {
				let label = [];

				if (this.textFilter) {
					label.push('"' + this.textFilter + '"');
				}

				if (label.length === 0) {
					return 'Filter...';
				} else {
					return label.join(' | ');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
