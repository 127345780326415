<template>
	<div>
		<bar
			:chart-id="barRefreshKey"
			datasetIdKey="insight-money-bar"
			:key="barRefreshKey"
			:chart-options="barChartOptions"
			:chart-data="barChart"
			:height="300"
		/>
		<basic-modal v-if="showDetail" :dialog="showDetail" @close="showDetail=false">
			<booking-detail :booking-detail="bookingDetail" @close="showDetail=false"></booking-detail>
		</basic-modal>
	</div>
</template>

<script>
	import { Bar } from 'vue-chartjs/legacy';
	import { v4 as uuidv4 } from 'uuid';
	import BasicModal from "@/components/BasicModal";
	import BookingDetail from "@/modules/home/bookings/BookingDetail";

	export default {
		name: 'ForwardChart',

		props: ['bookingData', 'earliest', 'latest'],

		components: {BookingDetail, BasicModal, Bar },

		data: function() {
			return {
				barRefreshKey: uuidv4(),
				barChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					onClick: this.handle,
					scales: {
						x: {
							stacked: true,
							grid: {
								display: false,
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 6,
								maxRotation: 0,
								minRotation: 0,
							},
						},
						y: {
							stacked: true,
						},
					},
				},
				bookingDetail: {
					month: null,
					client: null,
					bookings: [],
				},
				showDetail: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handle(point, event) {
				if (!event.length) {
					return;
				}

				let datasetIndex = event[0].datasetIndex;
				let index = event[0].index;
				let startMonth = this.earliest.plus({ months: index }).toISODate();

				let client = this.barChartData[datasetIndex];
				let bookings = this.bookingData.bookings
					.filter((b) => b.client.id === client.clientId)
					.filter((b) => b.month === startMonth);

				this.bookingDetail.month = startMonth;
				this.bookingDetail.client = client;
				this.bookingDetail.bookings.splice(0);
				this.bookingDetail.bookings.push(... bookings);
				console.log(this.bookingDetail);
				this.showDetail = true;
			},
		},

		computed: {
			barChart: function() {
				let result = {
					id: 'd7d33cdb-cbd8-47ad-8d6c-8c211a46237d',
					labels: this.barChartLabels,
					datasets: this.barChartData,
				};
				return result;
			},

			barChartLabels: function() {
				let start = this.earliest.startOf('month');
				let end = this.latest.endOf('month');
				let labels = [];

				while (start < end) {
					labels.push(start.toFormat('MMM yy'));
					start = start.plus({ months: 1 });
				}

				return labels;
			},

			barChartData: function() {
				let clients = [];
				let clientsInPeriod = new Set(this.bookingData.bookings.map((i) => i.client.id));

				clientsInPeriod.forEach((clientId) => {
					let c = this.$store.getters.getClientById(clientId);
					let start = this.earliest.startOf('month');
					let end = this.latest.endOf('month');
					let totals = [];

					let client = {
						clientId: clientId,
						data: [],
						label: c ? c.name : '[Deleted]',
						backgroundColor: c ? c.color : '#eee',
					};

					while (start < end) {
						totals.push({
							key: start.toISODate(),
							total: 0,
						});
						start = start.plus({ months: 1 });
					}

					this.bookingData.bookings
						.filter((b) => b.client.id === clientId)
						.forEach((b) => {
							let total = totals.find((k) => k.key === b.month);
							if (total) {
								total.total += b.amount;
							}
						});

					client.data = totals.map((t) => t.total);
					clients.push(client);
				});

				return clients;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
