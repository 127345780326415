<template>
	<div id="vendor-detail" :style="'max-width:' + maxWidth + 'px'">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="parentModal" :style="`--max-height:${divMaxHeight}px`">
			<h3 class="modal-title">{{ vendorHeading }}</h3>
			<!--						<div class="font-14 text-left gray_70&#45;&#45;text" v-if="vendorCopy.id">-->
			<!--							Current balance due: {{ $formatters.dollars(vendorCopy.balanceDue) }}-->
			<!--						</div>-->

			<v-container fluid class="px-0 mt-2">
				<v-row>
					<v-col :cols="vendorCopy.id ? 6 : 12" class="pt-0">
						<v-text-field
							class="h-outline mt-0"
							hide-details
							persistent-placeholder
							:label="$t('vendors.vendor-name')"
							v-model="vendorCopy.name"
						></v-text-field>

						<h4 class="brand-medium text-left gray_90--text ml-1">{{ $t('vendors.contact') }}</h4>

						<div class="d-flex mb-n1">
							<div class="pr-1 flex-grow-1">
								<v-text-field
									class="h-outline"
									hide-details
									persistent-placeholder
									:label="$t('contact.first-name')"
									v-model="vendorCopy.contact.firstName"
								></v-text-field>
							</div>
							<div class="pl-1 flex-grow-1">
								<v-text-field
									class="h-outline"
									hide-details
									persistent-placeholder
									v-model="vendorCopy.contact.lastName"
									:label="$t('contact.last-name')"
								></v-text-field>
							</div>
						</div>

						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.contact.email"
							:label="$t('contact.email')"
						></v-text-field>

						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.contact.phone"
							:label="$t('contact.mobile')"
						></v-text-field>

						<h4 class="brand-medium text-left gray_90--text ml-1">{{ $t('global.address') }}</h4>

						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.address1"
							:label="$t('global.address1')"
						></v-text-field>

						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.address2"
							:label="$t('global.address2')"
						></v-text-field>

						<div class="d-flex my-n1">
							<div style="flex: 0 0 30%;">
								<v-text-field
									class="h-outline"
									hide-details
									persistent-placeholder
									v-model="vendorCopy.city"
									:label="$t('global.city')"
								></v-text-field>
							</div>
							<div class="px-2" style="flex: 0 0 40%;">
								<v-autocomplete
									v-if="states.length"
									v-model="vendorCopy.locality"
									:label="$t('contact.locality')"
									autocomplete="new-password"
									class="h-outline"
									hide-details
									persistent-placeholder
									:items="states"
									item-value="abbreviation"
									item-text="name"
								/>
								<v-text-field
									v-else
									v-model="vendorCopy.locality"
									:label="$t('contact.locality')"
									hide-details
									class="h-outline"
									persistent-placeholder
								/>
							</div>
							<div style="flex: 0 0 30%">
								<v-text-field
									class="h-outline"
									hide-details
									persistent-placeholder
									v-model="vendorCopy.postal"
									:label="$t('contact.postal')"
								></v-text-field>
							</div>
						</div>

						<v-autocomplete
							v-model="vendorCopy.country"
							:label="$t('contact.country')"
							class="h-outline"
							hide-details
							persistent-placeholder
							:items="countries.getCountriesSimple()"
							item-value="code"
							item-text="name"
						/>

						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.website"
							:label="$t('global.website')"
						></v-text-field>

						<v-text-field
							class="h-outline mt-4"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.taxId"
							:label="$t('vendors.tax-id')"
						></v-text-field>

						<div class="h-outline d-flex justify-start align-center font-14 px-2 py-1 mb-2">
							<v-simple-checkbox v-model="vendorCopy.track1099" color="var(--v-primary-base)"></v-simple-checkbox>
							<label class="font-gray_90">{{ $t('vendors.track-1099') }}</label>
						</div>

						<v-textarea
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="vendorCopy.notes"
							:label="$t('global.notes')"
							:rows="1"
						></v-textarea>
					</v-col>
					<!-- Show Only in Edit Mode -->
					<v-col cols="6" v-if="vendorCopy.id" class="pl-2 pt-0">
						<v-row>
							<v-col cols="12">
								<file-attachments
									class="mt-6 mb-6"
									:files="vendorCopy.attachments"
									:file-service="attachmentService"
								></file-attachments>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="d-flex justify-space-between body-14 brand-semibold gray_90--text mb-1">
									<div>{{ $t('global.expenses') }}</div>
									<div v-if="expenses.length">{{ $t('vendors.total-expenses', { total: expensesTotal }) }}</div>
								</div>
								<expense-table
									:expenses="expenses"
									table-type="Vendor"
									:dense="true"
									:outline="true"
									@click="editOurExpense($event)"
								></expense-table>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div class="modal-footer" style="border-top:1px solid var(--v-gray_50-base)">
			<v-btn icon class="delete mr-1" @click="$emit('delete-vendor-dialog', vendorCopy)">
				<v-icon>$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action ml-1" :width="$vuetify.breakpoint.smAndUp ? '200' : '128'" @click="saveVendor">
				{{ $t(vendorCopy.id === null ? 'global.create' : 'global.save') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import VendorService from '@/modules/accounting/vendors/VendorService';
	import VendorAttachmentService from '@/modules/accounting/vendors/VendorAttachmentService';
	import ExpenseService from '@/modules/accounting/expenses/ExpenseService';
	import FileAttachments from '@/components/FileAttachments';
	// import HPillSelector from '@/components/HPillSelector';
	import ExpenseTable from '@/modules/accounting/expenses/ExpenseTable';
	import Countries from '@/modules/utils/Countries.js';
	import ExpenseMixin from '@/modules/accounting/expenses/ExpenseMixin';
	import ExpenseDetail from '@/modules/accounting/expenses/ExpenseDetail';

	export default {
		name: 'VendorDetail',

		props: ['vendor'],

		components: { FileAttachments, ExpenseTable },

		mixins: [ExpenseMixin],

		data: function() {
			return {
				vendorService: new VendorService(),
				vendorCopy: this.initialize(),
				attachmentService: new VendorAttachmentService(this.vendor),
				expenseService: new ExpenseService(),
				expenses: [],
				countries: new Countries(),
				headers: [
					{ text: this.$t('expenses.amount'), value: 'amount', class: 'nowrap' },
					{ text: this.$t('expenses.category'), value: 'category', class: 'nowrap' },
					{ text: this.$t('expenses.paid'), value: 'paid', class: 'nowrap', sortable: false },
					{ text: this.$t('expenses.reimbursable'), value: 'reimbursable', class: 'nowrap' },
					{ text: '', value: '', class: '' },
				],
				itemsPerPage: 15,
			};
		},

		mounted() {
			// console.log(this.states);
			if (this.vendor.id) this.getExpenses();
			this.getProjects();
			this.getClients();
			this.getVendors();
		},

		beforeDestroy() {},

		methods: {
			initialize: function() {
				let result;
				if (this.vendor) {
					result = JSON.parse(JSON.stringify(this.vendor));
				} else {
					result = {
						id: null,
						address1: null,
						address2: null,
						attachments: [],
						city: null,
						contact: {
							email: null,
							firstName: null,
							lastName: null,
							phone: null,
						},
						locality: null,
						name: null,
						notes: null,
						postal: null,
						country: null,
						taxId: null,
						track1099: true,
						website: null,
					};
				}
				return result;
			},
			getExpenses() {
				this.expenseService.getExpensesByVendor(this.vendor.id).then((res) => {
					this.expenses.splice(0, this.expenses.length);
					this.expenses.push(...res.data);
				});
			},

			saveVendor: function() {
				if (this.vendorCopy.id) {
					this.updateVendor();
				} else {
					this.createVendor();
				}
			},

			createVendor: function() {
				this.vendorService
					.createVendor(this.vendorCopy)
					.then((res) => {
						this.vendorCopy = res.data;
						this.attachmentService.setVendor(this.vendorCopy);
						this.$emit('result', res.data);
						this.$emit('created', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			updateVendor: function() {
				this.vendorService
					.updateVendor(this.vendorCopy.id, this.vendorCopy)
					.then((res) => {
						this.vendorCopy = res.data;
						this.$emit('updated', res.data);
						this.$emit('close');
						this.$emit('result', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			handleClose: function() {
				this.$emit('close');
				this.$emit('result');
			},

			editOurExpense: function(expense) {
				let binding = {
					expense: expense,
				};
				this.$store.state.globalModalController.openModal(ExpenseDetail, binding).then((res) => {
					if (res) {
						let ix = this.expenses.findIndex((e) => e.id === expense.id);
						if (res.action === 'DELETED') {
							if (ix > -1) {
								this.expenses.splice(ix, 1);
							}
						} else {
							if (ix > -1) {
								this.expenses.splice(ix, 1, res);
							} else {
								this.expenses.push(res);
							}
						}
					}
				});
			},
		},

		computed: {
			vendorHeading: function() {
				if (!this.vendorCopy || !this.vendorCopy.id) {
					return this.$t('vendors.create-vendor');
				} else {
					return this.$t('vendors.vendor');
				}
			},
			states: function() {
				if (this.vendorCopy && this.vendorCopy.country) {
					let country = this.countries.getCountries().find((c) => c.code === this.vendorCopy.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},
			divMaxHeight: function() {
				return this.$vuetify.breakpoint.height - 100;
			},
			maxWidth: function() {
				if (!this.vendor) return 420;
				return this.vendor.id ? 1100 : 700;
			},
			expensesTotal: function() {
				if (!this.expenses.length) return;
				let tmp = this.expenses.map((item) => item.amount).reduce((prev, next) => prev + next);
				return this.$formatters.dollars(tmp, true, true, this.$store.state.defaultCurrency);
			},
		},
	};
</script>

<style lang="scss">
	#vendor-detail {
		.parentModal {
			max-height: var(--max-height);
			padding: 16px;
			overflow-y: auto;
		}
		.modal-footer {
			position: sticky;
			position: -webkit-sticky;
			bottom: 0;
		}
	}
</style>
