<template>
	<div style="max-height: calc(var(--vh) - 450px); overflow-y: auto;" :key="refreshKey" class="pa-0 ma-0">
		<call-detail v-for="call in calls" :key="call.id" :call="call" :is-visible="isVisible" @mark-read="markCallAsRead(call)" @delete="deleteCall(call)"></call-detail>
	</div>
</template>

<script>
import {DateTime} from 'luxon';
import CallDetail from "@/modules/communicator/realtime/recents/CallDetail";
import CommunicatorCallService from "@/modules/communicator/CommunicatorCallService";
import ConfirmModal from "@/components/ConfirmModal";

export default {
	name: "RecentCalls",

	props: ['primary','isVisible'],

	components: {CallDetail},

	data: function () {
		return {
			communicatorService: new CommunicatorCallService(),
			earliest: DateTime.now().minus({days:30}).toISO(),
			latest: DateTime.now().endOf('day').toISO(),
			refreshKey: 0
		}
	},

	mounted() {
		if(this.primary) {
			this.getCallDetailRecords();
			this.setupListeners();
		}
	},

	beforeDestroy() {
		if(this.primary){
			this.stopListeners();
		}
	},

	methods: {
		setupListeners: function () {
			this.$store.state.eventBus.$on(`u-${this.userId}.communicatorCallDetail`, this.handleCallDetail);
		},

		stopListeners: function () {
			this.$store.state.eventBus.$off(`u-${this.userId}.communicatorCallDetail`, this.handleCallDetail);
		},

		deleteCall: function(call){
			let binding = {
				headingText: 'Confirm',
				bodyText: 'Do you want to delete this call record?'
			};
			this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
				if(res){
					this.communicatorService.deleteCall(call.id).then(() => {
						let ix = this.calls.findIndex(c => c.id === call.id);
						if(ix > -1){
							this.calls.splice(ix,1);
						}
					})
				}
			})
		},

		markCallAsRead: function(call){
			this.communicatorService.markCallAsRead(call.id);
			call.unread = false;
			let ix = this.calls.findIndex(c => c.id === call.id);
			console.log(call);
			console.log(ix);
			if(ix > -1){
				console.log('updating call in collection');
				this.calls.splice(ix,1,call);
			}
		},

		handleCallDetail: function(event){
			let call = event.message;

			let ix = this.calls.findIndex(c => c.id === call.id);
			if(ix > -1){
				this.calls.splice(ix,1,call);
			}else{
				this.calls.unshift(call);
			}
		},

		getCallDetailRecords: function(){
			this.communicatorService.getCallDetails(this.earliest,this.latest).then((res) => {
				this.calls.splice(0,this.calls.length);
				this.calls.push(... res.data);
				this.calls.sort((a,b) => b.startTime.localeCompare(a.startTime));
			});
		},
	},

	computed: {
		userId: function () {
			return this.$store.getters.getLoggedInUserId;
		},

		calls: {
			get(){
				return this.$store.state.calls;
			},
			set(newVal){
				this.$store.state.calls = newVal;
			}
		}
	},

}
</script>

<style scoped lang="scss">

</style>