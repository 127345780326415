<template>
	<div class="column-format fill-height">
		<div class="px-4 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<h1 class="pb-2">{{$store.state.podAccount.accountName}}: {{summaryLabel}}</h1>
			<div class="ml-auto" v-if="summaries.length">
				<v-btn icon @click="editSettings"><v-icon class="material-symbols-rounded">settings</v-icon></v-btn>
			</div>
		</div>
		<div class="row-format fill-height">
			<div
				v-if="hasAccess"
				class="text-left show-scrollbar"
				style="min-width: 300px; height:100%; max-height: 100%; overflow-y: auto; max-width: 300px; font-size: 14px; border-right: 1px solid var(--v-gray_30-base);"
			>
				<v-treeview :items="groupedSummaries" hoverable activatable>
					<template v-slot:prepend="{ item, open }">
						<v-icon v-if="item.summaryType !== 'DAILY'" class="material-symbols-rounded">{{
							open ? 'folder_open' : 'folder'
						}}</v-icon>
						<v-icon v-else class="material-symbols-rounded">summarize</v-icon>
					</template>
					<template v-slot:label="{ item }">
						<div @click="openItem(item)" :class="item.summary ? 'pointer' : ''">{{ item.name }}</div>
					</template>
				</v-treeview>
			</div>
			<div v-else>This feature is not enabled for your user account.</div>
			<div v-if="currentSummary" class="text-left font-14 pt-2 business-summary fill-width">
				<div style="max-height: calc(100vh - 160px); overflow-y: auto" class="show-scrollbar">
					<div v-html="marked(currentSummary.summary, { breaks: true })" class="px-8"></div>
					<ai-chat-core class="px-5" v-if="aiSession" :existing-session-id="aiSession.id"></ai-chat-core>
				</div>
				<div class="row-format centered pt-2 pb-2" v-if="!aiSession && hasAiAccess && currentSummary.eventContext.length">
					<v-btn class="super-action" style="height: 38px" @click="launchAiSession"
						><v-icon size="20">smart_toy</v-icon> Ask Moxie assistant</v-btn
					>
				</div>
			</div>
			<div v-else style="font-style: italic; font-size: 14px" class="fill-width pt-4 column-format centered">
				<div v-if="!isAiEnabled">
					<h1>This feature requires the Moxie Assistant add-on</h1>
					<v-btn color="primary" @click="learnMore">Learn more</v-btn>
				</div>
				<div v-else>Select a summary from the left to begin.</div>
			</div>
		</div>
		<basic-modal :dialog="showSettings" v-if="showSettings">
			<executive-summary-settings @close="showSettings = false"></executive-summary-settings>
		</basic-modal>
	</div>
</template>

<script>
	import ExecutiveSummaryService from '@/modules/home/summary/ExecutiveSummaryService';
	import { DateTime } from 'luxon';
	import marked from 'marked';
	import AiChatCore from "@/modules/ai/AiChatCore";
	import store from "@/store";
	import AiLicenseAlert from "@/modules/ai/AiLicenseAlert";
	import BasicModal from "@/components/BasicModal";
	import ExecutiveSummarySettings from "@/modules/home/ExecutiveSummarySettings";

	export default {
		name: 'ExecutiveSummary',

		props: [],

		components: {ExecutiveSummarySettings, BasicModal, AiChatCore},

		data: function() {
			return {
				marked: marked,
				summaryService: new ExecutiveSummaryService(),
				summaries: [],
				currentSummary: null,
				aiSession: null,
				showSettings: false,
			};
		},

		mounted() {
			if (this.hasAccess) {
				this.getSummaries();
			}
		},

		beforeDestroy() {},

		methods: {
			editSettings: function(){
				this.showSettings = true;
			},

			learnMore: function(){
				store.state.globalModalController.openModal(AiLicenseAlert);
			},

			getSummaries: function() {
				this.summaryService.getExecutiveSummaries().then((res) => {
					this.summaries.splice(0);
					this.summaries.push(...res.data);
					this.summaries.forEach((s) => {
						s.start = DateTime.fromISO(s.periodStart);
						s.start.setZone(this.$store.state.podAccount.timeZone);
					});
					this.summaries.sort((a, b) => a.start - b.start);
				});
			},

			openItem: function(item) {
				if (item.summary && item.summary.id) {
					this.summaryService.getExecutiveSummary(item.summary.id).then((res) => {
						this.currentSummary = res.data;
						this.aiSession = null;
					});
				}
			},

			launchAiSession: function(){
				if(this.currentSummary){
					this.summaryService.getExecutiveSummaryChatSession(this.currentSummary.id).then((res) => {
						this.aiSession = res.data;
					})
				}
			},

			isSameMonth: function(date1, date2) {
				return date1.year === date2.year && date1.month === date2.month;
			},

			getTreeDataStructure: function() {
				let result = [];

				if (this.summaries.length) {
					let earliest = this.summaries[0].start.startOf('month').startOf('week');
					let latest = this.summaries[this.summaries.length - 1].start.startOf('month');
					let current = earliest;

					while (current <= latest) {
						let endOfMonth = current.endOf('month');

						let month = {
							name: current.toFormat('MMMM yyyy'),
							id: current.toFormat('yyyy-MM'),
							type: 'MONTHLY',
							summary: null,
							children: [],
						};

						result.push(month);

						let startOfWeek = current.startOf('week');

						while (startOfWeek < endOfMonth && startOfWeek < DateTime.now()) {
							if (this.isSameMonth(startOfWeek, current)) {
								let week = {
									name: 'Week of ' + startOfWeek.toLocaleString(DateTime.DATE_MED),
									id: startOfWeek.toFormat('yyyy-MM-dd'),
									type: 'WEEKLY',
									summary: null,
									children: [],
								};
								month.children.push(week);
							}
							startOfWeek = startOfWeek.plus({ weeks: 1 });
						}

						current = current.plus({ months: 1 }).startOf('month');
					}
				}

				return result.reverse();
			},
		},

		computed: {
			summaryLabel: function(){
				if(this.currentSummary){
					let start = DateTime.fromISO(this.currentSummary.periodStart);
					start.setZone(this.$store.state.podAccount.timeZone);

					if(this.currentSummary.summaryType === 'MONTHLY'){
						return 'Report for the month of ' + start.toFormat('MMMM yyyy');
					}else if(this.currentSummary.summaryType === 'WEEKLY'){
						return 'Report for the week of ' + start.toLocaleString(DateTime.DATE_FULL)
					}else{
						return 'Report for ' + start.toLocaleString(DateTime.DATE_HUGE);
					}
				}else{
					return 'Interactive business summaries'
				}
			},

			groupedSummaries: function() {
				let dataStructure = this.getTreeDataStructure();
				for (let i = 0; i < this.summaries.length; i++) {
					let summary = this.summaries[i];

					if (summary.summaryType === 'MONTHLY') {
						let monthKey = summary.start.toFormat('yyyy-MM');
						let month = dataStructure.find((d) => d.id === monthKey);
						month.summary = summary;
					} else if (summary.summaryType === 'WEEKLY' || summary.summaryType === 'DAILY') {
						let monthKey = summary.start.startOf('week').toFormat('yyyy-MM');
						let weekKey = summary.start.startOf('week').toFormat('yyyy-MM-dd');
						let month = dataStructure.find((d) => d.id === monthKey);
						let week = month.children.find((d) => d.id === weekKey);
						if (summary.summaryType === 'WEEKLY') {
							week.summary = summary;
						} else {
							summary.name = summary.start.toLocaleString(DateTime.DATE_HUGE);
							summary.summary = {
								id: summary.id,
							};
							week.children.push(summary);
						}
					}
				}

				for (let i = dataStructure.length - 1; i >= 0; i--) {
					let month = dataStructure[i];
					for (let j = month.children.length - 1; j >= 0; j--) {
						let week = month.children[j];
						if (!week.summary && week.children.length === 0) {
							month.children.splice(j, 1);
						}
					}
					if (!month.summary && month.children.length === 0) {
						dataStructure.splice(i, 1);
					}
				}

				return dataStructure;
			},

			hasAiAccess: function() {
				return this.$store.getters.isAiAvailable;
			},

			isAiEnabled: function(){
				return this.$store.getters.isAiEnabledOnWorkspace;
			},

			hasAccess: function() {
				return this.$store.getters.isAccountFullUser;
			},
		},
	};
</script>

<style lang="scss">
	.business-summary {
		line-height: 1.6;
		margin: 0;
		color: var(--v-gray_90-base);

		h1,
		h2,
		h3 {
			color: var(--v-primary-base);
			margin-bottom: 4px;
			margin-top: 12px;
		}

		h1 {
			font-size: 2em;
			line-height: 1.2;
		}

		h2 {
			font-size: 1.75em;
			border-bottom: 2px solid var(--v-secondary-base);
		}

		h3 {
			font-size: 1.5em;
			color: var(--v-black-base);
		}

		p {
			margin: 0.2em 0 8px !important;
		}

		ul {
			padding: 0;
		}

		ul li {
			margin: 0.5em 0;
			padding: 0.1em 0.7em;
		}

		ul li strong {
			color: var(--v-primary-base);
		}
	}
</style>
