<template>
	<div class="quick-link-wrapper">
		<div class="quick-link pointer" :style="`background-color: ${backgroundColor};`">
			<h-icon2 size="30" :name="icon" :style="`--icon-color: ${color}`"></h-icon2>
		</div>
		<div class="font-10 mt-1 quick-link-name brand-medium">{{name}}</div>
		<div class="add-icon" :style="`--add-color: ${color}`"><v-icon small color="white">add</v-icon></div>
	</div>
</template>

<script>
import HIcon2 from "@/components/HIcon2";

export default {
	name: "QuickLink",

	props: ['name','icon','color','backgroundColor'],

	components: {HIcon2},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">
	.quick-link-wrapper {
		position: relative;

		.quick-link-name {
			opacity: 0;
			transition: all 250ms ease;
		}

		.add-icon {
			opacity: 0;
			background-color: var(--add-color);
			width: 16px;
			height: 16px;
			border-radius: 8px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 5px;
			bottom: 16px;
			transition: all 250ms ease;
		}

		&:hover {
			.quick-link-name {
				opacity: 1;
				transition: all 250ms ease;
			}
			.add-icon {
				opacity: 1;
				transition: all 250ms ease;
			}
		}
	}
	.quick-link {
		width: 56px;
		height: 56px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		margin-right: 12px;
	}
</style>