import ProposalsMain from '@/modules/proposals/ProposalsMain';

export default [
	{
		path: '/agreements',
		name: 'proposalsMain',
		component: ProposalsMain,
		title: 'Agreements',
		props: (route) => ({
			id: route.query.id,
		}),
	},
];
