<template>
	<div id="client-project-select-menu" class="pb-2" @click.stop>
		<div v-if="isReady">
			<div class="timer-container mb-2 mx-3 gap-4">
				<div class="inputs column-format">
					<div class="font-14 font-gray_70">Started</div>
					<input
						style="color: var(--v-black-base)"
						v-model="timerStart"
						id="timerStart"
						class="timerStart"
						@focus="setPauseTimer(true)"
						@blur="handleTimerStartBlur(false)"
						@keyup.enter="handleTimerStartBlur(true)"
						ref="timerStart"
					/>
				</div>
				<div class="inputs column-format">
					<div class="font-14 font-gray_70">Duration</div>
					<input
						style="color: var(--v-black-base)"
						v-model="runningTimer"
						:class="running ? ' running' : ''"
						@focus="setPauseTimer(true)"
						@blur="handleRunningTimerBlur(false)"
						@keydown.enter="handleRunningTimerBlur(true)"
						id="timer-display"
						ref="runningTimer"
					/>
				</div>
			</div>
			<div
				v-if="currentTimerClone.pausedSeconds"
				class="text-left font-12 font-gray_70 mx-3 row-format align-center pointer"
				@click="confirmClearPause"
			>
				<div class="ml-auto">Paused for: {{ pausedTimeFormatted }}</div>
				<v-icon size="16" class="material-symbols-rounded">delete</v-icon>
			</div>
			<select-client
				v-if="showClient"
				class="my-2 mx-3"
				:hide-archive="true"
				v-model="currentTimerClone.clientId"
				@input="update('client')"
				prepend-icon="group"
				label="Client"
				:placeholder="$t('timetrack.current.choose-client')"
			></select-client>

			<div id="project-autocomplete" v-if="showClientProjectSelect">
				<v-autocomplete
					v-if="showProject"
					v-model="currentTimerClone.projectId"
					@input="update('project')"
					prepend-icon="folder_special"
					:items="projectItems"
					label="Project"
					:placeholder="$t('timetrack.current.choose-project')"
					:no-data-text="$t('timetrack.current.no-project-data-available')"
					hide-details
					persistent-placeholder
					class="h-outline my-2 mx-3"
					auto-select-first
				>
					<template v-slot:item="data">
						<div v-html="data.item.text" class="autocomplete-custom-item"></div>
					</template>
				</v-autocomplete>
			</div>

			<div id="deliverable-autocomplete" v-if="showClientProjectSelect">
				<v-autocomplete
					v-if="showDeliverable"
					v-model="currentTimerClone.deliverableId"
					@input="update('deliverable')"
					:items="deliverableItems"
					prepend-icon="splitscreen"
					label="Task"
					:placeholder="$t('timetrack.current.choose-deliverable')"
					:no-data-text="$t('timetrack.current.no-deliverable-data-available')"
					hide-details
					persistent-placeholder
					class="h-outline my-2 mx-3 "
					auto-select-first
				>
					<template v-slot:item="data">
						<div v-html="data.item.text" class="autocomplete-custom-item"></div>
					</template>
					<!--				<template v-slot:item="data">-->
					<!--					<div v-html="data.item.text" @keyup.enter.stop="currentTimerClone.deliverableId = data.item.value"></div>-->
					<!--				</template>-->
				</v-autocomplete>
			</div>

			<div id="project-autocomplete" v-if="showTicketSelect">
				<v-autocomplete
					v-if="showProject"
					v-model="currentTimerClone.ticketId"
					@input="update('ticket')"
					prepend-icon="local_activity"
					:items="ticketItems"
					label="Ticket"
					:placeholder="$t('timetrack.current.choose-ticket')"
					:no-data-text="$t('timetrack.current.no-ticket-data-available')"
					hide-details
					persistent-placeholder
					class="h-outline my-2 mx-3"
					auto-select-first
				>
					<template v-slot:item="data">
						<div v-html="data.item.text" class="autocomplete-custom-item"></div>
					</template>
				</v-autocomplete>
			</div>

			<v-textarea
				persistent-placeholder
				hide-details
				:rows="1"
				:auto-grow="true"
				prepend-icon="comment"
				v-model="currentTimerClone.notes"
				class="mx-3 notes h-outline"
				:label="$t('timetrack.current.notes-label')"
				:placeholder="$t('timetrack.current.notes-placeholder')"
				@change="update()"
			/>

			<v-switch
				v-model="currentTimerClone.billable"
				size="small"
				hide-details
				dense
				label="Billable"
				color="primary"
				class="mx-3 mt-0"
			></v-switch>

			<div class="d-flex mx-3 py-3 justify-space-between">
				<v-btn class="mr-1 secondary-action" style="flex:0 0 auto;" icon @click="handleDelete">
					<v-icon size="20">$delete</v-icon>
				</v-btn>
				<v-btn class="primary-action ml-1" style="flex:1 0 auto;" @click="handleSave()">
					{{ $t('global.save') }}
				</v-btn>
			</div>
		</div>

		<confirm-dialog
			:dialog="deleteConfirm"
			icon="$alert"
			heading-text="Delete?"
			body-text="Are you sure you want to delete the running timer?"
			@confirm="handleDelete()"
			@close="deleteConfirm = false"
		></confirm-dialog>
	</div>
</template>

<script>
	import SelectClient from '@/modules/clients/SelectClient';
	import ProjectService from '@/modules/projects/ProjectService';
	import DeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import { cloneDeep, isEqual } from 'lodash';
	import TimeTrack from './TimeTrack';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import HDateTime from '@/modules/utils/HDateTime';
	import { Duration } from 'luxon';
	import ConfirmModal from '@/components/ConfirmModal';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';

	export default {
		name: 'ClientProjectSelect',
		components: { SelectClient, ConfirmDialog },
		props: {
			currentTimer: { type: Object, required: true },
			showClient: { type: Boolean, required: false, default: true },
			showProject: { type: Boolean, required: false, default: true },
			showDeliverable: { type: Boolean, required: false, default: true },
			showMenu: { type: Boolean, required: false, default: false },
		},

		data() {
			return {
				//clientList: null,
				projectService: new ProjectService(),
				deliverableService: new DeliverableService(),
				ticketService: new CommunicatorTicketService(),
				timeTrack: new TimeTrack(),
				projectList: null,
				deliverableList: null,
				ticketList: null,
				running: false,
				timerEvents: [],
				// dateStart: '',
				timerStart: '',
				timerEnd: '',
				showDate: false,
				maxDate: null, // For calendar
				minDate: null, // For calendar
				confirmDeleteDialog: false,
				currentTimerClone: null, // Deep clone of currentTimer
				deleteConfirm: false,

				// Timer support
				pauseTimer: false,
				runningTimer: null,
				parsedTimerStart: null,
				runningTimerInterval: null, //timer

				showMenuDelayed: false,
				projectChannelName: null,
			};
		},

		mounted() {
			this.currentTimerClone = cloneDeep(this.currentTimer);

			this.getProjectList();
			this.getDeliverableList();
			this.getTicketList();

			// Timer support
			this.getTimerStart(); // Get time portion of timerStart ISO for display
			this.runRunningTimer();

			this.projectChannelName = this.getProjectChannelName();
			this.$store.state.eventBus.$on(this.projectChannelName, this.projectUpdateEventHandler);
		},

		beforeDestroy() {
			clearInterval(this.runningTimerInterval);
			this.$store.state.eventBus.$off(this.projectChannelName, this.projectUpdateEventHandler);
		},

		methods: {
			confirmClearPause: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to clear the time in paused status?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.currentTimerClone.pausedSeconds = 0;
						this.$emit('update-timer', this.currentTimerClone);
					}
				});
			},

			setPauseTimer(pauseTimer) {
				if (pauseTimer) {
					clearInterval(this.runningTimerInterval);
					return;
				}
				this.runRunningTimer();
			},

			getProjectList() {
				this.projectService
					.getAllActiveProjects()
					.then((res) => {
						this.projectList = res.data;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			getTicketList() {
				if(this.$store.getters.hasFeatureAccess('tickets')) {
					this.ticketService
							.getTickets(null, null, true, null)
							.then((res) => {
								this.ticketList = res.data;
							})
							.catch((err) => {
								console.log(err);
								let msg = err.response.data.message;
								let status = err.response.status;
								this.$store.commit('warn', 'Error [' + status + '] ' + msg);
							});
				}
			},

			getDeliverableList() {
				this.deliverableService
					.getDeliverablesForAccount()
					.then((res) => {
						this.deliverableList = res.data;
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			getProject(projectId) {
				if (!this.projectList) return;
				return this.projectList.find((v) => v.id === projectId);
			},

			getDeliverable(deliverableId) {
				return this.deliverableList.find((v) => v.id === deliverableId);
			},

			getTicket(ticketId) {
				return this.ticketList.find((v) => v.id === ticketId);
			},

			handleDelete() {
				if (!this.deleteConfirm) {
					this.deleteConfirm = true;
					return;
				} else {
					this.deleteConfirm = false;
				}
				this.$emit('delete-timer');
			},

			handleCancel() {
				this.deleteConfirm = false;
			},

			update(scope) {
				if (scope) {
					this.sanitizeRelatedData(scope);
				}

				if (isEqual(this.currentTimer, this.currentTimerClone)) return;
				this.$emit('update-timer', this.currentTimerClone);
			},

			handleSave() {
				this.update();
				this.$emit('close');
			},

			sanitizeRelatedData(scope) {
				switch (scope) {
					case 'client': {
						let project = this.getProject(this.currentTimerClone.projectId);
						let deliverable = this.getDeliverable(this.currentTimerClone.deliverableId);
						let ticket = this.getTicket(this.currentTimerClone.ticketId);

						if (!this.currentTimerClone.clientId) {
							this.currentTimerClone.projectId = null;
							this.currentTimerClone.deliverableId = null;
							this.currentTimerClone.ticketId = null;

						} else {
							if(!ticket || ticket.clientId !== this.currentTimerClone.clientId){
								this.currentTimerClone.ticketId = null;
							}
							if (!project || project.client.id !== this.currentTimerClone.clientId) {
								this.currentTimerClone.projectId = null;
								this.currentTimerClone.deliverableId = null;
							} else {
								if (!deliverable || deliverable.project.id !== this.currentTimerClone.projectId) {
									this.currentTimerClone.deliverableId = null;
								}
							}
						}
						break;
					}

					case 'project': {
						let project = this.getProject(this.currentTimerClone.projectId);
						if (project) {
							this.currentTimerClone.clientId = project.client.id;
							this.currentTimerClone.ticketId = null;
							this.sanitizeRelatedData('client');
						} else {
							this.currentTimerClone.projectId = null;
						}
						break;
					}

					case 'ticket': {
						let ticket = this.getTicket(this.currentTimerClone.ticketId);
						if (ticket) {
							this.currentTimerClone.clientId = ticket.clientId;
							this.currentTimerClone.projectId = null;
							this.currentTimerClone.deliverableId = null;
							this.sanitizeRelatedData('client');
						} else {
							this.currentTimerClone.ticketId = null;
						}
						break;
					}

					case 'deliverable': {
						let deliverable = this.getDeliverable(this.currentTimerClone.deliverableId);
						if (deliverable) {
							this.currentTimerClone.projectId = deliverable.project ? deliverable.project.id : null;
							this.currentTimerClone.ticketId = null;
							this.sanitizeRelatedData('project');
						} else {
							this.currentTimerClone.deliverableId = null;
						}
						break;
					}
				}
			},

			// Timer support
			getTimerStart() {
				this.timerStart = HDateTime.fromISO(this.currentTimerClone.timerStart).toLocaleString(HDateTime.TIME_SIMPLE);
			},

			handleTimerStartBlur(close = false) {
				this.timerStart = this.timeTrack.scrubTimerStart(this.timerStart);
				const newISO = this.timeTrack.timerInputToISO(this.timerStart, this.currentTimerClone);
				if (!newISO) return;

				//console.log('newISO', newISO);
				this.currentTimerClone.timerStart = newISO;
				this.runningTimer = this.timeTrack.getCurrentRunningTimer(this.currentTimerClone);
				this.getTimerStart();
				this.setPauseTimer(false);

				if (close) {
					this.handleSave();
				} else {
					this.update();
				}
			},

			runRunningTimer() {
				if (this.runningTimerInterval) {
					clearInterval(this.runningTimerInterval);
				}

				this.runningTimerInterval = setInterval(() => {
					this.getRunningTimer();
				}, 1000);
			},

			getRunningTimer() {
				if (this.currentTimerClone) {
					this.runningTimer = this.timeTrack.getCurrentRunningTimer(this.currentTimerClone);
					return;
				}
				this.runningTimer = '0:00:00';
				return;
			},

			handleRunningTimerBlur(close = false) {
				this.runningTimer = this.timeTrack.scrubRunningTimer(this.runningTimer);
				this.handleRunningTimerEnter(close);
			},

			handleRunningTimerEnter(close) {
				let runningTime = this.timeTrack.parseRunningTimerInput(this.runningTimer);

				if (!runningTime) {
					this.setPauseTimer(false);
					return;
				}

				// Subtract duration from currentTimerClone time and set timerStart to result
				this.currentTimerClone.timerStart = HDateTime.local().minus(runningTime);

				let pausedTime = this.currentTimerClone.pausedSeconds;
				if (this.currentTimerClone.pausedAt) {
					let pausedAt = HDateTime.fromISO(this.currentTimerClone.pausedAt);
					pausedTime = pausedTime + HDateTime.now().diff(pausedAt, 'seconds').seconds;
				}

				this.currentTimerClone.timerStart = this.currentTimerClone.timerStart.minus({ seconds: pausedTime }).toISO();

				this.getTimerStart();
				this.setPauseTimer(false);

				if (close) {
					this.handleSave();
				} else {
					this.update();
				}
			},

			getProjectChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.pr';
			},

			projectUpdateEventHandler: function(event) {
				if (event.userMetadata === 'ProjectDeliverableMini') {
					this.processDeliverableDataEvent(event.message);
				}
			},

			processDeliverableDataEvent: function(deliverable) {
				let ix = this.deliverableList.findIndex((d) => d.id === deliverable.id);
				if (ix > -1 && deliverable.statusId === '__deleted__') {
					this.deliverableList.splice(ix, 1);
				} else if (ix > -1) {
					this.deliverableList.splice(ix, 1, deliverable);
				} else {
					this.deliverableList.push(deliverable);
				}
			},
		},
		computed: {
			showClientProjectSelect: function(){
				return !this.currentTimerClone.ticketId;
			},

			showTicketSelect: function(){
				return !(this.currentTimerClone.projectId || this.currentTimerClone.deliverableId);
			},

			pausedTimeFormatted: function() {
				if (this.currentTimerClone.pausedSeconds) {
					let dur = {
						seconds: this.currentTimerClone.pausedSeconds,
					};
					return Duration.fromObject(dur).toFormat('h:mm:ss');
				} else {
					return null;
				}
			},

			isReady: {
				get: function() {
					return this.projectList !== null && this.deliverableList !== null && this.currentTimerClone;
					// return true;
					//return !this.$validations.isEmpty(this.dateStart) && !this.$validations.isEmpty(this.timerStart);
				},
			},

			incompleteStatusList() {
				return this.$store.getters.getIncompleteStatusList.map((s) => s.id);
			},

			projectItems() {
				if (!this.projectList) return [];
				let projects; // filtered or unfiltered
				if (this.clientId) {
					projects = this.projectList.filter((item) => {
						// clientId filter
						return item.clientId === this.clientId && item.active;
					});
				} else {
					projects = this.projectList;
				}
				const values = projects.map((a) => a.id);
				const names = projects.map((a) => a.name);
				let items = [];

				for (let i = 0; i < names.length; i++) {
					items.push({ text: names[i], value: values[i], disabled: false });
				}
				items.sort(function(a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				if (this.projectId) {
					// Only show clear if a project is selected
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}

				return items;
			},

			ticketItems() {
				if (!this.ticketList) return [];
				let tickets; // filtered or unfiltered
				if (this.clientId) {
					tickets = this.ticketList.filter((item) => {
						return item.clientId === this.clientId;
					});
				} else {
					tickets = this.ticketList;
				}
				const values = tickets.map((a) => a.id);
				const names = tickets.map((a) => (a.ticketNumber ? (a.ticketNumber + ': ') : '') + a.subject);
				let items = [];

				for (let i = 0; i < names.length; i++) {
					items.push({ text: names[i], value: values[i], disabled: false });
				}
				items.sort(function(a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				if (this.ticketId) {
					// Only show clear if a project is selected
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}

				return items;
			},

			deliverableItems() {
				if (!this.deliverableList) return [];
				let deliverables = this.deliverableList
					.filter((item) => {
						if (item.archived === false) {
							return true;
						} else if (item.archived && item.id === this.currentTimer.deliverableId) {
							return true;
						} else {
							return false;
						}
					})
					.filter((item) => {
						if (this.projectId && item.project && item.project.id === this.projectId) return true;
						if (!this.projectId && this.clientId && item.client && item.client.id === this.clientId) return true;
						if (!this.projectId && !this.clientId) return true;
						return false;
					});

				//Only show deliverables that are not "done"
				deliverables = deliverables.filter((d) => this.incompleteStatusList.includes(d.statusId));

				const values = deliverables.map((a) => a.id);
				const names = deliverables.map((a) => a.name);
				let items = [];

				for (let i = 0; i < names.length; i++) {
					items.push({ text: names[i], value: values[i], disabled: false });
				}

				items.sort(function(a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				if (this.deliverableId) {
					// Only show clear if a deliverable is selected
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}

				return items;
			},

			clientId: function() {
				if (!this.currentTimerClone) return;
				return this.currentTimerClone.clientId;
			},

			projectId: function() {
				if (!this.currentTimerClone) return;
				return this.currentTimerClone.projectId;
			},

			deliverableId: function() {
				if (!this.currentTimerClone) return;
				return this.currentTimerClone.deliverableId;
			},

			ticketId: function() {
				if (!this.currentTimerClone) return;
				return this.currentTimerClone.ticketId;
			},

			footerClass() {
				if (this.deleteConfirm) return 'footer delete-confirm';
				return 'footer';
			},
		},

		watch: {
			currentTimer: {
				handler(n, o) {
					if (!o) return;
					this.currentTimerClone = cloneDeep(this.currentTimer);
					this.getTimerStart(); // Get time portion of timerStart ISO for display
				},
				deep: true,
			},
			showMenu: {
				handler(n) {
					if (n) {
						setTimeout(() => {
							this.showMenuDelayed = true;
						}, 100);
						return;
					}
					this.showMenuDelayed = false;
				},
			},
		},
	};
</script>

<style scoped lang="scss">
	.autocomplete-custom-item ::v-deep {
		max-width: 320px !important;
		-webkit-line-clamp: 2 !important;
		display: block;
		display: -webkit-box !important;
		-webkit-box-orient: vertical !important;
		line-height: 1.2;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
	}
</style>
<style lang="scss">
	#client-project-select-menu {
		background-color: var(--v-white-base);
		width: 300px;

		div.timer-container {
			display: flex;
			margin: 0 0 24px;
			div.inputs {
				text-align: left;
				flex: 1 1 0;
				padding: 0;
				margin-top: 12px;
				&.left {
					margin-right: 8px !important;
				}
				&.right {
					margin-left: 8px !important;
				}
				label {
					margin: 0;
					font-size: 12px;
					line-height: 12px;
					font-weight: 400;
					letter-spacing: 0.2px;
					text-align: left;
					color: var(--v-gray_80-base);
				}
				input {
					border: 1px solid var(--v-gray_50-base);
					border-radius: 4px;
					padding-left: 4px;
					margin-top: 4px;
					width: 100%;
					height: 40px;
					font-size: 14px;
				}
			}
		}

		.h-outline .v-input__control .v-select__slot input {
			overflow: hidden;
			text-overflow: ellipsis !important;
			white-space: nowrap;
			max-width: 176px !important;
		}

		.notes textarea {
			font-size: 14px;
			//line-height: 22px !important;
			//min-height: 20px !important;
		}
	}
</style>
