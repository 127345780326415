<template>
	<div style="max-height: calc(var(--vh) - 75px); height: calc(var(--vh) - 85px)" class="ma-3">
		<focus :full-user="$store.getters.hasFeatureAccess('any')" :key="'focus-' + refreshKey" ref="Focus" v-show="currentView === 'Focus'" :is-visible="currentView === 'Focus'"></focus>
		<calendar :key="'calendar-' + refreshKey" ref="Calendar" v-show="currentView === 'Calendar'" :is-visible="currentView === 'Calendar'" @set-view="setView($event)"></calendar>
		<insights v-if="hasInsightsAccess" :key="'insights-' + refreshKey" v-show="currentView === 'Insights'" :is-visible="currentView === 'Insights'"></insights>
		<bookings v-if="hasInsightsAccess" :key="'bookings-' + refreshKey" v-show="currentView === 'Bookings'" :is-visible="currentView === 'Bookings'"></bookings>
		<executive-summary :key="'summary-' + refreshKey" v-show="currentView === 'Summary'" :is-visible="currentView === 'Summary'"></executive-summary>
	</div>
</template>

<script>
	import Calendar from '@/modules/calendar/Calendar';
	import Focus from "@/modules/home/Focus";
	import Insights from "@/modules/home/Insights";
	import Bookings from "@/modules/home/Bookings";
	import ExecutiveSummary from "@/modules/home/ExecutiveSummary";

	export default {
		name: 'HomeBase',

		props: ['v'],

		components: {Bookings, Focus, Calendar, Insights, ExecutiveSummary },

		data: function () {
			return {
				currentView: 'Focus',
				refreshKey: 0,

			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.accountChanged);
			if(!this.v) {
				this.getLastView();
			}else{
				this.currentView = this.v;
			}
			console.log('mounted');
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.accountChanged);
		},

		methods: {
			accountChanged: function(){
				this.refreshKey++;
			},

			getLastView: function(){
				let lastView = localStorage.getItem('HOME_CURRENT_VIEW');
				if(lastView){
					this.$router.push(`/home?v=${lastView}`);
				}else{
					this.$router.push('/home?v=Focus');
				}
			},

			setView: function (view) {
				if(!view){
					view = 'Focus';
				}
				localStorage.setItem('HOME_CURRENT_VIEW',view);
				this.currentView = view;
			},
		},

		watch: {
			v: function(newVal){
				this.setView(newVal);
			},
			filter: {
				deep: true,
				handler: function(val) {
					this.saveFilter(val);
				},
			},
		},

		computed: {
			hasInsightsAccess: function () {
				return this.$store.getters.hasInvoiceAccess || this.$store.getters.hasFeatureAccess('timesheets');
			},

			unreadNotificationCount: function () {
				return this.$store.getters.unreadNotificationCount;
			},

			isCollaborator: function () {
				return this.$store.getters.getUserType === 'COLLABORATOR';
			},
		},
	};
</script>

<style scoped lang="scss">
	.umi {
		position: absolute;
		top: -4px;
		left: 22px;
		width: 16px;
		height: 16px;
		border-radius: 10px;
		background-color: var(--v-accent-base);
		color: var(--v-black-base);
		font-size: 12px;
		font-weight: 500;
	}
</style>
