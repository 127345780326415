<template>
	<v-container
		v-if="ourThread"
		class="font-14"
		fluid
		@mouseenter="hovering = true"
		@mouseleave="hovering = false"
		@click="$emit('click')"
	>
		<v-row dense align="center">
			<v-col cols="3" align="left" class="row-format">
				<div v-if="!clientId">
					<client-avatar v-for="client in clients" :key="client.id" :client="client" small class="mr-1"></client-avatar>
				</div>
				<div :class="`text-truncate ${clients.length && !clientId ? 'ml-3' : ''}`">{{ participants.join(', ') }}</div>
			</v-col>
			<v-col cols="7" align="left" class="row-format align-center">
				<v-icon color="gray_60" class="material-symbols-rounded">chat</v-icon>
				<div :class="`ml-2 text-truncate  ${ourThread.unread ? 'brand-bold' : ''}`">{{ ourThread.lastMessageText }}</div>
			</v-col>
			<v-col cols="2" align="right" class="nowrap">
				<inbox-actions
					v-show="hovering"
					class="ml-auto"
					@return-to-inbox="returnToInbox()"
					@snooze="snooze($event)"
					@archive="archive()"
					@unread="markThreadAsRead()"
					:unread="ourThread.unread > 0"
					:snooze-options="snoozeOptions"
					:folder="ourFolder"
				></inbox-actions>
				<div v-if="!hovering" style="height:32px">{{ $formatters.formatTimeAgo(communication.date) }}</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';
	import ClientAvatar from '@/components/ClientAvatar';
	import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import MessageThreadMixin from '@/modules/communicator/inbox/messages/MessageThreadMixin';

	export default {
		name: 'MessageThread',

		props: ['communication', 'snoozeOptions', 'folder', 'clientId'],

		components: { ClientAvatar, InboxActions },

		mixins: [MessageThreadMixin],

		data: function() {
			return {
				thread: this.communication.payload,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				hovering: false,
			};
		},

		mounted() {
			this.ourThread = { ...this.communication.payload };
			this.ourFolder = this.folder;
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			clients: function() {
				let result = [];
				this.communication.clientIds.forEach((c) => {
					result.push(this.$store.getters.getClientById(c));
				});
				return result;
			},

			contacts: function() {
				let result = [];
				this.thread.participants.forEach((p) => {
					let c = this.$store.getters.getContactByPhone(p.phone);
					if (c) {
						result.push(c);
					}
				});
				return result;
			},

			participants: function() {
				return this.thread.participants.map((p) => {
					let contact = this.$store.getters.getContactByPhone(p.phone);
					if (contact) {
						return contact.fullName;
					} else {
						let phone = this.PhoneUtil.parse(p.phone);
						return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
					}
				});
			},
		},
	};
</script>

<style scoped lang="scss"></style>
