<template>
	<div :class="'form-detail ' + (formSubmission.formData.answers.length ? 'with-answers' : 'no-answers')" v-if="isReady">
		<div id="form-inner-wrapper" class="modal-padding">
			<div class="row-format mb-2 align-center">
				<div class="brand-medium font-18">{{ $t('forms.form-modal.title') }}</div>
				<div class="ml-auto row-format align-center gap-4">
					<pipeline-link-widget
						:item-id="formSubmission.id"
						item-type="Form"
						:client-id="formSubmission.clientId"
					></pipeline-link-widget>
					<v-icon size="20" class="ml-auto pointer" @click="handleClose">$close</v-icon>
				</div>
			</div>
			<div>
				<v-container class="ml-0 pa-0">
					<v-row>
						<v-col :md="formSubmission.formData.answers.length ? 6 : 12" cols="12" class="ml-0" align="left">
							<v-container class="pa-0 ma-0 font-14">
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('forms.form-modal.form-name') }}</v-col>
									<v-col cols="8">{{ formSubmission.formName }}</v-col>
								</v-row>
								<v-row dense v-if="formSubmission.formData.sourceUrl">
									<v-col cols="4" class="brand-medium">Source URL</v-col>
									<v-col cols="8">{{ formSubmission.formData.sourceUrl }}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('global.contact') }}</v-col>
									<v-col cols="8" class="row-format align-center gap-2">
										<div>{{ formSubmission.formData.firstName }} {{ formSubmission.formData.lastName }}</div>
										<v-icon size="22" color="primary" @click="addNewContact()" v-if="showCreateContact"
											v-tippy="{ content: 'Create new contact' }"
											class="pointer material-symbols-rounded"
											>person_add</v-icon
										></v-col
									>
								</v-row>
								<v-row dense v-if="businessInfo">
									<v-col cols="4" class="brand-medium">{{ $t('forms.meeting-modal.business-info') }}</v-col>
									<v-col cols="8" v-html="businessInfo"></v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('global.email') }}</v-col>
									<v-col cols="8">{{ formSubmission.formData.email }}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('global.phone') }}</v-col>
									<v-col cols="8">{{ formSubmission.formData.phone }}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('forms.form-modal.submitted') }}</v-col>
									<v-col cols="8">{{
										DateTime.fromISO(formSubmission.submittedAt).toLocaleString(DateTime.DATETIME_MED)
									}}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('forms.form-modal.from-ip') }}</v-col>
									<v-col cols="8">
										<div class="ipBlock" v-if="formSubmission.ipLookup.ip">
											Country: <img :src="formSubmission.ipLookup.countryFlag" width="15px" /><br />
											Location: {{ formSubmission.ipLookup.city }}, {{ formSubmission.ipLookup.region }},
											{{ formSubmission.ipLookup.country }}<br />
										</div>
									</v-col>
								</v-row>

								<v-row dense>
									<v-col cols="12" class="mt-2 mb-0 pb-0">
										<select-client
											ref="selectClient"
											id="select-client"
											v-model="formSubmission.clientId"
											prepend-icon="$clients"
											:hide-archive="true"
											label="Client"
											:placeholder="$t('timetrack.current.choose-client')"
										/>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" class="mt-n2 text-center pa-0">
										<v-btn
											class="secondary-action add-new-client"
											color="primary"
											text
											v-if="!formSubmission.clientId"
											@click="addNewClient"
											>+ {{ $t('forms.meeting-modal.create-client') }}</v-btn
										>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" class="mt-0">
										<v-textarea
											v-model="formSubmission.notes"
											hide-details
											persistent-placeholder
											class="h-outline"
											label="Notes"
											rows="3"
											auto-grow
										></v-textarea>
										<div v-if="formSubmission.summary" class="text-left">
											<div v-html="formSubmission.summary"></div>
										</div>
										<div  class="row-format centered">
											<v-btn small text color="primary" class="" @click="generateSummary(false)">✨Summarize</v-btn>
										</div>
									</v-col>
								</v-row>

								<v-row dense v-if="fromLeadGen">
									<v-col cols="12" class="mt-0">
										<v-switch
											inset
											v-model="formSubmission.leadGenArchived"
											:label="$t('forms.form-modal.archive-from-leadgen')"
										/>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
						<v-col v-if="formSubmission.baseDiscovery" md="6" cols="12" align="left">
							<div class="mb-2 row-format">
								<div>{{ $t('forms.meeting-modal.questionnaire') }}</div>
								<div class="ml-auto font-14 font-primary" v-if="fullResultLink">
									<a target="_blank" style="color: var(--v-primary-base)" :href="fullResultLink"
										>View in form</a
									>
								</div>
							</div>
							<v-container
								class="pa-2 ma-0 font-14"
								style="background-color: var(--v-gray_20-base); border-radius: 4px"
							>
								<v-row dense v-if="formSubmission.formData.answers.length">
									<v-col class="pa-4">
										<div
											v-for="(answer, index) in formSubmission.formData.answers"
											:key="index"
											class="text-left font-14 pb-3"
										>
											<div class="font-12" v-html="answer.question"></div>
											<div v-if="answer.fieldType === 'FileInput'">
												<div v-for="fileName in JSON.parse(answer.answer)" :key="fileName">
													<div class="font-primary pointer" @click="downloadFile(fileName)">
														{{ fileName }}
													</div>
												</div>
											</div>
											<div v-else class="brand-medium" style="white-space: pre-wrap">{{ answer.answer }}</div>
										</div>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
		<div class="modal-footer gap-2 row-format centered">
			<v-btn class="secondary-action" icon>
				<v-icon size="20" @click="deleteFormSubmission">$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action ml-1" style="width: 160px" @click="saveFormSubmission">
				{{ $t('global.save') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import SelectClient from '@/modules/clients/SelectClient';
	import DateTime from '@/modules/utils/HDateTime';
	import ConfirmModal from '@/components/ConfirmModal';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import axios from 'axios';
	import ContactEdit from "@/modules/clients/contacts/ContactEdit";
	import AiAssistantService from "@/modules/ai/AiAssistantService";
	import marked from "marked";

	export default {
		name: 'FormSubmissionDetail',

		props: ['id', 'fromLeadGen'],

		components: { PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'), SelectClient },

		data: function() {
			return {
				isReady: false,
				formSubmission: null,
				formSubmissionService: new FormSubmissionService(),
				DateTime: DateTime,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'form-submission-detail' });
			this.getFormSubmission();
		},

		beforeDestroy() {},

		methods: {
			getFormSubmission: function() {
				this.formSubmissionService
					.getFormSubmission(this.id)
					.then((res) => {
						this.formSubmission = res.data;
						console.log(this.formSubmission);
						this.isReady = true;
					})
					.catch(() => {
						this.$store.commit('error', 'Unable to locate form submission.');
						this.$emit('result');
					});
			},

			saveFormSubmission: function(close=true) {
				this.formSubmissionService.updateFormSubmission(this.id, this.formSubmission).then((res) => {
					this.formSubmission = res.data;
					if(close) {
						this.handleClose();
					}
				});
			},

			downloadFile: function(fileName, download = true) {
				this.formSubmissionService.getSignedUrl(this.id, fileName).then((res) => {
					let signedUrl = res.data;

					if (download == false) {
						window.open(signedUrl, '_blank');
					} else {
						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', fileName); //or any other extension
							document.body.appendChild(link);
							link.click();
						});
					}
				});
			},

			deleteFormSubmission: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this form submission?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.formSubmissionService
							.deleteFormSubmission(this.id)
							.then(() => {
								this.$emit('result', { action: 'DELETED' });
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			addNewContact: function(){
				let binding = {
					formData: this.formSubmission.formData,
					clientId: this.formSubmission.clientId
				}
				this.$store.state.globalModalController.openModal(ContactEdit,binding).then((res) => {
					if(res){
						this.$store.commit('success','Contact created');
					}
				});
			},

			addNewClient: function() {
				let fromLeadGen = { ...this.formSubmission };
				fromLeadGen.notes = this.getClientNotes();
				fromLeadGen.isFormSubmission = true;
				let binding = {
					clientList: this.$store.getters.clients,
					leadGen: fromLeadGen,
				};

				this.$store.state.globalModalController.openModal(ClientCreate, binding).then((res) => {
					if (res) {
						this.formSubmission.clientId = res.id;
						this.$refs.selectClient.getClientList();
					}
				});
			},

			getClientNotes: function() {
				let notes = '';
				let answers = this.formSubmission.formData.answers;

				if (this.formSubmission.notes) {
					notes += this.formSubmission.notes + '\n\n';
				}

				answers.forEach((a, index) => {
					notes += a.question + ':\n' + a.answer + (index < answers.length - 1 ? '\n\n' : '');
				});

				return notes;
			},

			handleClose: function() {
				this.$emit('result', this.formSubmission);
			},

			async generateSummary(force = false) {
				if (this.summary && !force) {
					this.showSummary = !this.showSummary;
					return;
				}

				try {
					this.$store.commit('startLoading');
					let createRequest = {
						model: 'gpt-4o-mini',
						useCase: 'Summary',
						context: [],
						fetchResponse: true,
					};

					createRequest.context.push({
						role:'system',
						content:'You should provide a clear and concise summarization of the data provided by the user. Format the response with clear sections, bold headers, and properly formatted code blocks using markdown.'
					});

					for(let i=0; i < this.formSubmission.formData.answers.length; i++){
						let answer = this.formSubmission.formData.answers[i];
						createRequest.context.push({
							role: 'user',
							content: `Question: ${answer.question}; Answer: ${answer.answer}`
						});
					}

					if(this.formSubmission.notes){
						createRequest.context.push({
							role: 'user',
							content: `Notes: ${this.formSubmission.notes}`
						});
					}

					createRequest.context.push({
						role: 'user',
						content: 'Please summarize this question and answer thread.  Include sections for topics, important dates, and a high level summary.  Only output the result, no other content.'
					});


					let result = await new AiAssistantService().createChatSession(createRequest);
					let aiSession = result.data;
					let lastMessage = aiSession.messages[aiSession.messages.length - 1];
					let summary = lastMessage.content;
					this.formSubmission.summary = marked(summary,{breaks:true});
					this.saveFormSubmission(false);
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},
		},

		computed: {
			businessInfo: function() {
				let form = this.formSubmission.formData;
				let result = '';

				if (form.businessName) {
					result += `<span class="">${form.businessName}</span><br>`;
				}

				let address = [form.address1, form.address2, form.city, form.locality, form.postal, form.country];
				address = address.filter((a) => a !== null);

				if (address.length) {
					result += address.join(' ') + '<br>';
				}

				if (form.website) {
					result += form.website + '<br>';
				}

				return result;
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			showCreateContact: function(){
				if(this.formSubmission.formData && this.formSubmission.formData.email){
					if(this.$store.state.contacts.findIndex(c => c.email && c.email.toLowerCase() === this.formSubmission.formData.email.toLowerCase()) > -1){
						return false;
					}
				}
				return true;
			},

			fullResultLink: function() {
				if (this.formSubmission.submissionToken && this.formSubmission.baseDiscovery) {
					return (
						process.env.VUE_APP_MEET_LINK +
						this.podId +
						`/completedForm?token=${encodeURIComponent(this.formSubmission.submissionToken)}`
					);
				} else {
					return null;
				}
			},
		},
	};
</script>

<style lang="scss">
	.form-detail {
		@media #{map-get($display-breakpoints, 'sm-and-down')} {
			max-width: 500px;
		}
		@media #{map-get($display-breakpoints, 'md-and-up')} {
			&.no-answers {
				width: 500px;
			}
			&.with-answers {
				width: 920px;
			}
		}
		.v-btn.add-new-client .v-btn__content {
			font-weight: 600 !important;
			color: var(--v-gray_70-base);
			&:hover {
				color: var(--gray_90-base);
			}
		}
	}
</style>

<style scoped lang="scss">
	.form-detail {
		#form-inner-wrapper {
			max-height: calc(var(--vh) - 180px);
			overflow-y: auto;
			display: flex;
			flex-direction: column;
		}

		.modal-footer {
			position: sticky;
			position: -webkit-sticky;
			margin-top: 8px;
		}

		.strike {
			text-decoration: line-through;
		}
	}
</style>
