<template>
	<div id="recurring-expense-detail" v-if="isReady">

		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="modal-padding">

			<h3 class="modal-title">{{$t('recurring.expenses.heading')}} - {{$formatters.dollars(total,true,true,expenseCopy.currency)}}</h3>

			<recurring-event-config :recurring="recurring"></recurring-event-config>

			<hr class="mt-3 mb-6" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none">

			<v-container fluid class="pa-0 ma-0">
				<v-row>
					<v-col cols="6" class="pr-1">
						<div class="column-format">
							<div class="row-format gap-2">
								<amount-input
										class="h-outline"
										hide-details
										:label="$t('expenses.amount-label')"
										:placeholder="$t('expenses.amount-placeholder')"
										:prefix="currencySymbol"
										type="text"
										v-model="expenseCopy.amount"
										persistent-placeholder
								></amount-input>
								<v-text-field
										class="h-outline"
										hide-details
										label="Tax rate"
										placeholder="Tax %"
										suffix="%"
										type="number"
										v-model="expenseCopy.taxRate"
										persistent-placeholder
								></v-text-field>
							</div>
							<div class="row-format gap-2">
								<div style="width:47%">
									<v-autocomplete
											hide-details
											persistent-placeholder
											class="h-outline"
											:label="$t('account.currency')"
											:items="currencies"
											item-text="value"
											item-value="value"
											v-model="expenseCopy.currency"
									>
										<template v-slot:item="{item}">
											{{item.label}}
										</template>
									</v-autocomplete>
								</div>
								<v-checkbox :disabled="expenseCopy.taxRate === 0" hide-details dense v-model="expenseCopy.taxInclusive" label="Tax inclusive"></v-checkbox>
							</div>
						</div>

						<v-textarea
								class="h-outline"
								hide-details
								:label="$t('expenses.description-label')"
								:placeholder="$t('expenses.description-placeholder')"
								v-model="expenseCopy.description"
								:auto-grow="true"
								rows="1"
								persistent-placeholder
						></v-textarea>

						<v-autocomplete
								class="h-outline"
								hide-details
								persistent-placeholder
								:label="$t('expenses.category-label')"
								:placeholder="$t('expenses.category-placeholder')"
								:items="expenseCategoriesPlusClear"
								v-model="expenseCopy.category"
								@change="categoryChanged"
						></v-autocomplete>

						<v-autocomplete
								class="h-outline"
								hide-details
								persistent-placeholder
								:label="$t('expenses.vendor-label')"
								:placeholder="$t('expenses.vendor-placeholder')"
								v-model="expenseCopy.vendorId"
								:items="vendorsPlusClear"
								item-text="name"
								item-value="id"
								@change="vendorChanged"
						></v-autocomplete>

						<v-select
								class="h-outline"
								hide-details
								persistent-placeholder
								label="Automatically mark as paid"
								:placeholder="$t('expenses.paid-question')"
								:items="paidOptions"
								v-model="expenseCopy.paid"
						></v-select>

						<div v-if="!expenseCopy.paid">
							<div class="font-14 text-left font-gray_70">{{$t('recurring.expenses.due')}}</div>
							<div class="d-flex align-center justify-space-between half" style="gap:12px;">
								<v-text-field
										type="number"
										inputmode="number"
										:placeholder="$t('templates.packages.deliverable-detail-modal.duration-placeholder', { units: humanUnits })"
										hide-details
										class="h-outline-no-label"
										persistent-placeholder
										v-model="expenseCopy.dueDate.duration"
										@keydown="handleDurationKeydown($event)"
								/>
								<v-select
										:placeholder="$t('templates.packages.deliverable-detail-modal.units-placeholder')"
										hide-details
										class="h-outline-no-label"
										persistent-placeholder
										:items="timeUnits"
										item-value="value"
										item-text="text"
										v-model="expenseCopy.dueDate.timeUnit"
								/>
							</div>
						</div>


					</v-col>
					<v-col cols="6" class="pl-1">
						<v-autocomplete
								class="h-outline"
								hide-details
								persistent-placeholder
								:label="$t('client.client')"
								:placeholder="$t('expenses.link-to-client')"
								v-model="expenseCopy.clientId"
								:items="clientsPlusClear"
								item-text="name"
								item-value="id"
								@change="clientChanged"
								ref="clientId"
						></v-autocomplete>

						<div v-if="expenseCopy.clientId">
							<v-autocomplete
									class="h-outline"
									hide-details
									persistent-placeholder
									:label="$t('projects.project')"
									:placeholder="$t('expenses.link-to-project')"
									:disabled="noClientDisabled"
									v-model="expenseCopy.projectId"
									:items="clientProjectsPlusClear"
									item-text="name"
									item-value="id"
									@change="projectChanged"
									no-data-text="No projects for selected client"
							></v-autocomplete>

							<div class="h-outline d-flex justify-start align-center font-14 px-2 py-1 mb-2">
								<v-simple-checkbox
										v-model="expenseCopy.reimbursable"
										color="var(--v-primary-base)"
								></v-simple-checkbox>
								<label class="font-gray_90">{{ $t('expenses.reimburse-status') }}</label>
							</div>
							<div v-if="expenseCopy.reimbursable">
								<amount-input
										class="h-outline"
										hide-details
										label="Markup percentage to client"
										suffix="%"
										type="number"
										v-model="expenseCopy.markupPercent"
										persistent-placeholder
								></amount-input>
								<div class="body-12 mt-2 mb-3 gray_80--text text-left" v-if="expenseCopy.markupPercent">
									+{{$formatters.dollars(markup,true,true,expenseCopy.currency)}} markup = {{$formatters.dollars(totalWithMarkup,true,true,expenseCopy.currency)}}
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="mt-2 pa-2">
			<v-btn icon class="delete mr-2" @click="deleteRecurringExpense()" v-if="recurring.id">
				<v-icon color="gray_70">$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action" :width="$vuetify.breakpoint.smAndUp ? '160' : '128'" @click="saveRecurringExpense">
				{{ $t(!id ? 'global.create' : 'global.save') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import ExpenseMixin from "@/modules/accounting/expenses/ExpenseMixin";
	import expenseCategories from "@/assets/data/expense-categories.json";
	import SimpleTextInput from "@/components/SimpleTextInput";
	import VendorDetail from "@/modules/accounting/vendors/VendorDetail";
	import currencies from '@/assets/data/currencies.json';
	import { cloneDeep } from 'lodash';
	import RecurringEventConfig from "@/modules/recurrence/RecurringEventConfig";
	import RecurringEventMixin from "@/modules/recurrence/RecurringEventMixin";
	import ConfirmModal from "@/components/ConfirmModal";
  import AmountInput from "@/components/AmountInput.vue";

	export default {
		name: 'RecurringExpense',

		props: ['id'],

		mixins: [ExpenseMixin, RecurringEventMixin],

		components: {AmountInput, RecurringEventConfig},

		data: function () {
			return {
				currencies: currencies,
				expenseCopy: null,
				recurring: null,
				isReady: false,

				expenseCategories: expenseCategories,
				customExpenseCategories: null,
				timeUnits: [
					{ text: 'Day(s)', value: 'DAYS' },
					{ text: 'Week(s)', value: 'WEEKS' },
					{ text: 'Month(s)', value: 'MONTHS' },
				],
			};
		},

		mounted() {
			this.initialize();
			this.initRelatedData();
			this.getCustomExpenseCategories();
		},

		beforeDestroy() {},

		methods: {
			initialize: function(){
				if(this.id){
					this.getRecurringEvent(this.id).then((res) => {
						this.expenseCopy = res.data.expense;
						this.recurring = res.data;
						this.isReady = true;
					});
				}else{
					this.expenseCopy = this.initializeExpense();
					this.recurring = this.initRecurring('EXPENSE');
					this.isReady = true;
				}
			},

			saveRecurringExpense: function(){
				if(!this.expenseCopy.markupPercent){
					this.expenseCopy.markupPercent = 0;
				}else{
					this.expenseCopy.markupPercent = parseFloat(this.expenseCopy.markupPercent);
				}

				this.saveRecurringEvent(this.recurring,this.expenseCopy,'EXPENSE').then((res) => {
					this.$emit('result',res.data);
				})
			},

			deleteRecurringExpense: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this recurring expense?'
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.deleteRecurringEvent(this.id).then(() => {
							this.$emit('deleted',this.id);
						})
					}
				})
			},

			initializeExpense: function () {
				let result = {
						id: null,
						vendorId: null,
						clientId: null,
						projectId: null,
						amount: 0,
						taxInclusive: true,
						taxRate: 0,
						currency: this.$store.state.defaultCurrency,
						billNo: null,
						category: null,
						paid: true,
						dueDate: {duration:1, timeUnit: 'MONTHS'},
						description: null,
						notes: null,
						reimbursable: false,
						attachments: [],
					};

				return result;
			},


			initRelatedData: function(){
				this.getClients();
				this.getProjects();
				this.getVendors();
			},

			getCustomExpenseCategories: function () {
				this.expenseService.getCategories().then((res) => {
					this.customExpenseCategories = res.data;
				});
			},

			clientChanged: function (value) {
				if (this.expenseCopy.clientId !== value) {
					this.expenseCopy.projectId = null;
				}
				if (value === 'clear') {
					this.expenseCopy.clientId = null;
					this.expenseCopy.projectId = null;
				}
			},

			projectChanged: function (value) {
				if (value === 'clear') {
					this.expenseCopy.projectId = null;
				}
			},

			categoryChanged: function (value) {
				if (!value) {
					this.expenseCopy.category = null;
				} else if (value.trim() === '--Clear--') {
					this.expenseCopy.category = null;
				} else if (value.trim() === '+ Add new') {
					let binding = {
						label: 'Add new category',
						bodyText: null,
					};
					this.$store.state.globalModalController.openModal(SimpleTextInput, binding, false, false).then((res) => {
						if (res) {
							this.customExpenseCategories.categories.push(res);
							this.updateCustomCategories();
							this.expenseCopy.category = res;
						} else {
							this.expenseCopy.category = null;
						}
					});
				}
			},

			vendorChanged: function (value) {
				if (value === 'clear') {
					this.expenseCopy.vendorId = null;
				} else if (value.trim() === '+ Add new') {
					let binding = {
						vendor:  {
							contact: {},
						},
						vendors: this.vendors
					}
					this.$store.state.globalModalController.openModal(VendorDetail, binding, false, false).then((res) => {
						if(res){
							this.vendors.push(res);
							this.expenseCopy.vendorId = res.id;
							this.$emit('new-vendor',res);
						}
					});
				}
			},

			handleClose: function(){
				this.$emit('close');
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},
		},

		computed: {
			total: function(){
				if(this.expenseCopy.taxInclusive){
					return parseFloat(this.expenseCopy.amount);
				}else{
					return parseFloat(this.expenseCopy.amount) + (parseFloat(this.expenseCopy.amount) * (parseFloat(this.expenseCopy.taxRate) / 100));
				}
			},

			markup: function(){
				if(this.expenseCopy.markupPercent){
					return (this.total * parseFloat(this.expenseCopy.markupPercent)) / 100;
				}else{
					return 0;
				}
			},

			totalWithMarkup(){
				return this.total + this.markup;
			},

			humanUnits() {
				let i = this.timeUnits.findIndex((u) => {
					return u.value === this.expenseCopy.dueDate.timeUnit;
				});
				if (i > -1) return this.timeUnits[i].text;
				return '';
			},

			noClientDisabled() {
				if (this.expenseCopy.clientId) return false;
				return true;
			},

			paidOptions() {
				let item = [];
				item.push({ text: this.$t('global.yes'), value: true });
				item.push({ text: this.$t('global.no'), value: false });
				return item;
			},

			currencySymbol: function () {
				return this.$formatters.currencySymbol(this.expenseCopy.currency);
			},

			clientsPlusClear: function () {
				let tmp = cloneDeep(this.clients);
				if (this.expenseCopy.clientId) {
					tmp.unshift({ name: '--Clear--', id: 'clear' });
				}
				return tmp;
			},

			clientProjectsPlusClear: function () {
				if (this.expenseCopy.clientId && this.projects) {
					let tmp = this.projects.filter((p) => p.clientId == this.expenseCopy.clientId);
					if (this.expenseCopy.projectId) {
						tmp.unshift({ name: '--Clear--', id: 'clear' });
					}
					return tmp;
				} else {
					return [];
				}
			},

			expenseCategoriesPlusClear: function () {
				let tmp = cloneDeep(expenseCategories);

				if (this.customExpenseCategories) {
					tmp.push(...this.customExpenseCategories.categories);
				}

				if (this.expenseCopy && this.expenseCopy.category) {
					if (!tmp.includes(this.expenseCopy.category)) {
						tmp.push(this.expenseCopy.category);
					}
				}

				tmp.sort();

				if (this.expenseCopy && this.expenseCopy.category) {
					tmp.unshift('--Clear--');
				}

				tmp.push('+ Add new');

				return tmp;
			},

			vendorsPlusClear: function () {
				let tmp = cloneDeep(this.vendors);
				if (this.expenseCopy.vendorId) {
					tmp.unshift({ name: '--Clear--', id: 'clear' });
				}
				tmp.push('+ Add new');
				return tmp;
			},
		},
	};
</script>

<style scoped lang="scss">
	#recurring-expense-detail {
		min-width: 684px;
		max-width: 684px;
		background-color: var(--v-white-base);
	}
</style>
