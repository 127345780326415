import { render, staticRenderFns } from "./ClientNotes.vue?vue&type=template&id=5365b10e&scoped=true&"
import script from "./ClientNotes.vue?vue&type=script&lang=js&"
export * from "./ClientNotes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5365b10e",
  null
  
)

export default component.exports