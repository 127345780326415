<template>
	<v-container fluid class="ma-0 pa-0">
		<v-row dense>
			<v-col class="edit-note py-2" cols="12" v-for="note in filteredNotes" :key="note.id">
				<div class="row-format align-center">
					<div class="font-12">{{ note.author }}</div>
					<v-icon size="12" color="gray_70" class="ml-3 mr-1">$clock</v-icon>
					<div class="font-gray_70 font-12">
						{{ note.timestampUpdated ? note.timestampUpdated : note.timestamp | formatForTimeAgo }}
					</div>
					<div class="ml-auto pointer" style="margin-left: auto; padding-right: 1px">
						<v-icon v-if="note.id !== activeNote" small @click.native="setActive(note)" class="material-symbols-rounded mx-1">edit</v-icon>
						<v-icon small @click.native="confirmDeleteNote(note)">$delete</v-icon>
					</div>
				</div>
				<div @click="toggleNoteExpansion(note)">
					<div
						v-if="note.id !== activeNote"
						v-html="note.entry === '' ? 'Enter a note...' : note.entry"
						class="note-wrapper"
						style="border-bottom: 1px solid var(--v-gray_30-base)"
					></div>
					<div v-else autofocus class="text-left">
						<editor
							v-model="note.entry"
							:ref="'editor' + note.id"
							:api-key="$store.getters.getTinyMceKey"
							:inline="false"
							:init="mceConfig(note)"
							class="no-wrap-editor"
							style="width: 100%"
							@onblur="saveNote(note)"
						>
						</editor>
					</div>
				</div>
			</v-col>
		</v-row>

		<confirm-dialog
			v-if="confirmDeleteDialog"
			:dialog="confirmDeleteDialog"
			heading-text="Delete?"
			body-text="Are you sure you want to delete this note?"
			@close="cancelDeleteNote"
			@confirm="deleteNote"
		></confirm-dialog>
	</v-container>
</template>

<script>
	import NoteService from '@/services/NoteService';
	import marked from 'marked';
	import DateTime from '@/modules/utils/HDateTime';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import editor from '@tinymce/tinymce-vue';

	export default {
		mixins: [],

		name: 'Notes',

		props: ['client', 'filter', 'project'],

		components: { ConfirmDialog, editor },

		data() {
			return {
				DateTime: DateTime,
				noteService: new NoteService(),
				notes: [],
				activeNote: null,
				activeNewNote: false,
				editNoteId: null,
				deleteNoteId: null,
				confirmDeleteDialog: false,
				newNote: null,
			};
		},

		mounted() {
			this.getNotes();
		},

		methods: {
			mceConfig: function(note) {
				return {
					menubar: false,
					inline: false,
					paste_as_text: false,
          browser_spellcheck: true,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Add a note...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['autolink', 'paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: false,
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					default_link_target: '_blank',
					toolbar: [
						'undo redo | fontsizeselect styleselect | ' +
							'bold italic underline backcolor forecolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | table link unlink media code | savebutton',
					],
					content_style:
						"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",

					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('savebutton', {
							icon: 'new-save',
							onAction: () => {
								this.saveNote(note);
							},
						});
						editor.ui.registry.addIcon(
							'new-save',
							'<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/></svg>'
						);
					},
				};
			},

			getNotes: function() {
				this.noteService.getNoteList(this.client.id, this.projectId).then((res) => {
					this.notes = res.data.map((note) => ({
						...note,
						expanded: false,
					}));
					this.sortNotes();

					this.notes.forEach((n) => {
						if (n.format === 'Markdown') {
							n.entry = this.formatMarkdown(n.entry);
							n.format = 'HTML';
						}
					});

					if (this.notes.length > 0) {
						let firstNote = this.notes[0];
						firstNote.expanded = true;
						this.notes.splice(0, 1, firstNote);
					}
				});
			},

			toggleNoteExpansion(note) {
				note.expanded = !note.expanded;
			},

			addNewNote: function() {
				let note = {
					format: 'HTML',
					entry: '',
					projectId: this.projectId,
				};

				this.noteService.postNote(this.client.id, note).then((res) => {
					res.data.expanded = true;
					this.notes.push(res.data);
					this.setActive(res.data);
					this.sortNotes();
				});
			},

			sortNotes: function() {
				this.notes.sort((a, b) => {
					let atime = a.timestampUpdated ? a.timestampUpdated : a.timestamp;
					let btime = b.timestampUpdated ? b.timestampUpdated : b.timestamp;
					return atime > btime ? -1 : 1;
				});
			},

			setActive: function(note) {
				this.activeNote = note.id;
			},

			newNoteActive: function(newNote) {
				this.activeNewNote = newNote;
			},

			setInactive: function() {
				this.activeNote = null;
			},

			saveNote: function(note) {
				this.noteService.putNote(this.client.id, note.id, note).then((res) => {
					let ix = this.notes.findIndex((n) => n.id === note.id);
					if (ix > -1) {
						this.notes[ix].entry = res.data.entry;
						this.editNoteId = null;
						this.sortNotes();
					}
				});
				this.activeNote = null;
			},

			confirmDeleteNote(note) {
				this.confirmDeleteDialog = true;
				this.deleteNoteId = note.id;
			},

			cancelDeleteNote() {
				this.confirmDeleteDialog = false;
				this.deleteNoteId = null;
			},

			deleteNote: function() {
				this.noteService
					.deleteNote(this.client.id, this.deleteNoteId)
					.then(() => {
						let ix = this.notes.findIndex((n) => n.id === this.deleteNoteId);
						if (ix > -1) {
							this.notes.splice(ix, 1);
						}
					})
					.finally(() => {
						this.deleteNoteId = null;
						this.confirmDeleteDialog = false;
					});
			},

			formatMarkdown: function(comment) {
				let m = marked(comment, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},
		},

		computed: {
			projectId: function() {
				if (this.project) {
					return this.project.id;
				} else {
					return null;
				}
			},

			filteredNotes: function() {
				let notes = [...this.notes];
				if (this.filter.search) {
					let search = this.filter.search.toLowerCase();
					return notes.filter((n) => {
						if (n.entry && n.entry.toLowerCase().includes(search)) {
							return true;
						} else if (n.author && n.author.toLowerCase().includes(search)) {
							return true;
						} else {
							return false;
						}
					});
				} else {
					return notes;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.collapsed {
		width: 100%;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 22px;
	}
	.edit-note {
		.delete-button {
			visibility: hidden;
		}
		&:hover {
			.delete-button {
				visibility: visible;
			}
		}
	}

	.note-wrapper {
		box-sizing: border-box;
		text-align: left;
		padding: 12px;

		::v-deep p {
			margin: 0px;
		}
	}

	.editable-content {
		outline: none;
		cursor: text;
	}
</style>
