var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"list-view",attrs:{"item-key":"id","fixed-header":"","headers":_vm.headers,"items":_vm.subscriptionsComputed,"height":"calc(var(--vh) - 150px)","hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.clientMini)?_c('div',{staticClass:"row-format align-center"},[_c('client-avatar',{attrs:{"client":item.clientMini}}),_c('div',[_vm._v(_vm._s(item.clientMini.name))])],1):_c('div',[_vm._v("--")])]}},{key:"item.periodStart",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center",staticStyle:{"height":"100%"}},[(item.periodStart && item.periodEnd)?_c('div',[_vm._v(" "+_vm._s(_vm.DateTime.fromISO(item.periodStart).toLocaleString())+" - "+_vm._s(_vm.DateTime.fromISO(item.periodEnd).toLocaleString())+" ")]):_c('div',[_vm._v("--")])])]}},{key:"item.daysUntilDue",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center",staticStyle:{"height":"100%"}},[(item.daysUntilDue)?_c('div',[_vm._v(_vm._s(item.daysUntilDue))]):_c('div',[_vm._v("--")])])]}},{key:"item.subscriptionItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center",staticStyle:{"height":"100%"}},_vm._l((item.subscriptionItems),function(subItem,index){return _c('div',{key:item.clientMini + ':' + index},[_vm._v(" "+_vm._s(subItem.quantity)+" x "+_vm._s(subItem.description)+" @ "+_vm._s(_vm.$formatters.dollars(subItem.amount, true, true, subItem.currency))+" ")])}),0)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center",staticStyle:{"height":"100%"}},[(item.status === 'active')?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("$check")]):(item.status === 'canceled' || item.status === 'ended')?_c('v-icon',{attrs:{"color":"gray_80","small":""}},[_vm._v("$cancel")]):(item.status === 'sent')?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("$email")]):_c('v-icon',{attrs:{"color":"warning","small":""}},[_vm._v("$alert")]),(item.status === 'active' && item.cancelAtPeriodEnd)?_c('div',{staticClass:"ml-1"},[_vm._v("Cancellation pending")]):_c('div',{staticClass:"ml-1",staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.status.replace('_', ' ')))])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('subscription-action-menu',{attrs:{"subscription":item},on:{"cancel-subscription":function($event){return _vm.cancelSubscription(item)},"copy-payment-link":function($event){return _vm.copyPaymentLink(item)},"cancel-request":function($event){return _vm.cancelSubscriptionRequest(item)},"view-payments":function($event){return _vm.viewPayments(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }