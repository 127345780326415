<template>
	<div style="background: var(--v-white-base);" class="fill-width">
		<div class="row-format py-3 pr-5">
			<plus-button class="ml-auto" @click="addToDo()"></plus-button>
		</div>
		<table id="fields-table" class="table" style="table-layout: auto">
			<thead>
				<tr>
					<th style="text-align: center; width: 50px;"></th>
					<th>To do</th>
					<th style="width:200px">Relative due date</th>
					<th>Add on stage</th>
					<th style="text-align: center; width: 50px;"></th>
				</tr>
			</thead>
			<draggable
				v-model="pipelineToDos.toDos"
				group="options"
				tag="tbody"
				@change="saveToDos"
				handle=".drag"
				v-if="pipelineToDos && pipelineToDos.toDos.length"
			>
				<tr v-for="toDo in pipelineToDos.toDos" :key="toDo.id" class="font-14 text-left">
					<td style="width: 50px; text-align: center">
						<v-icon class="drag">drag_handle</v-icon>
					</td>
					<td>
						<v-text-field
							placeholder="ToDo"
							hide-details
							dense
							outlined
							persistent-placeholder
							v-model="toDo.item"
							@change="saveToDos()"
						/>
					</td>
					<td style="width:250px">
						<div class="row-format gap-2" style="max-width: fit-content">
							<v-text-field
								style="max-width: 70px"
								type="number"
								inputmode="number"
								:placeholder="
									$t('templates.packages.deliverable-detail-modal.duration-placeholder', {
										units: humanUnits(toDo.relativeDueDate.timeUnit),
									})
								"
								hide-details
								dense
								outlined
								persistent-placeholder
								v-model="toDo.relativeDueDate.duration"
								@keydown="handleDurationKeydown($event)"
								@change="saveToDos()"
							/>
							<v-select
								style="max-width: 180px"
								:placeholder="$t('templates.packages.deliverable-detail-modal.units-placeholder')"
								hide-details
								dense
								outlined
								clearable
								:items="timeUnits"
								item-value="value"
								item-text="text"
								v-model="toDo.relativeDueDate.timeUnit"
								@change="saveToDos()"
							/>
						</div>
					</td>
					<td>
						<div class="row-format gap-2">
							<v-select
								style="width:48%"
								placeholder="Stage"
								hide-details
								dense
								outlined
								clearable
								:items="stages"
								item-value="id"
								item-text="label"
								v-model="toDo.scopeId"
								@change="saveToDos()"
							/>
						</div>
					</td>
					<td style="text-align: center; width: 50px;">
						<v-icon class="material-symbols-rounded" size="20" @click="confirmDelete(toDo)">delete</v-icon>
					</td>
				</tr>
			</draggable>

			<tr v-else>
				<td colspan="3" style="text-align: center" class="pa-4 font-gray_70">No default to-dos defined</td>
			</tr>
		</table>
	</div>
</template>

<script>
	import PipelineSettingsService from '@/modules/pipeline/settings/PipelineSettingsService';
	import PlusButton from '@/components/PlusButton';
	import draggable from 'vuedraggable';
	import { v4 as uuidv4 } from 'uuid';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'DefaultToDos',

		props: [],

		components: { PlusButton, draggable },

		data: function() {
			return {
				pipelineToDos: null,
				stageSettings: null,
				settingsService: new PipelineSettingsService(),
				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],
			};
		},

		mounted() {
			this.getPipelineToDos();
			this.getStages();
		},

		beforeDestroy() {},

		methods: {
			getPipelineToDos: function() {
				this.settingsService.getPipelineToDos().then((res) => (this.pipelineToDos = res.data));
			},

			getStages: function() {
				this.settingsService.getPipelineStages().then((res) => {
					this.stageSettings = res.data;
				});
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},

			humanUnits(timeUnit) {
				let i = this.timeUnits.findIndex((u) => {
					return u.value === timeUnit;
				});
				if (i > -1) return this.timeUnits[i].text;
				return '';
			},

			confirmDelete: function(toDo) {
				let binding = {
					headingText: 'Confirm delete?',
					bodyText: 'Are you sure you want to remove this field from your opportunity settings?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.pipelineToDos.toDos.findIndex((f) => f.id === toDo.id);
						this.pipelineToDos.toDos.splice(ix, 1);
						this.saveToDos();
					}
				});
			},

			setItem: function(toDos, event) {
				if (event.target && event.target.innerText) {
					toDos.item = event.target.innerText;
					event.target.innerText = toDos.item;
					this.saveToDos();
				}
			},

			addToDo: function() {
				let field = {
					id: uuidv4(),
					item: '',
					scopeId: null,
					relativeDueDate: {
						duration: null,
						timeUnit: null
					}
				};
				this.pipelineToDos.toDos.push(field);
				this.saveToDos();
			},

			saveToDos: function() {
				this.settingsService.updatePipelineToDos(this.pipelineToDos);
			},
		},

		computed: {
			stages: function() {
				if (this.stageSettings) {
					return this.stageSettings.stages;
				} else {
					return [];
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.drag {
		cursor: grab;
		&:active {
			cursor: grabbing;
		}
	}

	#fields-table {
		text-align: left;
		border-collapse: collapse;
		width: 100%;


		thead {
			tr {
				th {
					padding: 8px;
					font-size: 12px;
					font-weight: 600;
					margin: 0px;
					border-top: 1px solid var(--v-gray_30-base);
					border-bottom: 1px solid var(--v-gray_30-base);
					border-right: 1px solid var(--v-gray_30-base);

					&:first-of-type {
						padding-left: 20px !important;
					}

					&:last-of-type {
						padding-right: 12px !important;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_30-base);
					border-right: 1px solid var(--v-gray_30-base);

					&:first-of-type {
						padding-left: 20px !important;
					}
					&:last-of-type {
						padding-right: 12px !important;
					}
				}

				&:hover {
					td {
						background-color: var(--v-gray_10-base);
					}
				}
			}
		}
	}
</style>
