<template>
	<div class="text-left font-14 pb-8 column-format gap-4">
		<div class="column-format">
			<div class="row-format align-center">
				<client-avatar large :client="client"></client-avatar>
				<div class="row-format align-center ml-auto gap-2">
					<div v-if="client.archive" class="font-gray_70"><v-icon color="gray_70">archive</v-icon> Archived</div>
					<div class="row-format align-center accounting" style="position: relative" v-if="client.integrationKeys.quickbooksId || client.integrationKeys.xeroId">
						<v-icon size="20" v-if="client.integrationKeys.quickbooksId" v-tippy :content="`Linked to QBO record: ${client.integrationKeys.quickbooksId}`">$quickbooks</v-icon>
						<v-icon size="20" v-if="client.integrationKeys.xeroId" v-tippy:content="`Linked to Xero record: ${client.integrationKeys.xeroId}`">$xero</v-icon>
						<div class="unlink" @click="confirmUnlink" style="background-color: var(--v-white-base); position: absolute; right: 12px; top: -8px; border-radius: 20px"><v-icon size="20" class="pointer" color="red">cancel</v-icon></div>
					</div>
					<v-btn class="primary-action" @click="$emit('edit-client')">
						<span class="font-secondary">Edit</span>
						<v-icon small class="ml-1" color="secondary">edit</v-icon>
					</v-btn>
				</div>
			</div>

			<div class="font-20 brand-medium mt-3 row-format align-center" style="line-height: 24px">{{ client.name }}</div>
			<div class="font-gray_70">{{ client.clientType }} &bull; <span class="text-link" @click="viewPortalAs()">View portal</span></div>
		</div>

		<div class="column-format">
			<div class="font-gray_70">{{ $t('contact.address') }}</div>
			<div v-if="client.address1 || client.address2 || client.city || client.locality || client.postal" class="text-link" @click="openMap">
				<div class="truncate">{{ client.address1 }}</div>
				<div class="truncate">{{ client.address2 }}</div>
				<div class="truncate">{{ client.city }} {{ client.locality }} {{ client.postal }}</div>
			</div>
			<div v-else>--</div>
		</div>

		<div class="column-format">
			<div class="font-gray_70">{{ $t('client.phone') }}</div>
			<div v-if="phone" class="text-link truncate" @click="makePhoneCall(phone)">{{ phone }}</div>
			<div v-else>--</div>
		</div>

		<div class="column-format">
			<div class="font-gray_70">{{ $t('client.website') }}</div>
			<div v-if="website" class="text-link truncate" @click="openWebsite(website)">{{ website }}</div>
			<div v-else>--</div>
		</div>

		<div class="column-format">
			<div class="font-gray_70">{{ $t('client.source') }}</div>
			<div v-if="client.leadSource" class="text-link truncate">{{ client.leadSource }}</div>
			<div v-else>--</div>
		</div>

		<div class="column-format" v-for="(customValue,index) in customValues" :key="index">
			<div class="font-gray_70">{{customValue.label}}</div>
			<div v-if="customValue.isLink" class="text-link truncate" @click="openWebsite(customValue.value)">{{ customValue.value }}</div>
			<div v-else class="truncate">{{customValue.value}}</div>
		</div>

		<div class="column-format" v-if="$store.getters.hasInvoiceAccess">
			<div class="font-gray_70">{{ $t('client.payment-terms') }}</div>
			<div><span class="">Hourly rate:</span> {{client.hourlyAmount ? $formatters.dollars(client.hourlyAmount,true,true,client.currency) :  $formatters.dollars(0,true,true,client.currency)}}</div>
			<div v-if="client.paymentTerms"><span class="">Payments due:</span> {{client.paymentTerms.paymentDays ? (client.paymentTerms.paymentDays + ' days') : '--'}}</div>
			<div v-if="client.paymentTerms"><span class="">Late fee:</span> {{client.paymentTerms.latePaymentFee ? (client.paymentTerms.latePaymentFee + '% monthly') : '--'}}</div>
		</div>

		<div class="column-format">
			<div class="font-18">{{ $t('global.contacts') }}</div>
			<div class="row-format mt-1 pl-2">
				<div
					class="row-format centered contact-avatar pointer"
					@click="$emit('edit-contact',contact)"
					v-for="(contact,index) in client.contacts" :style="`z-index: ${50-index}`"
					:key="contact.id"
					v-tippy="{content: getContactFullName(contact)}"
				>
					<div>
						{{ contact.firstName ? contact.firstName.substr(0, 1).toUpperCase() : ''
						}}{{ contact.lastName ? contact.lastName.substr(0, 1).toUpperCase() : '' }}
					</div>
				</div>
				<div class="row-format centered contact-avatar-new pointer" @click="$emit('create-contact')">
					<v-icon color="gray_50">$plus</v-icon>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import DateTime from "@/modules/utils/HDateTime";
	import AuthService from "@/modules/auth/AuthService";
	import ConfirmModal from "@/components/ConfirmModal";
	export default {
		name: 'ClientInfo',

		props: ['client'],

		components: { ClientAvatar },

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			confirmUnlink: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to reset the relationship between this client and your accounting system?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.$emit('unlink-accounting');
					}
				})
			},

			viewPortalAs: function(){
				let clientAccess = {
					clients: [
						{
							clientId : this.client.id,
							clientName : this.client.name,
							granted : DateTime.now().toISO(),
						}
					]
				}

				let commPref = this.$store.state.communicationPreferences;

				AuthService.getPortalAccessToken(clientAccess).then((res) => {
					let url = `https://${commPref.primaryDomain}/magicToken?origUri=/welcome&magicToken=${res.data}`;
					window.open(url,'_blank');
				})
			},

			makePhoneCall: function (phone) {
				this.$store.state.eventBus.$emit('communicator-place-call', phone);
			},

			getContactFullName: function (c) {
				let fullName = (c.firstName ? c.firstName : '') + ' ' + (c.lastName ? c.lastName : '');
				return fullName.trim();
			},

			openMap: function () {
				let client = this.client;
				let address = [client.address1, client.address2, client.city, client.locality, client.postal];
				address = address.filter((a) => a).join('+');
				let url = `https://www.google.com/maps/place/${address}`;
				window.open(url, '_blank');
			},

			openWebsite: function (website) {
				if (!website.toLowerCase().startsWith('http://') && !website.toLowerCase().startsWith('https://')) {
					website = 'https://' + website;
				}
				window.open(website, '_blank');
			},
		},

		computed: {
			customValues: function(){
				let result = [];

				for(let i=0; i < this.$store.state.clientFields.fields.length; i++){
					let field = this.$store.state.clientFields.fields[i];
					let value = this.client.customValues.find(v => v.fieldId === field.id);
					if(value && value.value !== null && value.value !== ''){
						let customValue = value.value;

						if(field.type === 'Date'){
							customValue = DateTime.fromISO(customValue).toLocaleString(DateTime.DATE_MED)
						}

						result.push({
							label: field.name,
							value: customValue,
							isLink: field.type === 'Link'
						})
					}
				}

				return result;
			},

			website: function () {
				if (this.client.website) {
					return this.client.website;
				} else {
					return null;
				}
			},

			hourlyRate: function () {
				if (this.client.hourlyAmount) {
					return this.$formatters.dollars(this.client.hourlyAmount, true, true, this.client.currency);
				} else {
					return null;
				}
			},

			phone: function () {
				if (this.client.phone) {
					return this.client.phone;
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.accounting {
		.unlink {
			visibility: hidden;
		}
		&:hover {
			.unlink {
				visibility: visible;
			}
		}
	}

	.contact-avatar {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		background-color: var(--v-black-base);
		color: var(--v-white-base);
		font-size: 16px;
		border: 2px solid var(--v-white-base);
		margin-left: -8px;
	}

	.contact-avatar-new {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		background-color: var(--v-white-base);
		color: var(--v-white-base);
		font-size: 16px;
		border: 2px dashed var(--v-gray_30-base);
		margin-left: -8px;
	}
</style>
