<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="processedEvents"
			:hide-default-footer="true"
			sort-by="timerStart"
			:sort-desc="true"
			height="calc(var(--vh) - 240px)"
			:fixed-header="true"
			style="cursor: pointer"
			:items-per-page="-1"
			@click:row="$emit('edit-timer', $event)"
		>
			<template v-slot:item.invoiceId="{ item }">
				<div class="brand-semilight font-12">
					<div
						v-if="item.invoiceId"
						class="invoice-status"
						style="--color: var(--v-success); --background: var(--v-success_10-base)"
						@click.stop="$emit('open-invoice', item.invoiceId)"
					>
						<div class="default-label">Billed</div>
						<div class="hover-label">View invoice</div>
					</div>

					<div v-else class="pointer" v-html="getStatus(item)"></div>
				</div>
			</template>

			<template v-slot:item.userId="{ item }">
				<assigned-user :show-name="false" :assigned-to="item.userId"></assigned-user>
			</template>

			<template v-slot:item.timerStart="{ item }">
				<span class="brand-semilight">{{ DateTime.fromISO(item.timerStart).toFormat('DD') }}</span>
			</template>

			<template v-slot:item.duration="{ item }">
				<span class="brand-semilight mr-5">{{ formatSeconds(item.duration) }}</span>
			</template>

			<template v-slot:item.client="{ item }">
				<client-avatar v-if="item.client" :client="item.client" />
				<div v-else>--</div>
			</template>

			<template v-slot:item.projectName="{ item }">
				<span class="brand-semilight">{{ item.projectName ? item.projectName : ' -- ' }}</span>
			</template>

			<template v-slot:item.deliverableOrNote="{ item }">
				<div class="brand-semilight" style="max-width: 250px;">
					<span style=" word-break: break-all;" v-html="item.deliverableOrNote ? item.deliverableOrNote : ' -- '"></span>
				</div>
			</template>

			<template v-slot:item.action="{item}">
				<v-icon class="mr-1" small @click.stop="$emit('duplicate',item)" v-tippy="{content:'Duplicate time entry'}">content_copy</v-icon>
			</template>

			<template v-slot:header.action="{}">
				<div class="row-format ml-auto mr-1" style="justify-content: flex-end">
					<v-icon size="20" class="pointer" @click="$emit('add-new')" v-if="showAdd">$plus</v-icon>
					<v-menu :close-on-click="true" :close-on-content-click="true" v-if="showDownload">
						<template v-slot:activator="{ on }">
							<v-icon size="20" class="ml-1" v-on="on">$download</v-icon>
						</template>
						<div class="more-menu">
							<div class="more-menu-item" @click="downloadReport('XLSX')">Excel file</div>
							<div class="more-menu-item" @click="downloadReport('PDF')">PDF file</div>
						</div>
					</v-menu>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import AssignedUser from '@/components/AssignedUser';
	import DateTime from '@/modules/utils/HDateTime';
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'TimerEventTable',

		props: {
			events: {
				type: Array,
				required: true,
			},

			showClient: {
				type: Boolean,
				required: false,
				default: false,
			},

			showAdd: {
				type: Boolean,
				required: false,
				default: true,
			},

			showDownload: {
				type: Boolean,
				required: false,
				default: true,
			},
		},

		components: { AssignedUser, ClientAvatar },

		data: function() {
			return {
				DateTime: DateTime,
				timeTrackService: new TimeTrackService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			downloadReport: function(fileType) {
				this.$store.commit('startLoading');
				let sortedEvents = [...this.events];
				sortedEvents.sort((a, b) => a.timerStart.localeCompare(b.timerStart));

				let earliest = DateTime.fromISO(sortedEvents[0].timerStart).startOf('day');
				let latest = DateTime.fromISO(sortedEvents[sortedEvents.length - 1].timerStart).endOf('day');

				let eventIds = this.events.map((e) => e.id);
				this.timeTrackService
					.timerEventDownload(earliest.toISO(), latest.toISO(), eventIds, fileType)
					.then((res) => {
						let fileURL = window.URL.createObjectURL(res.data);
						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute(
							'download',
							'TimerEvents-' +
								earliest.toISODate() +
								'-thru-' +
								latest.toISODate() +
								`.${fileType === 'PDF' ? 'pdf' : 'xlsx'}`
						);
						document.body.appendChild(fileLink);
						fileLink.click();
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			getStatus(event) {
				let background, color, label;

				if (event.invoiceId) {
					background = '--v-success_10-base';
					color = '--v-success';
					label = 'Billed';
				} else if (
					event.billable &&
					(!event.projectId ||
						(event.feeSchedule &&
							(event.feeSchedule.feeType === 'Hourly' ||
								(event.feeSchedule.feeType === 'Retainer' && event.feeSchedule.retainerOverageRate))))
				) {
					background = '--v-gray_20-base';
					color = '--v-gray_80-base';
					label = 'Un-billed';
				} else {
					background = '';
					color = '';
					label = '--';
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},

			formatSeconds: function(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				//let time = hours + ':' + minutes + ':' + seconds;
				let time = hours + ':' + minutes;
				return time;
			},

			formatSecondsToHours: function(seconds) {
				return Math.round(seconds / 60 / 60) + 'hr';
			},
		},

		computed: {
			processedEvents: function() {
				let events = JSON.parse(JSON.stringify(this.events));
				let clients = this.$store.state.clientCache;

				events.forEach((e) => {
					if (this.showClient) {
						e.client = clients.find((c) => c.id === e.clientId);
					}
					e.deliverableOrNote =
							(e.deliverableName ? e.deliverableName : '') +
							(e.ticketName ? e.ticketName : '') +
							((e.deliverableName || e.ticketName) && e.notes ? '<br>' : '') +
							(e.notes ? `<span class="font-gray_70 font-12">${e.notes}</span>` : '');
				});

				return events;
			},

			headers: function() {
				let result = [
					{ text: this.$t('timetrack.list.status'), value: 'invoiceId', width: '150px' },
					{ text: this.$t('timetrack.list.user'), value: 'userId' },
					{ text: this.$t('timetrack.list.date'), value: 'timerStart' },
					{ text: this.$t('timetrack.list.time-worked'), value: 'duration', align: 'right' },
					{ text: this.$t('timetrack.list.project'), value: 'projectName' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				if (this.showClient) {
					result.splice(4, 0, { text: this.$t('global.client'), value: 'client' });
				}

				if (this.$vuetify.breakpoint.lgAndUp) {
					result.splice(4, 0, { text: this.$t('timetrack.list.deliverable-notes'), value: 'deliverableOrNote' });
				}

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.invoice-status {
		.default-label {
			display: block;
		}

		.hover-label {
			display: none;
		}

		&:hover {
			.default-label {
				display: none;
			}
			.hover-label {
				display: block;
			}
		}
	}
</style>
