<template>
	<div v-if="isReady" class="pa-4">
		<div>
			<div class="text-left">
				<div class="column-format">
					<div class="row-format align-center">
						<div class="brand-medium">Your number: {{ formatPhone(subscription.numbers[0].number) }}</div>
						<v-btn class="primary-action ml-auto" width="150" @click="updatePreferences()">Save settings</v-btn>
					</div>

					<v-checkbox
							hide-details
							dense
							v-model="subscription.preferences.ringBrowser"
							label="Accept calls in browser"
					></v-checkbox>
					<v-checkbox
							hide-details
							dense
							v-model="subscription.preferences.playMessageSoundInBrowser"
							label="Play incoming message sound in browser"
					></v-checkbox>
					<v-checkbox
							hide-details
							dense
							:true-value="false"
							:false-value="true"
							v-model="subscription.preferences.notificationsOutsideSchedule"
							label="Silence missed call / message notifications outside of schedule."
					></v-checkbox>

				</div>

				<div class="mt-5 mb-1 font-gray_80 font-14">
					If you aren't logged in or do not answer within Moxie, you can optionally choose to have calls forwarded to
					another phone
				</div>
				<phone-number-input
					v-model="forwardPhone"
					:default-country-code="defaultCountry"
					:only-countries="onlyCountries"
					:clearable="true"
					@update="phoneUpdated"
					:color="$vuetify.theme.dark ? '#56524E' : '#D6D1CB'"
					:valid-color="$vuetify.theme.dark ? '#56524E' : '#D6D1CB'"
					size="lg"
					dark-color="#181818"
					:dark="$vuetify.theme.dark"
				></phone-number-input>

				<div class="row-format mt-1 gap-2">
					<v-select  v-if="subscription.provider === 'Bandwidth'"
						:items="zeroToSixty"
						v-model="subscription.preferences.forwardDelay"
						persistent-placeholder
						hide-details
						class="h-outline"
						label="Delay before forwarding"
					>
						<template v-slot:selection="{ item }">{{ item }} seconds</template>
						<template v-slot:item="{ item }">{{ item }} seconds</template>
					</v-select>

					<v-select
						:items="zeroToSixty"
						v-model="subscription.preferences.forwardRing"
						persistent-placeholder
						hide-details
						class="h-outline"
						label="How long to ring forwarding phone"
					>
						<template v-slot:selection="{ item }">{{ item }} seconds</template>
						<template v-slot:item="{ item }">{{ item }} seconds</template>
					</v-select>
				</div>

				<v-select
						:items="callerIdOptions"
						v-model="subscription.preferences.callerId"
						persistent-placeholder
						hide-details
						class="h-outline mt-1"
						item-text="label"
						item-value="value"
						label="Whose number do you want to see when calls are forwarded to your phone?"
				>
				</v-select>

				<div class="column-format gap-2" v-if="$store.getters.isTeamAccount">
					<div class="row-format gap-2">
						<v-select
								:items="[{label:'Yes -share line with other team members',value:true},{label:'No - do not share',value:false}]"
								v-model="subscription.preferences.sharedLine"
								persistent-placeholder
								hide-details
								class="h-outline"
								item-text="label"
								item-value="value"
								label="Enable line sharing?"
						>
						</v-select>

						<v-select v-if="otherTeamMembers.length && subscription.preferences.sharedLine"
								:items="otherTeamMembers"
								persistent-placeholder
								hide-details
								class="h-outline"
								item-text="fullName"
								item-value="userId"
								:return-object="true"
								@change="handleTeamSelection($event)"
								label="Select team member to share with"
						>
						</v-select>
					</div>
					<div v-if="subscription.preferences.sharedWith.length && subscription.preferences.sharedLine" class="mb-5">
						<v-container fluid class="ma-0 pa-0">
							<v-row v-for="(sharedWith,index) in subscription.preferences.sharedWith" :key="sharedWith.userId" class="py-2">
								<v-col cols="6" class="font-14 row-format align-center">
									<v-btn icon class="mr-1" @click="removeTeamMember(index)"><v-icon size="20" class="material-symbols-rounded">delete</v-icon></v-btn>
									<div v-if="$store.getters.getUserById(sharedWith.userId)">{{$store.getters.getUserById(sharedWith.userId).firstName}} {{$store.getters.getUserById(sharedWith.userId).lastName}} <span class="font-12 font-gray_70">({{$store.getters.getUserById(sharedWith.userId).email}})</span></div>
								</v-col>
								<v-col cols="6">
										<phone-number-input
												v-model="sharedWith.forwardToFormatted"
												:default-country-code="defaultCountry"
												:only-countries="onlyCountries"
												:clearable="true"
												@update="sharedWithPhoneUpdated(sharedWith,$event)"
												:color="$vuetify.theme.dark ? '#56524E' : '#D6D1CB'"
												:valid-color="$vuetify.theme.dark ? '#56524E' : '#D6D1CB'"
												size="sm"
												dark-color="#181818"
												:dark="$vuetify.theme.dark"
										></phone-number-input>
								</v-col>
							</v-row>
						</v-container>
					</div>
				</div>

				<div class="mt-3 mb-1 font-gray_80 font-14">Outgoing voicemail greeting</div>
				<outgoing-greeting :subscription="subscription"></outgoing-greeting>

				<div class="mt-5 mb-1 font-gray_80 font-14">
					Set your business hours to limit what days and times incoming calls are forwarded to your phone
				</div>
				<div class="py-2 px-3" style="border: 1px solid var(--v-gray_50-base); border-radius: 4px">
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Sun"
						:periods="subscription.preferences.schedule.sunday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Mon"
						:periods="subscription.preferences.schedule.monday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Tue"
						:periods="subscription.preferences.schedule.tuesday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Wed"
						:periods="subscription.preferences.schedule.wednesday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Thu"
						:periods="subscription.preferences.schedule.thursday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Fri"
						:periods="subscription.preferences.schedule.friday"
					></period-configuration>
					<period-configuration
						label="Sat"
						:periods="subscription.preferences.schedule.saturday"
					></period-configuration>
					<v-autocomplete
						persistent-placeholder
						hide-details
						class="h-outline mt-2"
						label="Schedule timezone"
						v-model="subscription.preferences.schedule.timezone"
						:items="timezones"
					>
					</v-autocomplete>
				</div>

				<div class="column-format gap-2 mt-3">
					<div class="font-gray_80 font-14">Block specific phone numbers from being able to call or message your phone line. </div>
					<div class="row-format gap-2">
						<div style="width:100%; position: relative">
							<vue-tel-input mode="international"  v-model="blockPhone" :valid-characters-only="true" :preferred-countries="preferredCountries" class="dense-phone" :inputOptions="{placeholder:'Phone'}"></vue-tel-input>
							<div class="phone-label">Phone</div>
						</div>
						<v-btn outlined style="min-height: 40px" color="gray_70" @click="addBlockedNumber">Add blocked number</v-btn>
					</div>
					<div class="row-format gap-1" style="flex-wrap: wrap">
						<v-chip v-for="(blockedNumber,index) in subscription.preferences.blockedNumbers" :key="index" color="error">
								{{blockedNumber}}
								<v-icon size="20" class="pointer material-symbols-rounded" @click="subscription.preferences.blockedNumbers.splice(index,1); updatePreferences(false)">delete</v-icon>
						</v-chip>
					</div>

				</div>

				<div class="row-format centered mt-12">
					<v-btn class="mr-1" @click="confirmCancel()" color="error" outlined
						><v-icon size="20" class="material-symbols-rounded" color="error">delete</v-icon> Cancel business phone line</v-btn
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ConfirmModal from '@/components/ConfirmModal';
	import BandwidthService from '@/modules/communicator/realtime/bandwidth/BandwidthService';
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import PeriodConfiguration from '@/components/PeriodConfiguration';
	import libPhoneNumber from 'google-libphonenumber';
	import OutgoingGreeting from '@/modules/communicator/settings/OutgoingGreeting';
	import timezones from '@/assets/data/timezones.json';
	export default {
		name: 'Subscriptions',

		props: [],

		components: { OutgoingGreeting, PeriodConfiguration },

		data: function () {
			return {
				blockPhone: null,
				callerIdOptions: [{label:'Their number',value:'TheirNumber'},{label:'My number',value:'MyNumber'}],
				bandwidthService: new BandwidthService(),
				communicatorService: new CommunicatorService(),
				subscription: null,
				forwardPhone: null,
				isReady: false,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				timezones: timezones,
			};
		},

		mounted() {
			this.getSubscription();
		},

		beforeDestroy() {},

		methods: {
			addBlockedNumber: function(){
				if(this.blockPhone){
					let result = this.blockPhone.replaceAll(" ","");
					this.subscription.preferences.blockedNumbers.push(result);
					this.updatePreferences(false);
					this.blockPhone = null;
				}
			},

			getSubscription: function () {
				this.communicatorService
					.getSubscription()
					.then((res) => {
						if(res.data) {
							this.subscription = res.data;
							this.forwardPhone = this.subscription.preferences.forwardTo;
							this.subscription.preferences.sharedWith.forEach(s => s.forwardToFormatted = s.forwardTo);
						}
					})
					.finally(() => (this.isReady = true));
			},

			updatePreferences: function (confirm = true) {
				this.communicatorService
					.updatePreferences(this.subscription.id, this.subscription.preferences)
					.then((res) => {
						this.subscription = res.data;
						this.subscription.preferences.sharedWith.forEach(s => s.forwardToFormatted = s.forwardTo);
						this.$store.state.communicatorSubscription = res.data;
						if(confirm)this.$store.commit('success','Preferences saved!')
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
			},

			phoneUpdated: function (updatedPhone) {
				if (updatedPhone.isValid) {
					this.subscription.preferences.forwardTo = updatedPhone.e164;
				} else {
					this.subscription.preferences.forwardTo = null;
				}
			},

			sharedWithPhoneUpdated: function(sharedWith, updatedPhone){
				let ix = this.subscription.preferences.sharedWith.findIndex(s => s.userId === sharedWith.userId);

				if (updatedPhone.isValid) {
					sharedWith.forwardTo = updatedPhone.e164;
				} else {
					sharedWith.forwardTo = null;
				}

				this.subscription.preferences.sharedWith.splice(ix,1,sharedWith);
			},

			formatPhone: function (phoneNumber) {
				try {
					let phone = this.PhoneUtil.parse(phoneNumber);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				} catch (err) {
					return phoneNumber;
				}
			},

			confirmCancel: function () {
				let number = this.subscription.numbers.map((n) => n.number).join(' & ');
				let binding = {
					headingText: 'Please confirm!',
					bodyText: `Are you sure you want to cancel service on  ${number}?  You will no longer be able to process calls or text messages and you will not be able to re-order this number in the future?<br><br>Please type the number exactly to confirm cancellation.`,
					confirmText: number,
					confirmHint: `Enter ${number}`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.cancelSubscription();
					}
				});
			},

			cancelSubscription: function () {
				this.$store.commit('startLoading');
				this.communicatorService
					.cancelSubscription(this.subscription.id)
					.then(() => {
						this.$store.state.communicatorSubscription = null;
						this.$store.commit('stopLoading');
						this.$store.commit('success', 'Your business line has been cancelled.');
						this.$emit('refresh');
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleTeamSelection: function(teamMember){
				this.subscription.preferences.sharedWith.push({userId:teamMember.userId,forwardTo:teamMember.phone})
			},

			removeTeamMember: function(index){
				this.subscription.preferences.sharedWith.splice(index,1);
			}
		},

		computed: {
			otherTeamMembers: function(){
				let users = this.$store.state.usersForAccount
						.filter(u => u.userId !== this.$store.getters.getLoggedInUserId && u.userId !== this.subscription.userId)
						.filter(u => u.userType === 'FULL_USER' || u.userType === 'OWNER' || u.userType === 'IMPLEMENTER' || u.userType === 'RESTRICTED_USER')
						.filter(u => this.subscription.preferences.sharedWith.findIndex(s => s.userId === u.userId) === -1);

				users.forEach(u => u.fullName = u.firstName + ' ' + u.lastName);

				return users;
			},
			zeroToSixty: function () {
				let result = [];
				for (let i = 0; i <= 60; i++) {
					result.push(i);
				}
				return result;
			},

			defaultCountry: function(){
				if(this.subscription.countryCode[0] === '+1'){
					return 'US'
				}else if(this.subscription.countryCode[0] === '+44'){
					return 'GB'
				}else{
					return 'US'
				}
			},

			preferredCountries: function() {
				let countries = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ'];
				return countries;
			},

			onlyCountries: function(){
				if(this.subscription.countryCode[0] === '+1'){
					return ['US','CA']
				}else if(this.subscription.countryCode[0] === '+44'){
					return ['GB']
				}else{
					return ['US']
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
