<template>
	<div id="insight-client-filter">
		<v-menu
			max-height="90vh"
			max-width="250"
			v-model="showMenu"
			:close-on-content-click="false"
			:close-on-click="true"
			content-class="filter-menu-class"
			style="overflow-y: auto"
		>
			<template v-slot:activator="{ on }">
				<div
					:class="`scope-button row-format align-center ${!isDefaultFilter ? 'active' : ''}`"
					v-on="on"
				>
					<div v-html="filterLabel"></div>
					<v-icon small>$arrowDown</v-icon>
				</div>
			</template>

			<div class="column-format open-menu">
				<div class="filter-option-box">

					<div>
						<v-checkbox
							v-model="clientsSelected"
							hide-details
							:indeterminate="clientsIndeterminate"
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
									class="client-label row-format pointer"
									style="align-content: center;"
								>
									<div class="tree-label">
										{{ $t('calendar.filter.select-deselect') }}
									</div>
								</div>
							</template>
						</v-checkbox>

						<v-checkbox
							v-model="filter.clients"
							v-for="client in clientList"
							:value="client.id"
							:key="client.id"
							hide-details
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
									class="client-label row-format pointer"
									style="align-content: center;"
								>
									<client-avatar :small="true" left :client="client" :disable-click="true"></client-avatar>
									<div class="tree-label">{{ client.name }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'ClientFilter',

		props: ['filter'],

		components: { ClientAvatar },

		data: function() {
			return {
				showMenu: false,
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			saveFilter: function(){
				this.showMenu = false;
			},

			addAllClients() {
				this.removeAllClients();
				this.clientList.forEach(c => this.filter.clients.push(c.id));
			},

			removeAllClients() {
				this.filter.clients.splice(0,this.filter.clients.length);
			},

			sort(a, b) {
				if (a.name > b.name) {
					return 1;
				} else if (a.name < b.name) {
					return -1;
				} else {
					return 0;
				}
			},
		},

		watch: {
			menu: function(newVal) {
				if (!newVal) {
					this.$emit('updated');
				}
			},
		},

		computed: {
			clientList: function(){
				return this.$store.getters.clients;
			},

			clientsSelected: {
				set: function(val) {
					if (val) return this.addAllClients();
					return this.removeAllClients();
				},
				get: function() {
					if (this.filter.clients.length > 0) return true;
					return false;
				},
			},

			clientsIndeterminate: function() {
				if (this.filter && this.filter.clients && this.filter.clients.length == this.clientList.length) return false;
				if (this.filter && this.filter.clients && this.filter.clients.length == 0) return false;
				return true;
			},

			filterLabel: function() {
				if(!this.isDefaultFilter){
					let text = [];

					if(this.filter.clients && this.filter.clients.length && this.filter.clients.length <= this.clientList.length){
						text.push(this.filter.clients.length + ' clients');
					}

					return text.join(" &bull; ")
				}else{
					return 'All clients'
				}
			},

			isDefaultFilter: function(){
				if (!this.filter.clients || !this.filter.clients.length){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style lang="scss">
	#insight-client-filter {
		position: relative;
		.clear-filter-in-activator {
			position: absolute;
			right: 6px;
		}


		.active {
			background-color: var(--v-gray_20-base) !important;
			color: var(--v-secondary-base) !important;
			border: 1px solid var(--v-gray_50-base) !important;
		}

		:deep(.scope-button) {
			background-color: var(--v-white-base);
			color: var(--v-gray_80-base);
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			padding: 4px 8px;
			width:fit-content;
			font-size: 12px;
			font-weight: 500;
			cursor: pointer;
			white-space: nowrap;

			&:hover {
				background-color: var(--v-gray_20-base);
			}
		}

		:deep(.open-menu) {
			background-color: var(--v-white-base);
			max-width: 250px;
			.client-label .tree-label {
				text-overflow: ellipsis !important;
				overflow: hidden;
				white-space: nowrap;
				max-width: 160px;
			}
		}

		.status-color {
			width: 20px;
			height: 20px;
			border-radius: 4px;
			background-color: var(--status-color);
		}
	}

</style>
