<template>
	<div id="expense-list" class="column-format" v-if="isReady">

		<div class="py-3 px-5 row-format align-center" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<expense-filter
					v-if="!!expenses && !!selectedFilters"
					slot="filter"
					:filter="selectedFilters"
					:is-default-filter="isDefaultFilter"
					:client-list="clients"
					@clear-filter="clearFilter()"
					@updated="setSelectedFilters($event)"
			/>
			<v-menu
					:nudge-bottom="0"
					:elevation="0"
					content-class="add-new-menu"
					bottom
					left
					rounded
					offset-overflow
					offset-y
					transition="slide-y-transition"
			>
				<template v-slot:activator="{ on }">
					<plus-button class="ml-auto" v-on="on"></plus-button>
				</template>
				<div class="add-new-dropdown">
					<div
							v-for="(item, index) in addNew"
							:key="index"
							@click="createExpense(item.value)"
							class="add-new-item"
					>
						{{ item.label }}
					</div>
				</div>
			</v-menu>
		</div>

		<div class="show-scrollbar column-format" style="flex: 1">
			<recurring-expense-list class="px-3" ref="RecurringEvents" @new-vendor="newVendor"></recurring-expense-list>

			<div v-if="filteredExpenses.length" style="width:100%">
				<expense-table :expenses="filteredExpenses" @click="editExpense($event)" @delete="confirmDeleteExpense($event)" />
			</div>

			<div v-else-if="filteredExpenses.length === 0 && totalExpenseCount > 0" class="mt-6">
				<empty-filtered-results />
			</div>

			<div v-else-if="isReady" class="row-format centered px-8" style="flex: 1">
				<empty-view
						header="Tame your books!"
						body="Get your finances under control with one place to collect and manage your expenses, vendors, and business accounts. “Tax filing was never so smooth” — Future you."
						cta="Add your first expense"
						video-header="See how it works"
						video-body="Learn how to create expenses and vendors, as well as how to read a profit & loss report. "
						video-cta="Watch the tutorial"
						video-id="1bii9QY7q4Q"
						@cta-clicked="createExpense()"
				></empty-view>
			</div>
		</div>


		<basic-modal
			v-if="expenseDetailDialog"
			:dialog="expenseDetailDialog"
			:persistent="true"
			@close="expenseDetailDialog = false"
			ref="editModal"
		>
			<expense-detail
				:expense="expenseToEdit"
				:in-clients="clients"
				:in-projects="projects"
				:in-vendors="vendors"
				@created="handleCreate"
				@updated="handleUpdate"
				@deleted="handleDelete"
				@new-vendor="newVendor"
				@close="expenseDetailDialog = false"
			/>
		</basic-modal>

		<modal :dialog="deleteExpenseDialog" :persistent="true" :max-width="290" @close="deleteExpenseDialog = false">
			<v-container class="ma-3">
				<v-row>
					<v-col cols="12">
						{{ $t('expenses.delete-confirm') }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-btn class="primary-action mr-2" @click="cancelDeleteExpense">
							{{ $t('global.no') }}
						</v-btn>
						<v-btn class="ml-2" elevation="0" @click="deleteExpense">
							{{ $t('global.yes') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</modal>
	</div>
</template>

<script>
	import ExpenseTable from '@/modules/accounting/expenses/ExpenseTable';
	import ExpenseDetail from '@/modules/accounting/expenses/ExpenseDetail';
	import Modal from '@bit/hecticapp.common.modal';
	import BasicModal from '@/components/BasicModal';
	import ExpenseMixin from '@/modules/accounting/expenses/ExpenseMixin';
	import ExpenseFilter from '@/modules/accounting/expenses/ExpenseFilter';
	import { isEqual, cloneDeep } from 'lodash';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyFilteredResults from '@/components/EmptyFilteredResults';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';
	import EmptyView from "@/components/EmptyView";
	import RecurringExpenseList from "@/modules/accounting/expenses/RecurringExpenseList";
	import PlusButton from "@/components/PlusButton";

	export default {
		name: 'Expenses',

		props: ['filter'],
		components: {
			RecurringExpenseList,
			EmptyView,
			EmptyFilteredResults,
			// ButtonHeader,
			ExpenseDetail,
			ExpenseTable,
			Modal,
			BasicModal,
			ExpenseFilter,
			PlusButton
		},
		mixins: [ExpenseMixin, AccountingMixin],

		data: function() {
			return {
				selectedFilters: null,
				deleteExpenseDialog: false,
				expenseToDelete: null,
				selectedFiltersDefault: {
					search: '',
					clients: [],
					reimbursable: -1,
					paid: -1,
					dateSpecifier: { text: 'Past 30 days', value: 'past-30-days' },
					earliest: null,
					latest: null,
				},
				filterOptions: [
					{ text: this.$t('global.states.active'), value: 'active' },
					{ text: this.$t('client.archive'), value: 'archive' },
					{ text: this.$t('global.states.all'), value: 'all' },
				],
			};
		},

		mounted() {
			localStorage.setItem('ACCOUNTING_PAGE','expenses');
			this.isReady = true;
      this.$track.record('page-view', { module: 'accounting-expenses' });
			this.$store.commit('setBreadcrumbs', [{ to: this.$route.path, text: this.$t('expenses.breadcrumb-title') }]);
			this.$store.state.eventBus.$on('account-changed', this.initialize);
			this.initialize();

		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
		},

		watch: {
			selectedFilters: {
				deep: true,
				handler(){
					this.saveCurrentFilter();
				}
			}
		},

		methods: {
			initialize() {
				this.getSelectedFilters();
				this.getClients();
				this.getProjects();
				this.getVendors();
				this.getDateFilteredExpenses();
			},

			createExpense: function(type) {
				if(type === 'RECURRING'){
					this.$refs.RecurringEvents.addNew();
				}else{
					this.expenseToEdit = null;
					this.expenseDetailDialog = true;
				}
			},

			clearFilter() {
				this.initSelectedFilters();
				this.getDateFilteredExpenses();
			},

			newVendor: function(vendor){
				this.vendors.push(vendor);
			},

			getDateFilteredExpenses() {
				let earliest;
				let latest;

				if (this.selectedFilters.dateSpecifier.value === 'between') {
					if (!this.selectedFilters.earliest || !this.selectedFilters.latest) return;
					earliest = this.selectedFilters.earliest.substr(0, 10);
					latest = this.$DateTime
						.fromISO(this.selectedFilters.latest)
						.plus({ day: 1 }) // Make last day inclusive
						.toISO()
						.substr(0, 10);
				} else {
					let dates = FilterHelpers.getEarliestAndLatest(this.selectedFilters.dateSpecifier.value);
					earliest = dates.earliest.substr(0, 10);
					latest = dates.latest.substr(0, 10);
				}

				this.getExpenses(earliest, latest);
			},

			setSelectedFilters() {
				this.getDateFilteredExpenses();
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.selectedFilters));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			getSelectedFilters() {
				try {
					this.selectedFilters = JSON.parse(localStorage.getItem(this.filterStateKey));
					if (!this.selectedFilters) {
						this.initSelectedFilters();
					}
				} catch (err) {
					console.log(err);
					console.log('Error reading filter preferences from local storage.');
				}
			},

			initSelectedFilters() {
				this.selectedFiltersDefault.earliest = null;
				this.selectedFiltersDefault.latest = null;
				this.selectedFilters = cloneDeep(this.selectedFiltersDefault);
				this.saveCurrentFilter();
			},

			confirmDeleteExpense: function(expense) {
				this.expenseToDelete = expense;
				this.deleteExpenseDialog = true;
			},

			cancelDeleteExpense: function() {
				this.expenseToDelete = null;
				this.deleteExpenseDialog = false;
			},

			deleteExpense: function() {
				this.expenseService
					.deleteExpense(this.expenseToDelete.id)
					.then(() => {
						this.handleDelete(this.expenseToDelete.id);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.deleteExpenseDialog = false;
						this.expenseToDelete = null;
					});
			},
		},

		computed: {
			addNew: function () {
				let result = [];
				result.push({ label: 'Expense', value: 'EXPENSE' });
				result.push({ label: 'Recurring expense', value: 'RECURRING' });
				return result;
			},

			filteredExpenses() {
				return this.expenses
					.filter((expense) => {
						if (this.selectedFilters.paid > -1) {
							return Boolean(expense.paid) === Boolean(this.selectedFilters.paid);
						}
						return true;
					})
					.filter((expense) => {
						if (this.selectedFilters.reimbursable > -1) {
							if (this.selectedFilters.reimbursable === 0 || this.selectedFilters.reimbursable === 1) {
								return Boolean(expense.reimbursable) === Boolean(this.selectedFilters.reimbursable);
							} else if (this.selectedFilters.reimbursable === 2) {
								return expense.reimbursable === true && expense.invoiceId === null;
							} else if (this.selectedFilters.reimbursable === 3) {
								return expense.reimbursable === true && expense.invoiceId !== null;
							}
						}
						return true;
					})
					.filter((expense) => {
						if (!this.selectedFilters.clients.length) return true;
						for (let i = 0; i < this.selectedFilters.clients.length; i++) {
							if (this.selectedFilters.clients[i] == expense.clientId) return true;
						}
						return false;
					})
					.filter((expense) => {
						let search = this.selectedFilters.search;

						if (!search) {
							return true;
						}

						search = search.toLowerCase();

						if (search == expense.invoiceNumber) {
							return true;
						} else if (expense.client && expense.client.name.toLowerCase().includes(search)) {
							return true;
						} else if (expense.category && expense.category.toLowerCase().includes(search)) {
							return true;
						} else if (expense.vendor && expense.vendor.name.toLowerCase().includes(search)) {
							return true;
						} else if (expense.description && expense.description.toLowerCase().includes(search)) {
							return true;
						}else if(expense.amount && expense.amount.toString().startsWith(search)){
							return true;
						}
						return false;
					});
			},

			// categorized: function() {
			// 	this.refreshKey;
			// 	return this.filteredExpenses.filter((e) => e.category !== null);
			// },
			//
			// uncategorized: function() {
			// 	this.refreshKey;
			// 	return this.filteredExpenses.filter((e) => e.category === null);
			// },

			isDefaultFilter() {
				let selectedFilters = this.selectedFilters;
				let defaultFilters = this.selectedFiltersDefault;
				return isEqual(defaultFilters, selectedFilters);
			},

			filterStateKey: function() {
				return 'EXPENSE_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},
		},
	};
</script>

<style lang="scss">
</style>
