<template>
	<div class="py-3 text-left">
		<v-checkbox
			hide-details
			dense
			v-model="paymentPlan.autoSend"
			label="Send automatically (no approval required)"
		></v-checkbox>

		<v-autocomplete class="mt-2" v-if="paymentPlan.autoSend" v-model="paymentPlan.emailTemplateId" :items="emailTemplates" hide-details dense outlined persistent-placeholder label="Email template (optional)" item-text="name" item-value="id" ></v-autocomplete>

		<div class="font-12 font-gray_70 mt-1" v-if="paymentPlan.autoSend">
			Automatically send to all "Invoice" and "Default" contacts on the client record.
		</div>
		<div class="font-12 font-gray_70 mt-1" v-else>
			You will receive a notification after the invoice is generated letting you know you to approve and send.
		</div>

		<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />

		<v-checkbox
			v-if="paymentsEnabled"
			hide-details
			dense
			v-model="paymentPlan.autoCharge"
			label="Automatically charge on due date"
		></v-checkbox>

		<v-select
			class="mt-3"
			v-if="paymentPlan.autoCharge && paymentsEnabled"
			hide-details
			persistent-placeholder
			dense
			outlined
			label="Auto charge payment method (optional)"
			:items="paymentMethods"
			item-value="id"
			:return-object="true"
			v-model="paymentPlan.paymentMethod"
			clearable
			v-tippy="{
				content:
					'This setting will allow you to select a specific payment method.  If this field is left blank,  the default method will be used to process payment.',
			}"
		>
			<template v-slot:item="{ item }">
				<span style="text-transform: capitalize">{{ item.label.toLowerCase() }} - {{ item.last4 }}</span>
			</template>
			<template v-slot:selection="{ item }">
				<span style="text-transform: capitalize">{{ item.label.toLowerCase() }} - {{ item.last4 }}</span>
			</template>
		</v-select>
		<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />
		<div class="font-primary pointer font-14 text-center" @click="addPayment">+ Add payment event</div>
		<div
			v-for="scheduledPayment in paymentPlan.scheduledPayments"
			:key="scheduledPayment.id"
			class="column-format gap-2 py-3"
			style="border-bottom: 1px solid var(--v-gray_30-base)"
		>
			<v-text-field
				v-model="scheduledPayment.label"
				hide-details
				dense
				outlined
				persistent-placeholder
				label="Label"
				:disabled="scheduledPayment.mileStoneAchieved"
			></v-text-field>
			<div class="row-format gap-2">
				<v-select
					:items="['Milestone', 'Date']"
					style="flex: 1 1 0"
					v-model="scheduledPayment.triggerType"
					hide-details
					dense
					persistent-placeholder
					outlined
					label="Payment type"
					:disabled="scheduledPayment.mileStoneAchieved"
				></v-select>
				<date-selector
					v-if="scheduledPayment.triggerType === 'Date'"
					:standard="true"
					style="flex: 1 1 0"
					label="Date"
					:date="scheduledPayment.triggerDate"
					@change="scheduledPayment.triggerDate = $event"
					:disabled="scheduledPayment.mileStoneAchieved"
				></date-selector>
			</div>
			<div v-if="!scheduledPayment.finalPayment" class="column-format gap-2">
				<div class="row-format gap-2">
					<v-select
						:items="types"
						item-text="label"
						item-value="value"
						style="flex: 1 1 0"
						v-model="scheduledPayment.amountType"
						hide-details
						dense
						persistent-placeholder
						outlined
						label="Amount type"
						:disabled="scheduledPayment.mileStoneAchieved"
					></v-select>
					<v-text-field
						:prefix="scheduledPayment.amountType === 'Fixed' ? currencySymbol : ''"
						:suffix="scheduledPayment.amountType === 'Percentage' ? '%' : ''"
						numeric
						style="flex: 1 1 0"
						v-model="scheduledPayment.amount"
						hide-details
						dense
						persistent-placeholder
						outlined
						label="Amount"
						oninput="this.value=this.value.replace(/[^0-9]/g,'');"
						:disabled="scheduledPayment.mileStoneAchieved"
					></v-text-field>
				</div>
				<div class="text-left font-14 ">
					<div class="row-format">
						<div class="brand-medium">Amount:</div>
						<div class="ml-auto">
							{{ $formatters.dollars(scheduledPayment.calculatedAmount, true, true, invoice.currency) }}
							({{scheduledPayment.calculatedPercentage.toFixed(2)}}%)
						</div>
						<v-icon small class="pointer" @click="deletePayment(scheduledPayment)" v-if="!scheduledPayment.mileStoneAchieved">delete</v-icon>
					</div>
				</div>
			</div>
			<div v-else class="text-left font-14 row-format">
				<div class="brand-medium">Final payment:</div>
				<div class="ml-auto">
					{{ $formatters.dollars(scheduledPayment.calculatedAmount, true, true, invoice.currency) }}
					({{scheduledPayment.calculatedPercentage.toFixed(2)}}%)
				</div>
			</div>
		</div>
		<div v-if="errorState" style="color: var(--v-error-base)" class="mt-2 font-14 brand-medium">
			<v-icon color="error" size="20">warning</v-icon>
			Please correct errors in your payment plan.  Final payment amount must be greater than 0%.
			<v-icon color="error" size="20">warning</v-icon>
		</div>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	import { v4 as uuid4 } from 'uuid';
	import ClientService from "@/modules/clients/ClientService";
	import EmailTemplateService from "@/modules/templates/EmailTemplateService";
	export default {
		name: 'PaymentPlanSettings',

		props: ['invoice', 'paymentPlan'],

		components: { DateSelector },

		data: function() {
			return {
				errorState: false,
				clientService: new ClientService(),
				types: [
					{ label: 'Percent', value: 'Percentage' },
					{ label: 'Fixed', value: 'Fixed' },
				],
				paymentMethods: [],
				emailTemplates: [],
			};
		},

		mounted() {
			this.getPaymentMethods();
			this.getEmailTemplates();
		},

		beforeDestroy() {},

		methods: {
			addPayment: function() {
				this.paymentPlan.scheduledPayments.splice(0, 0, {
					id: uuid4(),
					label: 'Payment',
					triggerType: 'Milestone',
					triggerDate: null,
					amountType: 'Percentage',
					amount: 0,
					mileStoneAchieved: false,
					finalPayment: false,
					calculatedAmount: 0,
					calculatedPercentage: 0
				});
			},

			getPaymentMethods: function() {
				this.clientService.getClientPaymentMethods(this.paymentPlan.clientId).then((res) => {
					this.paymentMethods.splice(0, this.paymentMethods.length);
					this.paymentMethods.push(...res.data);
					this.paymentMethods.sort((a, b) => a.label.localeCompare(b.label));
				});
			},

			getEmailTemplates: function(){
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0);
					this.emailTemplates.push(... res.data);
					this.emailTemplates.sort((a,b) => a.name.localeCompare(b.name));
				})
			},

			deletePayment: function(payment) {
				let ix = this.paymentPlan.scheduledPayments.findIndex((p) => p.id === payment.id);
				this.paymentPlan.scheduledPayments.splice(ix, 1);
			},

			getPaymentAmount: function(percentage) {
				return this.invoice.total * (percentage / 100);
			},

			getPaymentPercentage: function(amount) {
				if (this.invoice.total) {
					return (amount / this.invoice.total) * 100;
				} else {
					return 0;
				}
			},

			finalPaymentAmount: function() {
				let total = 0;

				for (let i = 0; i < this.paymentPlan.scheduledPayments.length - 1; i++) {
					let payment = this.paymentPlan.scheduledPayments[i];
					if (payment.amountType === 'Fixed') {
						total = total + Number(payment.amount);
					} else {
						total = total + this.invoice.total * (Number(payment.amount) / 100);
					}
				}

				return this.invoice.total - total;
			},

			finalPaymentPercentage: function() {
				if (this.invoice.total) {
					return (this.finalPaymentAmount() / this.invoice.total) * 100;
				} else {
					return 0;
				}
			},

			calculateTotals: function(){
				for(let i=0; i < this.paymentPlan.scheduledPayments.length; i++){
					let payment = this.paymentPlan.scheduledPayments[i];
					if(i === (this.paymentPlan.scheduledPayments.length -1)){
						payment.finalPayment = true;
						payment.calculatedPercentage = this.finalPaymentPercentage();
						payment.calculatedAmount = this.finalPaymentAmount();
						if(payment.calculatedAmount <= 0 && this.invoice.total > 0){
							this.errorState = true;
							this.$emit('error-state');
						}else{
							this.errorState = false;
							this.$emit('success-state');
						}
					}else{
						payment.finalPayment = false;
						if(payment.amountType === 'Percentage'){
							payment.calculatedPercentage = Number(payment.amount);
							payment.calculatedAmount = this.getPaymentAmount(payment.calculatedPercentage);
						}else{
							payment.calculatedAmount = Number(payment.amount);
							payment.calculatedPercentage = this.getPaymentPercentage(payment.calculatedAmount);
						}
					}
				}
			}
		},

		watch: {
			paymentPlan:{
				deep:true,
				handler: function(){
					this.calculateTotals();
				}
			},

			invoice: {
				deep:true,
				handler: function() {
					this.calculateTotals();
				}
			}
		},

		computed: {
			paymentsEnabled: function () {
				return (this.$store.state.podAccount.stripeIntegration &&
						this.$store.state.podAccount.stripeIntegration.chargesEnabled);
			},

			currencySymbol: function () {
				return this.$formatters.currencySymbol(this.invoice.currency);
			},
		}
	};
</script>

<style scoped lang="scss"></style>
