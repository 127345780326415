<template>
	<div class="row-format" style="flex-wrap: wrap">
		<quick-link
			name="Client"
			icon="clients"
			color="#5C00E2"
			background-color="#deccf9"
			@click.native="createClient()"
		>
		</quick-link>
		<quick-link
				name="Project"
				icon="projects"
				color="#B995F4"
				background-color="#f1eafd"
				@click.native="createProject()"
		>
		</quick-link>
		<quick-link
			name="Agreement"
			icon="proposals"
			color="#C90360"
			background-color="#f4cddf"
			@click.native="createProposal()"
		>
		</quick-link>
		<quick-link
			name="Task"
			icon="deliverable-box"
			color="#C75029"
			background-color="#f4dcd4"
			@click.native="createDeliverable()"
		>
		</quick-link>
		<quick-link
			name="Time entry"
			icon="stopwatch"
			color="#67D5AD"
			background-color="#e1f7ef"
			@click.native="createTimeEntry()"
		>
		</quick-link>
		<quick-link
			name="Expense"
			icon="accounting"
			color="#E7A73A"
			background-color="#faedd8"
			@click.native="createExpense()"
		>
		</quick-link>
		<quick-link
			name="Invoice"
			icon="invoices"
			color="#2C97DF"
			background-color="#d5eaf9"
			@click.native="createInvoice()"
		>
		</quick-link>
	</div>
</template>

<script>
	import QuickLink from "@/modules/home/focus/QuickLink";
	import QuickLinksMixin from "@/modules/home/focus/QuickLinksMixin";

	export default {
		name: "FocusQuickLinks",

		props: [],

		mixins: [QuickLinksMixin],

		components: {QuickLink},

		data: function () {
			return {}
		},

		mounted() {
		},

		beforeDestroy() {
		},

		methods: {

		},

		computed: {},

	}
</script>

<style scoped lang="scss">
	.quick-link {
		width: 56px;
		height: 56px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		margin-right: 8px;
	}
</style>
