<template>
	<div class="fill-height column-format" id="scheduler-list">
		<div class="show-scrollbar">
			<div
					v-if="!sampleMode && !paidAccount"
					class="column-format centered mb-3 px-3 mt-3 mx-1"
			>
				<free-tier-card
						style="height:90px; width:100%"
						message="Meeting scheduler is not available on the Essentials plan. You can explore this feature, but access to scheduling meetings will be restricted."
						@upgrade-clicked="$router.push('/subscription')"
				/>
			</div>

			<div class="column-format mt-2" style="flex: 1" v-if="isReady">
				<v-data-table
					:key="tableKey"
					:headers="headers"
					:items="meetings"
					@click:row="openMeeting($event.id)"
					:fixed-header="true"
					style="cursor: pointer"
					:items-per-page="-1"
					:hide-default-footer="true"
					:height="`calc(100vh - 80px)`"
					group-by="folder"
				>
					<template v-slot:group.header="{ group, headers, toggle }">
						<td colspan="6" class="text-left" @click="toggle">
							<div class="row-format align-center gap-2">
								<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ?  group : ' -- ' }}
							</div>
						</td>
					</template>

					<template v-slot:item.name="{ item }">
						<span>{{ item.name }}</span>
					</template>

					<template v-slot:item.active="{ item }">
						<span>{{ $formatters.yesNoFromBoolean(item.active) }}</span>
					</template>

					<template v-slot:item.duration="{ item }">
						<span v-if="item.meetingLength">{{ item.meetingLength.duration }} {{ item.meetingLength.timeUnit.toLowerCase() }}</span>
						<span v-else>--</span>
					</template>

					<template v-slot:item.location="{ item }">
						<div v-if="item.location && item.location.type === 'Zoom' && !getZoomUser(item)" class="row-format">
							<v-icon color="warning">$alert</v-icon>
							<div class="ml-2" style="color: var(--v-warning-base)">
								The Zoom account used in this scheduler is current disconnected. Please reconnect or choose a
								different meeting location.
							</div>
						</div>
						<div v-else-if="item.location">
							{{ item.location.type }}
						</div>
						<div v-else>--</div>
					</template>

					<template v-slot:item.ownerUserId="{ item }">
						<assigned-user :assigned-to="item.ownerUserId" :show-name="false"></assigned-user>
					</template>

					<template v-slot:item.action="{ item }">
						<meeting-action-menu @view="openMeeting(item.id)" @delete="confirmDelete(item)" @duplicate="duplicateScheduler(item)"></meeting-action-menu>
					</template>

					<template v-slot:header.action="{}">
						<div class="row-format">
							<plus-button class="ml-auto" @click="handleAddNew()"></plus-button>
						</div>
					</template>
				</v-data-table>
			</div>
		</div>
	</div>
</template>

<script>
	import MeetingDetail from '@/modules/meetings/SchedulerDetail';
	import MeetingService from '@/modules/meetings/MeetingService';
	import MeetingActionMenu from '@/modules/meetings/MeetingActionMenu';
	import ConfirmModal from '@/components/ConfirmModal';
	import FreeTierCard from '@/components/FreeTierCard';
	import ClearSampleModal from '@/modules/clients/ClearSampleModal';
	import ZoomAuthService from '@/modules/integrations/zoom/ZoomAuthService';
	import AssignedUser from "@/components/AssignedUser";
	import PlusButton from "@/components/PlusButton";

	export default {
		name: 'SchedulerList',

		props: ['v'],

		components: {AssignedUser, FreeTierCard, MeetingActionMenu, PlusButton },

		data: function() {
			return {
				meetingService: new MeetingService(),
				zoomAuthService: new ZoomAuthService(),
				tableKey: 0,
				meetings: [],
				zoomUsers: [],
				isReady: true,

				pages: [
					{ label: 'Meeting schedulers', value: 'schedulers' },
					{ label: 'Forms', value: 'forms' },
				],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'scheduler-list' });
			this.initialize()
			this.$store.state.eventBus.$on('account-changed', this.initialize);
			this.$store.state.eventBus.$on('schedulers_onboarding', this.handleOnboardingRequest);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
			this.$store.state.eventBus.$off('schedulers_onboarding', this.handleOnboardingRequest);
		},

		methods: {
			handleOnboardingRequest: function(){
				this.handleAddNew();
			},

			initialize: function(){
				this.getMeetingList();
				this.getConnectedZoomUsers();
			},

			setView: function (view) {
				this.$router.push('/pages/' + view);
			},

			getMeetingList: function(){
				this.meetingService.getMeetingList().then((res) => {
					this.meetings.splice(0,this.meetings.length);
					this.meetings.push(... res.data);
				})
			},

			getZoomUser: function(meeting){
				return this.zoomUsers.find(z => z.userId === meeting.location.zoomUserId);
			},

			openMeeting: function (id) {
				let binding = {
					id : id,
					usedNames: this.getOtherMeetingNames(id),
					folders: this.folders
				}
				this.$store.state.globalModalController.openModal(MeetingDetail, binding, false, true).then((res) => {
					if(res === 'UPGRADE'){
						setTimeout(() => this.$router.push('/subscription'),500);
					}else{
						this.getMeetingList();
					}
				})
			},

			getConnectedZoomUsers: function () {
				new ZoomAuthService().getUserList().then((res) => {
					this.zoomUsers.splice(0, this.zoomUsers.length);
					this.zoomUsers.push(...res.data);
				});
			},

			handleAddNew: function(){
				if(this.sampleMode) {
					let binding = {
						header: 'Are you finished exploring with sample data?',
						body: 'You cannot add or remove schedulers while in sample mode. If you are ready to add your own scheduler, you can clear out the sample data to get started.'
					}
					this.$store.state.globalModalController.openModal(ClearSampleModal,binding)
				}else{
					let name = '30 minute meeting';
					let finalName = name;
					let ix = 1;
					let names = []
					this.meetings.forEach(m => names.push(m.name));

					while(names.includes(finalName)){
						finalName = name + ' ' + ix++;
					}

					let uniqueMeetingId = finalName.replaceAll(' ', '-').toLowerCase().replaceAll('[^a-zA-Z0-9-]', '');

					this.meetingService.initializeMeeting(finalName,uniqueMeetingId).then((res) => {
						this.$onBoarding.track('schedulers_create_scheduler');
						this.openMeeting(res.data.id);
					})
				}
			},

			duplicateScheduler: function(schedulerToCopy){
				if(this.sampleMode) {
					let binding = {
						header: 'Are you finished exploring with sample data?',
						body: 'You cannot add or remove schedulers while in sample mode. If you are ready to add your own scheduler, you can clear out the sample data to get started.'
					}
					this.$store.state.globalModalController.openModal(ClearSampleModal,binding)
				}else{
					let name = schedulerToCopy.name;
					let finalName = name;
					let ix = 1;
					let names = []
					this.meetings.forEach(m => names.push(m.name));

					while(names.includes(finalName)){
						finalName = name + ' ' + ix++;
					}

					let scheduler = JSON.parse(JSON.stringify(schedulerToCopy));
					scheduler.id = null;
					scheduler.name = finalName;
					scheduler.uniqueMeetingId = finalName.replaceAll(' ', '-').toLowerCase().replaceAll('[^a-zA-Z0-9-]', '');
					this.meetingService.createMeeting(scheduler).then((res) => {
						this.openMeeting(res.data.id);
					})
				}
			},

			getOtherMeetingNames: function(id){
				let result = []
				this.meetings.filter(m => m.id !== id).forEach(m => result.push(m.name.replaceAll(' ', '-').toLowerCase().replaceAll('[^a-zA-Z0-9-]', '')));
				return result;
			},

			confirmDelete: function(meeting){
				let binding = {
					headingText: 'Delete meeting scheduler?',
					bodyText: `Are you sure you want to delete the "${meeting.name}" meeting scheduler?  This cannot be undone.`
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res)=> {
					if(res){
						this.$store.commit('startLoading');
						this.meetingService.deleteMeeting(meeting.id).then(() => {
							this.getMeetingList();
						}).finally(() => this.$store.commit('stopLoading'));
					}
				});
			}
		},

		computed: {
			folders: function(){
				return this.meetings.filter(e => e.folder).map(e => e.folder).filter((value, index, self) => self.indexOf(value) === index);
			},

			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			headers: function () {
				return [
					{ text: this.$t('meeting.name'), value: 'name' },
					{ text: this.$t('meeting.active'), value: 'active' },
					{ text: this.$t('meeting.duration'), value: 'duration' },
					{ text: this.$t('meeting.location'), value: 'location' },
					{ text: this.$t('meeting.owner'), value: 'ownerUserId' },
					{ text: '', value: 'action', sortable: false, align: 'right' },
				];
			},
		},
	};
</script>

<style lang="scss">

</style>
