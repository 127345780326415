<template>
	<div class="column-format pointer py-2 font-14 deliverable" @click="$emit('deliverable-clicked')">
		<div class="row-format">
			<v-icon v-if="!hideIcon" class="material-symbols-rounded mr-3">splitscreen</v-icon>
			<client-avatar  class="mr-3" :client="deliverable.client" v-if="deliverable.client && !hideIcon"></client-avatar>
			<div v-else style="width:2px; height: 32px"></div>
			<div class="row-format align-center" style="flex-wrap: wrap; gap: 4px">
				<div class="projectName mr-3" v-if="deliverable.project" @click.stop="routeToProject(deliverable)">{{ deliverable.project.name }}</div>
				<div class="deliverableName mr-3 brand-medium">{{ deliverable.name }}</div>
				<div class="dueDate mr-3 row-format align-center" v-if="deliverable.dueDate">
					<v-icon size="15" color="gray_70">$clock</v-icon>
					<div class="font-gray_80">
						{{ DateTime.fromISO(deliverable.dueDate).toLocaleString({ month: 'short', day: 'numeric' }) }}
					</div>
				</div>
				<div v-if="deliverable.assignedToList.length" class="row-format align-center">
					<assigned-user
						v-for="(assignedTo, index) in deliverable.assignedToList"
						:key="assignedTo"
						:show-name="false"
						:small="true"
						:assigned-to="assignedTo"
						:style="
							`${
								index > 0 ? 'margin-left: -6px' : ''
							}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
						"
					></assigned-user>
				</div>
				<div
					class="taskCount mr-3 row-format align-center"
					v-if="deliverable.tasks.length"
					@click.stop="tasksExpanded = !tasksExpanded"
				>
					<v-icon size="8" class="mr-1">$check</v-icon>
					<div class="font-gray_80">{{ getTaskCountLabel(deliverable.tasks) }}</div>
					<v-icon size="14" style="margin-left: 2px">{{ tasksExpanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
				</div>
				<div
					class="font-12 font-grey ml-1 mr-3 pointer task-box"
					v-if="deliverable.tasks.length == 0"
					@click.stop="addFirstTask()"
				>
					<v-icon size="8">$check</v-icon>
					<v-icon size="14">$plus</v-icon>
				</div>
				<div class="deliverableStatus row-format align-center" :style="`--status-color:${deliverable.status.hexColor}`">
					<div class="statusBox"></div>
					<div>{{ deliverable.status.label }}</div>
				</div>
			</div>
		</div>

		<div class="ml-10 mt-3" v-show="tasksExpanded && deliverable.tasks.length > 0">
			<tasks ref="tasks" v-model="deliverable.tasks" @empty="tasksExpanded = false" @change="updateTasks()"></tasks>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import DateTime from '@/modules/utils/HDateTime';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import AssignedUser from '@/components/AssignedUser';

	export default {
		name: 'DeliverableMini',

		props: ['deliverable','hideIcon'],

		components: { ClientAvatar, Tasks, AssignedUser },

		data: function() {
			return {
				DateTime: DateTime,
				tasksExpanded: false,
				deliverableService: new ProjectDeliverableService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			
			routeToProject: function(deliverable){
				this.$router.push(`/project/${deliverable.projectId}`);
			},

			addFirstTask: function() {
				this.$refs.tasks.addNewTask(0);
				this.tasksExpanded = true;
			},

			updateTasks() {
				const patch = [{ op: 'replace', path: '/tasks', value: this.deliverable.tasks }];
				this.deliverableService
					.patchDeliverable(this.deliverable.id, patch)
						.catch((err) => {
						console.log(err);
					});
			},

			getTaskCountLabel: function(tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					tasks.forEach((t) => (t.complete ? completedCount++ : null));

					return completedCount + '/' + taskCount;
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.deliverable {
		border-bottom: 1px solid var(--v-gray_30-base);
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.projectName {
		background-color: var(--v-sidebar-base);
		color: var(--v-black-base);
		border-radius: 4px;
		padding: 4px 12px;
	}

	.deliverableStatus {
		font-size: 12px;
		padding: 2px 8px;
		box-sizing: border-box;
		border-radius: 4px;
		border: 1px solid var(--v-gray_50-base);

		.statusBox {
			margin-right: 8px;
			width: 10px;
			height: 10px;
			border-radius: 2px;
			background-color: var(--status-color);
		}
	}
</style>
