<template>
	<div class="panel-modal" style="width: 600px" v-if="isReady">
		<input id="opportunityLink" name="opportunityLink" :value="opportunityLink" type="hidden" />
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="save">$chevronRight</v-icon>
				<v-icon color="black" class="mr-1 material-symbols-rounded">filter_alt</v-icon>
				<div class="brand-medium font-18 mater">Opportunity details</div>
				<div class="ml-auto">
					<v-btn class="super-action" @click="save">
						{{ $t('global.save') }}
					</v-btn>
					<v-icon size="20" class="pointer ml-2" @click="getShareLink()">link</v-icon>
					<v-icon size="20" class="pointer ml-2" @click="confirmDelete()">$delete</v-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left">
			<div id="opportunity-detail">
				<div class="row-format">
					<v-text-field
						hide-details
						dense
						outlined
						placeholder="Opportunity name"
						v-model="opportunity.name"
					></v-text-field>
				</div>
				<div class="mt-6 mb-4 row-format align-center">
					<div
						:class="`page-link ${currentView === 'Overview' ? 'page-link-active' : ''}`"
						@click="setView('Overview')"
					>
						<div>Overview</div>
					</div>

					<div :class="`page-link ${currentView === 'Notes' ? 'page-link-active' : ''}`" @click="setView('Notes')">
						<div>Notes</div>
					</div>
					<div
						:class="`page-link ${currentView === 'Attachments' ? 'page-link-active' : ''}`"
						@click="setView('Attachments')"
					>
						<div>Attachments</div>
					</div>
					<div
						:class="`page-link ${currentView === 'Activity' ? 'page-link-active' : ''}`"
						@click="setView('Activity')"
					>
						<div>Activity</div>
					</div>
					<div :class="`page-link ${currentView === 'Lead' ? 'page-link-active' : ''}`" @click="setView('Lead')">
						<div>Lead info</div>
					</div>
					<div
						:class="`page-link ${currentView === 'Automations' ? 'page-link-active' : ''}`"
						@click="setView('Automations')"
					>
						<div>Automations</div>
					</div>
				</div>
				<div class="show-scrollbar" style="max-height: calc(100vh - 210px); overflow-y: auto">
					<div v-if="currentView === 'Overview'">
						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">group</v-icon>
								<div class="ml-2" v-if="assignedClient">{{ assignedClient.clientType }}</div>
								<div class="ml-2" v-else>Client / Prospect</div>
							</div>
							<div class="overview-right">
								<v-menu
									:close-on-click="true"
									:close-on-content-click="false"
									offset-y
									v-model="clientMenu"
									:disabled="$store.getters.isAccountCollaborator"
								>
									<template v-slot:activator="{ on }">
										<div v-on="on" class="pointer">
											<div class="row-format align-center" v-if="assignedClient">
												<client-avatar x-small :client="assignedClient" :disable-click="true"></client-avatar>
												<div class="ml-2">{{ assignedClient.name }}</div>
											</div>
											<div v-else>--</div>
										</div>
									</template>
									<div class="column-format pa-2" style="background-color: var(--v-white-base)">
										<v-text-field
											v-model="clientFilter"
											hide-details
											dense
											outlined
											placeholder="Filter..."
											autofocus
										></v-text-field>
										<div
											class="text-left column-format pt-1 mt-2 show-scrollbar"
											style="max-height: 300px; overflow-y: scroll"
										>
											<div
												@click="setClient(client)"
												v-for="client in filteredClientList"
												:key="client.id"
												class="row-format align-center px-3 py-2 pointer hover-gray"
												style="border-bottom: 1px solid var(--v-gray_30-base)"
											>
												<client-avatar
													class="mr-2"
													:client="client"
													x-small
													v-if="client.id && client.id.length > 10"
												></client-avatar>
												<div class="brand-medium">{{ client.name }}</div>
											</div>
										</div>
									</div>
								</v-menu>
							</div>
						</div>
						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20">bolt</v-icon>
								<div class="ml-2">Stage</div>
							</div>
							<div class="overview-right">
								<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
									<template v-slot:activator="{ on }">
										<div
											v-on="on"
											class="opportunityStage row-format align-center pointer"
											:style="`--status-color:${stage.hexColor}`"
										>
											<div class="stageBox"></div>
											<div class="text-no-wrap">{{ stage.label }}</div>
										</div>
									</template>
									<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
										<div
											v-for="stage in stages"
											:key="stage.id"
											class="py-1 pointer"
											@click="updateStatus(stage)"
										>
											<div
												class="opportunityStage row-format align-center highlight-hover"
												:style="`--status-color:${stage.hexColor}`"
											>
												<div class="stageBox"></div>
												<div>{{ stage.label }}</div>
											</div>
										</div>
									</div>
								</v-menu>
							</div>
						</div>
						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">thumbs_up_down</v-icon>
								<div class="ml-2">Confidence</div>
							</div>
							<div class="overview-right">
								<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
									<template v-slot:activator="{ on }">
										<v-icon
											v-on="on"
											class="material-symbols-rounded ml-n1"
											:color="sentiment.color"
											size="28"
											>{{ sentiment.icon }}</v-icon
										>
									</template>
									<div style="background-color: var(--v-white-base);" class="pa-2 column-format gap-2">
										<div
											v-for="item in confidenceList"
											:key="item.value"
											class="pointer"
											@click="setSentiment(item.value)"
										>
											<v-icon :color="item.color" class="material-symbols-rounded" size="28">{{
												item.icon
											}}</v-icon>
										</div>
									</div>
								</v-menu>
							</div>
						</div>
						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20">attach_money</v-icon>
								<div class="ml-2">Est. value</div>
							</div>
							<div class="overview-right row-format gap-2">
								<currency-input
									style="width: 60px"
									v-model="opportunity.value"
									:options="{ currency: $store.state.defaultCurrency }"
								></currency-input>
								<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
									<template v-slot:activator="{ on }">
										<div class="pointer" v-on="on">{{ timePeriod.label }}</div>
									</template>
									<div class="more-menu">
										<div
											class="more-menu-item"
											v-for="period in timePeriods"
											:key="period.value"
											@click="opportunity.timePeriod = period.value"
										>
											{{ period.label }}
										</div>
									</div>
								</v-menu>
								<div v-if="timePeriod.hasLength" class="row-format">
									<div>for</div>
									<div contenteditable="true" class="px-2 editable-div" @blur="setPeriods">
										{{ opportunity.periods }}
									</div>
									<div>{{ timePeriod.label2 }}</div>
								</div>
							</div>
						</div>
						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20">today</v-icon>
								<div class="ml-2">Est. close date</div>
							</div>
							<div class="overview-right">
								<date-selector
									:micro="true"
									:show-year="true"
									micro-font="font-14"
									:date="opportunity.estCloseDate"
									label="--"
									@change="opportunity.estCloseDate = $event"
								></date-selector>
							</div>
						</div>

						<div class="row-format align-center py-2" v-if="closedStages.includes(opportunity.statusId)">
							<div class="overview-left">
								<v-icon size="20">event</v-icon>
								<div class="ml-2">Date closed</div>
							</div>
							<div class="overview-right">
								<date-selector
									:micro="true"
									:show-year="true"
									micro-font="font-14"
									:date="opportunity.actualCloseDate"
									label="--"
									@change="opportunity.actualCloseDate = $event"
								></date-selector>
							</div>
						</div>
						<div class="row-format py-2" v-for="field in fields" :key="field.id">
							<div class="overview-left">
								<v-icon size="20">{{ field.icon }}</v-icon>
								<div class="ml-2">{{ field.name }}</div>
							</div>
							<div class="overview-right">
								<custom-field
									:field="field"
									:value="getCustomValue(field.id)"
									@change="handleCustomFieldChange($event)"
								></custom-field>
							</div>
						</div>
						<div class="mt-3">
							<div class="brand-bold mb-2">Description</div>
							<div class="font-14">
								<editor
									v-model="opportunity.description"
									ref="Description"
									:api-key="$store.getters.getTinyMceKey"
									:inline="true"
									:init="mceConfig"
									:spellcheck="true"
									class="no-wrap-editor px-1"
									style="width: 100%"
								>
								</editor>
							</div>
						</div>
						<div class="mt-6">
							<div class="brand-bold mb-2">To-Do</div>
							<to-do v-model="opportunity.toDos"></to-do>
						</div>
						<div class="pa-2 text-left mt-6">
							<v-switch
								class=""
								style="margin-top: 0px"
								v-model="opportunity.archive"
								inset
								flat
								dense
								hide-details
								:label="`${opportunity.archive ? 'Archived' : 'Archive'} opportunity`"
							></v-switch>
							<div class="font-12 font-gray_80 mt-2">
								Archived opportunities will still be accessible from the list screen, but will be filtered off by
								default.
							</div>
						</div>
					</div>
					<div v-if="currentView === 'Notes'">
						<comments :opportunity="opportunity"></comments>
					</div>
					<div v-if="currentView === 'Activity'">
						<workflow :opportunity="opportunity" @save="save(false)" @send-email="sendEmail($event)"></workflow>
					</div>
					<div v-if="currentView === 'Attachments'">
						<file-attachments :opportunity="opportunity"></file-attachments>
					</div>
					<div v-if="currentView === 'Lead'">
						<lead-info :opportunity="opportunity" class="pt-4" @send-email="sendEmail($event)"></lead-info>
					</div>
					<div v-if="currentView === 'Automations'">
						<automation-list resource-type="Opportunity" :resource-id="opportunity.id"></automation-list>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import OpportunityService from '@/modules/pipeline/OpportunityService';
	import PipelineSettingsService from '@/modules/pipeline/settings/PipelineSettingsService';
	import DateSelector from '@/components/DateSelector';
	import CurrencyInput from '@/components/CurrencyInput';
	import CustomField from '@/components/CustomField';
	import ClientAvatar from '@/components/ClientAvatar';
	import Comments from '@/modules/pipeline/opportunity/Comments';
	import FileAttachments from '@/modules/pipeline/opportunity/FileAttachments';
	import Workflow from '@/modules/pipeline/opportunity/Workflow';
	import ToDo from '@/modules/pipeline/opportunity/ToDo';
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import ScheduledMeetingService from '@/modules/meetings/ScheduledMeetingService';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import LeadInfo from '@/modules/pipeline/opportunity/LeadInfo';
	import NewEmail from '@/modules/communicator/inbox/email/NewEmail';
	import { v4 as uuidv4 } from 'uuid';
	import HDateTime from '@/modules/utils/HDateTime';
	import ConfirmModal from '@/components/ConfirmModal';
	import AutomationList from '@/modules/workflow/AutomationList';
	import editor from '@tinymce/tinymce-vue';
  import marked from "marked";

	export default {
		name: 'OpportunityDetail',
		isRightModal: true,

		props: ['id', 'pipelineStages', 'defaultStatusId', 'clientId'],

		components: {
			editor,
			AutomationList,
			LeadInfo,
			ToDo,
			Workflow,
			FileAttachments,
			Comments,
			CustomField,
			CurrencyInput,
			DateSelector,
			ClientAvatar,
		},

		data: function() {
			return {
				currentView: 'Overview',
				opportunity: null,
				isReady: false,
				opportunityService: new OpportunityService(),
				pipelineService: new PipelineSettingsService(),
				formSubmissionService: new FormSubmissionService(),
				scheduledMeetingService: new ScheduledMeetingService(),
				settingsService: new PipelineSettingsService(),
				clientMenu: false,
				clientFilter: null,
				pipeStages: this.pipelineStages,
				timePeriods: [
					{ value: 'OneTime', label: 'One time', label2: null, hasLength: false },
					{ value: 'Day', label: 'Per day', label2: 'days', hasLength: true },
					{ value: 'Week', label: 'Per week', label2: 'weeks', hasLength: true },
					{ value: 'Month', label: 'Per month', label2: 'months', hasLength: true },
					{ value: 'Quarter', label: 'Per quarter', label2: 'quarters', hasLength: true },
					{ value: 'SemiAnnual', label: 'Per six months', label2: 'period', hasLength: true },
					{ value: 'Year', label: 'Per year', label2: 'years', hasLength: true },
				],
        mceConfig: {
          menubar: false,
          inline: true,
          paste_as_text: false,
          paste_data_images: true,
          table_style_by_css: true,
          statusbar: false,
          placeholder: 'Add more detail to this opportunity...',
          extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
          forced_root_block: 'div',
          plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
          contextmenu: 'paste | link image | table',
          indentation: '12pt',
          skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
          content_css: this.$vuetify.theme.dark ? 'dark' : '',
          toolbar: [
            'undo redo ' +
            'bold underline backcolor forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist formatting_group',
          ],
          content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",
          toolbar_groups: {
            formatting_group: {
              icon: 'format',
              tooltip: 'Formatting',
              items: 'italic fontsizeselect fontselect | numlist indent outdent table | removeformat | link unlink',
            },
          fontsize_formats: "10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 40px 48px",
            media_group: {
              icon: 'embed',
              tooltip: 'Media',
              items: ' link unlink media'
            }
          },
        },
			};
		},

		mounted() {
			this.initialize();

			if (!this.pipeStages) {
				this.getPipelineStages();
			}
		},

		beforeDestroy() {
			this.unregisterEventListener();
		},

		methods: {
			initialize: function() {
				if (this.id) {
					this.getOpportunity();
				} else {
					this.createOpportunity();
				}
			},

      convertMarkdown: function() {
        if(this.opportunity.format === 'Markdown') {
          this.opportunity.description = this.formatMarkdown(this.opportunity.description);
          this.opportunity.format = 'HTML';
        }
      },

      formatMarkdown: function(comment) {
        let m = marked(comment, { breaks: true });
        m = m.replaceAll('<a href', '<a target="_blank" href');
        return m;
      },

			sendEmail: function(toEmail) {
				let toList = [];

				if (toEmail) {
					toList.push(toEmail);
				}

				this.$store.state.globalModalController
					.openModal(NewEmail, { toList: toList, opportunityId: this.opportunity.id }, true, true, false, true)
					.then((res) => {
						if (res) {
							this.opportunity.workflow.push({
								id: uuidv4(),
								itemId: res.thread.id,
								itemType: 'EmailThread',
								timestamp: HDateTime.now().toISO(),
							});
						}
					});
			},

			getPipelineStages: function() {
				this.settingsService.getPipelineStages().then((res) => (this.pipeStages = res.data));
			},

			getShareLink() {
				let copyText = document.getElementById('opportunityLink');
				copyText.type = 'text';
				copyText.select();
				document.execCommand('copy');
				copyText.type = 'hidden';

				this.$store.commit('info', 'Link copied to clipboard');
			},

			getCustomValue: function(id) {
				let value = this.opportunity.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.opportunity.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.opportunity.customValues.splice(ix, 1, value);
					} else {
						this.opportunity.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.opportunity.customValues.push(value);
				}
			},

			setView: function(view) {
				this.currentView = view;
			},

			async setClient(client) {
				if (client.id === '+') {
					let leadGen = await this.getNewClientDefault();
					let binding = {
						leadGen: leadGen,
						customValues: this.opportunity.customValues,
						clientList: this.$store.getters.clients,
					};
					this.$store.state.globalModalController.openModal(ClientCreate, binding).then((res) => {
						if (res) {
							this.opportunity.clientId = res.id;
						}
					});
				} else {
					this.opportunity.clientId = client.id;
				}

				this.clientMenu = false;
			},

			async getNewClientDefault() {
				let inScope = this.opportunity.workflow.filter((w) => w.itemType === 'Form' || w.itemType === 'ScheduledMeeting');
				inScope.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
				let scaffolding = {};

				for (let i = 0; i < inScope.length; i++) {
					let formData = {};
					if (inScope[i].itemType === 'ScheduledMeeting') {
						formData = await this.getScheduledMeeting(inScope[i].itemId);
					} else if (inScope[i].itemType === 'Form') {
						formData = await this.getFormSubmission(inScope[i].itemId);
					}
					formData = Object.entries(formData).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});
					Object.assign(scaffolding, formData);
				}

				let oppFormData = Object.entries(this.opportunity.formData).reduce(
					(a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
					{}
				);
				Object.assign(scaffolding, oppFormData);

				return {
					formData: scaffolding,
				};
			},

			async getScheduledMeeting(id) {
				try {
					let result = await this.scheduledMeetingService.getScheduledMeeting(id);
					return result.data.formData;
				} catch (exception) {
					console.log('error', exception);
					return {};
				}
			},

			async getFormSubmission(id) {
				try {
					let result = await this.formSubmissionService.getFormSubmission(id);
					return result.data.formData;
				} catch (exception) {
					console.log('error', exception);
					return {};
				}
			},

			setSentiment: function(sentiment) {
				this.opportunity.sentiment = sentiment;
			},

			setName: function(event) {
				if (event.target && event.target.innerText) {
					this.opportunity.name = event.target.innerText;
					event.target.innerText = this.opportunity.name;
				}
			},

			setPeriods: function(event) {
				if (event.target && event.target.innerText) {
					let value = parseInt(event.target.innerText, 10);
					if (isNaN(value)) {
						value = 0;
					}
					this.opportunity.periods = value;
					event.target.innerText = value;
				}
			},

			handleClose: function() {
				this.save(true);
			},

			updateStatus: function(stage) {
				this.opportunity.statusId = stage.id;
			},

			save: function(close = true) {
				let oppPromise = this.opportunityService.updateOpportunity(this.opportunity.id, this.opportunity);
				if(close){
					this.$emit('result',this.opportunity);
				}else{
					oppPromise.then((res) => this.opportunity = res.data);
				}
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Confirm delete',
					bodyText: `Are you sure you want to delete the "${this.opportunity.name}" opportunity?  This cannot be undone.`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.opportunityService.deleteOpportunity(this.opportunity.id).then(() => {
							this.opportunity.deleted = true;
							this.$emit('result', this.opportunity);
						});
					}
				});
			},

			cancel: function() {
				this.$emit('result');
			},

			createOpportunity: function() {
				let opportunity = {
					statusId: this.defaultStatusId ? this.defaultStatusId : this.pipeStages.stages[0].id,
					clientId: this.clientId,
				};
				this.opportunityService
					.createOpportunity(opportunity)
					.then((res) => this.setOpportunity(res.data))
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => (this.isReady = true));
			},

			getOpportunity: function() {
				this.opportunityService
					.getOpportunity(this.id)
					.then((res) => this.setOpportunity(res.data))
					.catch((err) => {
						this.$store.commit('error', err.response.data.message)
						this.$emit('result');
					})
					.finally(() => (this.isReady = true));
			},

			setOpportunity: function(opportunity) {
				this.opportunity = opportunity;
				this.registerEventListener();
				if (!this.opportunity.formData) {
					this.setFormDataOnOpportunity();
				}
			},

			async setFormDataOnOpportunity() {
				let result = await this.getNewClientDefault();
				if (Object.keys(result.formData).length > 0) {
					this.opportunity.formData = result.formData;
				}
			},

			registerEventListener: function() {
				let channel = `${this.$store.getters.getMessageChannelPrefix}.opp-${this.opportunity.id}`;
				this.$store.state.eventBus.$on(channel, this.eventHandler);
			},

			unregisterEventListener: function() {
				let channel = `${this.$store.getters.getMessageChannelPrefix}.opp-${this.opportunity.id}`;
				this.$store.state.eventBus.$off(channel, this.eventHandler);
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'WorkflowItem') {
					this.opportunity.workflow.push(event.message);
				} else if (event.userMetadata === 'Opportunity') {
					this.opportunity = event.message;
				}
			},
		},

		computed: {
			opportunityLink: function() {
				return (
					document.location.protocol +
					'//' +
					document.location.host +
					'/pipeline/opportunities?opportunityId=' +
					this.opportunity.id
				);
			},

			timePeriod: function() {
				return this.timePeriods.find((t) => t.value === this.opportunity.timePeriod);
			},

			assignedClient: function() {
				if (this.opportunity.clientId) {
					return this.$store.getters.getClientById(this.opportunity.clientId);
				} else {
					return null;
				}
			},

			fixedClientList: function() {
				let result = [...this.$store.getters.clients];
				result.sort((a, b) => a.name.localeCompare(b.name));
				result.unshift({ id: '+', name: '+ Add new client/prospect' });
				result.unshift({ id: null, name: '-- Clear --' });

				return result;
			},

			filteredClientList: function() {
				if (this.clientFilter) {
					return this.fixedClientList.filter(
						(c) => c.name && c.name.toLowerCase().includes(this.clientFilter.toLowerCase())
					);
				} else {
					return this.fixedClientList;
				}
			},

			unknownStage: function() {
				return {
					id: 'unknown',
					label: '[Unknown status]',
					hexColor: '#000000',
				};
			},

			stage: function() {
				let stage = this.pipeStages.stages.find((s) => s.id === this.opportunity.statusId);
				if (!stage) {
					stage = this.unknownStage;
				}
				return stage;
			},

			stages: function() {
				if (this.pipeStages) {
					return this.pipeStages.stages;
				} else {
					return [];
				}
			},

			closedStages: function() {
				return this.stages.filter((s) => s.stageType === 'ClosedWon' || s.stageType === 'Complete').map((s) => s.id);
			},

			fields: function() {
				console.log(this.$store.state.pipelineFields);
				if (this.$store.state.pipelineFields) {
					return this.$store.state.pipelineFields.fields;
				} else {
					return [];
				}
			},

			sentiment: function() {
				return this.confidenceList.find((c) => c.value === this.opportunity.sentiment);
			},

			confidenceList: function() {
				let result = [];

				result.push({ value: 0, icon: 'sentiment_very_dissatisfied', color: '#BA4A00' });
				result.push({ value: 1, icon: 'sentiment_dissatisfied', color: '#D68910' });
				result.push({ value: 2, icon: 'sentiment_neutral', color: '#2ECC71' });
				result.push({ value: 3, icon: 'sentiment_satisfied', color: '#1E8449' });
				result.push({ value: 4, icon: 'sentiment_very_satisfied', color: '#267AE1' });

				return result.reverse();
			},
		},
	};
</script>

<style scoped lang="scss">
	.editable-div {
		&:focus {
			outline: 1px solid var(--v-gray_30-base);
		}
	}

	.opportunityStage {
		white-space: nowrap;

		.stageBox {
			margin-right: 8px;
			width: 20px;
			height: 20px;
			border-radius: 4px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}

	#opportunity-detail {
		padding-left: 12px;
		padding-top: 18px;

		.overview-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: fit-content;
			color: var(--v-gray_70-base);
			font-size: 14px;
			font-weight: 500;
			width: 150px;
			min-width: 150px;
			max-width: 150px;
			margin-right: 16px;
		}

		.overview-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 14px;
			font-weight: 500;
			flex: 1 1 auto;
		}
	}
</style>
