<template>
	<v-menu :close-on-click="true" :close-on-content-click="false" v-model="showMenu">
		<template v-slot:activator="{ on }">
			<div
				class="row-format pointer font-14 brand-medium font-secondary align-center gap-1 px-3 py-1 nowrap"
				style="background-color: var(--v-gray_10-base); border: 1px solid var(--v-gray_20-base); border-radius: 4px"
				v-on="on"
			>
				<v-icon class="" size="20" color="secondary">folder</v-icon>{{ folder }}
			</div>
		</template>
		<div class="more-menu">
			<div class="pa-2">
				<v-text-field v-model="newFolder" hide-details dense outlined placeholder="Add new..." @blur="addNewFolder" @keydown.enter="addNewFolder"></v-text-field>
			</div>
			<div v-for="(thisFolder, index) in folderList" :key="index" class="more-menu-item" @click="setFolder(thisFolder)">
				{{ thisFolder }}
			</div>
			<div class="more-menu-item" @click="setFolder(null)">--</div>
		</div>
	</v-menu>
</template>

<script>
	export default {
		name: 'FolderSelector',

		props: ['value', 'folders'],

		components: {},

		data: function() {
			return {
				newFolder: null,
				folderList: [... this.folders],
				folder: this.value,
				showMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setFolder: function(thisFolder){
				this.folder = thisFolder;
				this.showMenu = false;
			},

			addNewFolder: function(){
				if(this.newFolder) {
					this.folder = this.newFolder;
					this.folderList.push(this.newFolder);
					this.newFolder = null;
				}
				this.showMenu = false;
			}
		},

		watch: {
			folder: function() {
				this.$emit('input', this.folder);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
