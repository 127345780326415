<template>
	<div>
		<div class="row-format align-center py-3 px-5" style="border-bottom: 1px solid var(--v-gray_30-base)">
			<div class="font-14 brand-medium">
				<input
						style="width: 250px; color: var(--v-gray_80-base)"
						type="text"
						v-model="search"
						placeholder="Search..."
				/>
			</div>
			<plus-button class="ml-auto" @click="$refs.ProductTable.createNewProduct()"></plus-button>
		</div>
		<products-table ref="ProductTable" :filter="search"></products-table>
	</div>
</template>

<script>
import ProductsTable from "@/modules/invoices/InvoiceRenderer/ProductsTable";
import PlusButton from "@/components/PlusButton";
export default {
	name: "ProductList",

	props: [],

	components: {ProductsTable, PlusButton},

	data: function () {
		return {
			search: null
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>