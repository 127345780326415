<template>
	<div class="column-format fill-height pa-4">
		<notes ref="notes" :client="client" :filter="{}" class="mb-8"></notes>
	</div>
</template>

<script>
	import Notes from '@/modules/clients/detail/overview/Notes';

	export default {
		name: 'ClientNotes',

		props: ['client', 'filter'],

		components: { Notes },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addNew: function() {
				this.$refs.notes.addNewNote()
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
