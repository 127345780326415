<template>
	<div>
		<custom-fields-editor :type="type" :max-per-card="cardCount" :key="path"></custom-fields-editor>
	</div>
</template>

<script>
	import CustomFieldsEditor from "@/modules/fields/CustomFieldsEditor";
	export default {
		name: "CustomFieldRouter",

		props: ['path'],

		components: {CustomFieldsEditor},

		data: function () {
			return {}
		},

		mounted() {
			if(!this.path){
				this.$router.push('/custom-fields/clients');
			}
		},

		beforeDestroy() {
		},

		methods: {},

		computed: {
			cardCount: function(){
				if(this.type === 'Client'){
					return 0
				}else if(this.type === 'Contact'){
					return 0
				}else if(this.type === 'Project'){
					return 0
				}else if(this.type === 'Pipeline'){
					return 0
				}else if(this.type === 'Deliverable'){
					return 2
				}else{
					return 0;
				}
			},


			type: function(){
				if(this.path === 'clients') {
						return 'Client';
				}else if(this.path === 'contacts'){
						return 'Contact';
				}else if(this.path === 'projects'){
						return 'Project'
				}else if(this.path === 'tasks') {
					return 'Deliverable';
				}else if(this.path === 'pipeline'){
					return 'Pipeline'
				}else{
					return 'Client';
				}
			}
		},

	}
</script>

<style scoped lang="scss"></style>
