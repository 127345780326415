<template>
	<div class="column-format fill-height" v-if="initialized">
		<div class="row-format mt-2 mb-2 mx-3" v-if="invoices.length">
			<div class="kpi-box mr-3">
				<div class="fit text-left">Total invoiced</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(totalInvoiced, true, true, currency) }}</div>
			</div>
			<div class="kpi-box">
				<div class="fit text-left">Total received</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(totalReceived, true, true, currency) }}</div>
			</div>
		</div>

		<div class="row-format centered fill-height" style="flex: 1" v-if="!invoices.length">
			<empty-view
				header="No invoices yet"
				:body="`You haven’t sent any invoices for ${project.name} yet. Create an invoice and get paid for your work now.`"
				cta="Send an invoice"
				video-header="See how it works"
				video-body="Learn how to add the work you’ve done into an invoice. You can also see how to set up automatic invoices."
				video-cta="Watch the tutorial"
				video-id="3dIDz5H2TaE"
				@cta-clicked="addNew()"
			></empty-view>
		</div>

		<div style="width: 100%">
			<div class="ml-auto mr-3" style="width: 70px" v-if="currencies.length > 1">
				<v-select
					style="margin-top: 0px; margin-bottom: 0px"
					:items="currencies"
					v-model="currency"
					dense
					hide-details
				></v-select>
			</div>
		</div>

		<div v-if="invoices.length">
			<v-data-table
				:headers="headers"
				:items="filteredInvoices"
				:hide-default-footer="true"
				sort-by="dateCreated"
				:sort-desc="true"
				:fixed-header="true"
				style="cursor: pointer"
				@click:row="openInvoice($event)"
				:items-per-page="-1"
			>
				<template v-slot:item.dateCreated="{ item }">
					<span v-if="item.dateCreated" class="brand-semilight">{{
						DateTime.fromISO(item.dateCreated).toFormat('DD')
					}}</span>
				</template>

				<template v-slot:item.dateDue="{ item }">
					<span v-if="item.dateDue" class="brand-semilight">{{ DateTime.fromISO(item.dateDue).toFormat('DD') }}</span>
				</template>

				<template v-slot:item.invoiceNumberFormatted="{ item }">
					<span class="brand-semilight">{{ item.invoiceNumberFormatted }}</span>
				</template>

				<template v-slot:item.total="{ item }">
					<div class="brand-semilight">
						{{ $formatters.dollars(item.amount ? item.amount : 0, true, true, item.currency) }}
					</div>
					<div class="font-12 font-gray_70" v-if="item.localAmount && item.currency !== $store.state.defaultCurrency">
						{{
							$formatters.dollars(item.localAmount ? item.localAmount : 0, true, true, $store.state.defaultCurrency)
						}}
					</div>
				</template>

				<template v-slot:item.status="{ item }">
					<div class="row-format align-center">
						<div class="brand-semilight font-12" v-html="getStatus(item)"></div>
					</div>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyView from '@/components/EmptyView';
	import ProjectService from '@/modules/projects/ProjectService';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import InvoiceService from "@/modules/invoices/InvoiceService";

	export default {
		name: 'InvoiceList',
		components: { EmptyView },
		props: ['project', 'filter'],

		data() {
			return {
				projectService: new ProjectService(),
				invoiceService: new InvoiceService(),
				lineItems: [],
				initialized: false,
				DateTime: DateTime,
				currency: this.project.client.currency ? this.project.client.currency : this.$store.state.defaultCurrency,
			};
		},

		mounted() {
			this.getLineItemsForProject();
		},

		methods: {
			getLineItemsForProject: function () {
				this.projectService.getProjectPayments(this.project.id, this.currency, true).then((res) => {
					this.lineItems.splice(0, this.lineItems.length);
					this.lineItems.push(...res.data);
					this.initialized = true;
				}).finally(() => this.$store.commit('stopLoading'));
			},

			addNew: function () {
				this.$store.commit('startLoading');
				this.invoiceService
					.createInvoice(this.project.client.id, { invoiceType: 'STANDARD' })
					.then((res) => {
						this.openInvoice({
							invoiceId: res.data.id,
							clientId: res.data.clientId
						})
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			openInvoice: function (invoice) {
				let binding = {
					clientId: invoice.clientId,
					id: invoice.invoiceId,
					quick: false,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, false, true).then((res) => {
					if (!res) return;
					this.getLineItemsForProject();
				});
			},

			formatDate(date) {
				return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
			},

			getStatus(invoice) {
				let background, color, label;

				switch (invoice.status) {
					case 'PAID': {
						background = '--v-success_10-base';
						color = '--v-gray_80-base';
						label = 'Paid';
						break;
					}

					case 'PARTIAL':
					case 'SENT': {
						if (DateTime.fromISO(invoice.dateDue).endOf('day') < DateTime.now()) {
							background = '--v-alert_10-base';
							color = '--v-gray_80-base';
							label = 'Late';
						} else if (invoice.status === 'PARTIAL') {
							background = '--v-warning_10-base';
							color = '--v-gray_80-base';
							label = invoice.status === 'PARTIAL' ? 'Partially paid' : 'Pending';
						} else {
							background = '--v-blue_5-base';
							color = '--v-gray_80-base';
							label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
						}

						break;
					}

					default: {
						background = '--v-gray_20-base';
						color = '--v-gray_80-base';
						label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
						break;
					}
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},
		},

		computed: {
			invoices: function () {
				let grouped = this.$formatters.groupBy(this.lineItems, 'invoiceId');
				let result = [];

				for (const value of Object.values(grouped)) {
					let invoice = JSON.parse(JSON.stringify(value[0]));
					delete invoice.retainerPeriod;
					invoice.amount = 0;
					invoice.localAmount = 0;

					for (let i = 0; i < value.length; i++) {
						invoice.amount += value[i].amount;
						invoice.localAmount += value[i].localAmount;
					}
					result.push(invoice);
				}

				return result;
			},

			totalInvoiced: function () {
				return this.invoices.filter((i) => i.invoiceStatus !== 'DRAFT').reduce((sum, { localAmount }) => sum + localAmount, 0);
			},

			totalReceived: function () {
				return this.invoices
					.filter((i) => i.invoiceStatus === 'PAID')
					.reduce((sum, { localAmount }) => sum + localAmount, 0);
			},

			currencies: function () {
				let clientCurrency = this.project.client.currency;
				let defaultCurrency = this.$store.state.defaultCurrency;
				let result = [defaultCurrency];
				if (clientCurrency && clientCurrency !== defaultCurrency) {
					result.push(clientCurrency);
				}
				return result;
			},

			filteredInvoices: function () {
				let result = [...this.invoices];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let earliest, latest;
				let lateStatus = ['SENT', 'PARTIAL'];

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
						earliest = DateTime.fromISO(this.filter.earliest);
						latest = DateTime.fromISO(this.filter.latest);
					} else if (this.filter.dateSpecifier !== 'between') {
						let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
						earliest = helper.earliest;
						latest = helper.latest;
					}
				}

				return result
					.filter((i) => {
						if (!search || (search && i.invoiceNumber.toString().startsWith(search))) {
							return true;
						} else {
							return false;
						}
					})
					.filter((i) => {
						if (earliest && latest) {
							if (!i.dateDue && !i.dateCreated) {
								return false;
							} else {
								let dateDue = DateTime.fromISO(i.dateDue);
								let dateCreated = DateTime.fromISO(i.dateCreated);
								if (dateDue >= earliest && dateDue <= latest) {
									return true;
								}else if(dateCreated >= earliest && dateCreated <= latest){
									return true;
								} else {
									return false;
								}
							}
						} else {
							return true;
						}
					})
					.filter((i) => {
						if (this.filter.invoiceStatus && this.filter.invoiceStatus.length) {
							let lateSearch = this.filter.invoiceStatus.includes('LATE');
							if (this.filter.invoiceStatus.includes(i.status)) {
								return true;
							} else if (lateSearch && lateStatus.includes(i.status)) {
								let dateDue = DateTime.fromISO(i.dateDue);
								if (dateDue < DateTime.now()) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			headers: function () {
				let result = [
					{ text: this.$t('invoice.amount'), value: 'total' },
					{ text: this.$t('invoice.invoice-number'), value: 'invoiceNumberFormatted' },
					{ text: this.$t('invoice.date-created'), value: 'dateCreated' },
					{ text: this.$t('invoice.date-due'), value: 'dateDue' },
					{ text: this.$t('invoice.status'), value: 'status' },
				];

				return result;
			},
		},

		watch: {
			currency: function(){
				this.$store.commit('startLoading')
				this.getLineItemsForProject()
			}
		},
	};
</script>

<style lang="scss">
	.invoice-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}
</style>

<style scoped lang="scss">
	.action-menu ::v-deep {
		.primary-action {
			border: 0px !important;
		}
		.menu-activator {
			border: 0px !important;
		}
	}
</style>
