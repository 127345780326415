<template>
	<div class="text-left" v-if="ourThread" style="height: 100%">
		<div class="row-format">
			<div>
				<v-btn icon @click="$emit('back')"><v-icon class="material-symbols-rounded" color="black">arrow_back</v-icon></v-btn>
			</div>
			<div class="ml-auto row-format align-center">
				<div class="mr-2" v-if="ourFolder !== 'Inbox'">
					{{ ourFolder }}
					<span v-if="ourFolder === 'Snoozed'"
						>until {{ HDateTime.fromISO(ourThread.snoozedUntil).toLocaleString(HDateTime.DATETIME_MED) }}</span
					>
				</div>
				<inbox-actions
					:size="24"
					@return-to-inbox="returnToInbox()"
					@snooze="snooze($event)"
					@archive="archive()"
					@unread="markThreadAsRead()"
					:unread="ourThread.unread > 0"
					:snooze-options="snoozeOptions"
					:folder="ourFolder"
					item-type="MessageThread"
					:item-id="ourThread.id"
				></inbox-actions>
			</div>
		</div>
		<div class="pa-2 column-format" style="height: 100%;">
			<div class="row-format align-center">
				<client-avatar v-if="!clientId && client" :client="client"></client-avatar>
				<div :class="!clientId && client ? 'ml-2' : ''">{{participants.join(" & ")}}</div>
			</div>
			<div :id="`thread-${ourThread.id}`" style="height: calc(100% - 150px); max-height: calc(100% - 150px); overflow-y: scroll" class="show-scrollbar mb-3 mt-5">
				<div class="row-format centered pointer font-12 font-gray_70 mt-4" v-if="messages.length >= recordsPerFetch"><div @click="getOlderMessages()">Older messages</div></div>

				<div v-for="(message, ix) in messages" :key="message.id" class="my-2 row-format pr-4">
					<div class="column-format" style="width: 100%">
						<div
								:class="`font-10 font-gray_70 mt-2 ${message.direction === 'Out' ? 'ml-auto mr-1' : 'ml-1'}`"
								v-if="showTimeStamp(ix)"
						>
							{{ $formatters.formatTimeAgo(message.timestamp) }}
						</div>
						<div :class="message.direction">
							<div v-if="message.message && message.direction === 'In'">{{ message.message }}</div>
							<div v-if="message.message && message.direction === 'Out'" v-tippy="{content: $store.getters.getUserById(message.userId).firstName}">{{ message.message }}</div>
							<div v-for="s3File in message.s3Media" :key="s3File.path + s3File.fileName">
								<s3-file-handler :s3-file="s3File"></s3-file-handler>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<v-textarea
						dense
						hide-details
						outlined
						rows="1"
						no-resize
						auto-grow
						autofocus
						v-model="newMessage"
						@keydown.enter.exact.prevent
						@keyup.enter.exact="sendMessage"
				>
					<template v-slot:append>
						<div
								class="font-secondary pointer py-1 pl-2"
								style="border-left: 1px solid var(--v-gray_50-base); margin-top: 2px"
								@click="sendMessage"
						>
							Send
						</div>
					</template>
				</v-textarea>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import MessageThreadMixin from "@/modules/communicator/inbox/messages/MessageThreadMixin";
	import {DateTime} from "luxon";
	import libPhoneNumber from "google-libphonenumber";
	import S3FileHandler from "@/modules/communicator/inbox/messages/S3FileHandler";

	export default {
		name: 'MessageThreadDetail',

		props: ['thread', 'snoozeOptions', 'folder','inModal','clientId'],

		components: {S3FileHandler, ClientAvatar, InboxActions },

		mixins: [MessageThreadMixin],

		data: function () {
			return {

				messages: [],
				newMessage: null,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				page: 0,
				recordsPerFetch: 50,
			};
		},

		mounted() {
			this.ourThread = { ...this.thread };
			this.ourFolder = this.folder;
			this.getMessages();
			this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessage`, this.handleNewMessage);
			this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessageStatus`, this.handleMessageStatus);

			if(this.ourThread.unread > 0){
				this.markThreadAsRead();
			}
		},

		beforeDestroy() {
			this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessage`, this.handleNewMessage);
			this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessageStatus`, this.handleMessageStatus);
		},

		methods: {
			getOlderMessages: function(){
				this.page++;
				this.getMessages();
			},

			getMessages: function () {
				this.communicatorService.getMessagesInThread(this.ourThread.id,this.page,this.recordsPerFetch).then((res) => {
					if(this.page === 0){
						this.messages.splice(0,this.messages.length);
					}

					this.messages.unshift(... res.data);

					if(this.page === 0) {
						this.$nextTick(() => this.scrollToBottom());
					}
				});
			},

			sendMessage: function () {
				let payload = {
					threadId: this.ourThread.id,
					from: this.ourThread.userPhone,
					message: this.newMessage,
				};

				this.communicatorService.sendMessage(payload);
				this.newMessage = null;
			},


			handleNewMessage: function (event) {
				let thread = event.message.thread;

				if(thread.id !== this.ourThread.id){
					return;
				}

				let message = event.message.message;
				this.communicatorService.markThreadAsRead(thread.id);
				this.messages.push(message);
				this.$nextTick(() => this.scrollToBottom());
			},

			handleMessageStatus: function (event) {
				let threadId = event.message.threadId;

				if(threadId !== this.ourThread.id) {
					return;
				}

				let messageId = event.message.id;
				let status = event.message.status;
				let statusReason = event.message.statusReason;

				let messageIx = this.messages.findIndex((m) => m.id === messageId);
				if (messageIx > -1) {
					let ourMessage = this.messages[messageIx];
					ourMessage.status = status;
					ourMessage.statusReason = statusReason;
					this.messages.splice(messageIx, 1, ourMessage);
					this.messageUpdate++;
				}
			},

			scrollToBottom: function () {
				let div = document.getElementById(`thread-${this.ourThread.id}`);
				if (div) {
					div.scrollTop = div.scrollHeight;
				}
			},

			showTimeStamp: function (index) {
				if (index === 0) {
					return true;
				} else {
					let prev = DateTime.fromISO(this.messages[index - 1].timestamp);
					let curr = DateTime.fromISO(this.messages[index].timestamp);

					if (curr.diff(prev, ['minutes']).minutes > 10) {
						return true;
					} else {
						return false;
					}
				}
			},
		},

		watch: {
			contacts: {
				immediate: true,
				handler: function(val){
					this.$emit('set-contacts',val);
				}
			},
		},

		computed: {
			userId: function () {
				return this.$store.getters.getLoggedInUserId;
			},

			client: function () {
				let result = this.contacts.filter(c => c.clientId);
				if(result.length){
					return this.$store.state.clientCache.find(c => c.id === result[0].clientId);
				}else{
					return null;
				}
			},

			contacts: function(){
				let result = [];
				this.thread.participants.forEach((p) => {
					let c = this.$store.getters.getContactByPhone(p.phone);
					if(c){
						result.push(c);
					}else{
						result.push({
							id: null,
							email: null,
							phone: p.phone,
							firstName: null,
							lastName: null
						})
					}
				});
				console.log('setting contacts');
				return result;
			},

			participants: function () {
				return this.thread.participants.map((p) => {
					let contact = this.$store.getters.getContactByPhone(p.phone);
					if(contact){
						return contact.fullName;
					}else{
						try {
							let phone = this.PhoneUtil.parse(p.phone);
							return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
						}catch(err){
							return p.phone;
						}
					}
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.In {
		background-color: var(--v-gray_20-base);
		color: var(--v-black-base);
		border-radius: 12px 12px 12px 0px;
		padding: 8px 12px;
		width: 90%;
		white-space: pre-wrap;
	}

	.Out {
		background-color: var(--v-secondary-base);
		color: var(--v-white-base);
		border-radius: 12px 12px 0px 12px;
		padding: 8px 12px;
		width: 90%;
		margin-left: auto;
		white-space: pre-wrap;
	}

	.new-message {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		padding: 8px;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
