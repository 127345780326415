import AuthService from "@/modules/auth/AuthService";
import mixpanel from 'mixpanel-browser';
import store from '@/store';

export default {
    install(Vue) {
        mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY,{
            debug: process.env.VUE_APP_HECTIC_MODE === 'production' ? false : true,
            ignore_dnt:true,
            batch_flush_interval_ms:500
        });

        Object.defineProperties(Vue.prototype, {
            $track: {
                get() {
                    return {
                        identify: function(user) {
                            if(user.isSupport){
                                return;
                            }

                            console.log('identify called',user);

                            mixpanel.identify(user.userId);
                            mixpanel.people.set({$email:user.email, $name: user.firstName + ' ' + user.lastName});

                            AuthService.trackEvent('session started');

                            window.hj('identify', user.email, {
                                'email': user.email,
                            });
                        },

                        conversion: function(){
                            window.gtag('event', 'conversion', { send_to: 'AW-465538132/6DvzCJvb__MBENSY_t0B' });
                        },

                        captureDemographics: function(userInfo){
                            userInfo.channel = mixpanel.get_property('channel');
                            userInfo.utmSource = mixpanel.get_property('utm_source_first');
                            userInfo.utmMedium = mixpanel.get_property('utm_medium_first');
                            userInfo.utmCampaign = mixpanel.get_property('utm_campaign_first');
                            userInfo.utmTerm = mixpanel.get_property('utm_term_first');
                            userInfo.utmContent = mixpanel.get_property('utm_content_first');
                            userInfo.detectedDevice = mixpanel.get_property('detected_device');
                            AuthService.trackDemographics(userInfo);
                        },

                        userDelete: function(){
                            window.Intercom('update', {
                                subscription: 'DELETED',
                            });
                            AuthService.trackEvent("account-deleted", {});
                        },

                        record: function(event,props){
                            if(store.getters.isSupportMode){
                                return;
                            }
                            mixpanel.track(event.toLowerCase(),props);
                        },

                        recordServerSide: function(event,props){
                            if(store.getters.isSupportMode){
                                return;
                            }
                            AuthService.trackEvent(event.toLowerCase(),props);
                        }
                    };
                },
            },
        });

    }
}