import HttpClient from '../../services/HttpClient';
import store from "@/store";

export default class ProjectService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getAllActiveProjects(active = true, deliverables = false) {
		return this.httpClient
			.get('/projects',{
				params: {
					active:active,
					deliverables:deliverables
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectCount(active = true) {
		return this.httpClient
			.get('/projects/count',{
				params: {
					active:active
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	initializeNewProject(clientId, name) {
		return this.httpClient
			.post(`/projects?clientId=${clientId}&name=${encodeURIComponent(name)}`)
			.then((res) => {
				store.state.eventBus.$emit('created','project')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	initializeNewProjectFromProposal(clientId, name, proposalId) {
		return this.httpClient
			.post(`/projects/fromProposal`, null, {
				params: {
					clientId,
					name,
					proposalId,
				},
			})
			.then((res) => {
				store.state.eventBus.$emit('created','project')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	getProjectsByClient(clientId, deliverables = false) {
		return this.httpClient
			.get(`/clients/${clientId}/projects?deliverables=${deliverables}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectDetail(projectId) {
		return this.httpClient
			.get(`/projects/${projectId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectWithDeliverables(projectId) {
		return this.httpClient
			.get(`/projects/${projectId}/withDeliverables`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectPayments(projectId,currency,allStatus= false) {
		return this.httpClient
			.get(`/projects/${projectId}/payments`,{
				params: {
					currency: currency,
					allStatus:allStatus

				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTimeWorkedForProject(projectId) {
		return this.httpClient
			.get(`/projects/${projectId}/timeWorked`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteDeliverableAttachment(projectId, deliverableId, fileName) {
		return this.httpClient
			.delete(`/projects/${projectId}/deliverables/${deliverableId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(projectId, fileName) {
		return this.httpClient
			.get(`/projects/${projectId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getSignedUrlDeliverable(projectId, deliverableId, fileName) {
		return this.httpClient
			.get(`/projects/${projectId}/deliverables/${deliverableId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateProject(id, project) {
		return this.httpClient
			.put(`/projects/${id}`, project)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchProject(id, data) {
		return this.httpClient
			.patch(`/projects/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteProject(projectId) {
		return this.httpClient
			.delete(`/projects/${projectId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
