<template>
	<div class="row-format align-center text-left py-2 px-2 font-14 thread" @click="$emit('open-thread')">
		<div v-if="thread.unread" class="unread mr-2"></div>
		<div class="column-format" style="width: 100%">
			<div class="row-format font-14 align-center">
				<div class="brand-medium truncate mr-2">{{ participants.join(' & ') }}</div>
				<div class="ml-auto">{{ $formatters.formatTimeAgo(thread.lastMessage) }}</div>
			</div>
			<div class="truncate font-gray_80 font-12">
				{{ thread.lastMessageText }}
			</div>
		</div>
	</div>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';

	export default {
		name: 'Thread',

		props: ['thread'],

		components: {},

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			participants: function () {
				return this.thread.participants.map((p) => {
					let contact = this.$store.getters.getContactByPhone(p.phone);
					if (contact) {
						return contact.fullName;
					} else {
						try {
							let phone = this.PhoneUtil.parse(p.phone);
							return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
						}catch(err){
							return p.phone;
						}
					}
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.thread {
		padding-left: 4px;
		padding-right: 4px;
		border-bottom: 1px solid var(--v-gray_30-base);
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.unread {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		background-color: var(--v-primary-base);
		border-radius: 5px;
	}
</style>
