<template>
	<div>
		<div class="row-format mt-6 ml-1">
			<div class="font-gray_80 brand-medium">Work to be done</div>
			<div class="ml-auto row-format gap-2">
				<v-select hide-details dense outlined v-model="type" :items="types" item-text="label" item-value="value" style="width: 140px" label="Type">
					<template v-slot:selection="{item}">
						<span class="font-12">{{item.label}}</span>
					</template>
				</v-select>
				<v-select hide-details dense outlined v-model="assignedTo" :items="['All','Mine']" style="width:90px" label="Assigned">
					<template v-slot:selection="{item}">
						<span class="font-12">{{item}}</span>
					</template>
				</v-select>
			</div>
		</div>

		<div class="mt-3 pb-3 mx-1 show-scrollbar">
			<div v-if="filteredPastDue.length" class="mb-6">
				<div class="font-14 brand-bold" style="color: var(--v-error-base)"><v-icon class="material-symbols-rounded mb-1" size="20" color="error">warning</v-icon> Past due</div>
				<div v-for="item in filteredPastDue" :key="item.id">
					<deliverable-mini
							v-if="item.type === 'Deliverable'"
							:deliverable="item.deliverable"
							@deliverable-clicked="handleDeliverableClick(item.deliverable)"
					/>
					<opportunity-to-do
							v-if="item.type === 'OpportunityToDo'"
							:opportunity-to-do="item.toDo"
							@todo-clicked="handleOpportunityClick(item.toDo)"
					/>
					<ticket-mini
							v-if="item.type === 'Ticket'"
							:ticket="item.ticket"
							:client="item.ticket.client"
							@ticket-clicked="handleTicketClick(item.ticket)"
					></ticket-mini>
				</div>
			</div>

			<div>
				<div class="font-14 brand-medium font-gray_80">{{ $t('projects.due-today') }}</div>
				<div class="empty-yay" v-if="filteredToday.length === 0">
					&#127780; Nothing left to do today!
				</div>
				<div v-for="item in filteredToday" :key="item.id">
					<deliverable-mini
						v-if="item.type === 'Deliverable'"
						:deliverable="item.deliverable"
						@deliverable-clicked="handleDeliverableClick(item.deliverable)"
					/>
					<opportunity-to-do
						v-if="item.type === 'OpportunityToDo'"
						:opportunity-to-do="item.toDo"
						@todo-clicked="handleOpportunityClick(item.toDo)"
					/>
          <ticket-mini
            v-if="item.type === 'Ticket'"
            :ticket="item.ticket"
            :client="item.ticket.client"
            @ticket-clicked="handleTicketClick(item.ticket)"
          ></ticket-mini>
				</div>
				<div v-if="countComplete(todayList) > 0" @click.stop="showCompletedToday = !showCompletedToday">
					<div class="mt-2 font-gray_80 font-12 pointer">
						<span v-if="!showCompletedToday">+ {{ countComplete(todayList) }}</span
						><span v-else>- Hide</span> Completed
					</div>
				</div>
			</div>

			<div class="mt-6">
				<div class="font-14 brand-medium font-gray_80">{{ $t('projects.due-this') }}</div>
				<div class="empty-yay" v-if="filteredThisWeek.length === 0">
					&#128526; You seem to be all done for the week!
				</div>
				<div v-for="item in filteredThisWeek" :key="item.id">
					<deliverable-mini
						v-if="item.type === 'Deliverable'"
						:deliverable="item.deliverable"
						@deliverable-clicked="handleDeliverableClick(item.deliverable)"
					/>
					<opportunity-to-do
							v-if="item.type === 'OpportunityToDo'"
							:opportunity-to-do="item.toDo"
							@todo-clicked="handleOpportunityClick(item.toDo)"
					/>
          <ticket-mini
              v-if="item.type === 'Ticket'"
              :ticket="item.ticket"
              :client="item.ticket.client"
              @ticket-clicked="handleTicketClick(item.ticket)"
          ></ticket-mini>
				</div>
				<div v-if="countComplete(thisWeekList) > 0" @click.stop="showCompletedThisWeek = !showCompletedThisWeek">
					<div class="mt-2 font-gray_80 font-12 pointer">
						<span v-if="!showCompletedThisWeek">+ {{ countComplete(thisWeekList) }}</span
						><span v-else>- Hide</span> Completed
					</div>
				</div>
			</div>

			<div class="mt-6">
				<div class="font-14 brand-medium font-gray_80">{{ $t('projects.due-next') }}</div>
				<div class="empty-yay" v-if="filteredNextWeek.length === 0">
					&#128640; Nothing on the horizon...
				</div>
				<div v-for="item in filteredNextWeek" :key="item.id">
					<deliverable-mini
						v-if="item.type === 'Deliverable'"
						:deliverable="item.deliverable"
						@deliverable-clicked="handleDeliverableClick(item.deliverable)"
					/>
					<opportunity-to-do
							v-if="item.type === 'OpportunityToDo'"
							:opportunity-to-do="item.toDo"
							@todo-clicked="handleOpportunityClick(item.toDo)"
					/>
          <ticket-mini
              v-if="item.type === 'Ticket'"
              :ticket="item.ticket"
              :client="item.ticket.client"
              @ticket-clicked="handleTicketClick(item.ticket)"
          ></ticket-mini>
				</div>
				<div v-if="countComplete(nextWeekList) > 0" @click.stop="showCompletedNextWeek = !showCompletedNextWeek">
					<div class="mt-2 font-gray_80 font-12 pointer">
						<span v-if="!showCompletedNextWeek">+ {{ countComplete(nextWeekList) }}</span
						><span v-else>- Hide</span> Completed
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DeliverableMini from '@/modules/productivity/work/DeliverableMini';
	import DateTime from '@/modules/utils/HDateTime';
	import DeliverableTwoWeekMixin from '@/modules/productivity/work/DeliverableTwoWeekMixin';
	import OpportunityTwoWeekMixin from '@/modules/productivity/work/OpportunityTwoWeekMixin';
  import TicketTwoWeekMixin from '@/modules/productivity/work/TicketTwoWeekMixin';
	import OpportunityToDo from '@/modules/productivity/work/OpportunityToDo';
  import TicketMini from '@/modules/productivity/work/TicketMini';

	export default {
		name: 'WorkTwoWeek',

		props: [],

		mixins: [DeliverableTwoWeekMixin, OpportunityTwoWeekMixin, TicketTwoWeekMixin],

		components: { OpportunityToDo, DeliverableMini, TicketMini },

		data: function() {
			return {
				filter: null,
				tabHeaders: ['Today', 'This week', 'Next week'],
				today: null,
				endOneWeek: null,
				endTwoWeek: null,
				showCompletedToday: false,
				showCompletedThisWeek: false,
				showCompletedNextWeek: false,
				assignedTo: 'All',
				type: 'All',
				types: [
            {label:'All',value:'All'},
            {label:'Project Tasks',value:'Deliverable'},
            {label:'Pipeline Todos',value:'OpportunityToDo'},
            {label:'Tickets', value:'Ticket'}
        ],
			};
		},

		mounted() {
			this.handleStartUp();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function() {
				this.handleShutDown();
				this.handleStartUp();
			},

			handleShutDown: function() {
				this.handleDeliverableShutdown();
				this.handleOpportunityShutdown();
			},

			handleStartUp: function() {
				this.initializeDates();
				let assignedTo = localStorage.getItem('DELIVERABLE_TWO_WEEKS_ASSIGNED');
				if (assignedTo) {
					this.assignedTo = assignedTo;
				}
				let type = localStorage.getItem('DELIVERABLE_TWO_WEEKS_TYPE');
				if(type){
					this.type = type;
				}
				this.handleDeliverableStartup();
				this.handleOpportunityStartup();
        this.handleTicketStartup();
			},

			initializeDates: function() {
				this.today = DateTime.now().startOf('day');
				this.endOneWeek = this.today.endOf('week');
				this.endTwoWeek = this.endOneWeek.plus({ week: 1 });
			},

			sortDate: function(a, b) {
				let sort = a.luxonDate.toMillis() - b.luxonDate.toMillis();
				if (sort === 0) {
					return this.sortStatus(a, b);
				} else {
					return sort;
				}
			},

			sortStatus: function(a, b) {
				return a.statusIndex - b.statusIndex;
			},

			countComplete: function(list) {
				return list.filter((l) => l.complete).length;
			},

		},

		watch: {
			assignedTo: function(val) {
				localStorage.setItem('DELIVERABLE_TWO_WEEKS_ASSIGNED', val);
			},
			type: function(val){
				localStorage.setItem('DELIVERABLE_TWO_WEEKS_TYPE', val);
			}
		},

		computed: {
			combinedList: function() {
				let result = [];
				if(this.type === 'All' || this.type === 'Deliverable') {
					this.filteredDeliverables.forEach((d) => {
						result.push({
							id: d.id,
							type: 'Deliverable',
							luxonDate: d.luxonDate,
							complete: d.complete,
							statusIndex: d.statusIndex,
							deliverable: d,
						});
					});
				}

				if(this.type === 'All' || this.type === 'OpportunityToDo') {
					this.toDos.forEach((t) => {
						result.push({
							id: t.id,
							type: 'OpportunityToDo',
							luxonDate: t.luxonDate,
							complete: t.complete,
							statusIndex: t.statusIndex,
							toDo: t,
						});
					});
				}

        if(this.type === 'All' || this.type === 'Ticket') {
          this.tickets.forEach((ticket) => {
            if(!ticket.snoozedUntil) {
              result.push({
                id: ticket.id,
                type: 'Ticket',
                status: ticket.status,
                ticket: ticket,
                luxonDate: ticket.luxonDate,
              });
            }
          });
        }
				return result;
			},

			filteredPastDue: function(){
				return this.pastDueList.filter((d) => !d.complete);
			},

			filteredToday: function() {
				return this.todayList.filter((d) => this.showCompletedToday || !d.complete);
			},

			filteredThisWeek: function() {
				return this.thisWeekList.filter((d) => this.showCompletedThisWeek || !d.complete);
			},

			filteredNextWeek: function() {
				return this.nextWeekList.filter((d) => this.showCompletedNextWeek || !d.complete);
			},

			pastDueList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if(d.luxonDate && d.luxonDate < this.today){
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},

			todayList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if(d.luxonDate && d.luxonDate.hasSame(DateTime.local(), "day")){
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},

			thisWeekList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if (d.luxonDate && d.luxonDate > this.today && d.luxonDate <= this.endOneWeek) {
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},

			nextWeekList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if (d.luxonDate && d.luxonDate > this.endOneWeek && d.luxonDate <= this.endTwoWeek) {
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},
		},
	};
</script>

<style scoped lang="scss">
	.week-column {
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f9f9f7 100%), #f0efea;
		border-radius: 8px 8px 0px 0px;
	}

	.deliverable-container {
		display: flex;
		flex-direction: column;
		height: 389px;
		overflow-y: auto;
		margin-top: 10px;
	}

	.small-deliverable-container {
		display: flex;
		flex-direction: column;
		height: 362px;
		overflow-y: auto;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.empty-yay {
		font-size: 14px;
		padding: 12px 4px;
		color: var(--v-gray_70-base);
		border-bottom: 1px solid var(--v-gray_30-base);
	}
</style>
